import React from 'react'
import Sliders from "react-slick";

import { useMediaQuery } from 'react-responsive'


export default function Slider(props) {
    const isMobile = useMediaQuery({
        query: '(max-device-width: 420px)'
    })

    const Action = () => {
        let slideToShow = isMobile ? 1 : 1
        return {
            className: "",
            infinite: true,
            centerPadding: "",
            variableWidth: true,
            slidesToShow: slideToShow,
            swipeToSlide: true,
        }
    }

    let settings = Action(props.setting)
    return (
        <Sliders {...settings}>
            {props.content}
        </Sliders>
    )
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser'

import Api from './Api'

import classes from './SD_Community.module.css'

export default class SD_Community extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                { id: 1, name: "title", content: "Lorem Ipsum Lorem Ipsum?" },
                {
                    id: 2,
                    name: "subtitle",
                    content:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry standard dummy text ever since the 1500s",
                },

            ],

            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
            activeImg: 0
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];
                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    getHtmlParagraph(str) {
        if (str !== "") {
            return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
        }
    }

    toggleExtends = (i) => {
        this.setState({
            activeImg: i
        })
    }

    render() {
        const { contentSection } = this.state

        let runningText, banner, title, content1, content2, gallery, link

        if (this.state.isLoaded) {
            contentSection.forEach((s, i) => {
                switch (i) {
                    case 0:
                        s.sections.widget_contents.forEach(s => {
                            runningText = s.content ? s.content : ''
                        })
                        break;
                    case 1:
                        s.sections.widget_contents.forEach(s => {
                            banner = s ? s.file : require('./assets/default-image.svg')
                        })
                        break;
                    case 2:
                        s.sections.widget_contents.forEach(s => {
                            title = s.title ? s.title : ''
                            const splitStr = s.content.split("#")
                            content1 = this.getHtmlParagraph(splitStr[0] ? splitStr[0] : "")
                            content2 = this.getHtmlParagraph(splitStr[1] ? splitStr[1] : "")
                        })
                        break;
                    case 3:
                        gallery = s.sections.widget_contents.map((s, i) => {
                            let active = this.state.activeImg === i ? classes.activeImg : ''
                            return (
                                <div className={[classes.boxGallery, active].join(' ')} onClick={() => this.toggleExtends(i)}>
                                    <figure><img src={s.file} alt=""></img></figure>
                                </div>
                            )
                        })
                        break;
                    case 4:
                        link = s.sections.widget_contents.map(s => {
                            return (
                                <div className={classes.navBack}>
                                    <Link className={classes.customLink} to={'/sustainable'}>
                                        <div className={'d-flex'}>
                                            <div className={'d-flex'}>
                                                <i className={[classes.arrow, classes.left, classes.customMargin].join(" ")}></i>
                                            </div>
                                            <span className={classes.textBack}>{s.content}</span>
                                        </div>
                                    </Link>
                                </div>
                            )

                        })
                        break;
                    default:
                        break;
                }
            })
        } else if (this.state.isApiLoaded) {

        }

        return (
            <div className={classes.contentSection}>
                <div className={classes.banner}>
                    <img className={classes.imgBanner} src={banner} alt=""></img>
                    <div className={classes.contentRunning}>
                        <p className={classes.textRunning}>{runningText}</p>
                        <div className={classes.underlineAnimate}></div>
                    </div>
                </div>
                <div className={['container', classes.customContainer].join(' ')}>
                    <div className={classes.boxContent}>
                        <p className={classes.titleSection}>{title}</p>
                        <div className={["d-flex",]}>
                            <div className={["w-100 pr-5", classes.textIn].join(" ")}>
                                <span className={classes.content}>{ReactHtmlParser(content1)}</span>
                            </div>
                        </div>
                    </div>

                    <div className={classes.wrapGallery}>
                        {gallery}
                    </div>
                    {link}
                </div>
            </div>
        )
    }
}

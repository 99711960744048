import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

import classes from "./Educations.module.css";
import './Educations.css'

export default class Educations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        { id: 1, name: "Title", content: "What is Lorem Ipsum?" },
        {
          id: 2,
          name: "Content",
          content:
            "Where does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
        },
        {
          id: 4,
          name: "Background Image",
          assets: require("./assets/default-image.svg"),
        },
      ],

      isDetail: false,
    };
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }

  toggleDetail = () => {
    this.setState((prevState) => {
      return { isDetail: !prevState.isDetail };
    });
  };

  getUrlParser(url) {
    if (url !== "") {
      return url.includes("http") ? (
        <a
          className={classes.customLink}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>View Details</p>
        </a>
      ) : (
          <Link className={classes.customLink} to={url}>
            <div className={classes.outerCircle}>
              <div className={classes.innerCircle}></div>
            </div>
            <p className={classes.viewMore}>View Details</p>
          </Link>
        );
    }
  }

  render() {
    const { dummy } = this.state;
    let title,
      desc,
      img,
      content,
      link = null;
    let detailContent,
      contentL1,
      contentL2,
      contentL3,
      contentL4,
      contentL5,
      contentL6,
      contentL7,
      contentL8,
      contentL9,
      contentL10,
      contentL11,
      contentL12,
      contentL13,
      contentL14,
      contentL15,
      contentL16,
      contentL17,
      contentL18 = null;
    let contentBox,
      contentBox1,
      contentBox2,
      contentBox3 = null;
    if (this.props.items) {
      let items = this.props.items;
      title = <p className={classes.title}>{items[0].item.title}</p>;
      desc = (
        <span className={classes.content}>
          {ReactHtmlParser(items[0].item.content)}
        </span>
      );

      let contentImg = items[0].item.assets[0]
        ? items[0].item.assets[0].file
        : require("./assets/default-image.svg");
      img = (
        <img
          className={[classes.imgContent].join(" ")}
          src={contentImg}
          alt=""
        />
      );
      content = (
        <div className={classes.contentDesc}>
          {ReactHtmlParser(items[1].desc.content)}
        </div>
      );
      link = !this.state.isDetail ? (
        // <Link className={classes.link} to={{ pathname: `${items[1].desc.url ? items[1].desc.url : '/'}` }}>
        <div
          className={[classes.contentLink].join(" ")}
          onClick={this.toggleDetail}
        >
          <div className="d-flex">
            <div className={classes.outerCircle}>
              <div className={classes.innerCircle}></div>
            </div>
            <p className={classes.contentLink}>{this.props.readMore}</p>
          </div>
        </div>
      ) : (
          <></>
        );
      // </Link>

      const splitStr = items[0].item.content.split("#");
      desc = (
        <span className={classes.content}>
          {ReactHtmlParser(splitStr[0] ? splitStr[0] : "")}
        </span>
      );
      contentL1 = splitStr[1] ? splitStr[1] : "";
      contentL2 = splitStr[2] ? splitStr[2] : "";
      contentL3 = splitStr[3] ? splitStr[3] : "";
      contentL4 = splitStr[4] ? splitStr[4] : "";
      contentL5 = splitStr[5] ? splitStr[5] : "";
      contentL6 = splitStr[6] ? splitStr[6] : "";
      contentL7 = splitStr[7] ? splitStr[7] : "";
      contentL8 = splitStr[8] ? splitStr[8] : "";
      contentL9 = splitStr[9] ? splitStr[9] : "";
      contentL10 = splitStr[10] ? splitStr[10] : "";
      contentL11 = splitStr[11] ? splitStr[11] : "";
      contentL12 = splitStr[12] ? splitStr[12] : "";
      contentL13 = splitStr[13] ? splitStr[13] : "";
      contentL14 = splitStr[14] ? splitStr[14] : "";
      contentL15 = splitStr[15] ? splitStr[15] : "";
      contentL16 = splitStr[16] ? splitStr[16] : "";
      contentL17 = splitStr[17] ? splitStr[17] : "";
      contentL18 = splitStr[18] ? splitStr[18] : "";

      console.log(contentL5)

      let contentImg2 = items[0].item.assets[1]
        ? items[0].item.assets[1].file
        : require("./assets/default-image.svg");
      let contentImg3 = items[0].item.assets[2]
        ? items[0].item.assets[2].file
        : require("./assets/default-image.svg");
      let contentImg4 = items[0].item.assets[3]
        ? items[0].item.assets[3].file
        : require("./assets/default-image.svg");
      let contentImg5 = items[0].item.assets[4]
        ? items[0].item.assets[4].file
        : require("./assets/default-image.svg");
      let contentImg6 = items[0].item.assets[5]
        ? items[0].item.assets[5].file
        : require("./assets/default-image.svg");
      let contentImg7 = items[0].item.assets[6]
        ? items[0].item.assets[6].file
        : require("./assets/default-image.svg");
      let contentImg8 = items[0].item.assets[7]
        ? items[0].item.assets[7].file
        : require("./assets/default-image.svg");
      let contentImg9 = items[0].item.assets[8]
        ? items[0].item.assets[8].file
        : require("./assets/default-image.svg");

      if (this.props.dataExt) {
        this.props.dataExt.forEach((d, i) => {
          switch (i) {
            case 0:
              contentBox = d.sections.map((s, i) => {
                return (
                  <div className={classes.boxX}>
                    <p className={classes.num}>{s.name}</p>
                    <p className={classes.boxxContent}>{s.content}</p>
                  </div>
                );
              });
              break;
            case 1:
              contentBox1 = d.sections.map((s, i) => {
                return <span>{s.content}</span>;
              });
              break;
            case 2:
              contentBox2 = d.sections.map((s, i) => {
                return (
                  <div className={classes.boxX}>
                    <p className={classes.num1}>{s.name}</p>
                    <p className={classes.boxxContent}>{s.content}</p>
                  </div>
                );
              });
              break;
            case 3:
              contentBox3 = d.sections.map((s, i) => {
                let url = s.assets
                  ? s.assets[0].file
                  : require("./assets/default-image.svg");
                let link = s.url ? this.getUrlParser(s.url) : "/";
                return (
                  <div className={classes.box}>
                    <img src={url} alt=""></img>
                    <div className={classes.boxMask}></div>
                    <div className={classes.contentFront}>
                      <div className={classes.boxContentIn}>
                        <p>{s.title}</p>
                      </div>
                    </div>
                    <div className={classes.contentBack}>
                      <div className={classes.boxContentIn}>
                        <div className="d-flex flex-column text-right mt-auto mb-auto">
                          <p>{ReactHtmlParser(s.content)}</p>
                          <div className="d-flex flex-row justify-content-end position-relative mt-2">
                            {link}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              });
              break;
            default:
          }
        });
      }

      let animate = this.state.isDetail
        ? classes.fadeInBottom
        : classes.fadeOutTop;
      detailContent = this.state.isDetail ? (
        <div className={[classes.detailContent, animate].join(" ")}>
          <div className="section-a">
            <span className={classes.title}>{ReactHtmlParser(contentL1)}</span>

            <>
              <hr />
              <div className={classes.allContentB}>
                <div className={classes.txtParB}>
                  <div>
                    <p className={classes.titleCont}>
                      {ReactHtmlParser(contentL2)}
                    </p>
                    <p className={classes.txtReal}>
                      {ReactHtmlParser(contentL3)}
                    </p>
                  </div>
                </div>
                <div className={classes.txtParB2}>
                  <img className={classes.imgLogo} src={contentImg2} alt=" " />
                </div>
              </div>
            </>
            {/* <div className="d-flex pt-5">
              <div className={classes.boxL50}>
                {ReactHtmlParser(contentL2)}
                {ReactHtmlParser(contentL3)}
              </div>
              <div className={classes.boxR50_Img}>
                <figure>
                  <img src={contentImg2} alt="" />
                </figure>
              </div>
            </div> */}

            <>
              <hr />
              <div className={classes.allContentC}>
                <div className={classes.boxPar}>
                  <div>
                    <p className={classes.titleCont}>
                      {ReactHtmlParser(contentL4)}
                    </p>
                    {/* <p className={classes.txtParC}>
                      {ReactHtmlParser(contentL5)}
                    </p> */}
                  </div>
                </div>
                <div className={classes.absImg}>
                  <img className={classes.imgLogo2} src={contentImg3} alt=" " />
                </div>
              </div>
            </>
            {/* <div className="d-flex pt-5">
              <div className={classes.boxL50}>
                <figure>
                  <img src={contentImg3} alt="" />
                </figure>
              </div>
              <div className={classes.boxR50}>
                <div className={classes.overflowContent}>
                  {ReactHtmlParser(contentL5)}
                </div>
              </div>
            </div> */}

            <>
              <hr />
              <div className={classes.allContentB}>
                <div className={classes.txtParB}>
                  <div>
                    {/* <p className={classes.pArt}>Title</p> */}
                    <p className={classes.titleCont}>
                      {ReactHtmlParser(contentL5)}
                    </p>
                    <p className={classes.txtReal}>
                      {ReactHtmlParser(contentL6)}
                    </p>
                  </div>
                </div>
                <div className={classes.txtParB2}>
                  <img className={classes.imgLogo} src={contentImg4} alt=" " />
                </div>
              </div>
            </>
            {/* <div className="d-flex pt-5">
              <div className={classes.boxL50}>
                <figure>
                  <img src={contentImg4} alt="" />
                </figure>
              </div>
              <div className={classes.boxR50}>
                <div className={classes.overflowContent}>
                  {ReactHtmlParser(contentL7)}
                </div>
              </div>
            </div> */}

            {/* <div className='d-flex pt-5'>
                            <div className={classes.boxL50}>
                                {ReactHtmlParser(contentL8)}
                            </div>
                            <div className={classes.boxR50}>
                                <div className={classes.boxHighlight}>
                                    {ReactHtmlParser(contentL9)}
                                </div>
                            </div>
                        </div> */}

            <>
              <hr />
              <div className={classes.allContentC}>
                <div className={classes.boxPar}>
                  <div>
                    {/* <p className={classes.pArt}>Title</p>
                  <p className={classes.titleCont}>XXX</p> */}
                    <p className={[classes.txtParC, 'educations-box-table'].join(' ')}>
                      {ReactHtmlParser(contentL7)}
                    </p>
                  </div>
                </div>
                <div className={classes.absImg}>
                  <img className={classes.imgLogo2} src={contentImg5} alt=" " />
                </div>
              </div>
            </>

            {/* <div className="d-flex pt-5 pb-5">
              <div className={classes.boxL50}>
                <figure>
                  <img src={contentImg5} alt="" />
                </figure>
              </div>
              <div className={classes.boxR50}>
                <div className={classes.boxContentG}>
                  {ReactHtmlParser(contentL10)}
                </div>
              </div>
            </div> */}

            {/* {ReactHtmlParser(contentL11)}
            {ReactHtmlParser(contentL12)} */}
            {/* <div className="d-flex">
              <div className={classes.boxL50}>
                {ReactHtmlParser(contentL13)}
              </div>
              <div className={classes.boxR50_Img}>
                <figure>
                  <img src={contentImg6} alt="" />
                </figure>
              </div>
            </div> */}

            <>

            <span className={classes.title}>{ReactHtmlParser(contentL9)}</span>
              <hr />
              <div className={classes.allContentB}>
                <div className={classes.txtParB}>
                  <div>
                    {/* <p className={classes.pArt}>Title</p>
                  <p className={classes.titleCont}>XXX</p> */}
                    <p className={classes.txtReal}>
                      {ReactHtmlParser(contentL10)}
                      {ReactHtmlParser(contentL11)}
                    </p>
                  </div>
                </div>
                <div className={classes.txtParB2}>
                  <img className={classes.imgLogo} src={contentImg6} alt=" " />
                </div>
              </div>
            </>

            {/* <div className="d-flex pt-5">
              <div className={classes.boxL50}>
                <figure>
                  <img src={contentImg7} alt="" />
                </figure>
              </div>
              <div className={classes.boxR50}>
                {ReactHtmlParser(contentL14)}
              </div>
            </div> */}
            <>
              <hr />
              <div className={classes.allContentC}>
                <div className={classes.boxPar}>
                  <div>
                    {/* <p className={classes.pArt}>Title</p>
                  <p className={classes.titleCont}>XXX</p> */}
                    <p className={classes.txtParC}>
                      {ReactHtmlParser(contentL12)}
                    </p>
                  </div>
                </div>
                <div className={classes.absImg}>
                  <img className={classes.imgLogo2} src={contentImg7} alt=" " />
                </div>
              </div>
            </>

            {/* {ReactHtmlParser(contentL15)} */}
            {/* <div className="d-flex pt-5">
              <div className={classes.boxL50}>
                <figure>
                  <img src={contentImg8} alt="" />
                </figure>
              </div>
              <div className={classes.boxR50}>
                <div className={classes.overflowContent}>
                  {ReactHtmlParser(contentL16)}
                </div>
              </div>
            </div> */}
            <>
              <hr />
              <div className={classes.allContentB}>
                <div className={classes.txtParB}>
                  <div>
                    {/* <p className={classes.pArt}>Title</p>
                  <p className={classes.titleCont}>XXX</p> */}
                    <p className={classes.txtReal}>
                      {ReactHtmlParser(contentL13)}
                      {ReactHtmlParser(contentL14)}
                    </p>
                  </div>
                </div>
                <div className={classes.txtParB2}>
                  <img className={classes.imgLogo} src={contentImg8} alt=" " />
                </div>
              </div>
            </>
            {/* 
            {ReactHtmlParser(contentL17)}
            <div className="d-flex pt-5">
              <div className={classes.boxL50}>
                <figure>
                  <img src={contentImg9} alt="" />
                </figure>
              </div>
              <div className={classes.boxR50}>
                <div className={classes.overflowContent}>
                  {ReactHtmlParser(contentL18)}
                </div>
              </div>
            </div> */}

            {/* <>
              <hr />
              <div className={classes.allContentC}>
                <div className={classes.boxPar}>
                  <div>
                    <p className={classes.boxTxt2}>
                      {ReactHtmlParser(contentL15)}
                    </p>
                    <p className={classes.txtParC}>
                      {ReactHtmlParser(contentL16)}
                    </p>
                  </div>
                </div>
                <div className={classes.absImg}>
                  <img className={classes.imgLogo2} src={contentImg9} alt=" " />
                </div>
              </div>
            </> */}
            {/* <div className='d-flex'>
                            <div className={classes.boxL50}>
                                <div className={classes.relative}>
                                    {ReactHtmlParser(contentL6)}
                                    <div className={[classes.wordFlip, classes.animateFlip].join(' ')}>
                                        {contentBox1}
                                    </div>
                                </div>

                                {ReactHtmlParser(contentL7)}
                            </div>
                            <div className={classes.boxR50_Img}>
                                <figure><img src={contentImg3} alt="" />;</figure>
                            </div>
                        </div> */}
          </div>

          {/* <div className='section-b mt-4'>
                        <span className={classes.title}>{ReactHtmlParser(contentL8)}</span>
                        <div className='d-flex'>
                            <div className={classes.boxL50}>
                                {ReactHtmlParser(contentL9)}
                            </div>
                            <div className={classes.boxR50_Img}>
                                <figure><img src={contentImg4} alt="" /></figure>
                            </div>
                        </div>
                        {ReactHtmlParser(contentL10)}
                        <div className="d-flex flex-row flex-wrap justify-content-center m-0 p-0 mt-5">
                            {contentBox2}
                        </div>
                        {ReactHtmlParser(contentL11)}
                        <div className='d-flex'>
                            <div className={classes.boxL50_C}>
                                {ReactHtmlParser(contentL12)}
                                <div className={classes.boxMedImage}>
                                    <figure><img src={contentImg5} alt="" /></figure>
                                </div>
                            </div>
                            <div className={classes.boxR50_C}>
                                {ReactHtmlParser(contentL13)}
                                <div className={classes.boxMedImage}>
                                    <figure><img src={contentImg6} alt="" /></figure>
                                </div>
                            </div>
                        </div>
                        {ReactHtmlParser(contentL14)}
                        <div className="d-flex flex-row m-0 p-0 mb-5">
                            {contentBox3}
                        </div>
                    </div> */}

          <div
            className={[classes.contentLink].join(" ")}
            onClick={this.toggleDetail}
          >
            <div className="d-flex">
              <div className={classes.outerCircle}>
                <div className={classes.innerCircle}></div>
              </div>
              <p className={classes.contentLink}>{this.props.readLess}</p>
            </div>
          </div>
        </div>
      ) : (
          <></>
        );
    } else {
      title = <p className={classes.title}>{dummy[0].content}</p>;
      desc = <p className={classes.content}>{dummy[1].content}</p>;
      img = (
        <img className={classes.imgContentD} src={dummy[2].assets} alt="" />
      );
      content = (
        <div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book
          </p>
          <br />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book
          </p>
          <br />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book
          </p>
        </div>
      );
    }
    let customMargin = !this.state.isDetail ? "mb-5" : "";

    return (
      <div className={classes.sectionEducations}>
        <div
          className={[
            "container d-flex flex-column",
            classes.customContainer,
          ].join(" ")}
        >
          <div className={classes.boxImage}>
            <div className={classes.animate}>{img}</div>
          </div>
          <div className={["d-flex", customMargin].join(" ")}>
            <div className={classes.boxContent}>
              {title}
              {desc}
              {link}
            </div>
            <div className={classes.boxOverflow}>
              <div className={classes.boxContentOverflow}>{content}</div>
            </div>
          </div>

          {detailContent}
        </div>
      </div>
    );
  }
}

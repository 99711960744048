import React, { Component } from "react"

import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import LanguageItem from "./LanguageItem/LanguageItem"

import classes from "./Language.module.css"

const LangItem = styled.div`
  color: white;
  font: 16px/32px Helvetica;
  width: 100%;
  display: inline;
  @media only screen and (max-width: 1400px) {
    font: 14px Helvetica;
  }
  @media only screen and (max-width: 768px) {
    font: 14px Helvetica;
    margin-bottom: 5px;
  }
`
class LanguageItems extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // items: [
      //     { id: 1, title: 'Vietnamese', type: 'inactive', img: <img src={require('./assets/iconVn.svg')} alt='1'></img> },
      //     { id: 2, title: 'English', type: 'inactive', img: <img src={require('./assets/iconUk.svg')} alt='2'></img> }
      // ],
      items: [
        { id: 1, title: "Vietnamese", type: "inactive", img: require("./assets/iconVn.svg") },
        { id: 2, title: "English", type: "inactive", img: require("./assets/iconUk.svg") },
      ],
      selectLanguage: this.props.selectLanguage,
      language: null,
      activeLang: 0,
    }
  }

  componentDidMount() {
    let defaultLang = localStorage.getItem("language")
    let active = 0
    if (defaultLang && defaultLang.toLowerCase() === "vietnamese") {
      active = 0
    } else if (defaultLang && defaultLang.toLowerCase() === "english") {
      active = 1
    } else {
      active = 0
      localStorage.setItem("language", "Vietnamese")
    }

    this.setState({
      activeLang: active,
    })
  }

  toggleSwitchLanguage = (lang) => {
    let active = null
    if (lang.toLowerCase() === "vietnamese") {
      active = 0
    } else {
      active = 1
    }
    this.setState({
      language: lang,
      activeLang: active,
    })
    localStorage.setItem("language", lang)
    window.location.reload(true)
  }

  render() {
    // let langItem = this.state.items.map((i, index) => {
    //     let type = this.state.activeLang === index ? 'active' : 'inactive'
    //     return (
    //         <LanguageItem
    //             key={i.id}
    //             title={i.title}
    //             type={type}
    //             img={i.img}
    //             onClick={()=> this.toggleSwitchLanguage(i.title)}
    //         />
    //     );
    // });

    // console.log("SEL: " + this.props.selectLanguage)
    let langActive,
      langItem = null

    if (this.props.lang) {
      const icon = this.props.icon.sections.widget_contents
      langItem = this.props.lang.sections.widget_contents.map((i, index) => {
        let iconLang = icon[index] ? icon[index].file : ""
        let type = this.state.activeLang === index ? classes.active : classes.inActive
        langActive = this.state.activeLang === index ? i.content : "Vietnamese"
        return (
          <li onClick={() => this.toggleSwitchLanguage(i.content)}>
            <i className={classes.slFlag}>
              <img className={classes.imgFlag} src={iconLang} alt=''></img>
            </i>{" "}
            <span className={type}>{i.content}</span>
          </li>
        )
      })
    } else {
      langItem = this.state.items.map((i, index) => {
        let type = this.state.activeLang === index ? classes.active : classes.inActive
        langActive = this.state.activeLang === index ? i.title : "Vietnamese"
        return (
          <li onClick={() => this.toggleSwitchLanguage(i.title)}>
            <i className={classes.slFlag}>
              <img className={classes.imgFlag} src={i.img} alt=''></img>
            </i>{" "}
            <span className={type}>{i.title}</span>
          </li>
        )
      })
    }

    return (
      <div className='mt-5'>
        <div className={classes.slNav}>
          <LangItem>{this.props.selectLanguage}</LangItem>
          <ul>
            <li>
              <b className='ml-2'>{langActive}</b> <FontAwesomeIcon color='#d4d4d4' className='ml-auto' icon={faCaretDown} size='lg' />
              <div className={classes.triangle}></div>
              <ul>
                {langItem}
                {/* <li onClick={this.toggleLanguage('Vietnamese')}><i className={classes.slFlag}><img className={classes.imgFlag} src={require('./assets/iconVn.svg')} alt=""></img></i> <span>Vietnamese</span></li> */}
                {/* <li onClick={this.toggleLanguage('English')}><i className={classes.slFlag}><img className={classes.imgFlag} src={require('./assets/iconUk.svg')} alt=""></img></i> <span>English</span></li> */}
              </ul>
            </li>
          </ul>
        </div>
        {/* <LangItem>Select Language:</LangItem>
                {langItem} */}
      </div>
    )
  }
}

export default LanguageItems

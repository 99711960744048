import axios from 'axios';

export default class Api {
    base_api = 'http://54.255.70.35/'
    token = '60edaccc8f547344c09fe84bea12029a7aab6b36'


    async getSectionDetail() {
        let params = 584
        let url = null
        let lang = localStorage.getItem('language')
        if (lang === 'English') {
            url = this.base_api + `section_customer/${params}/?language=en`
        } else {
            url = this.base_api + `section_customer/${params}`
        }
        return await axios.get(url, {
            params: {
            },
            headers: {
                'Authorization': `Token ${this.token}`,
                'Content-Type': 'application/json'
            }
        })
    }

    async getArticleDetailByYear(param, filter, size, search) {
        let params = param
        let setfilter = `&category=${filter ? filter : ''}`
        let setSize = `?page_size=${size}`
        let setSearch = `&search=${search}`
        let order = '&order=date'
        let url = null
        let lang = localStorage.getItem('language')
        if (lang === 'English') {
            // url = this.base_api + `articles/${size ? setSize : `?page_size=${50}`}&widget=${params}&language=en${filter ? setfilter : ''}${search ? setSearch : ''}`
            url = this.base_api + `articles/${size ? setSize : `?page_size=${200}`}${order}&widget=${params}&language=en${filter ? setfilter : ''}${search ? setSearch : ''}`
        } else {
            // url = this.base_api + `articles/${size ? setSize : `?page_size=${50}`}&widget=${params}&language=vi${filter ? setfilter : ''}${search ? setSearch : ''}`
            url = this.base_api + `articles/${size ? setSize : `?page_size=${200}`}${order}&widget=${params}&language=vi${filter ? setfilter : ''}${search ? setSearch : ''}`
        }
        return await axios.get(url, {
            params: {
            },
            headers: {
                "Authorization": `Token ${this.token}`,
                "Content-Type": "application/json"
            }
        })
    }

    // async getArticleDetailByYear(param, filter, size, search) {
    //     let params = param
    //     let setfilter = `&category=${filter ? filter : ''}`
    //     let setSize = `?page_size=${size}`
    //     let setSearch = `&search=${search}`
    //     let order = '&order=date'
    //     let url = null
    //     let lang = localStorage.getItem('language')
    //     if (lang === 'English') {
    //         url = this.base_api + `articles/${size ? setSize : `?page_size=${200}`}${order}&widget=${params}&language=en${filter ? setfilter : ''}${search ? setSearch : ''}`
    //     } else {
    //         url = this.base_api + `articles/${size ? setSize : `?page_size=${200}`}${order}&widget=${params}&language=vi${filter ? setfilter : ''}${search ? setSearch : ''}`
    //     }

    //     console.log(url)

    //     return await axios.get(url, {

    //         params: {
    //         },
    //         headers: {
    //             "Authorization": `Token ${this.token}`,
    //             "Content-Type": "application/json"
    //         },
    //     })
    // }

    async getArticleDetailOrderByIndex(param) {
        let params = param
        let url = null
        let lang = localStorage.getItem('language')
        if (lang === 'English') {
            url = this.base_api + `articles/?widget=${params}&language=en&max_size=true&order=index`
        } else {
            url = this.base_api + `articles/?widget=${params}&language=vi&max_size=true&order=index`
        }
        return await axios.get(url, {
            params: {
            },
            headers: {
                'Authorization': `Token ${this.token}`,
                'Content-Type': 'application/json'
            }
        })
    }

    async getArticleDetailByArticleId(param) {
        let params = param;
        let url = null;
        let lang = localStorage.getItem('language');
        if (lang === 'English') {
            url = this.base_api + `articles/${params}/?language=en`;
        } else {
            url = this.base_api + `articles/${params}/?language=vi`;
        }
        return await axios.get(url, {
            params: {},
            headers: {
                Authorization: `Token ${this.token}`,
                'Content-Type': 'application/json',
            },
        });
    }

   async getAllCategories() {
       let lang = localStorage.getItem('language')
       let url
       if (lang === 'English') {
           url = this.base_api + 'article_categories/?language=en'
       } else {
           url = this.base_api + 'article_categories/?language=vi'
       }
       return await axios.get(url, {
       params: {
           },
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json'
           },
       })
   }

   async getAllArticleDetails(filter, size, search) {
       let setfilter = `&category=${filter ? filter : ''}`
       let setSize = `?page_size=${size}`
       let setSearch = `&search=${search}`
       let url = null
       let lang = localStorage.getItem('language')
       if (lang === 'English') {
           url = this.base_api + `articles/${size ? setSize : `?page_size=50`}&details=true&language=en${filter ? setfilter : ''}${search ? setSearch : ''}`
       } else {
           url = this.base_api + `articles/${size ? setSize : `?page_size=50`}&details=true&language=vi${filter ? setfilter : ''}${search ? setSearch : ''}`
       }
       // 'http://54.255.70.35/articles/?details=true&search=vicostone&exclude_category=9'
       return await axios.get(url, {
           params: {
           },
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json'
           },
       })
   }


    async getArticleGroupbyYear(param) {
        let paramId = param;
        let url = null;
        let lang = localStorage.getItem('language');
        if (lang === 'English') {
            url = this.base_api + `articles/group_by_year/?widget=${paramId}&language=en`;
        } else {
            url = this.base_api + `articles/group_by_year/?widget=${paramId}&language=vi`;
        }
        return await axios.get(url, {
            paramId: {},
            headers: {
                Authorization: `Token ${this.token}`,
                'Content-Type': 'application/json',
            },
        });
    }

}

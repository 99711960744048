import React from 'react'
import { Accordion } from 'react-bootstrap'

import classes from './Mobile.module.css'

export default function AccordionMobile(props) {
    let content = props.data ?
        props.data.map(s => {
            return (
                <div className={classes.boxAcc}>
                    <Accordion.Collapse eventKey={s.count.toString()}>
                        <div className={classes.box}>
                            <img className={classes.mobileImg} src={s.img} alt=""></img>
                        </div>
                    </Accordion.Collapse>
                    <Accordion.Toggle className={classes.customToggle} eventKey={s.count.toString()}>
                        <div className={classes.boxMask}></div>
                        <div className={classes.boxVertical}>
                            <p>{s.content}</p>
                        </div>
                    </Accordion.Toggle>
                </div>
            )
        }) : ''

    return (
        <div className={classes.contentAaccordion}>
            <Accordion defaultActiveKey="0">
                {content}
            </Accordion>
        </div>
    )
}

import React, { Component, Fragment, createRef } from "react";
import Header from "../../sections/Header/Header";
import Footer from "../../sections/Footer/Footer";
import HomeSectionShortcut from "./SectionShortcut/SusSectionShortcut";
import SectionShortcut from "./SectionShortcut/SectionShortcut";
import classes from "./sustainable.module.css";
import { ParallaxProvider } from "react-scroll-parallax";
import Section1 from "../../sections/SD_Section1/SD_Section1";
import Section2 from "../../sections/SD_Section2/SD_Section2";
import Section3 from "../../sections/SD_Section3/SD_Section3";
import Section4 from "../../sections/SD_Section4/SD_Section4";
import Section5 from "../../sections/SD_Section5/SD_Section5";
import Section6 from "../../sections/SD_Section6/SD_Section6";

class Sustainable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      section: [
        {
          id: 1,
          title: "Phát triển bền vững trên nền tảng kinh doanh có ý thức",
          component: "Section 1",
        },
        {
          id: 2,
          title: "Cam kết về phát triển bền vững của Tập đoàn Phenikaa",
          component: "Section 2",
        },
        {
          id: 3,
          title: "Định hướng Phát triển bền vững",
          component: "Section 3",
        },
        {
          id: 4,
          title: "Gắn kết các bên liên quan",
          component: "Section 4 ",
        },
        {
          id: 5,
          title: "Báo cáo Phát triển bền vững",
          component: "Section 5 ",
        },
        {
          id: 6,
          title: "Báo cáo Phát triển bền vững",
          component: "Section 6 ",
        },
      ],
    };
  }
  section1 = createRef();
  section2 = createRef();
  section3 = createRef();
  section4 = createRef();
  section5 = createRef();
  section6 = createRef();

  scrolltoSectionHandler = (key) => {
    if (key === "section1") {
      this.section1.current.scrollIntoView({ behavior: "smooth" });
    } else if (key === "section2") {
      this.section2.current.scrollIntoView({ behavior: "smooth" });
    } else if (key === "section3") {
      this.section3.current.scrollIntoView({ behavior: "smooth" });
    } else if (key === "section4") {
      this.section4.current.scrollIntoView({ behavior: "smooth" });
    } else if (key === "section5") {
      this.section5.current.scrollIntoView({ behavior: "smooth" });
    } else if (key === "section6") {
      this.section6.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  render() {
    let sec = this.state.section.map((s) => {
      let ref = "section" + s.id;
      return (
        <SectionShortcut
          key={s.id}
          title={s.title}
          onClick={() => this.scrolltoSectionHandler(ref)}
        />
      );
    });
    let homeSectionShortcut = <HomeSectionShortcut section={sec} />;

    return (
      <div className="" style={{}}>
        <Header onPage="sustainable" />

        <div className={classes.main}>
          <ParallaxProvider>
            <Fragment>
              <div ref={this.section1}>
                <Section1 />
              </div>
              <div ref={this.section2}>
                <Section2 />
              </div>
              <div ref={this.section3}>
                <Section3 />
              </div>
              <div ref={this.section4}>
                <Section4 />
              </div>
              <div ref={this.section5}>
                <Section5 />
              </div>
              <div ref={this.section5}>
                <Section6 />
              </div>
            </Fragment>
            {homeSectionShortcut}
          </ParallaxProvider>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Sustainable;

import React, { Component } from 'react'

import ReactHtmlParser from "react-html-parser";
import dateFormat from "dateformat";

import Api from '../Api'
import MobileView from './MobileViews'


import classes from './Mobile.module.css';

export default class Mobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                {
                    id: 1,
                    name: "Our History",
                    articles: [
                        {
                            name: "Main Article",
                            title: "What is Lorem Ipsum?",
                            content:
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                            assets: require("../assets/default-image.svg"),
                        },
                    ],
                },
                {
                    id: 2,
                    name: "Articles",
                    articles: [
                        {
                            name: "Articles1",
                            title: "What is Lorem Ipsum?",
                            year: "2020",
                            content:
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                            assets: require("../assets/default-image.svg"),
                        },
                        {
                            name: "Articles2",
                            title: "What is Lorem Ipsum?",
                            year: "2020",
                            content:
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                            assets: require("../assets/default-image.svg"),
                        },
                        {
                            name: "Articles3",
                            title: "What is Lorem Ipsum?",
                            year: "2020",
                            content:
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                            assets: require("../assets/default-image.svg"),
                        },
                        {
                            name: "Articles4",
                            title: "What is Lorem Ipsum?",
                            year: "2020",
                            content:
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                            assets: require("../assets/default-image.svg"),
                        },
                    ],
                },
            ],
            sections: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }
    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ sections: [], }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    this.setState({
                        isApiLoaded: true,
                    });
                    const sections = result.data;
                    const widget = [];
                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            internal: item.is_internal,
                            name: item.name,
                            sections: item,
                        });
                    });
                    console.log(widget)
                    sections.page = widget;
                    this.setState({
                        sections: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        }
        );
    }
    render() {


        const { sections, dummy } = this.state;

        let imgChairman, contentArticle, dummyImgChairman, dummyArticle = null

        dummyImgChairman = <img className={classes.imgContent} src={dummy[0].assets} alt="" />
        dummyArticle =
            <div className={classes.boxContent}>
                <p className={classes.title}>{dummy[0].title}</p>
                <span>{ReactHtmlParser(dummy[0].content)}</span>
                <p className={classes.readMore}>Read more</p>
            </div>

        if (this.state.isLoaded) {
            let count = 0;
            sections.forEach((item) => {
                count = count + 1;
                switch (item.index) {
                    case 1:
                        item.sections.widget_contents.forEach((s) => {
                            imgChairman = s.file !== "" ? <img className={classes.imgContent} src={s.file} alt="" /> : dummyImgChairman;
                        });
                        break;
                    case 2:
                        contentArticle = !item.is_internal ? <MobileView id={item.id}/> : dummyArticle
                        // contentArticle = item.sections.widget_contents.map((s) => {
                        //     let isImage = s.assets[0] ? s.assets[0].file : require("../assets/default-image.svg")
                        //     let strYear = dateFormat(s.date, "yyyy");
                        //     return (
                        //         <div className={classes.box}>
                        //             <div className={classes.boxImage}>
                        //                 <img className={classes.contentImage} src={require('../assets/default-image.svg')} alt=""></img>
                        //             </div>

                        //             <div className={classes.boxContent}>
                        //                 <div className={classes.boxYear}>
                        //                     <p className={classes.contentYear}>{strYear}</p>
                        //                 </div>
                        //                 <p className={classes.content}>{s.title}</p>
                        //             </div>
                        //             <div className={classes.boxLink}>
                        //                 <p className={classes.textLink}>View all history...</p>
                        //             </div>
                        //         </div>
                        //     );
                        // });
                        break;
                    default:
                    // code block
                }
            })
        } else if (this.state.isApiLoaded) {
            imgChairman = dummyImgChairman
            contentArticle = dummyArticle
        }
        const settings = {
            className: "",
            infinite: false,
            centerPadding: "",
            variableWidth: true,
            slidesToShow: 1,
            swipeToSlide: true,
        }
        return (
            <div className={classes.contentSection}>
                <div className={classes.sectionHistory}>
                    <p className={classes.sectionTitle}>OUR HISTORY</p>
                    <p className={classes.sectionSubTitle}>Quá trình hình hành và phát triển</p>
                        {contentArticle}
                </div>
            </div>
        )
    }
}

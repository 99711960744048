import React, { useState } from "react"
import styled from "styled-components"
import useScrollPosition from "@react-hook/window-scroll"
import { useMediaQuery } from "react-responsive"

import MegaMenu from "../MegaMenu/MegaMenu"
import "./HamburgerMenu.css"

const StyledBurger = styled.button`
  position: absolute;
  // top: 1.5rem;
  // right: 4rem;
  top: 20px;
  right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // background: #00000080 0% 0% no-repeat padding-box;
  background: transparent;
  height: 56px;
  width: 56px;
  padding: 5px;
  border: 1px solid #00000080;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100;

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 1400px) {
    top: 1rem;
  }

  @media only screen and (max-width: 420px) and (min-width: 361px) {
    right: 1rem;
  }

  @media only screen and (max-width: 360px) {
    right: 1rem;
  }

  span {
    top: 0;
    right: 0;
    width: 37px;
    height: 0.25rem;
    background: ${({ open }) => (open ? "#0D0C1D" : "#223971")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    @media only screen and (max-width: 1400px) {
      width: 32px;
      height: 0.15rem;
    }

    :first-child {
      margin: ${({ open }) => (open ? ".5rem 0 0 1rem" : "1rem auto 0 auto")};
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      margin: 0.4rem auto;
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(10px)" : "translateX(0)")};

      @media only screen and (max-width: 1400px) {
        margin: 0 auto;
      }
    }

    :nth-child(3) {
      margin: ${({ open }) => (open ? "0 0 1rem 1rem" : "0 auto 1rem auto")};
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

const Burger = (props) => {
  const [open, setOpen] = useState(false)

  let burgerColor = props.burgerColor ? props.burgerColor : "white"

  const scrollY = useScrollPosition(20)
  /*
  switch (props.from) {
    case ('home'):
      if ((scrollY >= "1700" && scrollY < "4200") || (scrollY > "5600")) {
        burgerColor = '#223771'
      } else {
        burgerColor = '#ffffff'
      }
      break;
    case ('about-us'):
      if ((scrollY >= "1150" && scrollY < "2150")) {
        burgerColor = '#ffffff'
      } else {
        burgerColor = '#223771'
      }
      break;
    case ('business'):
      burgerColor = '#223771'
      break;
    case ('awards'):
      burgerColor = '#223771'
      break;
    default:
      burgerColor = null;
  }
  */
  const isMobile = useMediaQuery({
    query: "(min-device-width: 360px), (min-device-height: 760px)",
  })

  //console.log("Hamburger: " + props.selectLanguage)

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <span style={{ backgroundColor: burgerColor }} />
        <span style={{ backgroundColor: burgerColor }} />
        <span style={{ backgroundColor: burgerColor }} />
      </StyledBurger>
      <MegaMenu open={open} items={props.items} placeholder={props.placeholder} lang={props.lang} icon={props.icon} selectLanguage={props.selectLanguage} />
    </>
  )
}
export default Burger

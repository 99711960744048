import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import ReactHtmlParser from "react-html-parser";
// import classes from './AO_Section1.module.css'
import classes from "./AO_Section1.module.scss";
import Views from "./Views/Views";
import Api from "./Api";
import { drop } from "lodash";
// import Accordion from './Accordion'

export default class AO_Section1 extends Component {
    constructor() {
        super();
        this.state = {
            hasRun: false,
            currentPage: null,
            articleArray: [],
            catfilter: [],
            tabs: [
                {
                    id: 1,
                    title: "All news & Events",
                    component: <div key={1} />,
                    logo: (<img
                        className={classes.button_tab}
                        src={require("./assets/Polygon7.svg")}
                        alt="description" />)
                }
            ],
            dummy: [
                {
                    id: 644,
                    title: "NEWS",
                    Content: "Education Cooperation – The highlight in international relations of Phenikaa Uni" +
                        "versity",
                    Date: "28/10/2019",
                    Detail: "Phenikaa University signed the memorandum of understanding with Andrews Universi" +
                        "ty...",
                    img: {
                        type: "side",
                        src: require("./assets/news.png")
                    }
                }, {
                    id: 744,
                    title: "NEWS",
                    Content: "Education Cooperation – The highlight in international relations of Phenikaa Uni" +
                        "versity",
                    Date: "28/10/2020",
                    Detail: "Phenikaa University signed the memorandum of understanding with Andrews Universi" +
                        "ty...",
                    img: {
                        type: "behind",
                        src: require("./assets/AEC-final.jpg")
                    }
                }
            ],
            yearG: [],
            yearO: [],
            sections: {
                widgets: []
            },
            isLoaded: false,
            isApiLoaded: false,
            views: null,
            activePage: <div></div>,
            valueQuery: null,
            articleID: null,
            sectionName: ""
        };
        this.getSectionDetail = this
            .getSectionDetail
            .bind(this);
        this.getHtmlParagraph = this
            .getHtmlParagraph
            .bind(this);
        this.getArticles = this
            .getArticles
            .bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
        window.addEventListener("scroll", this.handleScroll, true);
    }

    getSectionDetail() {
        const articleCollection = [];
        const apiData = new Api();
        this.setState({
            sections: [],
            articleArray: []
        }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    // this.setState({ isApiLoaded: true });
                    const sections = result.data;
                    const widget = [];
                    const yearG = [];
                    const yearT = [];
                    const yearO = [];

                    yearG.push(sections.widgets[4].article_years);
                    yearT.push(sections.widgets[5].article_years);
                    yearO.push(sections.widgets[6].article_years);

                    result
                        .data
                        .widgets
                        .forEach((item) => {
                            if (item.category === "Article" && !item.is_internal) {
                                this.getArticles(item.id, item.index);
                            }

                            widget.push({ id: item.id, index: item.index, category: item.category, name: item.name, sections: item });
                        });

                    sections.page = widget;
                    this.setState({
                        sectionName: result.data.name,
                        sections: widget,
                        yearG: yearG,
                        yearT: yearT,
                        yearO: yearO,
                        isLoaded: true,
                        articleID: widget[0].id,
                        articleArray: articleCollection
                    });
                })
                .catch((error) => {
                    this.setState({ isApiLoaded: true });
                });
        });
    }

    getArticles(param, index) {
        const apiData = new Api();
        this.setState({
            //contentArticle: [],
        }, () => {
            apiData
                .getArticleDetail(param)
                .then((result) => {
                    const articles = result.data;
                    const art = [];
                    articles
                        .results
                        .forEach((a) => {
                            const assets = [];
                            a
                                .article_assets
                                .forEach((ass, i) => {
                                    if (i === 0) {
                                        assets.push({ asset: ass.asset_file });
                                    }
                                });

                            art.push({
                                id: a.id,
                                index: index,
                                category: a.category_name,
                                name: a.name,
                                title: a.article_languages.title,
                                content: a.article_languages.content,
                                assets: assets,
                                date: a.date
                            });
                        });
                    if (this.state.articleArray.length > 0) {
                        var tempArray = this.state.articleArray;
                        var concatenatedArray = tempArray.concat(art);
                        this.setState({ articleArray: concatenatedArray });
                    } else {
                        this.setState({ articleArray: art });
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({ isApiLoaded: true });
                });
        });
    }

    getHtmlParagraph(str) {
        if (str !== "") {
            return str
                .replace(/<\/?p>/gi, " ")
                .replace(/<p>/gi, " ");
        }
    }

    render() {
        const { sections, collapseID, catfilter } = this.state;

        var readMore = "";
        var share = "";
        var searchHere = "";
        var newsButton = "";
        let Apititle1,
            Apititle2,
            apiContent,
            topTitle,
            images,
            contentArticle,
            dummytitle,
            dummytitle1,
            dummytitle2,
            dummyimagesBanner,
            dummyAccordion,
            dummytitlehighlight1,
            dummytitlehighlight2,
            dummyhighlight,
            imageBackground,
            logo,
            titleArticle,
            sectionTitle,
            allFilter,
            Article = null;
        let yearArray = [];
        let articleArray = [];

        dummytitle1 = <p className={classes.title1}>Awards</p>;
        dummytitle2 = <p className={classes.title1}>Overview</p>;
        dummytitle = (
            <div className={classes.titleSection}>
                <div className={classes.title1}>
                    <p>{Apititle1}</p>
                </div>
                <div>
                    <p className={classes.title2}>{Apititle2}</p>
                </div>
                <div className={classes.imgBanner}></div>
            </div>
        );
        dummyimagesBanner = (
            <div className={classes.imagesBanner}>
                <img src={require("./assets/banner.jpg")} />
            </div>
        );
        dummyhighlight = (
            <div className={classes.highlight}>
                <p>highlight</p>
                <p>2016</p>
                <p>2017</p>
                <p>2018</p>
                <p>2019</p>
                <p>2020</p>
                <p>2021</p>
            </div>
        );
        dummytitlehighlight1 = "Awards by government";
        dummytitlehighlight2 = "Awards ohter domestic and International organizations";

        let titleFilter1,
            titleFilter2,
            titleFilter3,
            yearGoverment,
            yearOther,
            idGoverment, idTypical, idOther, link = null;

        if (this.state.isLoaded) {
            apiContent = sections.map((item, i) => {
                logo = <img src={require("./Views/assets/Group3411.svg")} alt="img" />;
                Apititle1 = item.nameAwards;
                Apititle2 = item.nameOverview;
                switch (item.index) {
                    case 1:
                        imageBackground = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].file
                            : require("./assets/banner.jpg");
                        break;
                    case 2:
                        // share = item.sections.widget_contents[0] ?
                        // item.sections.widget_contents[0].content : "Don't share";
                        sectionTitle = item.sections.name
                            ? item.sections.name
                            : "dummytitle";
                        break;
                    case 3:
                        item.sections.widget_contents.forEach((dropDownText, index) => {
                            switch (index) {
                                case 0:
                                    allFilter = dropDownText.content;
                                    break;
                                case 1:
                                    titleFilter1 = dropDownText.content;
                                    break;
                                case 2:
                                    titleFilter2 = dropDownText.content;
                                    break;
                                case 3:
                                    titleFilter3 = dropDownText.content;
                                    break;
                                default:
                            }
                        })
                        break;
                    case 4:
                        // yearGoverment = sections[4].sections.article_years;

                        topTitle = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].title
                            : "Awards Overview";
                        titleArticle = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].content
                            : "A nice content of this overview introduction";
                        break;
                    case 5:
                        // yearOther = sections[5].sections.article_years   ?
                        // sections[5].sections.article_years   : "2020";
                        //titleFilter1 = item.name;
                        idGoverment = item.id

                        break;
                    case 6:
                        idTypical = item.id;
                        break;
                    case 7:
                        //titleFilter2 = item.name;
                        var url = item.sections.url
                            ? item.sections.url
                            : "/awards/detail/";
                        idOther = item.id;
                        return (<Views
                            idOther={item.id}
                            idGovernement={idGoverment}
                            idTypical={idTypical}
                            url={url}
                            category={item.sections.article_categories}
                            newsButton={newsButton}
                            titleFilter1={titleFilter1}
                            titleFilter2={titleFilter2}
                            titleFilter3={titleFilter3}
                            topTitle={topTitle}
                            title={sectionTitle}
                            filterAll={allFilter}
                            titleArticle={this.getHtmlParagraph(titleArticle)}
                            articleList={this.state.articleArray} />);
                    case 10:
                        link = item.sections.widget_contents.map((ele) => {
                            return (
                                <div className={classes.navBack}>
                                    <Link className={classes.customLink} to={item.sections.url ? item.sections.url : '/sustainable'}>
                                        <div className={'d-flex'}>
                                            <div className={'d-flex'}>
                                                <i className={[classes.arrow, classes.left, classes.customMargin].join(" ")}></i>
                                            </div>
                                            <span className={classes.textBack}>{ele.content ? ele.content : 'Back to prev'}</span>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })
                        break;
                    default:
                }

            });

            contentArticle = (
                <div className={classes.titleSection}>
                    <div>
                        <p>{Apititle1}</p>
                    </div>
                    <div>
                        <p>{Apititle2}</p>
                    </div>
                    <div className={classes.imgBanner}></div>
                </div>
            );
        } else {
            contentArticle = dummytitle;
            // apiContent = <Views/>;
        }

        return (
            <div className="page-news">
                <div className={classes.main}>
                    <div className={classes.bannerTop}>
                        <img src={imageBackground} alt="" />
                    </div>
                    {/* {contentArticle} */}
                    <div className={["container", classes.customContainer].join(' ')}>
                        <div className={[classes.contentWrap].join(' ')}>{apiContent}</div>
                        {link}
                    </div>
                </div>
            </div>
        );
    }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import dateFormat from 'dateformat';
import { Card, Button } from "react-bootstrap";
import Ripples from "react-ripples";
import Api from "./Api";
import Navbar from "./Navbar/Navbar";

import { Accordion } from "react-bootstrap";
import ContextAwareToggle from "./Accordion/Accordion";

import styled from "styled-components";
import classes from "./Blog.module.scss";

const CustomDiv = styled.div`
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
`;
export default class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                {
                    id: 1,
                    name: "article",
                    articles: [
                        {
                            name: "content1",
                            author: "John doe x Jane doe",
                            title:
                                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
                            content:
                                "From 8h30 to 12h30, the series of event start with the International Workshop on Frontier Materials and Nanotechnology (IWFMT 2018).",
                            assets: require("./assets/default-image.svg"),
                            date: "Monday, September 7, 2020",
                        },
                        {
                            name: "content2",
                            author: "John doe x Jane doe",
                            title:
                                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
                            content:
                                "From 8h30 to 12h30, the series of event start with the International Workshop on Frontier Materials and Nanotechnology (IWFMT 2018).",
                            assets: require("./assets/default-image.svg"),
                            date: "Monday, September 7, 2020",
                        },
                        {
                            name: "content3",
                            author: "John doe x Jane doe",
                            title:
                                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
                            content:
                                "From 8h30 to 12h30, the series of event start with the International Workshop on Frontier Materials and Nanotechnology (IWFMT 2018).",
                            assets: require("./assets/default-image.svg"),
                            date: "Monday, September 7, 2020",
                        },
                    ],
                },
            ],
            contentArticle: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
            idBlogs: "",
            filtered: "",
            url: null,
            articleCategories: null,
            pagination: 6,
            totalArticle: 0
        };
        this.getArticleDetail = this.getArticleDetail.bind(this);
        this.loadMoreArticle = this.loadMoreArticle.bind(this);
        this.getFilteredArticle = this.getFilteredArticle.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    componentDidMount() {
        // console.log(this.props.location.state)
        window.scrollTo(0, 0);

        if (this.props.id) {
            this.setState({
                idBlogs: this.props.id,
                articleCategories: this.props.category,
                url: this.props.url,
            });
            this.getArticleDetail(this.props.id, "", "", "");
        }
    }

    getArticleDetail(param, filter, size, search) {
        const apiData = new Api();
        this.setState({ contentArticle: [] }, () => {
            apiData
                .getArticleDetailByYear(param, filter, size, search)
                .then((result) => {
                    const articles = result.data;
                    const art = [];
                    articles.results.forEach((a) => {
                        const assets = [];
                        a.article_assets.forEach((ass, i) => {
                            if (i === 0) {
                                assets.push({
                                    asset: ass.asset_file,
                                });
                            }
                        });

                        art.push({
                            id: a.id,
                            category: a.category_name,
                            name: a.name,
                            author_name: a.author_name,
                            author_avatar: a.author_avatar,
                            title: a.article_languages.title,
                            content: a.article_languages.content,
                            assets: assets,
                            date: a.date,
                        });
                    });

                    this.setState({
                        contentArticle: art,
                        totalArticle: art.length,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    getFilteredArticle(filter) {
        this.setState({
            filtered: filter,
        });
        this.getArticleDetail(this.state.idBlogs, filter, "", "");
    }

    loadMoreArticle() {
        this.setState((prevState) => {
            return { pagination: prevState.pagination + 1 };
        });
        // this.getArticleDetail(this.props.match.params.id, this.state.filtered, (this.state.pagination + 3))
    }

    keyPress(e) {
        if (e.keyCode === 13) {
            this.getArticleDetail(
                this.state.idBlogs,
                this.state.filtered,
                "",
                e.target.value
            );
        }
    }

    onFilterChange(e) {
        this.setState({
            filtered: e.target.value,
        })
        this.getArticleDetail(this.state.idBlogs, e.target.value, "", "")
    }

    render() {
        const { dummy, contentArticle, pagination, articleCategories } = this.state;
        let dummyBlog, Blog, filter = null;
        let category, category1, category2, allAuthor, loadMore

        dummyBlog = dummy[0].articles.map((s, i) => {
            return (
                <div className={classes.boxBlog}>
                    <div className={classes.boxBody}>
                        <p className={classes.blogTitle}>{s.title}</p>
                        <p className={classes.blogContent}>{s.content}</p>
                    </div>
                    <div className={classes.boxFooter}>
                        <Link to={`/innovation/blogs/detail?id=${i}`}>
                            <p className={classes.blogLink}>Read more</p>
                        </Link>
                        <div>
                            <p className={classes.blogAuthor}>{s.author}</p>
                            <p className={classes.blogDate}>{s.date}</p>
                        </div>
                    </div>
                    <img
                        className={classes.imgProfile}
                        src={require("./assets/man.svg")}
                        alt=""
                    ></img>
                </div>
            );
        });

        if (this.state.isLoaded) {
            filter = articleCategories.map((item) => {
                return (
                    <div onClick={() => this.getFilteredArticle(item)}>
                        <input id="checkbox-2" className={classes.ckCustom} name="checkbox-2" type="checkbox" />
                        <label htmlFor="checkbox-2" className={classes.ckCustomLbl}>
                            {item}
                        </label>
                    </div>
                );
            });

            let arrAuthor = []
            Blog = contentArticle.slice(0, pagination).map((s, i) => {
                // let date = new Date(s.date)
                // const options = { year: "numeric", month: "long", day: "numeric" }
                // let dateBlog = date.toLocaleDateString("vi", options)
                const NewDate = dateFormat(s.date, 'dd-mm-yyyy')

                arrAuthor.push(s.author_name)
                return (
                    <div className={[classes.boxBlog, classes.fadeInBottom].join(" ")}>
                        <div className={classes.boxBody}>
                            <p className={classes.blogTitle}>{s.title}</p>
                        </div>
                        <div className={classes.boxFooter}>
                            <Link to={`${this.state.url}/${s.id}`}>
                                <p className={classes.blogLink}>{this.props.readmore ? this.props.readmore : 'Read more'}</p>
                            </Link>
                            <div>
                                <p className={classes.blogAuthor}>{s.author_name ? s.author_name : ''}</p>
                                <p className={classes.blogDate}>{NewDate}</p>
                            </div>
                        </div>
                        <img className={classes.imgProfile} src={s.author_avatar ? s.author_avatar : require("./assets/man.svg")} alt=""></img>
                    </div>
                );
            });

            allAuthor = arrAuthor.map(a => {
                return (
                    <>
                        <input id="checkbox-3" className={classes.ckCustom} name="checkbox-2" type="checkbox" />
                        <label htmlFor="checkbox-3" className={classes.ckCustomLbl}>
                            {a}
                        </label>
                    </>

                )
            })

            if (this.props.arrCategory) {
                this.props.arrCategory.forEach((c, i) => {
                    switch (i) {
                        case 0:
                            category = c
                            break;
                        case 1:
                            category1 = c
                            break;
                        case 2:
                            category2 = c
                            break;
                        default:
                            break;
                    }
                })
            }

            if (this.state.pagination < this.state.totalArticle) {
                loadMore = <div onClick={this.loadMoreArticle} className={classes.loadMore}>
                    <p className={classes._p}>{this.props.loadmore ? this.props.loadmore : 'Load more'}</p>
                </div>
            }

        } else if (this.state.isApiLoaded) {
            Blog = dummyBlog;
        }

        return (
            <>
                <CustomDiv>
                    <Navbar categories={articleCategories} search={this.props.search ? this.props.search : 'Search here...'} category={this.props.filter ? this.props.filter : 'All'} onKeyDown={this.keyPress} onChange={this.onFilterChange} />
                    <div className={classes.Accordion}>
                        <p className={classes.titleAccordion}>{category}</p>
                        <Accordion defaultActiveKey="0">
                            <div className={classes.boxAcc}>
                                <div className={classes.titleAcc}>
                                    <ContextAwareToggle eventKey="0">{category1}</ContextAwareToggle>
                                </div>
                                <Accordion.Collapse eventKey="0">
                                    <div>{filter}</div>
                                </Accordion.Collapse>
                            </div>
                            <div className={classes.boxAcc}>
                                <div className={classes.titleAcc}>
                                    <ContextAwareToggle eventKey="1">{category2}</ContextAwareToggle>
                                </div>
                                <Accordion.Collapse eventKey="1">
                                    <div>{allAuthor}</div>
                                </Accordion.Collapse>
                            </div>
                        </Accordion>
                    </div>
                    <div className={classes.contentSection}>
                        <div className={["container", classes.customContainer].join(" ")}>
                            <div className={classes.contentBlog}>{Blog}</div>
                            <div className={classes.boxMask}></div>
                        </div>
                    </div>

                    {loadMore}
                    {/* <div className="container d-flex justify-content-center">
            <button onClick={this.loadMoreArticle} className={classes.readMore}>
              Load More
            </button>
          </div> */}
                </CustomDiv>
            </>
        );
    }
}

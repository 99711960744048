import React, { Component, Fragment, createRef } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import Api from "./Api"

import classes from "./ContactUs.module.css"

class sec1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dummy: [],

      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
    this.scrollingHandlerCu = this.scrollingHandlerCu.bind(this)
    this.handleOverride = this.handleOverride.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
    window.addEventListener("scroll", this.scrollingHandlerCu, true)
    this.handleOverride()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollingHandlerCu, true)
  }

  cu_sec1_div1 = createRef()
  cu_sec1_div2 = createRef()

  handleOverride() {
    this.props.override(false)
  }

  scrollingHandlerCu() {
    // console.log(this.state.currentSection, this.state.darkLogo)
    if (this.cu_sec1_div1.current.getBoundingClientRect().bottom < this.cu_sec1_div1.current.getBoundingClientRect().height && this.cu_sec1_div1.current.getBoundingClientRect().bottom > -1) {
      this.props.updateLogo(true)
      // this.setState({
      //     currentSection: "Section1",
      //     darkLogo: false
      // })
    }
    //if(this.section2.current.getBoundingClientRect().y < 100 && this.section2.current.getBoundingClientRect().bottom > 100){
    if (this.cu_sec1_div2.current.getBoundingClientRect().bottom < this.cu_sec1_div2.current.getBoundingClientRect().height && this.cu_sec1_div2.current.getBoundingClientRect().bottom > -1) {
      this.props.updateLogo(false)
      // this.setState({
      //     currentSection: "bs_sec1_div1",
      //     darkLogo: true
      // })
    }
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data
          const widget = []
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            })
          })

          sections.page = widget
          this.setState({
            contentSection: widget,
            isLoaded: true,
          })
        })
        .catch((error) => {
          console.log(error)
          this.setState({
            isApiLoaded: true,
          })
        })
    })
  }

  render() {
    const { contentSection } = this.state

    let title, email, number, subject, content, error, btn, guest
    let contact, office, factory, emailS, phone, fax, qr, company, img

    if (this.state.isLoaded) {
      contentSection.forEach((c, i) => {
        switch (i) {
          case 0:
            c.sections.widget_contents.forEach((s, x) => {
              switch (x) {
                case 0:
                  title = s.content
                  break
                case 1:
                  email = s.content
                  break
                case 2:
                  number = s.content
                  break
                case 3:
                  subject = s.content
                  break
                case 4:
                  content = s.content
                  break
                case 5:
                  error = s.content
                  break
                case 6:
                  btn = s.content
                  break
                case 7:
                  guest = s.content
                  break
                default:
                  break
              }
            })
            break
          case 1:
            c.sections.widget_contents.forEach((s, x) => {
              {
                /* <FontAwesomeIcon color="black" icon={faArrowRight} /> */
              }
              switch (x) {
                case 0:
                  contact = s.content
                  break
                case 1:
                  office = (
                    <div className={classes.officeDiv}>
                      <p className={classes.smallAnchor}>
                        {s.name}
                        {/* <span className={classes.icon}>:</span> */}
                      </p>
                      <p className={classes.address}>{s.content}</p>
                    </div>
                  )
                  break
                case 2:
                  factory = (
                    <div className={classes.factoryDiv}>
                      <p className={classes.smallAnchor}>
                        {s.name}
                        {/* <span className={classes.icon}>:</span> */}
                      </p>
                      <p className={classes.address}>{s.content}</p>
                    </div>
                  )
                  break
                case 3:
                  emailS = (
                    <div>
                      <p className={[classes.label, classes.top].join(" ")}>{s.name}</p>
                      <p className={[classes.text, classes.email].join(" ")}>{s.content}</p>
                    </div>
                  )
                  break
                case 4:
                  phone = (
                    <div>
                      <p className={classes.label}>{s.name}</p>
                      <p className={classes.text}>{s.content}</p>
                    </div>
                  )
                  break
                case 5:
                  fax = (
                    <div>
                      <p className={classes.label}>{s.name}</p>
                      <p className={classes.text}>{s.content}</p>
                    </div>
                  )
                  break
                case 6:
                  qr = s.content
                  break
                case 7:
                  company = s.content
                  break
                default:
                  break
              }
            })
            break

          case 2:
            c.sections.widget_contents.forEach((s, x) => {
              let file = s.file ? s.file : require("./assets/Image 40.png")

              img = <img className={classes.imgQR} src={file} alt=' ' />
            })
            break
          default:
            break
        }
      })
    } else if (this.state.isApiLoaded) {
      office = (
        <div className={classes.officeDiv}>
          <p className={classes.smallAnchor}>Off</p>
          <p className={classes.address}>Office</p>
        </div>
      )

      factory = (
        <div className={classes.factoryDiv}>
          <p className={classes.smallAnchor}>Fact</p>
          <p className={classes.address}>Factory</p>
        </div>
      )

      emailS = (
        <div>
          <p className={[classes.label, classes.top].join(" ")}>Name</p>
          <p className={[classes.text, classes.email].join(" ")}>Email</p>
        </div>
      )

      phone = (
        <div>
          <p className={classes.label}>Phone</p>
          <p className={classes.text}>+6222222009</p>
        </div>
      )

      fax = (
        <div>
          <p className={classes.label}>Fax</p>
          <p className={classes.text}>xxx@phenikaa.com</p>
        </div>
      )

      qr = "QR"
      company = "Phenikaa"
      img = <img className={classes.imgQR} src={require("./assets/Image 40.png")} alt=' ' />
    }
    return (
      <div className={classes.prBox}>
        <div ref={this.cu_sec1_div1} className={classes.headMapContainer}>
          <div className={classes.frontMap}></div>
          <img className={[classes.headMapImg].join(" ")} src={require("./assets/Image 40@2x.png")} alt=''></img>
        </div>
        <div ref={this.cu_sec1_div2} className={["container", classes.mWid].join(" ")}>
          <div className='row'>
            <div className='col-md-6'>
              <form action='' method='POST' className={classes.sendMessageSection}>
                <h2 className={classes.heading}>{title}</h2>
                <input className={classes.input1} type='text' placeholder={guest} />
                <input className={classes.input} type='text' placeholder={email} />
                <input className={classes.input} type='text' placeholder={number} />
                <input className={classes.input} type='text' placeholder={subject} />
                <textarea className={classes.bigInput} placeholder={content} type='text' />
                {/* <div className={classes.alertBox}>
                  <p className={classes.alertBoxText}>{error}</p>
                </div> */}
                <button className={classes.btnSend}>{btn}</button>
              </form>
            </div>
            <div className='col-md-6'>
              <h2 className={classes.heading}>{contact}</h2>
              <p className={classes.companyName}>{company}</p>

              {office}
              {factory}

              <div className={classes.thirdDiv}>
                <div className={classes.QRCode}>{img}</div>
                <div className={classes.emailPhoneFaxContainer}>
                  {emailS}
                  {phone}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default sec1

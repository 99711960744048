import React, { Component } from 'react';
import Api from './Api'
import NavigationBar from './Navbar/Navbar'

import "./Header.css"

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentLogo: false,
            menus: {
                items: []
            },
            isLoaded: false
        };
        this.getMenu = this.getMenu.bind(this);
        this.updateColor = this.updateColor.bind(this);
    }

    componentDidMount() {
        this.getMenu();
    }

    getMenu() {
        const apiData = new Api();
        this.setState({ menus: [] }, () => {
            apiData.getMenuDetail()
                .then(result => {
                    const menu = result.data
                    const item = []

                    menu.items.forEach((i) => {
                        item.push({
                            index: i.index,
                            id: i.id,
                            name: i.title,
                            url: i.url,
                            children: i.children
                        })
                    })
                    menu.items = item
                    this.setState({
                        menus: menu,
                        isLoaded: true
                    })
                })
                .catch(error => {
                    console.log(error);
                });
        })
    }

    updateColor(){
        //console.log("UpdateState - " + this.props.logoColor)
        this.setState({
            currentLogo: this.props.logoColor,
        })
    }


    render() {
        const { menus } = this.state
        let dataSection, Navbar = null

        //console.log("Header - Logo: " + this.props.logoColor)
        if(this.state.currentLogo !== this.props.logoColor){
            this.updateColor();
        }

        if (this.state.isLoaded) {
            dataSection = menus
            Navbar = <NavigationBar onPage={this.props.onPage} items={dataSection} logoColor={this.state.currentLogo}/>
        } else {
            Navbar = <NavigationBar onPage={this.props.onPage} items={this.state.isLoaded} logoColor={this.state.currentLogo}/>
        }
        
        return (
            <div className="content-header">
                {/* <NavigationBar onPage={this.props.onPage} items={this.props.items} /> */}
                {Navbar}
            </div>
        );
    }
}

export default Header;
import React, { Component } from 'react'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ImageSlider.css'

import Api from './Api'

class ImageSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isApiLoadad: false,
            sections: {
                widgets: [],
            },
            items: [
                { id: 1, title: '', img: <img style={{ width: '100vw', height: '100vh', objectFit: 'cover' }} src={require('./assets/slide1.png')} alt='1'></img> },
                { id: 2, title: '', img: <img style={{ width: '100vw', height: '100vh', objectFit: 'cover' }} src={require('./assets/slide2.png')} alt='2'></img> },
                { id: 3, title: '', img: <img style={{ width: '100vw', height: '100vh', objectFit: 'cover' }} src={require('./assets/slide3.png')} alt='3'></img> },
            ]
        }
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.getcontentFromTextItem = this.getcontentFromTextItem.bind(this);
    }


    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState(
            {
                sections: [],
            },
            () => {
                apiData
                    .getSectionDetail()
                    .then((result) => {
                        this.setState({
                            isApiLoaded: true,
                        });
                        const sections = result.data;
                        this.state.isApiLoadad = true;
                        const widget = [];
                        result.data.widgets.forEach((item) => {
                            widget.push({
                                id: item.id,
                                index: item.index,
                                category: item.category,
                                name: item.name,
                                sections: item,
                            });
                        });
                        sections.page = widget;
                        this.setState({
                            sections: widget,
                            isLoaded: true,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.setState({
                            isApiLoaded: true,
                        });
                    });
            }
        );
    }

    play() {
        this.slider.slickPlay();
    }
    pause() {
        this.slider.slickPause();
    }

    getcontentFromTextItem(textItem) {

        var dummyText = "DummyText";

        if (!textItem.widget_contents[0]) { return dummyText }
        else {
            if (textItem.widget_contents[0].content) {
                return textItem.widget_contents[0].content;
            } else {
                return dummyText;
            }
        }

    }

    render() {

        const sections = this.state.sections;

        let titleLine1, titleLine2, description = "";

        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            // slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            swipeToSlide: true,
            appendDots: dots => (
                <div
                    style={{
                        backgroundColor: "transparent",
                        borderRadius: "10px",
                        paddingBottom: "2rem"
                    }}
                >
                    <ul style={{ margin: "0px" }}> {dots} </ul>
                </div>
            ),
            customPaging: i => (
                <div
                    style={{
                        width: '10px',
                        height: '10px',
                        color: 'white',
                        border: '1px solid white',
                        borderRadius: '50%',
                        marginRight: '2px'
                    }}
                >
                    {/* {i + 1} */}
                </div>
            )
        };

        if (this.state.isApiLoadad) {
            sections.forEach((item) => {
                switch (item.index) {
                    case 1:
                        titleLine1 = this.getcontentFromTextItem(item.sections);
                        break;

                    case 2:
                        titleLine2 = this.getcontentFromTextItem(item.sections);
                        break;

                    case 3:
                        description = this.getcontentFromTextItem(item.sections);
                        break;

                    case 4:
                        if (item.sections.widget_contents[0]) {
                            this.state.items = [];
                            console.log(item.sections.widget_contents)
                            item.sections.widget_contents.forEach((sliderItem, index) => {
                                var element = null;
                                var filesplit = sliderItem.file.split(".");
                                var filetype = filesplit[filesplit.length - 1]
                                switch (filetype) {
                                    case "mp4":
                                        element = <video style={{ width: '100vw', height: '100vh', objectFit: 'cover' }} autoPlay muted>
                                                    <source src={sliderItem.file} type='video/mp4' />
                                                  </video>;
                                        break;
                                    default:
                                        element = <img style={{ width: '100vw', height: '100vh', objectFit: 'cover' }} src={sliderItem.file} alt={index}></img>
                                }
                                this.state.items.push({ id: index, title: sliderItem.description, img: element })
                            })
                        }
                        break;
                    default:
                }
            });
        }

        const { items } = this.state;
        return (
            <div className="section-slider">
                <div className='slider-box'>
                    <div className='content-slider'>
                        <p className='title-slider'>{titleLine1}</p>
                        <p className='title-slider'>{titleLine2}</p>
                        <p className='title-slider-desc'>{description}</p>
                    </div>
                </div>
                <Slider style={{ height: '100vh', width: '100vw' }} {...settings}>
                    {items.map(item => <div key={item.id}>{item.img}</div>)}
                </Slider>
            </div>
        )
    }
}

export default ImageSlider;


import React, { Component } from "react"
import styled from "styled-components"
import MegaMenuItems from "./MegaMenuItems/MegaMenuItems"
import LanguageItems from "./MegaMenuItems/LanguageItems/LanguageItems"
import classes from "./MegaMenu.module.css"

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  clip-path: ${({ open }) => (open ? "circle(100% at 50% 0%)" : "circle(0% at 95.2% 2.7rem)")};
  transition: clip-path 1s, opacity 0.5s;

  height: ${({ open }) => open ? "100vh" : "0"};
  width: ${({ open }) => open ? "100vw" : "0"};
  text-align: left;
  padding: 2rem;
  position: absolute;
  z-index: ${({ open }) => (open ? "1" : "-1")};
  top: 0;
  right: 0;


  @media only screen and (max-width: 420px) {
    clip-path: ${({ open }) => (open ? "circle(100% at 50% 30%)" : "circle(0% at 76% 6%)")};
  }
  @media only screen and (max-width: 360px) {
    clip-path: ${({ open }) => (open ? "circle(100% at 50% 30%)" : "circle(0% at 88% 6%)")};
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0d0c1d;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`
export default class MegaMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.keyPress = this.keyPress.bind(this)
  }

  keyPress(e) {
    const currentUrl = window.location.href
    const pathname = window.location.pathname
    if (e.keyCode === 13) {
      if (pathname === "/") {
        window.location.href = currentUrl + `search/?search=${e.target.value}`
      } else if (pathname === "/search/") {
        window.location.href = currentUrl + `search/?search=${e.target.value}`
      } else {
        window.location.href = currentUrl.replace(pathname, "/") + `search/?search=${e.target.value}`
      }
      // window.location.href = currentUrl + `search/?search=${e.target.value}`
      // const param = `/search/?search=${e.target.value}`
      // console.log(protocol)
      // window.location.href = protocol + '://' + hostname + (port ? ':' + port + param : param)
    }
  }

  render() {
    let placeholder,
      lang,
      icon = null

    if (this.props.placeholder) {
      placeholder = this.props.placeholder ? this.props.placeholder : "search here..."
    } else {
      placeholder = "Search here"
    }
    if (this.props.lang) {
      lang = this.props.lang
      icon = this.props.icon
    } else {
      lang = false
    }

    return (
      <StyledMenu open={this.props.open}>
        <div style={{ flex: "none" }} className='col-md-3'></div>
        <div className='row'>
          <div style={{ left: "8vw" }} className={["col-md-6", classes.itemList].join(" ")}>
            <div className={["input-group", classes.customSearch].join(" ")}>
              <input onKeyDown={this.keyPress} type='text' autoComplete='off' id='btnSearch' placeholder='Tìm kiếm...' />
              <i className={["fa fa-search fa-sm", classes.faCustom].join(" ")} aria-hidden='true'></i>
            </div>
            <MegaMenuItems items={this.props.items} />
            <LanguageItems lang={lang} icon={icon} selectLanguage={this.props.selectLanguage} />
          </div>
        </div>
      </StyledMenu>
    )
  }
}

import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { ParallaxProvider } from "react-scroll-parallax";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from "react-bootstrap";

import Api from "./Api";
import Box from "./Box";
import ReactHtmlParser from "react-html-parser";

import classes from "./orientation.module.css";


class orientation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: <div></div>,
            activeSidebar: 0,
            box1: null,
            box2: null,
            titleZoom: [
                { id: 1, title: "xxxx" },
                { id: 2, title: "yyyy" },
                { id: 3, title: "zzz" },
            ],
            dummy: [
                { id: 1, name: "description", content: "Example description" },
                {
                    id: 2,
                    name: "categories",
                    articles: [
                        {
                            name: "categories1",
                            title: "What is Lorem Ipsum?",
                            content:
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                            assets: require("./assets/slide2.png"),
                        },
                        {
                            name: "categories2",
                            title: "What is Lorem Ipsum?",
                            content:
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                            assets: require("./assets/slide2.png"),
                        },
                    ],
                },
                {
                    id: 3,
                    name: "content 1",
                    content: [
                        {
                            name: "one",
                            title: "What is Lorem Ipsum?",
                            content:
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                        },
                        {
                            name: "two",
                            title: "What is Lorem Ipsum?",
                            content:
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                        },
                    ],
                },
                {
                    id: 4,
                    name: "content 2",
                    content: [
                        {
                            name: "one",
                            title: "What is Lorem Ipsum?",
                            content:
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                        },
                        {
                            name: "two",
                            title: "What is Lorem Ipsum?",
                            content:
                                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
                        },
                    ],
                },
            ],
            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: true,
            detailLoaded1: false,
            detailLoaded2: false,
            detailLoaded3: false
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.toggleHiding = this.toggleHiding.bind(this);

    }

    componentDidMount() {
        this.getSectionDetail();
    }
    changeIndex(key) {
        this.setState({ activeSidebar: key });
    }
    getHtmlParagraph(str) {
        if (str !== "") {
            return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
        }
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState(
            { contentSection: [], contentBox1: [], contentBox2: [] },
            () => {
                apiData
                    .getSectionDetail()
                    .then((result) => {
                        const sections = result.data;
                        const widget = [];
                        console.log(sections);
                        result.data.widgets.forEach((item) => {
                            widget.push({
                                id: item.id,
                                index: item.index,
                                name: item.name,
                                category: item.category,
                                url: item.url,
                                sections: item,
                            });
                        });

                        sections.page = widget;
                        this.setState({
                            contentSection: widget,
                            isLoaded: true,
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            isApiLoaded: true,
                        });
                    });
            }
        );
    }

    toggleShowing(i) {
        switch (i) {
            case 1:
                this.setState({ detailLoaded1: true });
                break;
            case 2:
                this.setState({ detailLoaded2: true });

                break;
            case 3:
                this.setState({ detailLoaded3: true });

                break;
            default:
                break;
        }
    }

    toggleHiding() {
        this.setState({
            detailLoaded1: false,
            detailLoaded2: false,
            detailLoaded3: false
        });
    }

    render() {
        const { contentSection, isLoaded, isApiLoaded } = this.state;
        let desc,
            img,
            content,
            titles,
            titles2,
            titles3,
            boxLeft,
            boxRight,
            boxBottom,
            detail1,
            detail2,
            detail3,
            link = null;

        var boxBottom1 = []
        var boxBottom2 = []

        if (isLoaded) {
            contentSection.forEach((item, index) => {
                switch (index) {
                    case 0:
                        item.sections.widget_contents.forEach((ele) => {
                            if (ele.content) {
                                desc = <p className={classes.titleTop}>{ele.content}</p>;
                            } else {
                                desc = <p className={classes.titleTop}>title?</p>;
                            }
                        });
                        break;
                    case 1:
                        item.sections.widget_contents.forEach((ele) => {
                            if (ele.content) {
                                titles = <p className={classes.titles}>{ele.content}</p>;
                            } else {
                                titles = <p className={classes.titles}>titless?</p>;
                            }
                        });
                        break;
                    case 2:
                        item.sections.widget_contents.forEach((ele) => {
                            if (ele.content) {
                                titles2 = <p className={classes.titles}>{ele.content}</p>;
                            } else {
                                titles2 = <p className={classes.titles}>titless?</p>;
                            }
                        });
                        break;
                    case 3:
                        item.sections.widget_contents.forEach((ele) => {
                            if (ele.content) {
                                titles3 = <p className={classes.titles}>{ele.content}</p>;
                            } else {
                                titles3 = <p className={classes.titles}>titless?</p>;
                            }
                        });
                        break;
                    case 4:
                        boxLeft = item.sections.widget_contents.map((ele) => {
                            img = ele.assets[0] ? (
                                <img
                                    className={classes.imgInside}
                                    src={ele.assets[0].file}
                                    alt=" "
                                />
                            ) : (
                                    <img
                                        className={classes.imgInside}
                                        src={require("./assets/default-image.svg")}
                                        alt=" "
                                    />
                                );

                            if (ele.content) {
                                let content2 = ele.content.substring(ele.content.indexOf(':') + 1)
                                let content1 = ele.content.split(":")[0];
                                content = (
                                    <p className={classes.textC}>
                                        {ReactHtmlParser(content1)}:&nbsp;
                                        <span>{ReactHtmlParser(content2)}</span>
                                    </p>
                                );
                            } else {
                                content = <p className={classes.textC}>Content?</p>;
                            }

                            return (
                                <div className={classes._L1}>
                                    <div className={classes._L1a}>{img}</div>
                                    <div className={classes._L1b}>{content}</div>
                                </div>
                            );
                        });
                        break;
                    case 5:
                        boxRight = item.sections.widget_contents.map((ele) => {
                            img = ele.assets[0] ? (
                                <img
                                    className={classes.imgInside}
                                    src={ele.assets[0].file}
                                    alt=" "
                                />
                            ) : (
                                    <img
                                        className={classes.imgInside}
                                        src={require("./assets/default-image.svg")}
                                        alt=" "
                                    />
                                );

                            if (ele.content) {
                                let content2 = ele.content.substring(ele.content.indexOf(':') + 1)
                                let content1 = ele.content.split(":")[0];
                                content = (
                                    <p className={classes.textC}>
                                        {ReactHtmlParser(content1)}:&nbsp;
                                        <span>{ReactHtmlParser(content2)}</span>
                                    </p>
                                );
                            } else {
                                content = <p className={classes.textC}>Content?</p>;
                            }

                            return (
                                <div className={classes._R1}>
                                    <div className={classes._R1a}>{img}</div>
                                    <div className={classes._L1b}>{content}</div>
                                </div>
                            );
                        });
                        break;
                    case 6:
                        var counter = 0;
                        boxBottom = item.sections.widget_contents.map((ele) => {
                            img = ele.assets[0] ? (
                                <img
                                    className={classes.imgInside}
                                    src={ele.assets[0].file}
                                    alt=" "
                                />
                            ) : (
                                    <img
                                        className={classes.imgInside}
                                        src={require("./assets/default-image.svg")}
                                        alt=" "
                                    />
                                );

                            if (ele.content) {
                                let content2 = ele.content.substring(ele.content.indexOf(':') + 1)
                                let content1 = ele.content.split(":")[0];
                                content = (
                                    <p className={classes.textC}>
                                        {ReactHtmlParser(content1)}:&nbsp;
                                        <span>{ReactHtmlParser(content2)}</span>
                                    </p>
                                );
                            } else {
                                content = <p className={classes.textC}>Content?</p>;
                            }
                            switch (counter) {

                            }
                            return (
                                <div className={classes._Left2a}>
                                    <div className={classes._L1a}>{img}</div>
                                    <div className={classes._L1b}>{content}</div>
                                </div>
                            );
                        });

                        boxBottom.forEach((item, index) => {
                            if (index < (boxBottom.length / 2)) {
                                boxBottom1.push(item);
                            } else {
                                boxBottom2.push(item);
                            }
                        })

                        break;
                    case 7:
                        link = item.sections.widget_contents.map((ele) => {
                            return (
                                <div className={classes.navBack}>
                                    <Link className={classes.customLink} to={item.sections.url ? item.sections.url : '/sustainable'}>
                                        <div className={'d-flex'}>
                                            <div className={'d-flex'}>
                                                <i className={[classes.arrow, classes.left, classes.customMargin].join(" ")}></i>
                                            </div>
                                            <span className={classes.textBack}>{ele.content ? ele.content : 'Back to prev'}</span>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })

                        break;
                    case 8:
                        item.sections.widget_contents.forEach((s) => {
                            detail1 = <>
                                <Modal.Header onClick={this.toggleHiding} closeButton>
                                    <Modal.Title className={["pl-3", classes.modalTitle].join(' ')}>{s.title}</Modal.Title>
                                </Modal.Header>
                                <div className={classes.boxModal}>{ReactHtmlParser(s.content)}</div>
                            </>
                        })
                        break;
                    case 9:
                        item.sections.widget_contents.forEach((s) => {
                            detail2 = <>
                                <Modal.Header onClick={this.toggleHiding} closeButton>
                                    <Modal.Title className={["pl-3", classes.modalTitle].join(' ')}>{s.title}</Modal.Title>
                                </Modal.Header>
                                <div className={classes.boxModal}>{ReactHtmlParser(s.content)}</div>
                            </>
                        })
                        break;
                    case 10:
                        item.sections.widget_contents.forEach((s) => {
                            detail3 =
                                <>
                                    <Modal.Header onClick={this.toggleHiding} closeButton>
                                        <Modal.Title className={["pl-3", classes.modalTitle].join(' ')}>{s.title}</Modal.Title>
                                    </Modal.Header>
                                    <div className={classes.boxModal}>{ReactHtmlParser(s.content)}</div>
                                </>
                        })
                        break;
                    default:
                }
            });
        } else if (isApiLoaded) {
            desc = <p className={classes.titleTop}>Lorem Ipsum</p>;
            titles = <p className={classes.titles}>Lorem Ipsum</p>;
            titles2 = <p className={classes.titles}>Lorem Ipsum</p>;
            titles3 = <p className={classes.titles}>Lorem Ipsum</p>;
            content = <p className={classes.textC}>Lorem Ipsum</p>;
        }

        return (
            <div className="" style={{}}>
                <div className={["container", classes._joinBox].join(" ")}>
                    <div className={classes.maintitle}>{desc}</div>
                    <div className={classes._box1}>
                        <div className={classes._boxLeft}>
                            {boxLeft}
                            <div className={classes._boxImgL}>
                                <div className={classes._boxInner}>
                                    {titles}
                                    <img className={classes.imgCircle} src={require("./assets/1.svg")} alt=" " />
                                </div>
                                <div className={classes.boxDetail} onClick={() => this.toggleShowing(1)}>
                                    <FontAwesomeIcon color='#233871' className='m-auto' icon={faPlusCircle} size="lg" />
                                </div>
                                <Modal
                                    size="sm"
                                    aria-labelledby="example-modal-sizes-title-sm"
                                    centered
                                    dialogClassName={classes.modalCustom}
                                    show={this.state.detailLoaded1}
                                    onHide={this.toggleHiding}
                                >
                                    {detail1}
                                </Modal>
                            </div>
                        </div>

                        <div className={classes._boxRight}>
                            {boxRight}

                            <div className={classes._boxImgR}>
                                <div className={classes._boxInner}>
                                    {titles2}
                                    <img
                                        className={classes.imgCircle}
                                        src={require("./assets/2.svg")}
                                        alt=" "
                                    />
                                </div>
                                <div className={classes.boxDetail} onClick={() => this.toggleShowing(2)}>
                                    <FontAwesomeIcon color='#233871' className='m-auto' icon={faPlusCircle} size="lg" />
                                </div>
                                <Modal
                                    size="sm"
                                    aria-labelledby="example-modal-sizes-title-sm"
                                    centered
                                    dialogClassName={classes.modalCustom}
                                    show={this.state.detailLoaded2}
                                    onHide={this.toggleHiding}
                                >

                                    {detail2}
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <div className={classes._box2}>
                        <div className={classes.boxLeft2}>{boxBottom1}</div>
                        <div className={classes.boxLeft2}>{boxBottom2}</div>
                        <div className={classes._box2ImgR}>
                            <div className={classes._boxInner2}>
                                {titles3}
                                <img
                                    className={classes.imgCircle}
                                    src={require("./assets/3.svg")}
                                    alt=" "
                                />
                            </div>
                            <div className={classes.boxDetail} onClick={() => this.toggleShowing(3)}>
                                <FontAwesomeIcon color='#233871' className='m-auto' icon={faPlusCircle} size="lg" />
                            </div>
                            <Modal
                                size="sm"
                                aria-labelledby="example-modal-sizes-title-sm"
                                centered
                                dialogClassName={classes.modalCustom}
                                show={this.state.detailLoaded3}
                                onHide={this.toggleHiding}
                            >
                                {detail3}
                            </Modal>
                        </div>
                    </div>

                    {link}
                </div>
            </div>
        );
    }
}

export default orientation;

import React, { Suspense, useCallback, useRef, useEffect, useState, useMemo, Component } from 'react'
import { Canvas, useFrame, useLoader, useThree } from 'react-three-fiber'
import * as THREE from 'three'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import worldImg from './assets/earth_atmos_2048.jpg'
import cloudImg from './assets/output-onlinepngtools.png'

import { CubeTextureLoader } from "three";
import { text } from '@fortawesome/fontawesome-svg-core';


import img1 from './assets/1.jpg'
import img2 from './assets/2.jpg'
import img3 from './assets/3.jpg'
import img4 from './assets/4.jpg'
import img5 from './assets/5.jpg'
import img6 from './assets/6.jpg'

import houseOfPhenikaaGLTF from './assets/Phenikaa_3D.gltf'
import ThreeJs from '../../UI/ThreeJs/ThreeJs';

const phenikaaBlue = new THREE.Color('#223771')
const phenikaaOrange = new THREE.Color('#f16b26')
const phenikaaLightGrey = new THREE.Color('#F1F1F1')
const phenikaaGrey = new THREE.Color('#DDDDDD')


function changeColorMeshToLightGrey(mesh){
    mesh.material.color = phenikaaLightGrey;
}


function changeColorMeshToGrey(mesh){
    mesh.material.color = phenikaaGrey;
}


function changeColorMeshToOrange(mesh){
    mesh.material.color = phenikaaOrange;
}

function changeColorMeshToBlue(mesh){
    mesh.material.color = phenikaaBlue;
}

function setColorToConnectorDots(object3D){
    //object3D[0].visible = true;
    changeColorMeshToBlue(object3D[0])
}

function SetColorToHouseOfPhenikaaListrikLines(children){

    //console.log(children)

    children.forEach((child, index) => {
        //console.log(index, child)
        if(child.type === "Mesh"){
            
            switch(index){
                case 30:
                    //Bottom sign
                    changeColorMeshToGrey(child)
                    break;

                case 31:
                    //Electric lines
                    changeColorMeshToBlue(child)
                    break;
                case 32:
                    //Top Frame
                    changeColorMeshToBlue(child)                    
                    break;                    
                default:
                    console.log(child)
                    child.visible = false;
                    break
            }
        }
        else {
            //connectorDots
            setColorToConnectorDots(child.children)
        }
    })
}
    

function SetColorToHouseOfPhenikaa(children){

    children.forEach((child, index) => {
        
        if(child.type === "Mesh"){
            //console.log(index, child)
            switch(index){
                case 1:
                    //Text in the middle - Education & Training
                    changeColorMeshToBlue(child);
                    break;
                case 2:
                    // Vertikal text in top - Patronage
                    break;
                case 3:
                    // Vertikal text in top - Human Resource
                    break;                    
                case 4:
                    // Vertikal text in left - Practicum
                    break;                    
                case 5:
                    // Vertikal text in left - Support
                    break;                    
                case 6:
                    // Vertikal text in left - Practicum
                    break;                                
                case 7:
                    // Vertikal text in left - Support
                    break;        
                case 8:
                     // Vertikal text in right - Humoan Resource
                     break;                    
                case 9:
                    // Vertikal text in right - Transfer
                    break;
                case 10:
                    // Vertikal text in right - Investment
                     break;
                case 11:
                    // Vertikal text in right - Administration
                    break;                                                                  
                case 12:
                    // Text for first sign - Technology
                    //changeColorMeshToOrange(child)
                    break;                                                                                      
                case 13:
                    // Text for first sign - Technology - Mirrored from 12
                    //child.visible = false;
                    break;
                case 14:
                    //Text in 2nd sign - System
                    break;
                case 15:
                    // Text for 2nd sign - System - Mirrored from 14
                    //child.visible = false;
                    break;
                case 16:
                    // Right text - Subsidiaries
                    break;                     
                case 17: 
                    //Right text - Subsidiaries - mirrored of 16
                    //child.visible = false
                    break;                   
                case 18:
                    // Left text - Research
                    break;                   
                case 19: 
                    //Right text - Research - mirrored of 18
                    //child.visible = false
                    break;                                        
                case 20:
                   // Left text - Institute
                    break;                   
                case 21: 
                    //Right text - Institute - mirrored of 20
                    //child.visible = false
                    break;
                case 22:
                   // Left text - Centers
                   break;                   
                 case 23: 
                    //Right text - Centers - mirrored of 22
                    //child.visible = false
                    break;
                 case 24:
                     //Text bottom sign - Human Resources
                     break;   
                case 25:
                    //Text bottom sign - Human Resources
                     break;   
                case 26:
                    //Top text Tap Doan
                    break;
                case 27:
                    //Top text Tap Doan - Mirrored from 26
                    break;                                
                case 28:
                    // Top Text - Phenikaa
                    break;                             
                case 29:
                    // Top Text - Phenikaa - Mirrored from 28
                    break;                    
                case 30:
                    // Center text - Education and Training
                    break
                case 31:
                    changeColorMeshToLightGrey(child)
                    // Back plate center - Education and Training
                    break;
                case 32:
                    // Back plate subsidiaries
                    changeColorMeshToLightGrey(child)
                    break;
                case 33:
                    //Back plate research / Institutes / Centers
                    changeColorMeshToLightGrey(child)
                    break;
                case 34:
                    //Back plate technology
                    changeColorMeshToGrey(child)
                    break;
                case 35:
                    //Back Plate System
                    changeColorMeshToGrey(child)
                    break;
                case 36:
                    //Back plage Tap Doan Phenikaa
                    changeColorMeshToLightGrey(child)
                    break;                    

                default:
                    //console.log(child)
                    child.visible = false;
                    break
            }
        }
        else {
            SetColorToHouseOfPhenikaaListrikLines(child.children)
        }
    })
    

    return null
}


function HouseOfPhenikaa() {
    const gltf = useLoader(GLTFLoader, houseOfPhenikaaGLTF)
    //console.log(gltf.scene)
    gltf.scene.children.forEach((child, index) => {
        
        switch(index){
            case 6:
                SetColorToHouseOfPhenikaa(child.children)
                break;
            default:
                child.visible = false;
            break;
        }

    })
    
    //gltf.materials.forEach((material, index) => {
    //    console.log(gltf.materials[index])
    //})
    return <primitive object={gltf.scene} position={[0, 0, 0]} scale={[0.2,0.2,0.2]} />
  }


function BigSkyBox(props){

    const mesh = useRef()
    const texture = useLoader(THREE.TextureLoader, './assets/earth_normal_2048.jpg')

    const loader = new CubeTextureLoader();
    const textureImages = loader.load([
    //const textureImages = useLoader(THREE.TextureLoader, [
     img1, img2, img3, img4, img5, img6
     ]);

      useFrame(() => (mesh.current.rotation.y += 0.0002))

      console.log(texture)

    return (
        <mesh
            {...props}
            ref={mesh}
            scale={[1, 1, 1]}
            >
            <boxBufferGeometry args={[30, 30, 30]}/>
            {/* <meshStandardMaterial color={props.color} /> */}
            <meshStandardMaterial attach="material" map={texture} transparent={true} side={THREE.BackSide}/>

        </mesh>
    )

}

function SphereWithTexture(props) {
    // This reference will give us direct access to the mesh
    const mesh = useRef()

    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)

    //`console.log("Loading texture")
    //try {
    const texture = useLoader(THREE.TextureLoader, props.texture)
    
    useFrame(() => (mesh.current.rotation.y += (0.0002*props.rotationalSpeed)))

    return (
        <mesh
            {...props}
            ref={mesh}
            scale={[1, 1, 1]}
            onClick={(e) => setActive(!active)}
            onPointerOver={(e) => setHover(true)}
            onPointerOut={(e) => setHover(false)}
        >
            <sphereBufferGeometry args={[props.scaleFactor, 100, 100]}/>
            {/* <meshStandardMaterial color={props.color} /> */}
            <meshStandardMaterial attach="material" map={texture} transparent={true}/>

        </mesh>
    )
}

/*
function Camera(props) {
    const ref = useRef()
    const { setDefaultCamera } = useThree()
    // Make the camera known to the system
    useEffect(() => void setDefaultCamera(ref.current), [])
    // Update it every frame
    useFrame(() => ref.current.updateMatrixWorld())
    return <perspectiveCamera ref={ref} {...props} />
  }
  */

 function SkyBox() {
    const { scene } = useThree();
    const loader = new CubeTextureLoader();
    const textureImages = loader.load([
        img1, img2, img3, img4, img5, img6
    ]);        
    //   "./assets/1.jpg",
    //   "./assets/2.jpg",
    //   "./assets/3.jpg",
    //   "./assets/4.jpg",
    //   "./assets/5.jpg",
    //   "./assets/6.jpg"


    //]);
    // Set the scene background property to the resulting texture.
    scene.background = textureImages;
    console.log("Making the background thing")
    console.log(scene)
    console.log(textureImages)
    return null;
  }

  const CameraControls = () => {
      var {camera, gl} = useThree()
      useEffect(() => {
        //camera.setRotationFromEuler(new THREE.Euler(100,10,1))
        camera.position.set(0,0,330)

      const controls = new OrbitControls(camera, gl.domElement);
      controls.minDistance = 3;
      //controls.maxDistance = 20;

      return () => {
          controls.dispose();
      };

    }, [camera, gl]);
    return null;      
  }

function MeshComponent () {

    

    
        const mouse = useRef([0, 0])
        const mouseDown = useRef(false)
        var earthSpeed = 2
        var cloudSpeed = earthSpeed + 1;

        const onMouseMove = useCallback(({ clientX: x, clientY: y }) => (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]), [])
        const onMouseDown = useCallback(({}) => (mouseDown.current = true), [])
        const onMouseUp = useCallback(({}) => (mouseDown.current = false), [])
        const onMouseEvent = useCallback((mouseEvent) => {
                if(mouseDown.current){
                    earthSpeed = 0
                    //console.log(mouseEvent.clientX, mouseEvent.clientY)
                    //console.log(clientX, clientY, mouseDown.current)
                    mouse.current = [mouseEvent.clientX - window.innerWidth / 2, mouseEvent.clientY - window.innerHeight / 2]
                    // mouse.current.instanceMatrix.needsUpdate = true
                    // this.setState({reRender: true});
                }
        }, []);
        console.log(mouse.current[0])
        return (
            <div style={{ width: '100%', height: '100%' }} onMouseMove={onMouseEvent} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
                <Canvas style={{width: '100%', height: '2000px'}}>
                    <Suspense fallback={<>loading...</>}>
                        {/* <Camera position={[0, 0, 10]} /> */}
                        {/* <SkyBox/> */}
                        <CameraControls/>
                        <ambientLight />
                        <pointLight position={[300, 300, 300]} />
                        <pointLight position={[-300, 300, -300]} />
                        <HouseOfPhenikaa/>
                        {/* <SphereWithTexture position={[0, 0, 0]} texture={worldImg} scaleFactor={4.990} rotationalSpeed={earthSpeed}/> */}
                        {/* <SphereWithTexture position={[0, 0, 0]} texture={cloudImg} scaleFactor={5} rotationalSpeed={cloudSpeed}/> */}
                        {/* <BigSkyBox /> */}
                    </Suspense>
                </Canvas>
            </div>
        )
    
}
export default MeshComponent;

import React, { Component } from 'react'
import anime from 'animejs';

import classes from './News.module.css'

export default class Circuit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
    }
    render() {
        let pathEls = document.querySelectorAll('svg#circuit path');
        for (var i = 0; i < pathEls.length; i++) {
            let pathEl = pathEls[i];
            let offset = anime.setDashoffset(pathEl);
            pathEl.setAttribute('stroke-dashoffset', offset);
            anime({
                targets: pathEl,
                strokeDashoffset: [offset, 0],
                duration: anime.random(1000, 3000),
                delay: anime.random(0, 2000),
                loop: true,
                direction: 'normal',
                easing: 'easeInOutSine',
                autoplay: true
            });
        }
        var pathElsP = document.querySelectorAll('polyline');
        for (let x = 0; x < pathElsP.length; x++) {
            let pathElsPx = pathElsP[x];
            let offset = anime.setDashoffset(pathElsPx);
            pathElsPx.setAttribute('stroke-dashoffset', offset);
            anime({
                targets: pathElsPx,
                strokeDashoffset: [offset, 0],
                duration: anime.random(1000, 3000),
                delay: anime.random(0, 2000),
                loop: true,
                direction: 'normal',
                easing: 'easeInOutSine',
                autoplay: true
            });
        }
        return (
            <svg version="1.1" id="circuit" x="0px" y="0px" viewBox="0 0 600 699.3">
            
                <path className={classes.st0} d="M275.1,82.2c4.3,0,8.6,0,13,0c7.7,7.7,15.5,15.5,23.2,23.2c0,21.4,0,42.7,0,64.1c3.5,3.5,7,7,10.5,10.5
                c0,3.8,0,7.5,0,11.3c3.2,3.2,6.4,6.4,9.6,9.6"/>
                <path className={classes.st0} d="M302.2,117.8c0,20.2,0,40.4,0,60.6c1.5,1.5,3,3,4.5,4.5"/>
                <path className={classes.st0} d="M259,3.4c16.1,16.1,35.3,35.3,51.5,51.5c0,7.7,0,15.4,0,23.2"/>
                <path className={classes.st0} d="M251.3,3v32.6c6.4,0,12.9,0,19.3,0c2.2,2.2,4.5,4.5,6.7,6.7"/>
                <path className={classes.st0} d="M233.9,3.3c0,11.6,0,25.5,0,37.1c4.2,4.2,8.4,8.4,12.6,12.6c5.4,0,10.7,0,16.1,0"/>
                <path className={classes.st0} d="M217.7,3.3c0,11.8,0,25.6,0,37.3c7.4,7.4,14.8,14.8,22.3,22.3c13.1,0,26.2,0,39.4,0"/>
                <path className={classes.st0} d="M198.3,3.3c0,13,0,32.5,0,45.6c14.6,14.6,29.2,29.2,43.8,43.8c9.3,0,18.5,0,27.8,0c3,3,6,6,9.1,9.1
                c0,8.3,0,16.6,0,24.9c3.5,3.5,7.1,7.1,10.6,10.6"/>
                <path className={classes.st0} d="M184.9,3.3c0,15.3,0,32.7,0,48c14.2,14.2,28.5,28.5,42.7,42.7"/>
                <path className={classes.st0} d="M5.4,31.4c39.2,0,105,0,144.2,0c15,15,30,30,45,45"/>
                <path className={classes.st0} d="M5.9,41.2c45.5,0,91.8,0,137.3,0c5.6,5.6,11.3,11.3,16.9,16.9"/>
                <path className={classes.st0} d="M124,59c5.8,5.8,11.6,11.6,17.4,17.4c5.2,0,10.5,0,15.7,0"/>
                <path className={classes.st0} d="M5.4,59c8.8,0,79,0,87.8,0c11.7,11.7,23.4,23.4,35.1,35.2c25.5,0,51,0,76.6,0c6.1,6.1,12.2,12.2,18.2,18.2
                c8.5,0,16.9,0,25.4,0c14.1,14.1,28.1,28.1,42.2,42.2c0,9.4,0,18.7,0,28.1c13.3,13.3,26.7,26.7,40,40"/>
                <path className={classes.st0} d="M5.5,79.8c27.4,0,54.9,0,82.3,0c8.4,8.4,16.8,16.8,25.3,25.3c27.7,0,55.5,0,83.2,0
                c17.5,17.5,34.9,34.9,52.4,52.4c0,9,0,18,0,27c3.1,3.1,6.3,6.3,9.4,9.4c5.8,0,11.6,0,17.4,0c17.9,17.9,35.9,35.9,53.8,53.8"/>
                <path className={classes.st0} d="M277.3,211.9c12.7,12.7,25.4,25.4,38.2,38.2"/>
                <path className={classes.st0} d="M5.4,97.7c11.4,0,35.2,0,46.6,0c3.2,3.2,6.3,6.3,9.5,9.5c11.8,0,23.6,0,35.3,0c7.1,7.1,14.1,14.1,21.2,21.2
                c25.4,0,50.8,0,76.2,0c11.9,11.9,23.9,23.9,35.8,35.8c0,5.5,0,11,0,16.5"/>
                <path className={classes.st0} d="M210.5,163.1c-2-2-4-4-5.9-5.9c-13,0-26.1,0-39.1,0c-3.9-3.9-7.7-7.7-11.6-11.6c-40.5,0-108,0-148.5,0"/>
                <path className={classes.st0} d="M5.4,165.6c12.5,0,38.1,0,50.7,0c3.3-3.3,6.6-6.6,9.8-9.8c27.5,0,55.1,0,82.6,0c4.2,4.2,8.4,8.4,12.6,12.6
                c14.2,0,28.4,0,42.7,0c2.8,2.8,5.6,5.6,8.4,8.4"/>
                <path className={classes.st0} d="M5.4,332.4c12.5,0,38.1,0,50.7,0c3.3-3.3,6.6-6.6,9.8-9.8c27.5,0,55.1,0,82.6,0c4.2,4.2,8.4,8.4,12.6,12.6
                c14.2,0,28.4,0,42.7,0c2.8,2.8,5.6,5.6,8.4,8.4"/>
                <path className={classes.st0} d="M5.4,182.7c11.2,0,49.6,0,60.8,0c2.6-2.6,5.2-5.2,7.8-7.8c22.8,0,45.6,0,68.4,0c2.6,2.6,5.2,5.2,7.8,7.8
                c12.2,0,24.4,0,36.6,0"/>
                <path className={classes.st0} d="M5.4,276.2c11.2,0,49.6,0,60.8,0c2.6-2.6,5.2-5.2,7.8-7.8c22.8,0,45.6,0,68.4,0c2.6,2.6,5.2,5.2,7.8,7.8
                c12.2,0,24.4,0,36.6,0"/>
                <path className={classes.st0} d="M5.5,195.5c17.2,0,62.3,0,79.5,0c3.4-3.4,6.7-6.7,10.1-10.1c12,0,24,0,36.1,0c7,7,14,14,21,21
                c28.8,0,57.5,0,86.3,0"/>
                <path className={classes.st0} d="M5.4,206.8c26.4,0,80.1,0,106.5,0"/>
                <path className={classes.st0} d="M5.4,219.4c36.7,0,107.3,0,144,0c1.7,1.7,3.5,3.5,5.2,5.2c18.1,0,36.3,0,54.4,0c3.2-3.2,6.3-6.3,9.5-9.5
                c10.6,0,21.3,0,31.9,0c21.8,21.8,43.6,43.6,65.4,65.4c4.8,0,9.5,0,14.3,0"/>
                <path className={classes.st0} d="M5.4,235c35.3,0,83.6,0,118.9,0c2.9,2.9,5.8,5.8,8.7,8.7c11.1,0,22.2,0,33.2,0c3.3-3.3,6.6-6.6,9.8-9.8
                c25.4,0,50.8,0,76.2,0c2.3,2.3,4.6,4.6,7,7"/>
                <path className={classes.st0} d="M5.4,255.3c13.2,0,54.3,0,67.5,0c2.9-2.9,5.8-5.8,8.7-8.7c11.6,0,23.2,0,34.9,0c2.9,2.9,5.7,5.7,8.6,8.6
                c5.7,0,11.5,0,17.2,0c3.4,3.4,6.9,6.9,10.3,10.3c5.6,0,11.1,0,16.7,0c2.4-2.4,4.8-4.8,7.2-7.2c10.9,0,21.8,0,32.6,0c2-2,4-4,6-6"/>
                <path className={classes.st0} d="M5.4,311.2c36.8,0,101.4,0,138.2,0c4.8-4.8,9.5-9.5,14.3-14.3c23.7,0,47.4,0,71,0c5-5,10-10,15.1-15.1
                c0-8.9,0-17.8,0-26.7"/>
                <path className={classes.st0} d="M161.2,196.6c28.9,0,57.7,0,86.6,0c23.4,23.4,46.8,46.8,70.2,70.2"/>
                <path className={classes.st0} d="M248.4,129.6c9.5,9.5,19,19,28.5,28.5c0,5.2,0,10.4,0,15.6"/>
                <circle className={classes.st0} cx="244.1" cy="252.9" r="3.1"/>
                <circle className={classes.st0} cx="402.2" cy="444.2" r="3.1"/>
                <circle className={classes.st0} cx="155.8" cy="378" r="3.1"/>
                <circle className={classes.st0} cx="289" cy="455.7" r="3.1"/>
                <circle className={classes.st0} cx="288.7" cy="402.2" r="3.1"/>
                <circle className={classes.st0} cx="247.7" cy="406.8" r="3.1"/>
                <circle className={classes.st0} cx="218.7" cy="386.3" r="3.1"/>
                <circle className={classes.st0} cx="302.9" cy="413.7" r="3.1"/>
                <circle className={classes.st0} cx="352.3" cy="503.2" r="3.1"/>
                <circle className={classes.st0} cx="286.7" cy="498" r="3.1"/>
                <circle className={classes.st0} cx="282.6" cy="516.9" r="3.1"/>
                <circle className={classes.st0} cx="293.4" cy="548.1" r="3.1"/>
                <circle className={classes.st0} cx="283.5" cy="595.2" r="3.1"/>
                <circle className={classes.st0} cx="170.1" cy="452.1" r="3.1"/>
                <circle className={classes.st0} cx="39.4" cy="406.8" r="3.1"/>
                <circle className={classes.st0} cx="14.5" cy="418.7" r="3.1"/>
                <circle className={classes.st0} cx="201.9" cy="413.7" r="3.1"/>
                <circle className={classes.st0} cx="273.3" cy="662.3" r="3.1"/>
                <circle className={classes.st0} cx="236.4" cy="666.6" r="3.1"/>
                <circle className={classes.st0} cx="130.1" cy="534.8" r="3.1"/>
                <circle className={classes.st0} cx="100.3" cy="514.6" r="3.1"/>
                <circle className={classes.st0} cx="180.8" cy="428.1" r="3.1"/>
                <circle className={classes.st0} cx="384.5" cy="559" r="3.1"/>
                <circle className={classes.st0} cx="465.6" cy="460.2" r="3.1"/>
                <circle className={classes.st0} cx="456.7" cy="302.2" r="3.1"/>
                <circle className={classes.st0} cx="441.2" cy="318.7" r="3.1"/>
                <circle className={classes.st0} cx="354.3" cy="171.4" r="3.1"/>
                <circle className={classes.st0} cx="363.2" cy="98.5" r="3.1"/>
                <circle className={classes.st0} cx="402.8" cy="171.2" r="3.1"/>
                <circle className={classes.st0} cx="435.8" cy="200.1" r="3.1"/>
                <circle className={classes.st0} cx="460.7" cy="221.7" r="3.1"/>
                <circle className={classes.st0} cx="403.4" cy="186.9" r="3.1"/>
                <circle className={classes.st0} cx="366.3" cy="195.9" r="3.1"/>
                <circle className={classes.st0} cx="333.2" cy="202.7" r="3.1"/>
                <circle className={classes.st0} cx="332.4" cy="224.4" r="3.1"/>
                <circle className={classes.st0} cx="330.7" cy="249.2" r="3.1"/>
                <circle className={classes.st0} cx="317" cy="251.6" r="3.1"/>
                <circle className={classes.st0} cx="319.5" cy="268.4" r="3.1"/>
                <circle className={classes.st0} cx="332.4" cy="280.5" r="3.1"/>
                <circle className={classes.st0} cx="348.4" cy="308.8" r="3.1"/>
                <circle className={classes.st0} cx="314.7" cy="296.3" r="3.1"/>
                <circle className={classes.st0} cx="272.3" cy="266.8" r="3.1"/>
                <circle className={classes.st0} cx="245.7" cy="293.4" r="3.1"/>
                <circle className={classes.st0} cx="225.5" cy="320.6" r="3.1"/>
                <circle className={classes.st0} cx="403.4" cy="330.6" r="3.1"/>
                <circle className={classes.st0} cx="353.8" cy="331.4" r="3.1"/>
                <circle className={classes.st0} cx="373.9" cy="256.7" r="3.1"/>
                <circle className={classes.st0} cx="332.4" cy="182.7" r="3.1"/>
                <circle className={classes.st0} cx="333.2" cy="238.1" r="3.1"/>
                <circle className={classes.st0} cx="485.2" cy="278.6" r="3.1"/>
                <circle className={classes.st0} cx="472.2" cy="265.3" r="3.1"/>
                <circle className={classes.st0} cx="452.1" cy="179.6" r="3.1"/>
                <circle className={classes.st0} cx="494.9" cy="196.7" r="3.1"/>
                <circle className={classes.st0} cx="449" cy="151.5" r="3.1"/>
                <circle className={classes.st0} cx="448.4" cy="139.7" r="3.1"/>
                <circle className={classes.st0} cx="415.3" cy="82.2" r="3.1"/>
                <circle className={classes.st0} cx="409.8" cy="76" r="3.1"/>
                <circle className={classes.st0} cx="404.1" cy="70" r="3.1"/>
                <circle className={classes.st0} cx="391.4" cy="30.9" r="3.1"/>
                <circle className={classes.st0} cx="499.3" cy="147.2" r="3.1"/>
                <circle className={classes.st0} cx="476.9" cy="97" r="3.1"/>
                <circle className={classes.st0} cx="472.2" cy="151" r="3.1"/>
                <circle className={classes.st0} cx="503" cy="212.3" r="3.1"/>
                <circle className={classes.st0} cx="539.3" cy="260.4" r="3.1"/>
                <circle className={classes.st0} cx="444.8" cy="434.2" r="3.1"/>
                <circle className={classes.st0} cx="470.1" cy="385.7" r="3.1"/>
                <circle className={classes.st0} cx="420.1" cy="458.1" r="3.1"/>
                <circle className={classes.st0} cx="260.8" cy="242.8" r="3.1"/>
                <circle className={classes.st0} cx="216.8" cy="250.5" r="3.1"/>
                <circle className={classes.st0} cx="214.1" cy="345.4" r="3.1"/>
                <circle className={classes.st0} cx="123.7" cy="368.7" r="3.1"/>
                <circle className={classes.st0} cx="75" cy="367.3" r="3.1"/>
                <circle className={classes.st0} cx="190.4" cy="276.2" r="3.1"/>
                <circle className={classes.st0} cx="276" cy="210" r="3.1"/>
                <circle className={classes.st0} cx="114.4" cy="206.9" r="3.1"/>
                <path className={classes.st0} d="M5.4,292c26.4,0,80.1,0,106.5,0"/>
                <circle className={classes.st0} cx="114.4" cy="292.1" r="3.1"/>
                <circle className={classes.st0} cx="240.9" cy="206.4" r="3.1"/>
                <circle className={classes.st0} cx="213.6" cy="178.5" r="3.1"/>
                <circle className={classes.st0} cx="229.9" cy="182.9" r="3.1"/>
                <circle className={classes.st0} cx="276.9" cy="175.8" r="3.1"/>
                <circle className={classes.st0} cx="291.1" cy="138.4" r="3.1"/>
                <circle className={classes.st0} cx="247" cy="128.1" r="3.1"/>
                <circle className={classes.st0} cx="302.2" cy="115.7" r="3.1"/>
                <circle className={classes.st0} cx="272.9" cy="82.2" r="3.1"/>
                <circle className={classes.st0} cx="161.8" cy="59.8" r="3.1"/>
                <path className={classes.st0} d="M5.4,9.8h137.9c5.6,5.6,11.3,11.3,16.9,16.9"/>
                <circle className={classes.st0} cx="161.8" cy="28.4" r="3.1"/>
                <circle className={classes.st0} cx="229.8" cy="95.7" r="3.1"/>
                <circle className={classes.st0} cx="159.6" cy="76.6" r="3.1"/>
                <circle className={classes.st0} cx="196.7" cy="78" r="3.1"/>
                <circle className={classes.st0} cx="310.6" cy="80.7" r="3.1"/>
                <circle className={classes.st0} cx="314" cy="92.4" r="3.1"/>
                <circle className={classes.st0} cx="265.4" cy="52.9" r="3.1"/>
                <circle className={classes.st0} cx="279.3" cy="44.1" r="3.1"/>
                <circle className={classes.st0} cx="281.6" cy="62.9" r="3.1"/>
                <circle className={classes.st0} cx="189.1" cy="182.7" r="3.1"/>
                <circle className={classes.st0} cx="212.2" cy="164.6" r="3.1"/>
                <circle className={classes.st0} cx="122.3" cy="56.7" r="3.1"/>
                <circle className={classes.st0} cx="308.3" cy="184.7" r="3.1"/>
                <polyline className={classes.st0} points="315.5,94 345.7,124.3 326.4,143.6 352.3,169.5 "/>
                <polyline className={classes.st0} points="293.1,3.3 401.4,111.5 401.4,144 435.6,178.2 435.6,197.6 "/>
                <polyline className={classes.st0} points="364.4,100.5 380.4,116.5 380.4,148 401.1,168.6 "/>
                <polyline className={classes.st0} points="275.6,2.8 351.9,79.1 351.9,111.5 369.1,128.7 369.1,175.8 456.7,263.3 456.7,299.1 "/>
                <polyline className={classes.st0} points="333.9,183.8 416.3,266.1 416.3,331.3 469.1,384.1 "/>
                <polyline className={classes.st0} points="273.8,268.4 341.4,336 341.4,368.7 376.6,403.9 376.6,418.1 401.1,442.5 "/>
                <polyline className={classes.st0} points="335,239.7 360.8,265.6 360.8,316.4 389.8,345.3 407.8,345.3 444.8,382.3 444.8,431.5 "/>
                <polyline className={classes.st0} points="375.6,258 392.6,274.9 392.6,319.5 402.1,329 "/>
                <polyline className={classes.st0} points="247,295.2 310.4,358.7 310.4,399.1 352.1,440.8 352.1,455.4 386.6,489.9 435.6,489.9 463.8,461.7 "/>
                <polyline className={classes.st0} points="226.7,322.6 273.5,369.4 273.5,408 305.6,440.2 305.6,480 382.8,557.2 "/>
                <polyline className={classes.st0} points="5.9,345.4 77.1,345.4 120,388.2 202.4,388.2 265.2,451.1 265.2,475.6 285.9,496.2 "/>
                <polyline className={classes.st0} points="109.6,348.5 164.9,348.5 193.5,377.1 237.8,377.1 257.5,396.9 257.5,423.2 288.1,453.8 "/>
                <polyline className={classes.st0} points="126.8,368.7 147.3,368.7 154.5,375.8 "/>
                <polyline className={classes.st0} points="323.4,3.2 412.8,92.6 416.1,129.6 485.2,198.6 485.2,276.2 "/>
                <polyline className={classes.st0} points="5.4,379 53.4,379 109.1,434.6 179.8,434.6 291.8,546.7 "/>
                <polyline className={classes.st0} points="40.5,409.2 74.5,443.1 131.9,443.1 193.6,504.8 193.6,582.4 271.6,660.5 "/>
                <polyline className={classes.st0} points="76.7,368.7 121.1,413.1 165.7,413.1 179.3,426.6 "/>
                <polyline className={classes.st0} points="203.2,415.2 247.1,459 247.1,481.4 280.6,514.9 "/>
                <polyline className={classes.st0} points="171.5,453.8 239.4,521.7 239.4,550.9 281.8,593.3 "/>
                <polyline className={classes.st0} points="16.1,420.8 76.5,481.2 120.6,481.2 165.2,525.7 165.2,595.2 234.7,664.8 "/>
                <polyline className={classes.st0} points="355.4,333.1 401.8,379.5 401.8,401.1 420,419.3 420,455.2 "/>
                <polyline className={classes.st0} points="435.8,3.4 435.8,68.6 478.2,111 478.2,141.5 503,166.4 503,209.3 "/>
                <polyline className={classes.st0} points="420,4.2 420,69.1 452.8,101.9 452.8,131.7 470.3,149.2 "/>
                <polyline className={classes.st0} points="400.3,4.2 400.3,47.2 410.2,57.2 410.2,73.5 "/>
                <polyline className={classes.st0} points="345.9,3.3 376.8,34.2 386.8,34.2 394.2,41.6 394.2,59.8 402.9,68.4 "/>
                <polyline className={classes.st0} points="374.4,4.2 391.4,21.2 391.4,28 "/>
                <polyline className={classes.st0} points="417,83.8 438.7,105.5 438.7,129.6 446.8,137.7 "/>
                <polyline className={classes.st0} points="453.6,4.2 453.6,62.9 512.6,121.9 512.6,145.6 505.9,152.3 505.9,204.8 539.3,238.2 539.3,258 "/>
                <polyline className={classes.st0} points="478.3,98.8 501,121.5 501,129.6 491.7,138.8 498.1,145.2 "/>
                <polyline className={classes.st0} points="453.6,181.6 471.3,199.4 471.3,222.7 457.5,236.5 457.5,250.1 471.1,263.7 "/>
                <polyline className={classes.st0} points="405.3,188.7 430.5,213.9 452.6,213.9 459,220.3 "/>
                <polyline className={classes.st0} points="450.6,153.1 466.3,168.8 487.2,168.8 494.9,176.5 494.9,194.3 "/>
                <polyline className={classes.st0} points="368,197.6 435.5,265 435.5,292 441.2,297.7 441.2,316.6 "/>
                <polyline className={classes.st0} points="227.6,307.9 266.6,346.9 284.6,346.9 299.8,362.1 299.8,390.6 290.5,400 "/>
                <polyline className={classes.st0} points="161.2,314.3 193.5,314.3 207.1,327.9 219.9,327.9 244.6,352.7 244.6,368.7 219.9,368.7 "/>
                <polyline className={classes.st0} points="255.3,254.7 255.3,280.5 283.5,308.7 296.5,308.7 324.4,336.5 324.4,382.6 345.4,403.6 356.9,403.6 
                "/>
                <polyline className={classes.st0} points="219.9,387 233.3,400.4 241.9,400.4 246.2,404.7 "/>
                <polyline className={classes.st0} points="304.7,415.6 330.5,441.3 330.5,462.8 352.3,484.6 352.3,501.1 "/>
                <polyline className={classes.st0} points="317,296.5 337.4,296.5 347.5,306.7 "/>
                <polyline className={classes.st0} points="5.9,453.8 27.3,453.8 46.5,473.1 46.5,485.6 75.9,515 98.2,515 "/>
                <polyline className={classes.st0} points="5.9,463.3 19.7,463.3 35.5,479.2 35.5,501.1 78,543.6 93.2,543.6 102,534.9 126.8,534.9 "/>
            </svg>
            
        )
    }
}

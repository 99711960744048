import React, { Component } from "react";
import classes from "./Section2.module.css";
import Circuit from "./Circuit";
import Zoom from "./Zoom";
import Api from "./Api";

export default class SD_Section2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                {
                    id: 1,
                    name: "title",
                    content: "Lorem ipsum dummy data...",
                },
                {
                    id: 2,
                    name: "scroll_list",
                    content: "Lorem ipsum dummy data...",
                },
            ],
            circleData:[1,2,3,4,5],
            istoggleOn: false,
            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: true,
            activeIndex: [],
            activeText: []
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.toggleDetail = this.toggleDetail.bind(this);
        this.mouseEnter = this.mouseEnter.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    mouseEnter() {
        console.log("mouse enter")
    }
    mouseLeave() {
        console.log("mouse leave")
    }

    handleClick = () => {
        this.setState((prevState) => {
            return {
                istoggleOn: !prevState.istoggleOn
            }
        })
    }

    toggleDetail = (i) => {
        var result = true
        var result2 = []


        for (var a = 0; a <= this.state.activeIndex.length; a++) {
            if (i === this.state.activeIndex[a])
                result = false
        }

        if (result === true) {
            result2.push(i)
            this.setState({
                activeIndex: this.state.activeIndex.concat(result2)

            }, () => {

            }
            )

        } else {
            let mySet = new Set(this.state.activeIndex)
            if (mySet.has(i)){
                mySet.delete(i)
                this.setState({
                    activeIndex: [...mySet] 
                }, () => {

                }
                )
            } 
        }
    }

    toggleEnter = (i) => {
        var result = true
        var result2 = []

        for (var a = 0; a <= this.state.activeText.length; a++) {
            if (i === this.state.activeText[a])
                result = false
        }

        if (result === true) {
            result2.push(i)
            this.setState({
                activeText: this.state.activeText.concat(result2)

            }, () => {

            }
            )

        } else {
            let mySet = new Set(this.state.activeText)
            if (mySet.has(i)){
                mySet.delete(i)
                this.setState({
                    activeText: [...mySet] 
                }, () => {

                }
                )
            } 
        }
    }

    render() {
        const { contentSection, dummy, activeIndex, activeText ,circleData} = this.state;



        let titlecont,
        dataCircle,
        insideText,
            scroll_list,
            dummy_titlecont,
            box_content,
            dummy_scroll_list,
            dataS,
            dataText,
            titleInner,
            imageBackground,
            hexaBtn = null;

        if (this.state.isLoaded) {
            let count = 0;
            let arrIcon1 = []
            let arrIcon2 = []
            contentSection.forEach((item) => {
                count = count + 1;

                console.log(item)

               

                if (count === 1 && item.index === 1 && item.category === "Media") {
                    item.sections.widget_contents.forEach((s) => {
                        arrIcon1.push(s.file)
                    })
                }
                if (count === 2 && item.index === 2 && item.category === "Media") {
                    item.sections.widget_contents.forEach((s) => {
                       
                        arrIcon2.push(s.file)
                    })
                }
                if (count === 3 && item.index === 3 && item.category === "Text") {

                    item.sections.widget_contents.forEach((ele) => {

                       
                        titlecont = (
                           
                            <p className={classes.titleContent1}>{ele.content}</p>
                           
                        );
                    });
                } else if (
                    count === 1 &&
                    item.index === 1 &&
                    item.category !== "Text"
                ) {
                    titlecont = dummy_titlecont;
                }
                if (count === 4 && item.index === 4 && item.category === "Text") {


                    const dataScroll = [];
                    box_content = item.sections.widget_contents.forEach((dots) => {
                        dataScroll.push(dots.content);
                    });

                 

                    hexaBtn = item.sections.widget_contents.map((z, i) => {
                        
                        if (i !== 0) {
                            return (
                                <div>

                                </div>
                               
                            )
                        }
                    })

                  
                    
                    dataCircle = circleData.map((e) => {
                        return (
                            <div className={classes.circle}>
                                {/* {e} */}
                            </div>
                        )
                    })

                    dataText = dataScroll.map((e) => {
                        return (
                           
                                <div className={classes.circle}>
                                    <div className={classes.textBox}>
                                            {e}
                                    </div>
                            </div>
                       
                        )
                    })

                    insideText = dataScroll.map((z,i) => {
                        
                        let showingText = activeIndex.find((item) => { return (item === i + 1) }) ? classes.activeText : ''
                        let boldText = activeText.find((item) => { return (item === i + 1) }) ? classes.boldText : ''
                        return (
                          
                                 <div className={classes.circleBig} onClick={() => this.toggleDetail(i + 1)} onMouseEnter={() => this.toggleEnter(i + 1)} onMouseLeave={() => this.toggleEnter(i + 1)}>
                                     <img src={arrIcon2[i] ? arrIcon2[i] : './assets/head.svg'} alt="" onClick={this.handleClick}/>
                                     {/* <div className={['magnetBox', classes.smallBox].join(' ')}> */}
                                         <div className={classes.textBox}>

                                         <p className={classes.activeText}>{z}</p>
                                         </div>
                                 </div>
                              
                                
                       
                          
                        )
                    }) 


                } else if (
                    count === 2 &&
                    item.index === 2 &&
                    item.category !== "Text"
                ) {
                    scroll_list = dummy_scroll_list;
                }
                if (count === 5 && item.index === 5 && item.category === "Text") {
                    titleInner = item.sections.widget_contents[0].content
                }
                if (count === 6 && item.index === 6 && item.category === "Media"){
                    imageBackground = 
                        <div  className={classes.Content2}>

                            <div style={{background:`url(${item.sections.widget_contents[0].file}) center no-repeat ` , backgroundSize:"cover"}} className={classes.wrapBox}>

                            </div>
                              <p className={classes.innerText}>{titleInner}</p>
                        </div>

                   
                    

                }


            });
        }
        return (
            <div className={classes.Section2}>
                <div className={["container",classes.boxC].join(" ")}>
                    <div className={classes.containerBox2}>
                    <div className={classes.outerContent2}>
                      {dataCircle}
                      {insideText}
                     

                      {imageBackground}                           
                     
                    </div>
                        
                    </div>

                </div>
            </div>
        );
    }
}

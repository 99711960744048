    import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import Api from "./../../Api";
import classes from "./Industry.module.css";
import Slider from "react-slick";

export default class Industry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                { id: 1, name: "Title", content: "What is Lorem Ipsum?" },
                {
                    id: 2,
                    name: "Content",
                    content:
                        "Where does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
                },
                {
                    id: 4,
                    name: "Background Image",
                    assets: require("./assets/default-image.svg"),
                },
            ],
            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isDetail: false,
            boxCount: 0,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }
    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];
                    widget.push({
                        id: sections.widgets[0].widget_contents[0].id,
                        index: 0,
                        category: sections.widgets[0].widget_contents[0].category,
                        sections: sections.widgets[0].widget_contents[0],
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    // getSectionDetail() {
    //   const apiData = new Api();
    //   this.setState({ contentSection: [] }, () => {
    //     apiData
    //       .getSectionDetail()
    //       .then((result) => {
    //         const sections = result.data;
    //         const widget = [];

    //         result.data.widgets.forEach((item) => {
    //           widget.push({
    //             id: item.id,
    //             index: item.index,
    //             category: item.category,
    //             name: item.name,
    //             sections: item,
    //           });
    //         });

    //         sections.page = widget;
    //         this.setState({
    //           contentSection: widget,
    //           isLoaded: true,
    //         });
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         this.setState({
    //           isApiLoaded: true,
    //         });
    //       });
    //   });
    // }

    getHtmlParagraph(str) {
        if (str !== "") {
            return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ").replace(/&nbsp;/gi, " ");
        }
    }

    toggleDetail = () => {
        this.setState((prevState) => {
            return { isDetail: !prevState.isDetail };
        });
    };

    getUrlParser(url) {
        if (url !== "/") {
            return url.includes("http") ?
                <a className={classes.customLink} href={url} target="_blank" rel="noopener noreferrer">
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.viewMore}>{this.props.viewDetails}</p>
                </a>
                :
                <Link className={classes.customLink} to={url}>
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.viewMore}>{this.props.viewDetails}</p>
                </Link>
                ;
        } else {
            return <Link className={classes.customLink} to={url}>
                <div className={classes.outerCircle}>
                    <div className={classes.innerCircle}></div>
                </div>
                <p className={classes.viewMore}>{this.props.viewDetails}</p>
            </Link>
        }
    }

    render() {
        const settings = {
            // className: "center",
            centerMode: false,
            infinite: true,
            centerPadding: "",
            variableWidth: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            speed: 500,
            // swipe: true,
        };
        const { contentSection, dummy } = this.state;
        let title,
            desc,
            img,
            content,
            link = null;

        let detailContent,
            contentL1,
            contentL2,
            contentL3,
            contentL4,
            contentL5,
            contentL6,
            contentL7,
            contentL8,
            contentBox = null;
        let contentImage1,
            contentImage2,
            contentImage3 = null;

        if (this.props.items) {
            // console.log(this.props.dataAll);
            let items = this.props.items;
            title = <p className={classes.title}>{items[0].item.title}</p>;

            let contentImg = items[0].item.assets[0]
                ? items[0].item.assets[0].file
                : require("./assets/default-image.svg");
            img = (
                <img
                    className={[classes.imgContent].join(" ")}
                    src={contentImg}
                    alt=""
                />
            );
            content = (
                <div className={classes.contentDesc}>
                    {ReactHtmlParser(this.getHtmlParagraph(items[1].desc.content))}
                </div>
            );
            link = !this.state.isDetail ? (
                <div
                    className={[classes.contentLink].join(" ")}
                    onClick={this.toggleDetail}
                >
                    <div className="d-flex">
                        <div className={classes.outerCircle}>
                            <div className={classes.innerCircle}></div>
                        </div>
                        <p className={classes.contentLink}>{this.props.readMore}</p>
                    </div>
                </div>
            ) : (
                    <></>
                );

            const splitStr = items[0].item.content.split("#");
            desc = (
                <span className={classes.content}>
                    {ReactHtmlParser(splitStr[0] ? splitStr[0] : "")}
                </span>
            );
            contentL1 = splitStr[1] ? splitStr[1] : "";
            contentL2 = splitStr[2] ? splitStr[2] : "";
            contentL3 = splitStr[3] ? splitStr[3] : "";
            contentL4 = splitStr[4] ? splitStr[4] : "";
            contentL5 = splitStr[5] ? splitStr[5] : "";
            contentL6 = splitStr[6] ? splitStr[6] : "";
            contentL7 = splitStr[7] ? splitStr[7] : "";
            contentL8 = splitStr[8] ? splitStr[8] : "";

            let contentImg1 = items[0].item.assets[1]
                ? items[0].item.assets[1].file
                : require("./assets/default-image.svg");
            let contentImg2 = items[0].item.assets[2]
                ? items[0].item.assets[2].file
                : require("./assets/default-image.svg");
            let contentImg3 = items[0].item.assets[3]
                ? items[0].item.assets[3].file
                : require("./assets/default-image.svg");

            if (this.props.dataExt) {
                this.props.dataExt.forEach((d, i) => {
                    switch (i) {
                        case 0:
                            contentBox = d.sections.map((s, i) => {
                                let url = s.assets[0]
                                    ? s.assets[0].file
                                    : require("./assets/default-image.svg");
                                let link = s.url ? this.getUrlParser(s.url) : this.getUrlParser("/");
                                return (
                                    <div className={classes.box}>
                                        <img src={url} alt=""></img>
                                        <div className={classes.boxMask}></div>
                                        <div className={classes.contentFront}>
                                            <div className={classes.boxContentIn}>
                                                <p>{this.getHtmlParagraph(s.title)}</p>
                                            </div>
                                        </div>
                                        <div className={classes.contentBack}>
                                            <div className={classes.boxContentIn}>
                                                <div className="d-flex flex-column text-right mt-auto mb-auto">
                                                    <p>{this.getHtmlParagraph(s.content)}</p>
                                                    <div className="d-flex flex-row justify-content-end position-relative mt-2">
                                                        {link}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            });
                            break;
                        default:
                    }
                });
            }

            let animate = this.state.isDetail
                ? classes.fadeInBottom
                : classes.fadeOutTop;
            detailContent = this.state.isDetail ? (
                <div className={[classes.detailContent, animate].join(" ")}>
                    {ReactHtmlParser(contentL1)}

                    <p className={classes.titleContent}>{ReactHtmlParser(contentL2)}</p>

                    <>
                        <hr />
                        <div className={classes.allContentB}>
                            <div className={classes.txtParB2}>
                                <img className={classes.imgLogo} src={contentImg1} alt=" " />
                            </div>
                            <div className={classes.txtParB}>
                                <div className={classes.intxtParb}>
                                    <p className={classes.txtReal}>{ReactHtmlParser(contentL3)}</p>
                                </div>
                            </div>
                            
                        </div>
                    </>
                    <>
                        <hr />
                        <div className={classes.allContentC}>
                            <div className={classes.boxPar}>
                                <p className={classes.txtParC}>{ReactHtmlParser(contentL4)}</p>
                            </div>
                            <div className={classes.absImg}>
                                <img className={classes.imgLogo2} src={contentImg2} alt=" " />
                            </div>
                        </div>
                    </>

                    <p className={classes.titleContent}>{ReactHtmlParser(contentL5)}</p>
                    <>
                        <hr />
                        <div className={classes.allContentB}>
                            <div className={classes.txtParB}>

                                <p className={classes.txtReal}>{ReactHtmlParser(contentL6)}</p>
                            </div>
                            <div className={classes.txtParB2}>
                                <img className={classes.imgLogo} src={contentImg3} alt=" " />
                            </div>
                        </div>
                    </>

                    {ReactHtmlParser(contentL7)}
                    <Slider {...settings}>{contentBox}</Slider>

                    <div
                        className={[classes.contentLink].join(" ")}
                        onClick={this.toggleDetail}
                    >
                        <div className="d-flex">
                            <div className={classes.outerCircle}>
                                <div className={classes.innerCircle}></div>
                            </div>
                            <p className={classes.contentLink}>{this.props.readLess}</p>
                        </div>
                    </div>
                </div>
            ) : (
                    <></>
                );
        } else {
            title = (
                <p onClick={this.props.onClick} className={classes.title}>
                    {dummy[0].content}
                </p>
            );
            desc = <p className={classes.content}>{dummy[1].content}</p>;
            img = (
                <img className={classes.imgContentD} src={dummy[2].assets} alt="" />
            );

            content = (
                <div>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specimen book
          </p>
                    <br />
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specimen book
          </p>
                    <br />
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specimen book
          </p>
                </div>
            );
        }

        let customMargin = !this.state.isDetail ? "mb-5" : "";

        return (
            <div className={classes.sectionIndustry}>
                <div
                    className={[
                        "container d-flex flex-column",
                        classes.customContainer,
                    ].join(" ")}
                >
                    <div className={[classes.boxImage].join(" ")}>
                        <div className={classes.animate}>{img}</div>
                    </div>
                    <div className={["d-flex", customMargin].join(" ")}>
                        <div className={classes.boxContent}>
                            {title}
                            {desc}
                            {link}
                        </div>
                        <div className={classes.boxOverflow}>
                            <div className={classes.boxContentOverflow}>
                                <div className={[classes.contentOverflow].join(" ")}>
                                    {content}
                                </div>
                            </div>
                        </div>
                    </div>

                    {detailContent}
                </div>
            </div>
        );
    }
}

import * as React from "react";
import dateFormat from "dateformat";
import useScrollPosition from "@react-hook/window-scroll";
import classes from "./Navbar.module.css";

export default function Navbar(props) {
    const scrollY = useScrollPosition(60);
    let sticky, contentSticky, categories, category, search = null;
    let now = new Date();
    // let dateNow = dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT Z");
    let dateNowVi = now.toLocaleDateString('vi-VN', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    if (scrollY > "36") {
        sticky = classes.sticky;
        contentSticky = classes.contentSticky;
    } else {
        sticky = contentSticky = "";
    }

    if (props.categories) {
        let sortedCategories = [...props.categories]
        sortedCategories.sort()

        categories = sortedCategories.map(category => {
            return <option value={category}>{category}</option>
        })
    }

    return (
        <div className={["container", classes.customNavbar, sticky].join(" ")}>
            <div className={classes.navbar}>
                <p className={classes.navTitle}>Blog Đổi mới sáng tạo </p>
                <div className={[classes.boxNavbar, contentSticky].join(" ")}>
                    <select name="filter" id="filter" onChange={props.onChange}>
                        <option value="">{props.category}</option>
                        {categories}
                    </select>
                    <p className={classes.date}>{dateNowVi}</p>
                    <div className={classes.boxSearch}>
                        <input
                            onKeyDown={props.onKeyDown}
                            className={classes.inputSearch}
                            type="text"
                            placeholder={props.search}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import classes from "./Educations.module.css";

export default class Educations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        { id: 1, name: "Title", content: "What is Lorem Ipsum?" },
        {
          id: 2,
          name: "Content",
          content:
            "Where does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
        },
        {
          id: 4,
          name: "Background Image",
          assets: require("./assets/default-image.svg"),
        },
      ],
    };
  }
  render() {
    const { dummy } = this.state;
    let title,
      desc,
      img,
      content = null;
    if (this.props.items) {
      let items = this.props.items;
      title = <p className={classes.title}>{items[0].item.title}</p>;
      desc = (
        <span className={classes.content}>
          {ReactHtmlParser(items[0].item.content)}
        </span>
      );
      let contentImg = items[0].item.assets[0].file
        ? items[0].item.assets[0].file
        : require("./assets/default-image.svg");
      img = <img className={classes.imgContent} src={contentImg} alt="" />;
      content = <div>{ReactHtmlParser(items[1].desc.content)}</div>;
    } else {
      title = <p className={classes.title}>{dummy[0].content}</p>;
      desc = <p className={classes.content}>{dummy[1].content}</p>;
      img = (
        <img className={classes.imgContentD} src={dummy[2].assets} alt="" />
      );
      content = (
        <div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book
          </p>
          <br />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book
          </p>
          <br />
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book
          </p>
        </div>
      );
    }
    return (
      <div className={classes.sectionEducations}>
        <div
          className={[
            "container d-flex flex-column",
            classes.customContainer,
          ].join(" ")}
        >
          {/* <div className={classes.boxImage}>{img}</div> */}
          <div className="d-flex">
            <div className={classes.boxContent}>
              {title}
              {desc}
              <Link
                className={classes.link}
                to={{
                  pathname: `/article${
                    this.props.dataParam ? this.props.dataParam[0].url : ""
                  }/${
                    this.props.dataParam
                      ? this.props.dataParam[0].dataIndex[0].id
                      : ""
                  }`,
                }}
              >
                <div className={[classes.contentLink].join(" ")}>
                  <div className="d-flex">
                    <div className={classes.outerCircle}>
                      <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.contentLink}>Read More</p>
                  </div>
                </div>
              </Link>
            </div>
            {/* <div className={classes.boxOverflow}>
              <div className={classes.boxContentOverflow}>{content}</div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

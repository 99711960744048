import React, { Component } from "react";
import { Link } from "react-router-dom";

import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactHtmlParser from "react-html-parser";
import Api from "./Api";
import classes from "./Career_Top.module.css";
import Join from "./Join/Join";

export default class Career_Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        { id: 1, name: "title", content: "CAREER AT PHENIKAA GROUP" },
        { id: 2, name: "subtitle", content: "Lorem ipsum lorem ipsum lorem" },
        {
          id: 3,
          name: "desc",
          content:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
          id: 4,
          name: "brands",
          articles: [
            {
              name: "brand1",
              title: "VICOSTONE",
              assets: require("./assets/Vicostone.jpg"),
            },
            {
              name: "brand2",
              title: "PHENIKAA UNIVERSITY",
              assets: require("./assets/Phenikaa_University.jpg"),
            },
            {
              name: "brand3",
              title: "PRATI",
              assets: require("./assets/Phenikaa_Prati.jpg"),
            },
            {
              name: "brand4",
              title: "SCHOOL",
              assets: require("./assets/Phenikaa_School.jpg"),
            },
            {
              name: "brand5",
              title: "PHENIKAA CHEMICAL",
              assets: require("./assets/Phenikaa_Chermical.jpg"),
            },
            {
              name: "brand6",
              title: "PHENIKAA MANUFACTURING",
              assets: require("./assets/Phenikaa_Manufacturing.jpg"),
            },
          ],
        },
        {
          id: 4,
          name: "culture",
          articles: [
            {
              name: "culture1",
              title: "Lorem Ipsum",
              assets: require("./assets/Vicostone.jpg"),
            },
            {
              name: "culture2",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_University.jpg"),
            },
            {
              name: "culture3",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_Prati.jpg"),
            },
            {
              name: "culture4",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_School.jpg"),
            },
            {
              name: "culture5",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_Chermical.jpg"),
            },
            {
              name: "culture6",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_Manufacturing.jpg"),
            },
          ],
        },
      ],

      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    let joinUs,
      joinUsUrl,
      topTitle,
      topContent,
      brandText,
      brandCards,
      globalOpportunities,
      companyCulture,
      companyCultureImages,
      topBannerBackground,
      imageBackground,
      running,
      runningTxt = null;

    if (this.state.isLoaded) {
      this.state.contentSection.forEach((item, index) => {
        switch (index) {
          case 0:
            topBannerBackground = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].file
              : null;
            break;
          case 1:
            joinUsUrl = item.sections.widget_contents[0]
              ? item.sections.url
              : "/";
            joinUs = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Join Us";
            // console.log(joinUs);
            break;
          case 2:
            topTitle = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].title
              : "TopTitle";
            topContent = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Join Us";
            break;
          case 3:
            running = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Running";

            runningTxt = (
              <p
                className={[classes.runningBanner, classes.animated].join(" ")}
              >
                {running}
              </p>
            );

            break;
          default:
            break;
        }

        imageBackground = (
          <img
            src={
              topBannerBackground
                ? topBannerBackground
                : require("./assets/banner.jpg")
            }
            alt=""
          ></img>
        );
      });
    }

    const { dummy, contentSection } = this.state;
    let title,
      subtitle,
      desc,
      brands,
      culture,
      link = null;
    let dummyTitle,
      dummySubTitle,
      dummyDesc,
      dummyBrands,
      dummyCulture,
      dummyLink = null;

    dummyTitle = (
      <p className={classes.title}>{topTitle ? topTitle : dummy[0].content}</p>
    );
    //dummySubTitle = <p className={classes.subTitle}>{dummy[1].content}</p>
    dummyDesc = (
      <p className={classes.desc}>
        {topContent ? ReactHtmlParser(topContent) : dummy[2].content}
      </p>
    );
    dummyBrands = dummy[3].articles.map((s, i) => {
      return (
        <div className={classes.box}>
          <img src={s.assets} alt=""></img>
          <div className={classes.boxMask}></div>
          <div className={classes.boxContent}>
            <div className={classes.content}>
              <p className={classes.boxTitle}>
                <span>{s.title}</span>
              </p>
              {/* <div className={classes.boxLink}>
                <div>
                  <div className={classes.outerCircle}>
                    <div className={classes.innerCircle}></div>
                  </div>
                  <span>Join us</span>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      );
    });
    dummyCulture = dummy[4].articles.map((s, i) => {
      return (
        <div className={classes.boxCulture}>
          <figure>
            <img src={s.assets} alt=""></img>
          </figure>
          <div className={classes.boxMask}></div>
        </div>
      );
    });

    dummyLink = ( <></>
      // <div className={classes.circleLink}>
      //   <div>
      //     <span>Join us</span>
      //   </div>
      // </div>
    );
    if (this.state.isLoaded) {
      contentSection.forEach((c, i) => {
        switch (i) {
          case 0:
            link = c.sections.widget_contents.map((s) => {
              return (
                <>
                  {/* <Link to={joinUsUrl}>
                    <div className={classes.circleLink}>
                      <div>
                        <span>{joinUs}</span>
                      </div>
                    </div>
                  </Link> */}
                </>
              );
            });

            break;
          default:
            break;
        }
      });

      title = dummyTitle;
      subtitle = dummySubTitle;
      desc = dummyDesc;
      brands = dummyBrands;
      culture = dummyCulture;
    } else if (this.state.isApiLoaded) {
      title = dummyTitle;
      subtitle = dummySubTitle;
      desc = dummyDesc;
      brands = dummyBrands;
      culture = dummyCulture;
      link = dummyLink;
    }

    return (
      <div className={classes.section}>
        <div className={classes.banner}>
          {/* <img
            src={
              topBannerBackground
                ? topBannerBackground
                : require("./assets/banner.jpg")
            }
            alt=""
          ></img> */}
          {imageBackground}
          <div className={classes.bannerContent}>
            <p className={[classes.titleBanner, classes.animate].join(" ")}>
              {running}
            </p>
            <div className={classes.underlineAnimate}></div>
          </div>
        </div>
        <div className={["container", classes.customContainer].join(" ")}>
          {title}
          {desc}
          {link}
        </div>
      </div>
    );
  }
}
/*
                    <div className={classes.sectionWrap}>
                        <div className={classes.section1}>
                            <p className={classes.titleSection}>{brandText}</p>
                            <div className={["d-flex flex-wrap justify-content-center", classes.flexBrands].join(' ')}>
                                {brandCards ? brandCards : dummyBrands}
                            </div>
                        </div>

                        <div className={classes.section2}>
                            <p className={classes.titleSection}>{globalOpportunities}</p>
                            <div className='d-flex'>
                                <div className={[classes.slNav, 'mr-5'].join(' ')}>
                                    <ul>
                                        <li><b className='mr-5'>All brands</b> <FontAwesomeIcon color='#d4d4d4' className='ml-auto' icon={faCaretDown} size="lg" />
                                            <div className={classes.triangle}></div>
                                            <ul>
                                                <li>Brands1</li>
                                                <li>Brands2</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <div className={classes.slNav}>
                                    <ul>
                                        <li><b className='mr-5'>All opportunities</b> <FontAwesomeIcon color='#d4d4d4' className='ml-auto' icon={faCaretDown} size="lg" />
                                            <div className={classes.triangle}></div>
                                            <ul>
                                                <li>Opportunities1</li>
                                                <li>Opportunities2</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={classes.boxImgFull}>
                                <img src={require('./assets/map.png')} alt=""></img>
                            </div>
                        </div>

                        <div className={classes.section3}>
                            <p className={classes.titleSection}>{companyCulture}</p>
                        </div>

                    </div>
                </div>
                <div className={classes.cultureWrap}>
                    {companyCultureImages ? companyCultureImages : dummyCulture}
                </div>
            </div>
        )
    }
}
*/

import React, { Component } from "react";
import classes from "./Job_Details.module.css";
import Api from "./Api";
import dateFormat from "dateformat";
import {
  faArrowRight,
  faArrowLeft,
  faCircle,
  faMapMarkerAlt,
  faShareAlt,
  faBookmark,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class JobBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        widgets: [],
      },
      url: "",
      readMore: "Read More",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ article: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const data = [];
          data.push({
            share: sections.widgets[2].widget_contents[0]
              ? sections.widgets[2].widget_contents[0].content
              : "Share",
            save: sections.widgets[3].widget_contents[0]
              ? sections.widgets[3].widget_contents[0].content
              : "Saved",
          });

          this.setState({
            article: data,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { article } = this.state;
    let share,
      save = null;

    if (this.state.isLoaded) {
      share = article[0].share;
      save = article[0].save;
    } else if (this.state.isApiLoaded) {
      share = "Share";
      save = "Save";
    }
    return (
      <div className={classes.shareSaveApplyCon}>
        <div className={classes.topShare}>
          <div className={classes.share}>
            <FontAwesomeIcon className={classes.faIcon} icon={faShareAlt} />
            <span>{share}</span>
          </div>
          <div className={classes.share}>
            <FontAwesomeIcon className={classes.faIcon} icon={faBookmark} />
            <span>{save}</span>
          </div>
        </div>
        {/* <div className={classes.applySquare}>
          <p>{apply}</p>
        </div> */}
      </div>
    );
  }
}

import React, { Component } from "react";
import HorizontalScroll from "react-scroll-horizontal";

import HistoryDetail from "./AU_HistoryDetail";
import HistoryList from "./AU_HistoryListFixNew";

import Api from "./Api";
import classes from "./History.module.css";

class historysample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        {
          id: 2,
          name: "Articles",
          articles: [
            {
              name: "Articles1",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles2",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles3",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
          ],
        },
      ],
      sections: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState(
      {
        sections: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            this.setState({
              isApiLoaded: true,
            });
            const sections = result.data;
            const widget = [];

            result.data.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                internal: item.is_internal,
                name: item.name,
                years: item.article_years,
                sections: item,
              });
            });
            sections.page = widget;
            this.setState({
              sections: widget,
              isLoaded: true,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              isApiLoaded: true,
            });
          });
      }
    );
  }

  render() {
    const { sections, dummy } = this.state;

    let historyList,
      dummyContentSample,
      year,
      contents,
      titleContent,
      backBtn = null;

    dummyContentSample = dummy[0].articles.map((ele) => {
      year = <p>{ele.year}</p>;
      titleContent = <p>{ele.title}</p>;
      contents = <p>{ele.content}</p>;
      let contentDetail = (
        <HistoryDetail
          style={classes.boxDetailShow}
          title={ele.title}
          image={ele.assets}
          text={ele.content}
          year={year}
          date={year}
          onClick={this.toggleHandlerBack}
          onChange={this.changeBox}
        />
      );

      return (
        <div>
          <div className={classes.box}>
            <div className={classes._overlay}></div>
            <div className={classes.colorbox}>
              <p className={classes.text_fake}>{year}</p>

              <img
                className={classes.btn_hover}
                src={require("./asset/plus.svg")}
                alt=""
              />
            </div>
            <p className={classes.title_mid}>{titleContent}</p>
            <figure className={classes.figureimg}>
              <img className={classes.image_box} src={ele.assets} alt="" />
            </figure>
          </div>
        </div>
      );
    });

    if (this.state.isLoaded) {
      let count = 0;
      sections.forEach((item, index) => {
        count += 1;
        if (count === 1 && item.index === 1 && item.category === "Text") {
          backBtn = item.sections.widget_contents[0]
            ? item.sections.widget_contents[0].content
            : "Back";
        }
        if (count === 2 && item.index === 2 && item.category === "Article") {
          historyList = (
            <HistoryList id={item.id} years={item.years} backBtn={backBtn} />
          );
        }
      });
    } else if (this.state.isApiLoaded) {
      historyList = (
        <div className={classes.wrapp}>
          <HorizontalScroll
            reverseScroll={true}
            config={{ stiffness: 160, damping: 110 }}
          >
            {dummyContentSample}
          </HorizontalScroll>
        </div>
      );
    }

    return <>{historyList}</>;
  }
}

export default historysample;

import React, { Component } from 'react'

import Header from '../Header/Header'

import classes from './Articles.module.css'
import Api from './Api';
import ReactHtmlParser from "react-html-parser";


export default class Articles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            article: [],
            sections: [],
            isLoaded: false,
            idSection: 0,
            isApiLoaded: false
        }
        

        this.getSectionDetail = this.getSectionDetail.bind(this)
        
        
    }

    componentDidMount() {
        let paramId = this.props.id;
        //console.log(this.props.id)
        this.getSectionDetail(paramId);
      }
      getSectionDetail(paramId) {
        const apiData = new Api();
    
        this.setState(
          {
            article: [],
            sections: [],
          },
          () => {
              
            apiData.getSectionDetail().then((result) => {
                
              const sections = result.data;
              const widget = [];
              let id = sections.id;
              result.data.widgets.forEach((item) => {
                widget.push(item);
              });
              this.state.sections = widget;
              this.setState({
                idSection: id,
              });
            });
            
            apiData
              .getArticleDetailByArticleId(paramId)
              .then((result) => {
                //  console.log(result)
                const article = result.data;
    
                const assets = [];
    
    
                article.article_assets.forEach((item) => {
                  assets.push({ assets: item.asset_file });
                });
    
                const allcontent = article.article_languages;
    
                let content = allcontent.content;
                let title = allcontent.title;
                let category = article.category_name;
                let date = article.date;
    
                const widget = [];
    
                widget.push({
                  content: content,
                  title: title,
                  category: category,
                  date: date,
                  assets: assets,
                });
    
                 console.log(widget);
                article.page = widget;
                this.setState({ article: widget, isApiLoaded: true });
              })
              .catch((error) => {
                // console.log(error);
                this.setState({ isApiLoaded: true });
              });
          }
        );
        
      }

    render() {

        let title = null

        if(this.state.isApiLoaded){
            let content = this.state.article[0].content;
            title = this.state.article[0].title;
            
            
            var paragraphs = content.split('</p><p>')
            var leftRight = true;
            var counter = 0;
            var result = paragraphs.map((textItem, index) => {

                if(textItem === "&nbsp;"){}
                else if(this.state.article[0].assets[counter]){
                    let image = this.state.article[0].assets[counter].assets
                    counter += 1
                if(leftRight){
                    leftRight = !leftRight
                        return (
                            <div className={classes.box}>
                            <div className={classes.boxContent}>
                                <div className={classes.contentWrapper}>
                                    <p className={classes.description}>{ReactHtmlParser(textItem)}</p>
                                </div>
                            </div>
                            <div className={classes.boxImage}>
                                <img className={classes.contentImage} src={image} alt=""></img>
                            </div>
                        </div>
    
                        )

                } else {
                    leftRight = !leftRight
                    return (
                    <div className={classes.box}>
                        <div className={classes.boxImage}>
                            <img className={classes.contentImage} src={image} alt=""></img>
                        </div>
                        <div className={classes.boxContent}>
                            <div className={classes.contentWrapper}>
                                <p className={classes.description}>{ReactHtmlParser(textItem)}</p>
                            </div>

                        </div>
                    </div>
                    );

                }
                
            }
            else {

                return (
                    <div className={classes.box}>
                    
                        
                            <p className={classes.description}>{ReactHtmlParser(textItem)}</p>
                        
                    
                </div>
                )


            }
            }

            )
        }
        //if(this.state.article){console.log(this.state);}
        

        return (
            
            <div className={classes.contentSection}>
                <Header onPage={'home'} />
                <div className={['container', classes.customContainer].join(' ')}>
                    <div className={classes.contentHeader}>
                        <p className={classes.title}>{title}</p>
                        {result}
                    </div>
                </div>
            </div>
        )
    }
}




{/* <div className={classes.box}>
<div className={classes.boxContent}>
    <div className={classes.contentWrapper}>
        <p className={classes.contentTitle}>Lorem Ipsum</p>
        <p className={classes.subTitle}>Lorem Ipsum</p>
        <p className={classes.description}>Lorem Ipsum</p>
    </div>
</div>
<div className={classes.boxImage}>
    <img className={classes.contentImage} src={require('./assets/default-image.svg')} alt=""></img>
</div>
</div>
<div className={classes.box}>
<div className={classes.boxImage}>
    <img className={classes.contentImage} src={require('./assets/default-image.svg')} alt=""></img>
</div>
<div className={classes.boxContent}>
    <div className={classes.contentWrapper}>
        <p className={classes.contentTitle}>Lorem Ipsum</p>
        <p className={classes.subTitle}>Lorem Ipsum</p>
        <p className={classes.description}>Lorem Ipsum</p>
    </div>

</div>
</div> */}
import React, { Component } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import ReactHtmlParser from "react-html-parser";
import Api from "./Api";
import dateFormat from "dateformat";
import {
  faArrowRight,
  faArrowLeft,
  faCircle,
  faMapMarkerAlt,
  faShareAlt,
  faBookmark,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./Job_Details.module.css";
import JobBox from "./JobBox";
import BackBox from "./BackBox";
import TitleBox from "./TitleBox";
import BackNext from "./BackNext";

export default class JobDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogActive: 0,
      isBlog: false,
      contentArticle: {
        widgets: [],
      },
      backnext: [],
      asset: [],
      idSection: this.props.id,
      isLoaded: false,
      isApiLoaded: false,
      id: "0",
      idSection: this.props.id,
    };
    // this.setActiveBlog = this.setActiveBlog.bind(this);
    this.getArticleDetailByArticleId = this.getArticleDetailByArticleId.bind(
      this
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let param = this.props.id;
    this.getArticleDetailByArticleId(param);
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      return str
        .replace(/<\/?p>/gi, " ")
        .replace(/<p>/gi, " ")
        .replace(/&nbsp;/gi, " ");
    }
  }

  getArticleDetailByArticleId(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetailByArticleId(param)
        .then((result) => {
          // console.log(result);
          const articles = result.data;
          const art = [];
          const assets = [];
          const backnext = [];


          articles.article_assets.forEach((ass, i) => {
            if (i === 0) {
              assets.push({
                asset: ass.asset_file,
              });
            }
          });

          art.push({
            id: articles.id,
            category: articles.category_name,
            name: articles.name,
            title: articles.article_languages.title,
            content: articles.article_languages.content,
            author: articles.author_name,
            assets: assets,
            date: articles.date,
          });
          // })

          this.setState({
            contentArticle: art,
            asset: assets,
            isLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { blogActive, contentArticle, asset } = this.state;
    let title,
      date,
      content,
      titlejobDesc,
      benefit,
      benefitContent,
      jobDesc,
      date_job,
      reqContent,
      author,
      require,
      img = null;
    if (this.state.isLoaded) {
      img = asset[0] ? asset[0].asset : require("./assets/image.png");
      let a = contentArticle;
      let now = a[0].date;

      let d_job = new Date(now);
      const options = { year: "numeric", month: "long", day: "numeric" };
      date_job = d_job.toLocaleDateString("vi", options);

      author = a[0].author ? a[0].author : "Author";
      title = <p className={classes.title}>{a[0].title}</p>;

      const splitStr = a[0].content.split("#");

      content = (
        <p className={classes.content}>
          {ReactHtmlParser(splitStr[0] ? splitStr[0] : "")}
        </p>
      );

      titlejobDesc = (
        <p className={classes.jobDesc}>
          {ReactHtmlParser(splitStr[1] ? splitStr[1] : "")}
        </p>
      );

      jobDesc = (
        <p className={classes.reQ}>
          {ReactHtmlParser(splitStr[2] ? splitStr[2] : "")}
        </p>
      );

      require = (
        <p className={classes.jobDesc}>
          {ReactHtmlParser(splitStr[3] ? splitStr[3] : "")}
        </p>
      );

      reqContent = (
        <p className={classes.reQ}>
          {ReactHtmlParser(splitStr[4] ? splitStr[4] : "")}
        </p>
      );

      benefit = (
        <p className={classes.jobDesc}>
          {ReactHtmlParser(splitStr[5] ? splitStr[5] : "")}
        </p>
      );
      benefitContent = (
        <p className={classes.reQ}>
          {ReactHtmlParser(splitStr[6] ? splitStr[6] : "")}
        </p>
      );
    }

    return (
      <div className="blog">
        <div className={classes.boxBanner}>
          <div className={classes.frontDiv}></div>
          <img src={img} alt="" />
          <div className={classes.navigator}>
                    {/*<BackNext />*/}
          </div>
        </div>

        <TitleBox />

        <div className={classes.mainClass}>
          <ParallaxProvider>
            <React.Fragment>
              <div className={["container", classes.blogs].join(" ")}>
                <div className={classes.boxContent}>
                  <div className={classes.boxFlex}>
                    <div className={classes.leftOnly}>{title}</div>
                    <JobBox />
                  </div>

                  <div className={classes.companyAndLocContainer}>
                    <div>
                      <span>{author}</span>
                    </div>
                    <div className={classes.loc}>
                      <FontAwesomeIcon className="mr-2" icon={faMapMarkerAlt} />
                      <span>Hanoi, Vietnam</span>
                    </div>
                  </div>
                  <div className={classes.date}>{date_job}</div>
                  <div className={classes.contP}>
                    <p>&nbsp;</p>
                    {content}
                    <hr className={classes.line} />
                    {titlejobDesc}
                    {jobDesc}
                    <hr className={classes.line} />
                    {require}
                    {reqContent}
                    {benefit}
                    {benefitContent}
                  </div>
                </div>
              </div>
              <BackBox />
            </React.Fragment>
          </ParallaxProvider>
        </div>
      </div>
    );
  }
}

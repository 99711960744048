import React, { Component } from 'react';
import classes from './Diagram.module.scss'
class Diagram extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                { id: 1, title: "Lorem Ipsum 1" },
                { id: 2, title: "Lorem Ipsum 2" },
                { id: 3, title: "Lorem Ipsum 3" },
                { id: 4, title: "Lorem Ipsum 4" }

            ],
        }
    }
    render() {
        const { data } = this.state
        let dataDiagram
        console.log(this.props.arr)
        if (this.props.arr) {
            dataDiagram = this.props.arr.map((s, i) => {
                let items = s.map((element, i) => {
                    if (i !== 0){
                        return <div className={classes.textDes}><p>{element}</p></div>
                    }
                })
                let title = s.map((element, i) => {
                    if (i === 0){
                        return(
                            <div className={classes.boxRound}>
                                <div className={classes.boxContent}>
                                    <p className={classes.txtBoxRound}>{element}</p>
                                </div>
                            </div>
                        ) 
                    }
                })
                
            
                return (
                    <div className={classes.box}>
                        {/* <div className={classes.boxRound}><p className={classes.txtBoxRound}>{s.title}</p></div> */}
                        {title}
                        <div className={classes.line1}></div>
                        <div className={classes.line2}></div>
                        <div className={classes.line3}></div>
                        <div className={classes.line4}></div>
                        <div className={classes.line5}></div>
                        <div className={classes.line6}></div>
                        <div className={classes.line7}></div>
                        <div className={classes.line8}></div>
                        <div className={classes.line9}></div>
                        <div className={classes.line10}></div>
                        <div className={classes.line11}></div>
                        <div className={classes.line12}></div>
                        <div className={classes.line13}></div>
                        <div className={classes.line14}></div>
                        <div className={classes.circle1}></div>
                        <div className={classes.circle2}></div>
                        <div className={classes.circle3}></div>
                        <div className={classes.circle4}></div>
                        <div className={classes.boxDes}>
                            {items}
                            {/* {
                                s.forEach(element => {
                                    console.log(element)
                                    return <div><p>{element}</p></div>
                                })
                            } */}
                            {/* <div>
                                <p>Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>

                            </div>
                            <div>
                                <p>Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>

                            </div>
                            <div>
                                <p>Lorem Ipsum Lorem Ipsum Lorem Ipsum</p>

                            </div> */}


                        </div>
                    </div>
                )
            })
        }


        return (
            <div className={classes.wrappContent}>
                <div className={classes.diagram}>
                    {/* <p className={classes.titleSection}>Phenikaa</p> */}
                    <div className={classes.wrappBox}>

                        {dataDiagram}
                    </div>
                </div>
            </div>
        );
    }
}

export default Diagram;
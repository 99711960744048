import React, { Component } from "react";
import { Navbar, Nav, Form, Button, FormControl } from "react-bootstrap";
import "./CareerHeader.css";
import classes from "./CareerHeader.module.css";

class CareerHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBar: 1,
    };
    this.updateSelectedBtn = this.updateSelectedBtn.bind(this);
  }

  updateSelectedBtn(index) {
    this.setState({
      activeBar: index,
    });
  }

  render() {
    let careerTitle, jobVa, workEn, myApp, navLink, search;

    if (this.props.widget) {
      this.props.widget.map((z, index) => {
        if (index === 0) {
          careerTitle = z.content;
        }
      });

      navLink = this.props.widget.map((z, i) => {
        let selected,
          data = null;

        if (this.state.activeBar === i) {
          selected = classes.selected;
        }

        if (i !== 0 && i !== 4) {
          data = z.content;
        } else if (i === 4) {
          search = z.content;
        }

        return (
          <Nav.Link
            className={[classes.navLinks, selected].join(" ")}
            href="#features"
            onClick={(e) => this.updateSelectedBtn(i)}
          >
            {data}
          </Nav.Link>
        );
      });
    } else {
      careerTitle = "CAREER";
      jobVa = "Job Vacancy";
      workEn = "Work Environment";
      myApp = "My Applicatuon";
    }

    return (
      <Navbar className="navbar-custom">
        <div className={["container", "cusT"].join(" ")}>
          <Navbar.Brand className="nav-brand">{careerTitle}</Navbar.Brand>
          <Nav className="mr-auto">
            {navLink}
            {/* <Nav.Link className="selected" href="#home">
              {jobVa}
            </Nav.Link>
            <Nav.Link href="#features">{workEn}</Nav.Link>
            <Nav.Link href="#pricing">{myApp}</Nav.Link> */}
          </Nav>
          <Form inline>
            <FormControl
              type="text"
              placeholder={search}
              className="search-box"
            />
            <Button className="search-box-btn">
              <i class="fa fa-search" aria-hidden="true"></i>
            </Button>
          </Form>
        </div>
      </Navbar>
    );
  }
}

export default CareerHeader;

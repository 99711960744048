import React, { Component } from "react";
import { Card, Button } from "react-bootstrap";
import Api from "./api";
import classes from "./C_Section2.module.css";
import dateFormat from "dateformat";
import Slider from "react-slick";
import { Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import "./C_Section2.css";

export default class BoxCareer2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                { id: 1, name: "title", content: "Blog Đổi mới sáng tạo" },
                {
                    id: 2,
                    name: "content",
                    content:
                        "“Đổi mới sáng tạo” là một trong những giá trị cốt lõi của Tập đoàn Phenikaa. Với chúng tôi, đổi mới sáng tạo từ mỗi cá nhân, và sẽ được nhân lên với tinh thần sẻ chia, phản biện và hiện thực hóa bằng sức mạnh tập thể. Đây là nơi các chuyên gia, các nhà khoa học uy tín trao đổi về những sáng tạo trong lĩnh vực chuyên môn của họ, hướng tới việc tạo nên những giá trị ảnh hưởng sâu rộng tới Phenikaa nói riêng và cộng đồng xã hội nói chung. Và chính bạn cũng có thể nói lên quan điểm hay chia sẻ câu chuyện sáng tạo của chính mình cùng chúng tôi.",
                },
                {
                    id: 3,
                    name: "article",
                    articles: [
                        {
                            name: "content1",
                            author: "John doe x Jane doe",
                            content:
                                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
                            assets: require("../assets/default-image.svg"),
                        },
                        {
                            name: "content2",
                            author: "What is Lorem Ipsum?",
                            content:
                                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
                            assets: require("../assets/default-image.svg"),
                        },
                        {
                            name: "content2",
                            author: "What is Lorem Ipsum?",
                            content:
                                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
                            assets: require("../assets/default-image.svg"),
                        },
                    ],
                },
            ],
            contentArticle: {
                widgets: [],
            },
            url: "",
            readMore: "Read More",
            isLoaded: false,
            isApiLoaded: true,
        };
        this.getArticleDetail = this.getArticleDetail.bind(this);
    }

    componentDidMount() {
        if (this.props.id) {
            this.getArticleDetail(this.props.id);
        }
    }

    getArticleDetail(param) {
        const apiData = new Api();
        this.setState({ contentArticle: [] }, () => {
            apiData
                .getArticleDetail(param)
                .then((result) => {
                    const articles = result.data;
                    const art = [];
                    articles.results.forEach((a) => {
                        const assets = [];

                        a.article_assets.forEach((ass, i) => {
                            if (i === 0) {
                                assets.push({
                                    asset: ass.asset_file,
                                });
                            }
                        });

                        art.push({
                            id: a.id,
                            category: a.category_name,
                            name: a.name,
                            title: a.article_languages.title,
                            content: a.article_languages.content,
                            assets: assets,
                            date: a.date,
                        });
                    });

                    this.setState({
                        contentArticle: art,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    render() {
        const { contentArticle, isLoaded, isApiLoaded } = this.state;
        let positions, thisUrl;

        const sliderSettings = {
            className: "classSlider",
            infinite: true,
            centerPadding: "20px",
            variableWidth: true,
            slidesToShow: 3,
            swipeToSlide: true,
        };
        if (isLoaded) {
            const arr = contentArticle.reverse()
            positions = arr.map((z, index) => {
                thisUrl = this.props.array[index]
                    ? this.props.array[index].url
                    : "/";
                let strDate = z.date;
                let getDate = dateFormat(strDate, "dd-mm-yyyy");
                return (
                    <div>
                        <Link to={thisUrl}>
                            <Card className={[classes.box, classes.margcustom].join(" ")}>
                                <div className={classes.cardContent}>
                                    <div className={classes.boxTop}>
                                        <div className={classes.cardStatus}>
                                            <p className={[classes.text, classes.font_18].join(" ")}>
                                                {z.category}
                                            </p>
                                        </div>
                                        <h4 className={classes.titleCard}>{z.title}</h4>
                                    </div>

                                    <div className={classes.boxBottom}>
                                        {/* <div className={classes.company}>
                                            <p className={[classes.text, classes.font_16].join(" ")}>
                                                {z.category}
                                            </p>
                                        </div> */}
                                        <div>
                                            <p className={[classes.text, classes.font_16].join(" ")}>
                                                {getDate}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Link>
                    </div>
                );
            });
            //   boxPositionAv = contentArticle.map((z, index) => {
            //     thisUrl = this.props.array[index].url
            //       ? this.props.array[index].url
            //       : "/";

            //     let strDate = z.date;
            //     getDate = dateFormat(strDate, "dd-mm-yyyy");
            //     title = z.title;
            //     asset = z.assets[0]
            //       ? z.assets[0].asset
            //       : require("../assets/default-image.svg");

            //     return (
            //       <Card className={classes.box}>
            //         <div className={classes.cardContent}>
            //           <h4 className={classes.titleCard}>{title}</h4>
            //           <p className={classes.titleDate}>{getDate}</p>
            //         </div>
            //         <div className={classes.cardContentHover}>
            //           <div className={classes.boxText}>
            //             <h4 className={classes.titleCard2}>{title}</h4>
            //           </div>
            //           <div className={classes.boxTrans}>
            //             <Link to={thisUrl} className={classes.customLink}>
            //               <div className={classes.btnApply}>
            //                 <p>{this.props.app}</p>
            //               </div>
            //             </Link>
            //           </div>
            //         </div>
            //       </Card>
            //     );
            //   });
        }

        return (
            <>
                <div className={classes.bottomContent}>
                    <div className="d-flex flex-wrap justify-content-between">
                        {positions}
                    </div>
                </div>
            </>
        );
    }
}

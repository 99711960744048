import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../Home/Home";
import Awards from "../Awards/Awards";
import AwardsDetail from "../AwardsDetails/AwardsDetail";
import AboutUs from "../AboutUs/AboutUs";
import Chart from "../OrganizationChart/OrganizationChart";
import History from "../History/History";
import Business from "../Business/Business";
import News from "../News/News";
import Newsdetail from "../NewsDetail/Newsdetail";
// import Newsdetail from "../../sections/ND_Section1/ND_Section1";

import Sustainable from "../Sustainable/sustainable";
import Orientation from "../../sections/SD_Orientation/SD_Orientation";
import Stakeholders from "../Stakeholders/stakeholders";
import Innovation from "../Innovation/Innovation";
import Development from "../../sections/SD_Development/SD_Development";
import Environment from "../../sections/SD_Environment/SD_Environment";
import Article from "../ArticleDetail/article";
import ArticleNew from "../ArticleNew/ArticleNew";
import Articles from '../../sections/Articles/Articles'
import BOD from '../BOD/BOD'
import Industry from '../Industry/Industry'
import Technology from '../Technology/Technology'
import Educations from '../Educations/Educations'
import Scientific from '../Scientific/Scientific'
import Distribution from '../Distribution/Distribution'
import Community from '../Community/Community'
import ThreeJs from '../../components/UI/ThreeJs/ThreeJs'
import Sample from '../../components/UI/Sample/Sample'

// import Navorientation from "../../components/sections/Sustainable/Section 3/Navbar Orientation/NavOrientation"
import Blogs from "../Blogs/Blogs"
import BlogView from "../BlogView/BlogView"
import OrganizationChart from "../OrganizationChart/OrganizationChart";
import GlobalSearch from "../GlobalSearch/GlobalSearch"
import Career from "../Career/Career"
import ContactUs from '../ContactUs/ContactUs'
import CareerList from "../Career_List/Career_List"
import JobDetails from "../Job_Details/Job_Details"


class site extends Component {
  render() {
    return (
      <div>
        <Switch>

          <Route path="/career/career-list/career-details-page/:id" component={JobDetails} />

          <Route path="/contact-us" component={ContactUs} />
          <Route path="/career/career-list/" component={CareerList} />
          <Route path="/career" component={Career} />
          <Route path="/bod" component={BOD} />
          <Route path="/innovation/blog/:id" component={BlogView} />
          <Route path="/đổi-mới/blog-vi" component={Blogs} />
          <Route path="/innovation/blogs" component={Blogs} />
          <Route path="/innovation" component={Innovation} />
          <Route path="/đổi-mới" component={Innovation} />
          {/* <Route path="/business-area/detail/:id" component={Business} /> */}
          {/* <Route path="/business/detail/:id" component={Business} /> */}
          
          <Route path="/business/distribution" component={Distribution} />
          <Route path="/business/scientific" component={Scientific} />
          <Route path="/business/educations" component={Educations} />
          <Route path="/business/technology" component={Technology} />
          <Route path="/business/industry" component={Industry} />
          <Route path="/business/:id" component={Business} />
          <Route path="/kinh-doanh/:id" component={Business} />

          {/* <Route path="/business-area/:url/:id" component={Business} /> */}
          {/* <Route path="/business-area" component={Business} /> */}
          <Route path="/articlenew/:id" component={ArticleNew} />
          <Route path="/article/:url/:id" component={Article} />
          <Route path="/business/:url/:id" component={Article} />
          <Route path="/về-chúng-tôi/lịch-sử" component={History} />
         
          <Route path="/kinh-doanh/" component={Business} />
          <Route path="/business" component={Business} />

          <Route path="/tin-tức-và-sự-kiện/chi-tiết/:id" component={Newsdetail} />
          <Route path="/news-and-events/details/:id" component={Newsdetail} />
          <Route path="/news/:url/:id" component={Newsdetail} />
          <Route path="/news-events" component={News} />
          <Route path="/news" component={News} />
          <Route path="/awards/detail/:id" component={AwardsDetail} /> 
          <Route path="/awards" component={Awards} />
          <Route path="/about-us/organization" component={OrganizationChart} />
          <Route path="/about-us/history/" component={History} />
          <Route path="/about-us/chart" component={Chart} />
          <Route path="/about-us/awards/:id" component={AwardsDetail} />          
          <Route path="/about-us/awards" component={Awards} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/về-chúng-tôi" component={AboutUs} />
          <Route path="/sustainable-development/community-and-society" component={Community} />
          <Route path="/sustainable-development/orientation" component={Orientation} />
          <Route path="/sustainable-development/stakeholders" component={Stakeholders} />
          <Route path="/sustainable-development/development" component={Development} />
          <Route path="/sustainable-development/environment" component={Environment} />
          <Route path="/sustainable-development" component={Sustainable} />
          <Route path="/phát-triển-bền-vững/community-and-society" component={Community} />
          <Route path="/phát-triển-bền-vững/orientation" component={Orientation} />
          <Route path="/phát-triển-bền-vững/stakeholders" component={Stakeholders} />
          <Route path="/phát-triển-bền-vững/development" component={Development} />
          <Route path="/phát-triển-bền-vững/environment" component={Environment} />
          <Route path="/phát-triển-bền-vững" component={Sustainable} />
          <Route path="/search" component={GlobalSearch} />
          <Route path="/articles" component={Articles} />
          <Route path="/sample" exact component={Sample} />
          <Route path="/" exact component={Home} />
          
        </Switch>
      </div>
    );
  }
}

export default site;

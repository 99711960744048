import React, { useState } from 'react'

import { isMobile } from "react-device-detect";
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from './GlobalNetwork.module.css'

// function onVisible(isVisible) {
//     console.log('Element is now %s', isVisible ? 'visible' : 'hidden');
// }

export default function Box(props) {
    const formatNumber = (num) => {
        console.log(num)
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    const settings = {
        className: "",
        infinite: false,
        centerPadding: "",
        variableWidth: true,
        slidesToShow: 1,
        swipeToSlide: true,
    }
    let blackBox = false;
    let titleCountry, countryList = null

    const onVisible = (isVisible) => {
        console.log(isVisible ? "true" : 'false')
    }

    const [focus, setFocus] = useState(false);
    let content = props.content.map((item, i) => {
        let countdown = ""
        let highlight = ""
        let description = ""
        let lightClass = ""
        let dur = 15
        if (i < 4) {
            item.sections.widget_contents.forEach((element, index) => {
                switch (index) {
                    case 0:
                        countdown = element.content ? (element.content) : ''
                        if (countdown >= 10) { dur = 12 }
                        if (countdown >= 100) { dur = 10 }
                        if (countdown >= 1000) { dur = 8 }
                        if (countdown >= 10000) { dur = 6 }
                        break
                    case 1:
                        highlight = element.content ? element.content : ''
                        break
                    case 2:
                        description = element.content ? element.content : ''
                        break;
                    default:
                }
            })
        } else {
            let arr = []

            titleCountry = <p>{item.sections.name ? item.sections.name : ''} </p>
            countryList = item.sections.widget_contents.map((s, i) => {
                let boxes = null
                arr.push(s.content)

                if (Number.isInteger(parseInt(i + 1) / 6)) {
                    let items = arr.map(x => {
                        return (
                            <p className={classes.country}>{x}</p>
                        )
                    })
                    boxes = <div className={classes.boxCountry}>
                        {items}
                    </div>
                    arr = []
                }
                return (
                    <>
                        {boxes}
                    </>
                )
            })
        }

        if (blackBox) { lightClass = classes.boxLight }
        else { lightClass = classes.boxDark }

        blackBox = !blackBox;
        let visualContent = !isMobile ?
            (countdown !== "" ? <div className={lightClass}>
                <div className='align-self-center m-auto'>
                    <CountUp className={classes.num} start={focus ? 0 : null} end={countdown} duration={dur} separator=".">
                        {({ countUpRef }) => (
                            <VisibilitySensor onChange={(isVisible) => {
                                if (isVisible) { setFocus(true); }
                            }}>
                                <span className={classes.num} ref={countUpRef} />
                            </VisibilitySensor>
                        )}
                    </CountUp>
                    <span className={classes.text}>{highlight}</span><br />
                    <span className={classes.content}>{description}</span>
                </div>
            </div> : <></>)
            :
            <div className={classes.box}>
                <div className={classes.contentBox}>
                    <CountUp className={classes.contentSlider} end={countdown} duration={dur} />
                    <span className={classes.textSlider}>{highlight}</span><br />
                    <span className={classes.contentSlider}>{description}</span>
                </div>
            </div>

        return (
            <>
                {visualContent}
            </>
        )
    })

    let contents = !isMobile ?
        content :
        <Slider {...settings}>
            {content}
        </Slider>

    return (
        <>

            <div className={['container', classes.containerCountry].join(' ')}>
                {/* <div className={classes.boxTitle}>
                    {titleCountry}
                </div>
                <div className="d-flex flex-row">
                    {countryList}
                </div> */}
            </div>
            <div className={classes.boxContent}>
                {contents}
            </div>
        </>
    )
}

import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import classes from "./C_Section2.module.css";
import "./C_Section2.css";
import Api from "./../api";
import dateFormat from "dateformat";
import BoxCareer2 from "./BoxCareer2";

class sec2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: [],
      articleArray: [],
      categories: [],
      dummy: [
        {
          id: 1,
          name: "categories",
          options: [
            {
              name: "All Positions",
              isSelected: true,
            },
            {
              name: "Account Executive",
              isSelected: false,
            },
            {
              name: "Accounting",
              isSelected: false,
            },
            {
              name: "Creative",
              isSelected: false,
            },
            {
              name: "Engineering",
              isSelected: false,
            },
          ],
        },
        {
          id: 2,
          name: "positions",
          positions: [
            {
              title: "Engineering Team Lead - Data Engineering",
              company: "VICOSTONE",
              city: "Ha Noi",
              country: "Vietnam",
              expiredDate: "XX/XX/XXXX",
              category: "Available Position",
            },
            {
              title: "Engineering Team Lead - Data Engineering",
              company: "VICOSTONE",
              city: "Ha Noi",
              country: "Vietnam",
              expiredDate: "XX/XX/XXXX",
              category: "Available Position",
            },
            {
              title: "Engineering Team Lead - Data Engineering",
              company: "VICOSTONE",
              city: "Ha Noi",
              country: "Vietnam",
              expiredDate: "XX/XX/XXXX",
              category: "Available Position",
            },
            {
              title: "Engineering Team Lead - Data Engineering",
              company: "VICOSTONE",
              city: "Ha Noi",
              country: "Vietnam",
              expiredDate: "XX/XX/XXXX",
              category: "Available Position",
            },
            {
              title: "Engineering Team Lead - Data Engineering",
              company: "VICOSTONE",
              city: "Ha Noi",
              country: "Vietnam",
              expiredDate: "XX/XX/XXXX",
              category: "Available Position",
            },
            {
              title: "Engineering Team Lead - Data Engineering",
              company: "VICOSTONE",
              city: "Ha Noi",
              country: "Vietnam",
              expiredDate: "XX/XX/XXXX",
              category: "Available Position",
            },
          ],
        },
      ],
      activeBtn: 0,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.getArticles = this.getArticles.bind(this);
    this.getHtmlParagraph = this.getHtmlParagraph.bind(this);
    this.updateSelectedBtn = this.updateSelectedBtn.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }
  getSectionDetail() {
    const articleCollection = [];
    const apiData = new Api();
    this.setState(
      {
        sections: [],
        articleArray: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            this.setState({ isApiLoaded: true });
            const sections = result.data;
            const categories = [];
            const widget = [];

            categories.push(sections.widgets[1].article_categories);

            sections.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                sections: item,
              });
            });

            sections.page = widget;
            this.setState({
              sectionName: result.data.name,
              sections: widget,
              isLoaded: true,
              articleID: widget[0].id,
              articleArray: articleCollection,
              categories: categories,
            });
          })
          .catch((error) => {
            // console.log(error);
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  getArticles(param, articleURL) {
    const apiData = new Api();
    this.setState(
      {
        //contentArticle: [],
      },
      () => {
        apiData
          .getArticleDetailByYear(param, null, 50, null)
          .then((result) => {
            const articles = result.data;
            const art = [];
            articles.results.forEach((a) => {
              const assets = [];
              a.article_assets.forEach((ass, i) => {
                if (i === 0) {
                  assets.push({ asset: ass.asset_file });
                }
              });

              art.push({
                id: a.id,
                category: a.category_name,
                name: a.name,
                title: a.article_languages.title,
                content: a.article_languages.content,
                assets: assets,
                date: a.date,
                url: articleURL + "/" + a.id,
              });
            });

            this.setState({
              articleArray: art,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }

  updateSelectedBtn(index) {
    this.setState({
      activeBtn: index,
    });
  }

  render() {
    var boxPositionAv,
      availablePositionsText,
      openPositionsText,
      careerList,
      btmCareer2,
      positions,
      urlS = null;

    let categories = this.state.dummy[0].options.map((category) => (
      <div
        className={
          "cat-button " + (category.isSelected ? "cat-button-selected" : "")
        }
      >
        {category.name}
      </div>
    ));

    let availablePositions = 0;
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    if (this.state.isLoaded) {
      var expiredText = "Expired";
      var openText = "Open";

      this.state.sections.forEach((item, index) => {
        switch (index) {
          case 0:
            openPositionsText = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Open Positions";
            break;
          case 1:
            careerList = (
              <BoxCareer2
                id={item.id}
                array={this.state.articleArray}
                app={this.state.app}
              />
            );

            categories = this.state.categories[0].map((category, index) => {
              let selected = null;
              if (this.state.activeBtn === index) {
                selected = classes.selected;
              }
              return (
                <div
                  className={["cat-button", selected].join(" ")}
                  onClick={(e) => this.updateSelectedBtn(index)}
                >
                  {category}
                </div>
              );
            });
            break;
          case 2:
            availablePositionsText = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Available Positions";
            break;
          case 3:
            openText = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Open";
            expiredText = item.sections.widget_contents[1]
              ? item.sections.widget_contents[1].content
              : "Expired";
            break;
          case 4:
            availablePositions = item.contentItems;
            break;
          case 5:
            this.state.app = item.sections.widget_contents[0].content;
            break;

          default:
        }
        // switch (index) {
        //   case 0:
        //     openPositionsText = item.sections.widget_contents[0]
        //       ? item.sections.widget_contents[0].content
        //       : "Open Positions";
        //     break;
        //   case 1:
        //     newOpenPosition = item.sections.widget_contents[0]
        //       ? item.sections.widget_contents[0].content
        //       : "New Open Positions";
        //     break;

        //   case 2:
        //     availablePositionsText = item.sections.widget_contents[0]
        //       ? item.sections.widget_contents[0].content
        //       : "Available Positions";
        //     break;
        //   case 3:
        //     openText = item.sections.widget_contents[0]
        //       ? item.sections.widget_contents[0].content
        //       : "Open";
        //     expiredText = item.sections.widget_contents[1]
        //       ? item.sections.widget_contents[1].content
        //       : "Expired";
        //     break;
        //   case 4:
        //     availablePositions = item.contentItems;
        //     break;
        //   default:
        //     break;
        // }
      });

      // categories = this.state.categories[0].map((category) => {
      //   return <div className={"cat-button"}>{category}</div>;
      // });

      // positions = this.state.articleArray.map((position) => {
      //   var expired = date > position.date ? openText : expiredText;

      //   let strDate = position.date;
      //   let getDate = dateFormat(strDate, "dd-mm-yyyy");
      //   return (
      //     <div>
      //       <Link to={position.url}>
      //         <Card className={[classes.box, classes.margcustom].join(" ")}>
      //           <div className={classes.cardContent}>
      //             <div className={classes.cardStatus}>
      //               <p className={[classes.text, classes.font_18].join(" ")}>
      //                 {position.category}
      //               </p>
      //             </div>
      //             <h4 className={classes.titleCard}>{position.title}</h4>
      //             <div className={classes.company}>
      //               <p className={[classes.text, classes.font_16].join(" ")}>
      //                 {position.category}
      //               </p>
      //               {/* <p className={[classes.text, classes.font_16].join(" ")}><span>{ position.city }</span> - <strong><span>{ position.country }</span></strong></p> */}
      //             </div>
      //             <div>
      //               <p className={[classes.text, classes.font_16].join(" ")}>
      //                 {expired}
      //               </p>
      //               <p
      //                 className={[
      //                   classes.expiredDate,
      //                   classes.text,
      //                   classes.font_16,
      //                 ].join(" ")}
      //               >
      //                 {getDate}
      //               </p>
      //             </div>
      //           </div>
      //         </Card>
      //       </Link>
      //     </div>
      //   );
      // });
    } else {
      positions = this.state.dummy[1].positions.map((position) => {
        return (
          <div>
            <Card className="box">
              <div className="content">
                <div className="status">
                  <p className="text font-16">{position.category}</p>
                </div>
                <h4 className="titleCard">{position.title}</h4>
                <div className="company">
                  <p className="text font-16">{position.company}</p>
                  <p className="text font-16">
                    <span>{position.city}</span> -{" "}
                    <strong>
                      <span>{position.country}</span>
                    </strong>
                  </p>
                </div>
                <div>
                  <p className="text font-16">Expired</p>
                  <p className="text font-16">{position.expiredDate}</p>
                </div>
                <div className="fwd-button">
                  <i class="fa fa-share" aria-hidden="true"></i>
                </div>
              </div>
            </Card>
          </div>
        );
      });
    }
    return (
      <div className={["container", classes.cusT].join(" ")}>
        <div className="category">
          {categories}
          <div className="cat-button-plus">
            <p>+</p>
          </div>
        </div>
        {careerList}
        {/* <div className={classes.bottomContent}>
          <div className="d-flex flex-wrap justify-content-between">
            {positions}
          </div>
        </div> */}
      </div>
    );
  }
}

export default sec2;

import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { VectorMap } from '@south-paw/react-vector-maps';

import Anime from './Anime'

import './Map.css'
import classes from './Map.module.css'
import worldMap from '../Flag/World.json'

const MapCustom = styled.div`
  margin: 0 auto 1rem auto;
  width: 45vw;

  @media only screen and (max-width:420px) and (min-width: 361px) { 
    width: 100vw;
  }

  @media only screen and (max-width:360px) { 
    width: 100vw;
  }

  svg {
    stroke: rgba(255,255,255, .5);

    path {
      fill: rgba(0,0,0,.2);
      cursor: pointer;
      outline: none;

      &[id='node-light-id'], &[id='node-light-aus'], &[id='node-light-afsel'], &[id='node-light-ca'], &[id='node-light-br'], &[id='node-light-ru'], &[id='node-light-us'] {
        fill: rgba(255,255,255,1);
        filter: blur(10px);
        -webkit-filter: blur(100px);
        -moz-filter: blur(10px);
        -o-filter: blur(10px);
        -ms-filter: blur(10px);
      }

      &[id='node-light-vn'] {
        fill: #2A3B71;
      }

      // &[id='node-light-vn'] {
      //   fill: #fff;
      // }

      // When a layer is focused.
      &:focus {
        fill: rgba(0,0,0,1);
      }

      // When a layer is 'checked' (via checkedLayers prop).
      &[aria-checked='true'] {
        fill: red;
      }

      // When a layer is 'selected' (via currentLayers prop).
      &[aria-current='true'] {
        fill: red;
      }

      &[id='vn-id'], &[id='vn-aus'], &[id='vn-afsel'], &[id='vn-us'], &[id='vn-ca'], &[id='vn-br'], &[id='vn-ru'] {
        fill: transparent;
      }

      

      // &[id='us'], &[id='ca'], &[id='ar'], &[id='au'] , &[id='be'] , &[id='br'] , &[id='bn'], &[id='cn'], &[id='co'], &[id='cd'], 
      // &[id='cy'], &[id='cz'], &[id='gb'], &[id='ee'], &[id='fr'], &[id='de'], &[id='gr'], &[id='hk'], &[id='in'], &[id='id'], 
      // &[id='ie'], &[id='il'], &[id='it'], &[id='jp'], &[id='lb'], &[id='li'], &[id='mx'], &[id='mc'], &[id='mz'], &[id='ph'], 
      // &[id='pt'], &[id='qa'], &[id='ru'], &[id='sg'], &[id='rs'], &[id='si'], &[id='za'], &[id='kr'], &[id='es'], &[id='lk'], 
      // &[id='tw'], &[id='th'], &[id='ua'], &[id='us'], &[id='ae'] {
      //   fill: #2A3B71;
      // }

      &[id='us'], &[id='ca'], &[id='au'], &[id='br'], &[id='id'], &[id='ru'], &[id='za']
      {
        fill: #2A3B71;
      }

      &[id='vn']{
        fill: #F26522;
      }

      // You can also highlight a specific layer via it's id
      // &[id="nz-can"] {
      //   fill: rgba(56,43,168,0.6);
      // }
    }
  }
`;

export default function Map(props) {
  const [selected, setSelected] = useState([]);
  const [checked, setChecked] = useState([]);

  let backgroundImg = null
  if (props.mapsData) {
    props.mapsData.forEach((m, i) => {
      if (i === 3) {
        backgroundImg = m.sections.widget_contents.map(s => {
          // let image = s ? <img className={classes.backgroundImg} src={s.file} alt=""></img> : <></>
          // let image = s ? <div className={classes.imageMask} style={{backgroundImage: `linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(34,51,113,0.6190185546875) 50%, rgba(0,0,0,1) 100%), , url(${s.file})`}}></div> : <></>
          let image = s ? <div className={classes.imageMask} style={{ backgroundImage: `url(${s.file})` }}></div> : <></>

          return (
            <>
              {image}
            </>
          )
        })
      }
    })
  }

  const onClick = ({ target }) => {
    const name = target.attributes.name.value;
    const id = target.attributes.id.value;
    // id ? setChecked({checked: id}) : 'vn'
    setChecked({ checked: id })
    // selected.includes(id)
    //   ? setSelected(selected.filter(sid => sid !== id))
    //   : setSelected([...selected, id]);

    selected.includes(name) ? setSelected(name) : setSelected(name);
  }

  return (
    <div id="map" className={classes.Map}>
      {/* <div className={classes.boxImage}>
                {backgroundImg}
            </div> */}
      <Fragment>
        <div className={classes.fullWidth}>
          <MapCustom>
            <VectorMap {...worldMap}>
            </VectorMap>
          </MapCustom>
          <Anime />
        </div>
      </Fragment>
    </div >
  )
}
import React, { Component } from 'react'

import CareerHeader from './CareerHeader/CareerHeader'
import Career1 from './C_Section1/C_Section1'
import Career2 from './C_Section2/C_Section2'


export default class Career extends Component {
    render() {
        return (
            <div>
                <Career1 />
                <Career2 />
            </div>
        )
    }
}

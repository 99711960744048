import React, { Component } from "react"
import { Link } from "react-router-dom"

import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ReactHtmlParser from "react-html-parser"

import Api from "./Api"
import classes from "./Career_Full_Screen_Image_Part.module.css"

export default class Career_Full_Screen_Image_Part extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dummy: [
        { id: 1, name: "title", content: "CAREER AT PHENIKAA GROUP" },
        { id: 2, name: "subtitle", content: "Lorem ipsum lorem ipsum lorem" },
        { id: 3, name: "desc", content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." },
        {
          id: 4,
          name: "brands",
          articles: [
            {
              name: "brand1",
              title: "VICOSTONE",
              assets: require("./assets/Vicostone.jpg"),
            },
            {
              name: "brand2",
              title: "PHENIKAA UNIVERSITY",
              assets: require("./assets/Phenikaa_University.jpg"),
            },
            {
              name: "brand3",
              title: "PRATI",
              assets: require("./assets/Phenikaa_Prati.jpg"),
            },
            {
              name: "brand4",
              title: "SCHOOL",
              assets: require("./assets/Phenikaa_School.jpg"),
            },
            {
              name: "brand5",
              title: "PHENIKAA CHEMICAL",
              assets: require("./assets/Phenikaa_Chermical.jpg"),
            },
            {
              name: "brand6",
              title: "PHENIKAA MANUFACTURING",
              assets: require("./assets/Phenikaa_Manufacturing.jpg"),
            },
          ],
        },
        {
          id: 4,
          name: "culture",
          articles: [
            {
              name: "culture1",
              title: "Lorem Ipsum",
              assets: require("./assets/Vicostone.jpg"),
            },
            {
              name: "culture2",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_University.jpg"),
            },
            {
              name: "culture3",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_Prati.jpg"),
            },
            {
              name: "culture4",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_School.jpg"),
            },
            {
              name: "culture5",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_Chermical.jpg"),
            },
            {
              name: "culture6",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_Manufacturing.jpg"),
            },
          ],
        },
      ],

      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      activeImg: 0,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data
          const widget = []

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            })
          })

          sections.page = widget
          this.setState({
            contentSection: widget,
            isLoaded: true,
          })
        })
        .catch((error) => {
          console.log(error)
          this.setState({
            isApiLoaded: true,
          })
        })
    })
  }

  toggleExtends = (i) => {
    this.setState({
      activeImg: i,
    })
  }

  render() {
    let joinUs,
      joinUsUrl,
      topTitle,
      topContent,
      brandText,
      brandCards,
      globalOpportunities,
      companyCulture,
      companyCultureImages,
      topBannerBackground = null

    const { dummy, contentSection } = this.state
    let title,
      subtitle,
      desc,
      brands,
      culture,
      link = null
    let dummyTitle,
      dummySubTitle,
      dummyDesc,
      dummyBrands,
      dummyCulture,
      dummyLink = null

    dummyTitle = <p className={classes.title}>{topTitle ? topTitle : dummy[0].content}</p>
    dummyDesc = <p className={classes.desc}>{topContent ? ReactHtmlParser(topContent) : dummy[2].content}</p>
    dummyBrands = dummy[3].articles.map((s, i) => {
      return (
        <div className={classes.box}>
          <img src={s.assets} alt=''></img>
          <div className={classes.boxMask}></div>
          <div className={classes.boxContent}>
            <div className={classes.content}>
              <p className={classes.boxTitle}>
                <span>{s.title}</span>
              </p>
              <div className={classes.boxLink}>
                <div>
                  <div className={classes.outerCircle}>
                    <div className={classes.innerCircle}></div>
                  </div>
                  <span>Join us</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
    dummyCulture = dummy[4].articles.map((s, i) => {
      return (
        <div className={classes.boxCulture}>
          <figure>
            <img src={s.assets} alt=''></img>
          </figure>
          <div className={classes.boxMask}></div>
        </div>
      )
    })

    dummyLink = (
      <div className={classes.circleLink}>
        <div>
          <span>Join us</span>
        </div>
      </div>
    )

    if (this.state.isLoaded) {
      this.state.contentSection.forEach((item, index) => {
        switch (index) {
          case 0:
            topBannerBackground = item.sections.widget_contents[0] ? item.sections.widget_contents[0].file : null
            break
          case 1:
            joinUsUrl = item.sections.widget_contents[0] ? item.sections.url : "/"
            joinUs = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Join Us"
            break
          case 2:
            topTitle = item.sections.widget_contents[0] ? item.sections.widget_contents[0].title : "TopTitle"
            topContent = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Join Us"
            break
          case 3:
            brandText = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Phenikaa Brands"
            break
          case 4:
            var imageUrl = item.sections.url
            brandCards = item.sections.widget_contents.map((imageItem) => {
              var imageFile = imageItem.file
              var imageName = imageItem.description
              return (
                <div className={classes.box}>
                  <img src={imageFile} alt=''></img>
                  <div className={classes.boxMask}></div>
                  <div className={classes.boxContent}>
                    <div className={classes.content}>
                      <p className={classes.boxTitle}>
                        <span>{imageName}</span>
                      </p>
                      <div className={classes.boxLink}>
                        <div>
                          <div className={classes.outerCircle}>
                            <div className={classes.innerCircle}></div>
                          </div>
                          <span>{joinUs}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
            break
          case 5:
            globalOpportunities = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Global Opportunities"
            break
          case 6:
            companyCulture = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Company Culture"
            break
          case 7:
            var imageUrl = item.sections.url
            companyCultureImages = item.sections.widget_contents.map((imageItem, i) => {
              var imageFile = imageItem.file
              var imageName = imageItem.description
              let active = this.state.activeImg === i ? classes.activeImg : ""
              return (
                <div className={[classes.boxGallery, active].join(" ")} onClick={() => this.toggleExtends(i)}>
                  <figure>
                    <img src={imageItem.file} alt=''></img>
                  </figure>
                </div>
              )
            })
            break
          default:
            break
        }
      })
    } else if (this.state.isApiLoaded) {
      title = dummyTitle
      subtitle = dummySubTitle
      desc = dummyDesc
      brands = dummyBrands
      culture = dummyCulture
      link = dummyLink
    }

    return (
      <>
        <div className={["container", classes.customContainer].join(" ")}>
          <hr />

          <div className={classes.section}>
            <div className={classes.cultureWrap}>{companyCultureImages ? companyCultureImages : dummyCulture}</div>
          </div>
        </div>
      </>
    )
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import ReactHtmlParser from "react-html-parser";

import Api from "./Api";

import classes from "./Section4.module.css";

export default class Section4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dummy: [
        { id: 1, name: "title", content: "Kaizen" },
        {
          id: 2,
          name: "article",
          articles: [
            {
              name: "KAIZEN",
              title: "KAIZEN",
              content: "Lorem ipsum lorem ipsum lorem ipsum",
            },
          ],
        },
        {
          id: 3,
          name: "chart",
          dataChart: [
            {
              x: "January",
              y: 50,
            },
            {
              x: "January",
              y: 60,
            },
            {
              x: "January",
              y: 70,
            },
          ],
        },
      ],
      chartData: {},
      data: [],
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: true,
    };
    this.chartRef = React.createRef();
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];
          const chartData = [];

          let cek = "Jan 90";
          let spilt = cek.split(" ", 2);

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              is_internal: item.is_internal,
              name: item.name,
              sections: item,
            });
          });
          const chartY = [];
          const chartX = [];
          result.data.widgets[3].widget_contents.forEach((ele, index) => {
            
            chartX.push(ele.content.split(" ", 2));
            return chartX;
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            chartData: chartX,
            isLoaded: true,
          });
          //   console.log("ini chart" + this.state.contentSection);
        })

        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { dummy, contentSection, chartData } = this.state;
    let dummyTitle,
      dummyArticle,
      dummyReadMore,
      title,
      dataChart,
      article,
      chart,
      url,
      readMore = null;

    dummyTitle = <p className={classes.title}>{dummy[0].content}</p>;
    dummyArticle = dummy[1].articles.map((s, i) => {
      return (
        <>
          <p className={classes.content}>
            Chương trình Kaizen được phát động lần đầu tiên trên toàn Tập đoàn
            Phenikaa vào năm 2015, tới nay đã trở thành văn hóa, tư tưởng của
            mỗi người lao động Phenikaa.
            <br />
            Trên tinh thần cải tiến không ngừng, Tập đoàn Phenikaa khuyến khích
            và tạo mọi điều kiện cho CBCNV tham gia đóng góp ý tưởng cải tiến,
            đổi mới trong công việc ngay từ những hành động đơn giản nhất. Thay
            đổi nhỏ nhưng chính những ý tưởng Kaizen đó đã góp phần không nhỏ
            vào thành công của Tập đoàn Phenikaa trong việc làm chủ công nghệ,
            nâng cao chất lượng, năng suất và tối ưu hiệu quả sức lao động và
            thời gian.
            <br />
            (Graph the number of Kaizen ideas from 2015 to 2019 and the value of
            benefits) The data will be updated in accordance with the year with
            pictures / articles about the Group's kaizen activities on the
            Journal ...)
          </p>
        </>
      );
    });

    dummyReadMore = <p className={classes.linkAll}>Read more</p>;
    if (this.state.isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;

        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              title = <p className={classes.title}>{s.content}</p>;
            } else {
              title = dummyTitle;
            }
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Text"
        ) {
          title = dummyTitle;
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          console.log(item)
          url = item.sections.url;
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              readMore = s.content;
            } else {
              readMore = "Read More";
            }
          });
        } else if (
          count === 2 &&
          item.index === 2 &&
          item.category !== "Text"
        ) {
          readMore = "Read More";
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          if (item.sections.is_internal) {
            article = item.sections.widget_contents
              ? item.sections.widget_contents.map((s) => {
                  return (
                    <>
                      <span className={classes.content}>
                        {ReactHtmlParser(s.content)}
                      </span>
                    </>
                  );
                })
              : dummyArticle;
            readMore = item.sections.widget_contents
              ? item.sections.widget_contents.map((s) => {
                  return (
                    <>
                      <Link
                        to={`${url}/${s.id}`}
                        className={classes.linkAll}
                      >
                        <span>{readMore}</span>
                      </Link>
                    </>
                  );
                })
              : dummyReadMore;
          }
        } else if (
          count === 3 &&
          item.index === 3 &&
          item.category !== "Article"
        ) {
          article = dummyArticle;
        }

        if (count === 4 && item.index === 4 && item.category === "Text") {
          let XX = [];
          let YY = [];
          chartData.map((n, index) => {
           
            XX.push(n[0]);
            YY.push(n[1]);
          });
          dataChart = (canvas) => {
            // console.log(XX);
            const ctx = canvas.getContext("2d");
            const gradient = ctx.createLinearGradient(0, 0, 0, 450);
            gradient.addColorStop(0, "rgba(20, 122, 214, 0.5)");
            gradient.addColorStop(0.5, "rgba(20, 122, 214, 0.25)");
            gradient.addColorStop(1, "rgba(20, 122, 214, 0)");
            return {
              labels: XX,
              datasets: [
                {
                  label: "Customer",
                  backgroundColor: gradient,
                  pointBackgroundColor: "white",
                  borderWidth: 1,
                  borderColor: "#147AD6",
                  data: YY,
                },
              ],
            };
          };
        }
      });
    } else if (this.state.isApiLoaded) {
      title = dummyTitle;
      article = dummyArticle;
      readMore = dummyReadMore;
    }

    var options = {
      responsive: true,
      maintainAspectRatio: true,
      animation: {
        easing: "easeInOutQuad",
        duration: 520,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              // color: 'rgba(200, 200, 200, 0.05)',
              color: "rgba(20, 122, 214, 0.05)",
              lineWidth: 1,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              // color: 'rgba(200, 200, 200, 0.08)',
              color: "rgba(20, 122, 214, 0.08)",
              lineWidth: 1,
            },
          },
        ],
      },
      elements: {
        line: {
          tension: 0.4,
        },
      },
      legend: {
        display: false,
      },
      point: {
        backgroundColor: "white",
      },
      tooltips: {
        titleFontFamily: "Open Helvetica",
        backgroundColor: "rgba(0,0,0,0.3)",
        titleFontColor: "red",
        caretSize: 5,
        cornerRadius: 2,
        xPadding: 10,
        yPadding: 10,
      },
    };

    return (
      <div className={classes.contentSection}>
        <div className={["container", classes.boxC].join(" ")}>
          {title}

          <div className={classes.box}>
            <div className={classes.boxLeft}>{article}</div>
            <div className={classes.boxRight}>
              <p className={classes.titleChart}>
                Vẽ biểu đồ số ý tưởng Kaizen từ 2015- 2019
              </p>
              <div className={classes.lineChart}>
                <Line
                  id="lineChart"
                  ref={this.chartRef}
                  data={dataChart}
                  width={100}
                  height={55}
                  options={options}
                />
              </div>
            </div>
          </div>

          <div className={classes.boxLink}>
            <div className={classes.outerCircle}>
              <div className={classes.innerCircle}></div>
            </div>
            {readMore}
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component, Fragment } from "react";
import classes from "./article.module.css";
import Header from "../../sections/Header/Header";
import Footer from "../../sections/Footer/Footer";
import { ParallaxProvider } from "react-scroll-parallax";
import Slider from "react-slick";
import Api from "./Api";
import ReactHtmlParser from "react-html-parser";

class article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: <div></div>,
      currentPage: null,
      contentArticle: [],
      contentArticle_In: [],
      isIn: false,
      isLoaded: false,
      isApiLoaded: false,
      articleId: 0,
      isHover: false,
      sec: [],
    };
    this.changeHover = this.changeHover.bind(this);
  }
  componentDidMount() {
    let url = this.props.match.params.url;
    let id = this.props.match.params.id;
    console.log(url);
    console.log(id)
    this.getArticleDetail(id);
    this.setState({ articleId: id });
  }

  changeHover() {
    this.setState({ isHover: true });
  }

  getArticleDetail(id) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetail(id)
        .then((result) => {
          const articles = result.data;
          const dataAll = [];
          console.log("masuk")

          console.log(articles);

          if (id === "") {
            articles.results.forEach((ele) => {
              const dataAsset = [];
              ele.article_assets.forEach((x) => {
                console.log("here");
                dataAsset.push({
                  asset: x.asset_file,
                });
              });

              dataAll.push({
                category: ele.category_name,
                date: ele.date,
                title: ele.article_languages.title,
                content: ele.article_languages.content,
                asset: dataAsset,
              });
            });
            this.setState({
              contentArticle: dataAll,
              isLoaded: true,
            });
          } else {
            const dataAsset = [];
            dataAsset.push({
              asset: articles.article_assets[0]
                ? articles.article_assets[0].asset_file
                : "",
            });

            dataAll.push({
              category: articles.category_name,
              date: articles.date,
              title: articles.article_languages.title,
              content: articles.article_languages.content,
              asset: dataAsset,
            });
            this.setState({
              contentArticle_In: dataAll,
              isLoaded: true,
              isIn: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {
    const { contentArticle, contentArticle_In, dummy, sec } = this.state;
    let contentArticles,
      boxAbsolute,
      boxShare,
      boxRight,
      boxSlide,
      boxImg = null;

    if (this.state.isLoaded) {
      if (this.state.isIn) {
        contentArticle_In.map((ele) => {
          boxAbsolute = (
            <div className={classes.box_absolute}>
              <div
                className={classes.boxBack}
                onClick={() => this.props.history.goBack()}
              >
                <img src={require("./assets/Path235.svg")} alt="" />
                &nbsp; Back
              </div>
              <div className={classes.categoryBox}>
                <p className={classes.categoryText}>{ele.category}</p>
              </div>
              &nbsp;
              <div className={classes.titleBox}>
                <p className={classes.titleText}>Page</p>
              </div>
            </div>
          );
          contentArticles = (
            <div className={classes.scroll_content}>
              <div className={classes.title}>{ele.title}</div>
              <div className={classes.tgl}>{ele.date}</div>
              <div className={classes.isi}>{ReactHtmlParser(ele.content)}</div>
            </div>
          );
          if (ele.asset.length === 1) {
            let imgData = ele.asset[0] ? (
              <figure className={classes.figure}>
                <img
                  className={classes.contentImgRight}
                  src={ele.asset[0].asset}
                  alt=" "
                />
              </figure>
            ) : (
              <figure className={classes.figure}>
                <img
                  className={classes.contentImgRight}
                  src={require("./assets/default-image.svg")}
                  alt=" "
                />
              </figure>
            );

            boxRight = (
              <div className={classes.boxRight} onMouseLeave={this.changeHover}>
                {imgData}
              </div>
            );
          } else if (ele.asset.length >= 2) {
            this.setState({ isHover: true });
            let imgData = ele.asset[0] ? (
              <figure className={classes.figure}>
                <img
                  className={classes.contentImgRight}
                  src={ele.asset[0].asset}
                  alt=" "
                />
              </figure>
            ) : (
              <figure className={classes.figure}>
                <img
                  className={classes.contentImgRight}
                  src={require("./assets/default-image.svg")}
                  alt=" "
                />
              </figure>
            );

            boxImg = ele.asset.map((i) => {
              return (
                <div className={classes.padd}>
                  <img
                    className={classes.text_hover}
                    ssrc={ele.asset[{ i }].asset}
                    alt=" "
                  />
                </div>
              );
            });

            boxSlide = (
              <div className={classes.imgDiv}>
                <div className="d-flex flex-row">{boxImg}</div>
              </div>
            );

            boxRight = (
              <div className={classes.boxRight} onMouseLeave={this.changeHover}>
                <div className={classes.content_hover}>{boxSlide}</div>
                {imgData}
              </div>
            );
          }
        });
      } else {
        contentArticle.map((ele, i) => {
          boxAbsolute = (
            <div className={classes.box_absolute}>
              <div
                className={classes.boxBack}
                onClick={() => this.props.history.goBack()}
              >
                <img src={require("./assets/Path235.svg")} alt="" />
                &nbsp; Back
              </div>
              <div className={classes.categoryBox}>
                <p className={classes.categoryText}>{ele.category}</p>
              </div>
              &nbsp;
              <div className={classes.titleBox}>
                <p className={classes.titleText}>Page</p>
              </div>
            </div>
          );
          if (parseInt(this.state.articleIndex) === i) {
            contentArticles = (
              <div className={classes.scroll_content}>
                <div className={classes.title}>{ele.title}</div>
                <div className={classes.tgl}>{ele.date}</div>
                <div className={classes.isi}>
                  {ReactHtmlParser(ele.content)}
                </div>
              </div>
            );

            if (ele.asset.length === 1) {
              let imgData = ele.asset[0] ? (
                <figure className={classes.figure}>
                  <img
                    className={classes.contentImgRight}
                    src={ele.asset[0].asset}
                    alt=" "
                  />
                </figure>
              ) : (
                <figure className={classes.figure}>
                  <img
                    className={classes.contentImgRight}
                    src={require("./assets/default-image.svg")}
                    alt=" "
                  />
                </figure>
              );

              boxRight = (
                <div
                  className={classes.boxRight}
                  onMouseLeave={this.changeHover}
                >
                  {imgData}
                </div>
              );
            } else if (ele.asset.length >= 2) {
              this.setState({ isHover: true });
              let imgData = ele.asset[0] ? (
                <figure className={classes.figure}>
                  <img
                    className={classes.contentImgRight}
                    src={ele.asset[0].asset}
                    alt=" "
                  />
                </figure>
              ) : (
                <figure className={classes.figure}>
                  <img
                    className={classes.contentImgRight}
                    src={require("./assets/default-image.svg")}
                    alt=" "
                  />
                </figure>
              );

              boxImg = ele.asset.map((i) => {
                return (
                  <div className={classes.padd}>
                    <img
                      className={classes.text_hover}
                      ssrc={ele.asset[{ i }].asset}
                      alt=" "
                    />
                  </div>
                );
              });

              boxSlide = (
                <div className={classes.imgDiv}>
                  <div className="d-flex flex-row">{boxImg}</div>
                </div>
              );

              boxRight = (
                <div
                  className={classes.boxRight}
                  onMouseLeave={this.changeHover}
                >
                  <div className={classes.content_hover}>{boxSlide}</div>
                  {imgData}
                </div>
              );
            }
          }
        });
      }
    }

    boxShare = (
      <div
        className={classes.share}
        style={{
          fontFamily: "Helvetica",
        }}
      >
        Share on : &nbsp;
        <img
          className={classes.icon}
          src={require("./assets/Subtraction 1.svg")}
          alt=""
        />
        <img
          className={classes.icon}
          src={require("./assets/Group 17.svg")}
          alt=""
        />
        <img
          className={classes.icon}
          src={require("./assets/Group 19.svg")}
          alt=""
        />
        <img
          className={classes.icon}
          src={require("./assets/Group 831.svg")}
          alt=""
        />
      </div>
    );

    return (
      <div className="pages-new">
        <Header onPage={"business"} />
        <div className={classes.wrapper}>
          <div className={classes.main}>
            <ParallaxProvider>
              <Fragment>
                <div className={classes.box}>
                  <div className={classes.boxContent}>
                    <div className={classes.box_scroll}>
                      {boxAbsolute}
                      {boxShare}
                      {contentArticles}
                    </div>
                  </div>
                  {boxRight}
                </div>
              </Fragment>
            </ParallaxProvider>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default article;

import React, { Component, createRef } from "react";
import Header from "../../sections/Header/Header";

import AD_Section1 from "../../sections/AD_Section1/AD_Section1";

import Footer from "../../sections/Footer/Footer";
import { ParallaxProvider } from "react-scroll-parallax";

import styled from "styled-components";

const CustomDiv = styled.div`
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
`;
export default class AwardsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
   
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    console.log("Page: " + this.props.match.params.id)
  }
  render() {
    //let homeSectionShortcut = <HomeSectionShortcut section={sec} />;
    return (
      <div className="news-page">
        <Header onPage={"News"} />
        <CustomDiv>
          <ParallaxProvider>
            <React.Fragment>
              <div ref={this.section1}>
              <AD_Section1 id={this.props.match.params.id}/>
              </div>
            </React.Fragment>
          </ParallaxProvider>
        </CustomDiv>
        <Footer />
      </div>
    );
  }
}

import React, { Component } from "react";
import Api from "../Api";
import Aux from "../Auxs";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { Slide } from "react-awesome-reveal";
import ArtBox from "../ArtBox";
import classes from "./Desktop.module.css";

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        { id: 1, name: "title", content: "Lorem Ipsum Lorem Ipsum?" },
        {
          id: 2,
          name: "desc",
          content:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry standard dummy text ever since the 1500s",
        },
        {
          id: 3,
          name: "image",
          assets: require("../assets/default-image.svg"),
        },
        {
          id: 4,
          name: "articles",
          articles: [
            {
              name: "image1",
              title: "Lorem Ipsum",
              assets: require("../assets/default-image.svg"),
            },
            {
              name: "image2",
              title: "Lorem Ipsum",
              assets: require("../assets/default-image.svg"),
            },
            {
              name: "image3",
              title: "Lorem Ipsum",
              assets: require("../assets/default-image.svg"),
            },
          ],
        },
      ],
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      boxCount: 0,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              name: item.name,
              index: item.index,
              category: item.category,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { contentSection, dummy } = this.state;
    let titleContent,
      contentDesc,
      contentBox,
      contentOverflow,
      dummyTitle,
      dummyDesc,
      InternalBox,
      dummyContentBox,
      dummyContentOverflow,
      readTextItem = null;

    dummyTitle = <p className={classes.sectionTitle}>{dummy[0].content}</p>;
    dummyDesc = (
      <div className={classes.boxParent}>
        <Slide triggerOnce direction="top">
          <div className={classes.boxChild}>
            <div className={[classes.contentBoxChild].join(" ")}>
              <p className={[classes.title, classes.mask].join(" ")}>
                {dummy[1].title}
              </p>
              <span className={classes.content}>{dummy[1].content}</span>
              <Link to={"/article"} className={classes.link}>
                <p style={{ color: "#223771" }}>
                  <strong>Read more</strong>
                </p>
              </Link>
            </div>
          </div>
        </Slide>
      </div>
    );

    dummyContentOverflow = (
      <div className={classes.imgLeft}>
        <Slide triggerOnce direction="top">
          <img className={classes.imgOverflow} src={dummy[2].assets} alt="" />
        </Slide>
      </div>
    );
    dummyContentBox = dummy[3].articles.map((s, i) => {
      let content = null;
      if (i !== 2) {
        content = (
          <Slide triggerOnce direction="top">
            <div className={classes.boxContentDetail}>
              <div className={classes.boxContentBehind}>
                <h1 className={classes.contentBehindBox}>{s.title}</h1>
              </div>
              <img
                className={classes.contentImageD}
                src={require("../assets/default-image.svg")}
                alt=""
              />
            </div>
          </Slide>
        );
      } else {
        content = (
          <Slide triggerOnce direction="top">
            <div className={classes.boxContentDetail}>
              <div className={classes.boxContents}>
                <div className="absolute m-auto">
                  <img
                    className={classes.imgUpload}
                    src={require("../assets/uploadLogo.svg")}
                    alt="logo"
                  />
                  <span className={classes.textUpload}>{s.title}</span>
                </div>
              </div>
            </div>
          </Slide>
        );
      }
      return <>{content}</>;
    });

    if (this.state.isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              titleContent = (
                <p className={classes.sectionTitle}>{s.content}</p>
              );
            } else {
              titleContent = dummyTitle;
            }
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Text"
        ) {
          titleContent = dummyTitle;
        }
        if (count === 2 && item.index === 2 && item.category === "Text") {
          readTextItem = item.sections.widget_contents[0]
            ? item.sections.widget_contents[0].content
            : "Read More";
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          contentDesc = item.sections.is_internal ? (
            <>{InternalBox}</>
          ) : (
            <ArtBox
              id={item.sections.id}
              url={item.sections.url}
              name={item.sections.name}
            />
          );
          InternalBox = item.sections.widget_contents.forEach((s, i) => {
            let isImage = s.assets[0] ? (
              <img
                className={classes.imgOverflow}
                src={s.assets[0].file}
                alt={s.assets[0].description}
              ></img>
            ) : (
              <img
                className={classes.imgOverflow}
                src={require("../assets/default-image.svg")}
                alt=""
              />
            );

            let str = s.content.toString();
            let x = str.split("</p>");
            let content = x.map((x) => {
              let c = x.replace(/(<([^>]+)>)/gi, " ").replace(/&nbsp;/gi, " ");
              return <p>{c}</p>;
            });

            contentDesc = (
              <div className={classes.boxParent}>
                <Slide triggerOnce direction="top">
                  <div className={classes.boxChild}>
                    <div className={[classes.contentBoxChild].join(" ")}>
                      <p className={[classes.title, classes.mask].join(" ")}>
                        {s.title}
                      </p>
                      <div className={classes.boxContentt}>
                        <p className={classes.contentt2}>{content}</p>
                      </div>
                      <div className={classes.boxLinks}>
                        <div className={classes.outerCircle2}>
                          <div className={classes.innerCircle2}></div>
                        </div>
                        <p className={classes.readMore}>{readTextItem}</p>
                      </div>

                      {/* <Link
                                               
                                                className={classes.link}
                                                to={{
                                                    pathname: `${item.sections.url}`,
                                                }}
                                            >
                                                <p style={{ color: "#223771" }}>
                                                    <p>Read more</p>
                                                </p>
                                            </Link> */}
                    </div>
                  </div>
                </Slide>
              </div>
            );

            contentOverflow = (
              <div className={classes.imgLeft}>
                <Slide triggerOnce direction="top">
                  {isImage}
                </Slide>
              </div>
            );
          });
        } else if (
          count === 3 &&
          item.index === 3 &&
          item.category !== "Article"
        ) {
          contentDesc = dummyDesc;
          contentOverflow = dummyContentOverflow;
        }

        if (count === 4 && item.index === 4 && item.category === "Article") {
          contentBox = item.sections.widget_contents.map((s, i) => {
            let path = s.url;
            let imgFront = s.assets[1] ? (
              <img
                className={classes.imgFront}
                src={s.assets[1].file}
                alt={s.assets[1].file}
              />
            ) : (
              <img
                className={classes.imgFront}
                src={require("../assets/uploadLogo.svg")}
                alt="logo"
              />
            );
            let content = null;
            let customPadding = i === 1 ? classes.paddingCenter : "";
            // if (i === ) {
            let isImage = s.assets[0] ? (
              <img
                className={classes.contentImage}
                src={s.assets[0].file}
                alt={s.assets[0].description}
              ></img>
            ) : (
              <img
                className={classes.contentImageD}
                src={require("../assets/default-image.svg")}
                alt=""
              />
            );

            content = (
              <Slide triggerOnce direction="top">
                <div
                  className={[classes.boxContentDetail, customPadding].join(
                    " "
                  )}
                >
                  <Link to={path} className={classes.link1}>
                    <div className={classes.boxContentBehind}>
                      <h1 className={classes.contentBehindBox}>{s.title}</h1>
                    </div>
                    <div className={classes.boxContentFront}>{imgFront}</div>
                  </Link>
                  {isImage}
                </div>
              </Slide>
            );
            // }
            // } else {
            //   content = (
            //     <Slide triggerOnce direction="top">
            //       <div className={classes.boxContentDetail}>
            //         <div className={classes.boxContents}>
            //           <div className="absolute m-auto">
            //             <img
            //               className={classes.imgUpload}
            //               src={require("../assets/uploadLogo.svg")}
            //               alt="logo"
            //             />
            //             <span className={classes.textUpload}>{s.title}</span>
            //           </div>
            //         </div>
            //       </div>
            //     </Slide>
            //   );
            // }
            return <Aux key={s.title}>{content}</Aux>;
          });
        } else if (
          count === 4 &&
          item.index === 4 &&
          item.category !== "Article"
        ) {
          contentBox = dummyContentBox;
        }
      });
    } else if (this.state.isApiLoaded) {
      titleContent = dummyTitle;
      contentDesc = dummyDesc;
      contentBox = dummyContentBox;
      contentOverflow = dummyContentOverflow;
    }
    return (
      <div className="section-content">
        <div className={classes.contentDetail}>
          <div className={["container", classes.customContainer].join(" ")}>
            <div className={classes.ppD}>
              <Slide triggerOnce direction="top">
                {titleContent}
              </Slide>
              <div className={classes.boxContentDesc}>{contentDesc}</div>
            </div>
          </div>
          {contentOverflow}

          <div className={["container", classes.customContainer2].join(" ")}>
            <div className={classes.boxParentXs}>
              <div className={classes.boxContentParentXs}>{contentBox}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Desktop;

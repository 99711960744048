import React, { Component, Fragment } from 'react'
import { Link } from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';

import Api from "./Api";
import Views from './Views/Views'

import BackgroundMask from './BackgroundMask'

import classes from './GS_Section1.module.css'

export default class GS_Section1 extends Component {
    constructor() {
        super();
        this.state = {
            currentPage: null,

            tabs: [
                {
                    id: 1,
                    title: 'All news & Events',
                    component: <div key={1} />,
                    logo: <img
                        className={classes.button_tab}
                        src={require('./assets/Polygon7.svg')}
                        alt="description" />
                }
            ],
            dummy: [
                {
                    id: 644,
                    title: 'NEWS',
                    Content: 'Education Cooperation – The highlight in international relations of Phenikaa Uni' +
                        'versity',
                    Date: '28/10/2019',
                    Detail: 'Phenikaa University signed the memorandum of understanding with Andrews Universi' +
                        'ty...',
                    img: {
                        type: 'side',
                        src: require('./assets/news.png')
                    }
                }, {
                    id: 744,
                    title: 'NEWS',
                    Content: 'Education Cooperation – The highlight in international relations of Phenikaa Uni' +
                        'versity',
                    Date: '28/10/2020',
                    Detail: 'Phenikaa University signed the memorandum of understanding with Andrews Universi' +
                        'ty...',
                    img: {
                        type: 'behind',
                        src: require('./assets/AEC-final.jpg')
                    }
                }, {
                    id: 844,
                    title: 'NEWS',
                    Content: 'Education Cooperation – The highlight in international relations of Phenikaa Uni' +
                        'versity',
                    Detail: 'Phenikaa University signed the memorandum of understanding with Andrews Universi' +
                        'ty...',
                    Date: '28/10/2021',
                    img: {
                        type: 'behind',
                        src: require('./assets/news.png')
                    }
                }, {
                    id: 944,
                    title: 'EVENT',
                    Content: 'Education Cooperation – The highlight in international relations of Phenikaa Uni' +
                        'versity',
                    Detail: 'Phenikaa University signed the memorandum of understanding with Andrews Universi' +
                        'ty...',
                    Date: '28/10/2022',
                    img: {
                        type: 'behind',
                        src: require('./assets/news.png')
                    }
                }, {
                    id: 1044,
                    title: 'NEWS',
                    Content: 'Education Cooperation – The highlight in international relations of Phenikaa Uni' +
                        'versity',
                    Detail: 'Phenikaa University signed the memorandum of understanding with Andrews Universi' +
                        'ty...',
                    Date: '28/10/2023',
                    img: {
                        type: 'behind',
                        src: require('./assets/news.png')
                    }
                }, {
                    id: 1144,
                    title: 'NEWS',
                    Content: 'Education Cooperation – The highlight in international relations of Phenikaa Uni' +
                        'versity',
                    Detail: 'Phenikaa University signed the memorandum of understanding with Andrews Universi' +
                        'ty...',
                    Date: '28/10/2023',
                    img: {
                        type: 'behind',
                        src: require('./assets/news.png')
                    }
                }, {
                    id: 1244,
                    title: 'NEWS',
                    Content: 'Education Cooperation – The highlight in international relations of Phenikaa Uni' +
                        'versity',
                    Detail: 'Phenikaa University signed the memorandum of understanding with Andrews Universi' +
                        'ty...',
                    Date: '28/10/2023',
                    img: {
                        type: 'behind',
                        src: require('./assets/news.png')
                    }
                }
            ],

            sections: {
                widgets: []
            },
            isLoaded: false,
            article_categories: [],
            isApiLoaded: false,
            views: null,
            activePage: <div></div>,
            valueQuery: null,
            articleID: null,
            sectionName: "",
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.getAllCategories = this.getAllCategories.bind(this)
    }

    componentDidMount() {
        this.getSectionDetail();
        this.getAllCategories();
    }

    getAllCategories() {

        const apiData = new Api()

        const categories = []

        this.setState({
            article_Categories: [],
        }, () => {

            apiData.getAllCategories().then(result => {
                console.log(result)
                const values = result.data
                const articleCategories = []
                console.log(values)
                values.results.map((item) => {
                    articleCategories.push(item.category)
                })

                this.setState({
                    article_categories: articleCategories,
                })
            })

        })

    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({
            sections: []
        }, () => {
            apiData
                .getSectionDetail()
                .then(result => {
                    this.setState({ isApiLoaded: true })
                    const sections = result.data
                    console.log(result.data)

                    const widget = []
                    result
                        .data
                        .widgets
                        .forEach((item) => {
                            widget.push({ id: item.id, index: item.index, category: item.category, name: item.name, sections: item })
                        })

                    sections.page = widget
                    this.setState({
                        sectionName: result.data.name,
                        sections: widget,
                        isLoaded: true,
                        articleID: widget[0].id
                    })
                })

                .catch(error => {
                    this.setState({ isApiLoaded: true })
                });
        })
    }

    render() {
        const { sections, dummy } = this.state
        let placeholderSearch, textFilter, TextFound, TextError, LoadMore, ReadMore

        let dummyContentList = dummy.map((s, i) => {
            let backgroundColor = s.title === 'NEWS' ? classes.newsColor : classes.eventColor
            return (
                <Link to={"/news/detail/" + s.id} className={classes.link}>
                    <div className={classes.contentWrap}>
                        <div className={classes.boxContent}>
                            <div className={classes.contentBox}>
                                {s.title}
                            </div>
                            <div className={classes.title_content}>
                                {s.Content}
                            </div>
                            <div className={classes.txt_content}>
                                {s.Detail}
                            </div>
                            <div className={classes.foot_content}>
                                <p >Read more</p>
                                <p className={classes.date_right}>{s.Date}</p>
                            </div>
                        </div>
                        <div className={classes.boxImage}>
                            <figure className={classes.wrapp_image}>
                                <img className={classes.img_content} src={s.img.src} alt="description" /> {/* <div className={classes.inside_image}>test</div> */}
                                <div className={classes.inside_image}>
                                    <img
                                        className={classes.icon_share}
                                        src={require("./assets/Group 843.svg")}
                                        alt="description" />
                                    <br />
                                    <p>Share on :</p>
                                    <img
                                        className={classes.icon}
                                        src={require("./assets/Subtraction 1.svg")}
                                        alt="description" />
                                    <img
                                        className={classes.icon}
                                        src={require("./assets/Group 17.svg")}
                                        alt="description" />
                                    <img
                                        className={classes.icon}
                                        src={require("./assets/Group 19.svg")}
                                        alt="description" />
                                    <img
                                        className={classes.icon}
                                        src={require("./assets/Group 831.svg")}
                                        alt="description" />
                                </div>
                            </figure>
                        </div>
                    </div>
                </Link>
            )
        })

        let dummyContentGrid = dummy.map((s, i) => {

            let gridrow = null
            // if (i > 0) {
            gridrow = <div >
                <Link to={"/news/detail/" + s.id} className={classes.link}>
                    <div className={classes.contentWrap}>
                        <div className={classes.boxContent1}>
                            <div className={classes.contentBox}>
                                {s.title}
                            </div>
                            <div className={classes.title_content}>
                                {s.Content}
                            </div>
                            <div className={classes.txt_content}>
                                {s.Detail}
                            </div>
                            <div className={classes.foot_content}>
                                <p>Read more</p>
                                <p className={classes.date_right}>{s.Date}</p>
                            </div>
                            <div className={classes.box_behind}>
                                <div className={classes.box_content_image}>
                                    <img className={classes.box_image} src={s.img.src} alt="description" />
                                </div>
                                <div className={classes.div_readmore1}>
                                    <p className={classes.readmore_btn1}>Read more</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            // }
            let backgroundColor = s.title === 'NEWS'
                ? classes.newsColor
                : classes.eventColor
            return (
                <div>
                    {gridrow}
                </div>
            )
        })

        let viewOnChange = null;

        if (this.state.isLoaded) {
            sections.forEach(item => {
                switch (item.index) {
                    case 1:
                        placeholderSearch = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "type article name, news, events, blog";
                        break;
                    case 2:
                        textFilter = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "All";
                        break;
                    case 3:
                        TextFound = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "results found";
                        break;
                    case 4:
                        TextError = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "";
                        break;
                    case 5:
                        LoadMore = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Load more";
                        break;
                    case 6:
                        ReadMore = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Load more";
                        break;
                    default:
                }
            })
            viewOnChange = <Views view={"grid"} category={this.state.article_categories} title={this.state.sectionName} searchHere={this.props.param} textSearch={placeholderSearch} textFilter={textFilter} textFound={TextFound} textError={TextError} loadMore={LoadMore} readMore={ReadMore}/>

        } else if (this.state.isApiLoaded) {
            viewOnChange = dummyContentGrid
        }

        return (
            <div className={classes.contentSection}>
                <div className={['container', classes.customContainer].join(' ')}>
                    {viewOnChange}
                </div>
            </div>
        )
    }
}

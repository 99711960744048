import React, { Component } from "react";
import Api from "./Api";
import classes from "./Section4.module.css";

export default class Box extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentArticle: [],
      aboutPhenikaa: [],
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      this.getArticleDetail(this.props.id);
    }
  }

  getArticleDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetail(param)
        .then((result) => {
          const articles = result.data;
          const assets = [];
          articles.results.forEach((a) => {
            assets.push(a.article_languages.title);
          });

          this.setState({
            contentArticle: assets,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {
    let content_flex1,
      content_flex2,
      content_flex3,
      title = null;

    const { contentArticle } = this.state;

    content_flex1 = contentArticle.map((ele, index) => {
      title = <p className={classes.read}>{ele}</p>;
      if (index === 0) {
        title = <p className={classes.read}>{ele}</p>;
        return <div className="align-self-center column">{title}</div>;
      } else if (index === 1) {
        title = <p className={classes.read}>{ele}</p>;
        return <div className="align-self-center column">{title}</div>;
      } else if (index === 2) {
        title = <p className={classes.read}>{ele}</p>;
        return <div className="align-self-center column">{title}</div>;
      }
      return (
        <>
          {content_flex1}
          {/* <div className="align-self-center column">{title}</div> */}
        </>
      );
    });

    content_flex2 = contentArticle.map((ele, index) => {
      if (index === 3) {
        title = <p className={classes.read}>{ele}</p>;
        return <div className="align-self-center column">{title}</div>;
      } else if (index === 4) {
        title = <p className={classes.read}>{ele}</p>;
        return <div className="align-self-center column">{title}</div>;
      } else if (index === 5) {
        title = <p className={classes.read}>{ele}</p>;
        return <div className="align-self-center column">{title}</div>;
      }
      return <>{content_flex2}</>;
    });

    content_flex3 = contentArticle.map((ele, index) => {
      if (index === 6) {
        title = <p className={classes.read}>{ele}</p>;
        return <div className="align-self-center column">{title}</div>;
      } else if (index === 7) {
        title = <p className={classes.read}>{ele}</p>;
        return <div className="align-self-center column">{title}</div>;
      } else if (index === 8) {
        title = <p className={classes.read}>{ele}</p>;
        return <div className="align-self-center column">{title}</div>;
      }
    });

    return (
      <div className="d-flex">
        <div className={classes.miniBox}>{content_flex1}</div>
        <div className={classes.miniBox}>{content_flex2}</div>
        <div className={classes.miniBox}>{content_flex3}</div>
      </div>
    );
  }
}

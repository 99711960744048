import React, { Component } from "react";
import classes from "./orientation.module.css";
import Api from "./Api";
import ReactHtmlParser from "react-html-parser";

export default class Box extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentArticle: [],
      aboutPhenikaa: [],
      isLoaded: false,
      isApiLoaded: true,
      activeSidebar: 0,
      box1: null,
      box2: null,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      this.getArticleDetail(this.props.id);
    }
  }
  changeIndex(key) {
    this.setState({ activeSidebar: key });
  }
  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }

  getArticleDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetail(param)
        .then((result) => {
          const articles = result.data;
          const assets = [];

          articles.results.forEach((a, index) => {
            if (index !== 0) {
              assets.push({
                title: a.article_languages.title,
                content: a.article_languages.content,
                asset: a.article_assets[0].asset_file,
              });
            }
          });

          this.setState({
            contentArticle: assets,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { contentArticle, isLoaded, isApiLoaded } = this.state;
    let box1,
      img,
      desc,
      box2,
      title2,
      titless,
      navtitle,
      content1,
      content2,
      title1 = null;

    if (isLoaded) {
      navtitle = contentArticle.map((z, i) => {
        const splitStr = z.content.split("#");

        let textZoom = null;

        if (i === this.state.activeSidebar) {
          textZoom = classes.textZoom;
          title1 = ReactHtmlParser(
            splitStr[0] ? this.getHtmlParagraph(splitStr[0]) : ""
          );
          content1 = ReactHtmlParser(
            splitStr[1] ? this.getHtmlParagraph(splitStr[1]) : ""
          );
          title2 = ReactHtmlParser(
            splitStr[2] ? this.getHtmlParagraph(splitStr[2]) : ""
          );
          content2 = ReactHtmlParser(
            splitStr[3] ? this.getHtmlParagraph(splitStr[3]) : ""
          );

          img = <img className={classes.contentImgRight} src={z.asset} />;
        }
        titless = <p>{z.title} </p>;
        box1 = (
          <div className={classes.contentInBox1}>
            <div className={classes.boxIn}>
              {title1} 
              {content1}
            </div>
          </div>
        );

        box2 = (
          <div className={classes.contentInBox2}>
            <div className={classes.boxIn2}>
              {title2}
              {content2}
            </div>
          </div>
        );

        return (
          <>
            <div
              className={[classes.miniBox, textZoom].join(" ")}
              onClick={() => this.changeIndex(i)}
            >
              {titless}
            </div>
          </>
        );
      });
    }

    return (
      <div className="container d-flex flex-column">
        <div className={classes.boxRight}>{img}</div>
        <div className={classes.boxTop}>
          {/* {desc} */}
          <div className="d-flex">{navtitle}</div>
        </div>
        <div className="d-flex">
          {box1}
          {box2}
        </div>
      </div>
    );
  }
}

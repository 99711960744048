import React from 'react'
import { Link } from 'react-router-dom'

import classes from "../MenuDetail.module.css"

const menuPreview3 = (props) => {
    let title, items, item, subTitle, content, menus = null
    let isDirection = []
    if (props.items) {

        props.items.forEach((i) => {
            isDirection.push(i.children ? true : false)
        })

        title = <p className={classes.title}>{props.name}</p>
        items = props.items.map((i, index) => {
            if (i.index >= 0) {
                let url = null

                url = i.url !== '' ? i.url : '/'
                item = i.children ? i.children.map((itm, x) => {
                    let urls = itm.url !== '' ? itm.url : '/'
                    return (
                        <Link to={urls} className={classes.customLink}>
                            <p className={classes.subTitleD}>{itm.title}</p>
                        </Link>
                    )
                }) : <></>

                content = isDirection.includes(true) ?
                    <div className='d-flex flex-column p-0 m-0 pr-4'>
                        <Link to={url} className={classes.customLink}>
                            <p className={classes.subTitleD}>{i.title}</p>
                        </Link>
                        {item}
                    </div>
                    :
                    <div className='p-0 m-0 pr-2'>
                        <Link to={url} className={classes.customLink}>
                            <p className={classes.subTitleD}>{i.title}</p>
                        </Link>
                        {item}
                    </div>
            }

            return (
                <>
                    {content}
                </>
            )
        })

        menus = isDirection.includes(true) ?
            <div id='preview3' className='row col-md-12'>
                {title}
                {subTitle}
                {items}
            </div>
            :
            <div id='preview3' className='col-md-12'>
                {title}
                {subTitle}
                {items}
            </div>

    } else {
        items = <div></div>
    }

    return (
        <div className={classes.sectionPreview}>
            {menus}
        </div>
    )
}

export default menuPreview3
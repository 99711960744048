import React, { Component } from "react";

import Api from "./Api";

import ReactHtmlParser from "react-html-parser";
import classes from "./BOD_Section1.module.css";

class BOD_Section1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollY: 0,

            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
            isBoxHover: false,
            boxCount: 0,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        });
    }

    // componentDidMount () {
    //     window.addEventListener('scroll', this.handleScroll)
    // }

    componentWillUnmount() {
        window.addEventListener('scroll', this.handleScroll)
    }

    handleScroll = () => {
        this.setState({
            scrollY: window.scrollY
        })
    }

    toggleBoxHandlerEnter = () => {
        this.setState((prevState) => {
            return { isBoxHover: !prevState.isBoxHover };
        });
    };

    render() {

        var title, description, img = null;
        var dummyTitle = "Title";
        var dummyDescription = "Nice overview"

        if (this.state.isLoaded) {
            console.log(this.state.contentSection)
            title = this.state.contentSection[0] ? this.state.contentSection[0].sections.widget_contents[0] ? this.state.contentSection[0].sections.widget_contents[0].title : dummyTitle : dummyTitle;
            description = this.state.contentSection[0] ? this.state.contentSection[0].sections.widget_contents[0] ? this.state.contentSection[0].sections.widget_contents[0].content : dummyDescription : dummyDescription;
            img = this.state.contentSection[0] ? this.state.contentSection[0].sections.widget_contents[0] ? this.state.contentSection[0].sections.widget_contents[0].assets[0] ? this.state.contentSection[0].sections.widget_contents[0].assets[0].file : null : null : null;
            // console.log(this.state.contentSection[0].sections.widget_contents[0].title)
            // console.log(this.state.contentSection[0].sections.widget_contents[0].content)
            // console.log(this.state.contentSection[0].sections.widget_contents[0].assets[0].file)


        }

        return (
            <>
            <img className={classes.imageFull} src={img} alt="" />
                <div onScroll={this.handleScroll} className={classes.posRelative}>
                
                <div className={['container', classes.customContainer].join(' ')}>

                    
                    <p className={classes.title}>{title}</p>
                    <span className={classes.contentDesc}>
                        {ReactHtmlParser(description)}
                    </span>
                </div>
            </div> 
            </>
        );
    }
}

export default BOD_Section1;


import React from 'react';
import { Link } from "react-router-dom"

import classes from './Card.module.css';

function Card({url, text, imgUrl}){
    const bottomText = (
        <div className={classes.textContainer}>
            <div className={classes.textContent}>
                <div className={classes.title_content1}>{text}</div>
            </div>
        </div>
    )

    return(
            <div className={[classes.contentWrap, classes.fadeInBottomm].join(" ")}>
                <div className={classes.boxContent1}>
                        <div className={classes.box_content_image}>
                            <img className={classes.box_image} src={imgUrl ? imgUrl : ""} alt={text} />
                        </div>
                        {url ? (
                            <a href={url} target='_blank' className={classes.link}>
                                {bottomText}
                            </a>
                        )
                        :
                        bottomText}
                </div>
            </div>
    )
}

export default Card;
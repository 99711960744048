import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";

import classes from "./Distribution.module.css";

export default class Distribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        { id: 1, name: "Title", content: "What is Lorem Ipsum?" },
        {
          id: 2,
          name: "Content",
          content:
            "Where does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
        },
        {
          id: 4,
          name: "Background Image",
          assets: require("./assets/default-image.svg"),
        },
      ],

      isDetail: false,
    };
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }

  toggleDetail = () => {
    this.setState((prevState) => {
      return { isDetail: !prevState.isDetail };
    });
  };

  getUrlParser(url) {
    if (url !== "/") {
      return url.includes("http") ?
        <a className={classes.customLink} href={url} target="_blank" rel="noopener noreferrer">
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>{this.props.viewDetails}</p>
        </a>
        :
        <Link className={classes.customLink} to={url}>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>{this.props.viewDetails}</p>
        </Link>
        ;
    } else {
      return <Link className={classes.customLink} to={url}>
        <div className={classes.outerCircle}>
          <div className={classes.innerCircle}></div>
        </div>
        <p className={classes.viewMore}>{this.props.viewDetails}</p>
      </Link>
    }
  }

  render() {
    const { dummy } = this.state;
    let title,
      desc,
      img,
      content,
      link = null;
    let detailContent,
      contentL1,
      contentL2,
      contentL3,
      contentL4,
      contentL5,
      contentL6,
      contentL7,
      contentImg1,
      contentImg2,
      contentBox = null;

    if (this.props.items) {
      let items = this.props.items;
      title = <p className={classes.title}>{items[0].item.title}</p>;
      let contentImg = items[0].item.assets[0]
        ? items[0].item.assets[0].file
        : require("./assets/default-image.svg");
      img = <img className={classes.imgContent} src={contentImg} alt="" />;
      content = (
        <div className={classes.contentDesc}>
          {ReactHtmlParser(items[1].desc.content)}
        </div>
      );
      link = !this.state.isDetail ? (
        // <Link className={classes.link} to={{
        // pathname: `/article${this.props.dataParam ? this.props.dataParam[0].url : ''}/${this.props.dataParam ? this.props.dataParam[0].dataIndex[4].id : ''}`,
        // pathname: `${items[1].desc.url ? items[1].desc.url : '/'}`}}>
        <div
          className={[classes.contentLink].join(" ")}
          onClick={this.toggleDetail}
        >
          <div className="d-flex">
            <div className={classes.outerCircle}>
              <div className={classes.innerCircle}></div>
            </div>
            <p className={classes.contentLink}>{this.props.readMore}</p>
          </div>
        </div>
      ) : (
          <>
            {/* <Link className={classes.link} to={{
                    pathname: `/article${this.props.dataParam ? this.props.dataParam[0].url : ''}/${this.props.dataParam ? this.props.dataParam[0].dataIndex[4].id : ''}`,
                    data: this.state.apa
                }}>
               
                </Link> */}
          </>
        );
      // </Link>
      const splitStr = items[0].item.content.split("#");
      desc = (
        <span className={classes.content}>
          {ReactHtmlParser(splitStr[0] ? splitStr[0] : "")}
        </span>
      );
      contentL1 = splitStr[1] ? splitStr[1] : "";
      contentL2 = splitStr[2] ? splitStr[2] : "";
      contentL3 = splitStr[3] ? splitStr[3] : "";
      contentL4 = splitStr[4] ? splitStr[4] : "";
      contentL5 = splitStr[5] ? splitStr[5] : "";
      contentL6 = splitStr[6] ? splitStr[6] : "";
      contentL7 = splitStr[7] ? splitStr[7] : "";

      let contentImg1 = items[0].item.assets[1]
        ? items[0].item.assets[1].file
        : require("./assets/default-image.svg");
      let contentImg2 = items[0].item.assets[2]
        ? items[0].item.assets[2].file
        : require("./assets/default-image.svg");

      if (this.props.dataExt) {
        this.props.dataExt.forEach((d, i) => {
          switch (i) {
            case 0:
              contentBox = d.sections.map((s, i) => {
                let url = s.assets
                  ? s.assets[0].file
                  : require("./assets/default-image.svg");
                let link = s.url ? this.getUrlParser(s.url) : this.getUrlParser("/");

                return (
                  <div className={classes.box}>
                    <img src={url} alt=""></img>
                    <div className={classes.boxMask}></div>
                    <div className={classes.contentFront}>
                      <div className={classes.boxContentIn}>
                        <p>{s.title}</p>
                      </div>
                    </div>
                    <div className={classes.contentBack}>
                      <div className={classes.boxContentIn}>
                        <div className="d-flex flex-column text-right mt-auto mb-auto">
                          {ReactHtmlParser(s.content)}
                          <div className="d-flex flex-row justify-content-end position-relative mt-2">
                            {link}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              });
              break;
            default:
          }
        });
      }

      let animate = this.state.isDetail
        ? classes.fadeInBottom
        : classes.fadeOutTop;
      detailContent = this.state.isDetail ? (
        <div className={[classes.detailContent, animate].join(" ")}>
          <>
            <hr />
            <div className={classes.allContentB}>
              <div className={classes.txtParB}>
                <p className={classes.txtReal}> {ReactHtmlParser(contentL1)}</p>
              </div>
              <div className={classes.txtParB2}>
                <img className={classes.imgLogo} src={contentImg1} alt=" " />
              </div>
            </div>
          </>

          <>
            <hr />
            <div className={classes.allContentC}>
              <div className={classes.boxPar}>
                <p className={classes.txtParC}>{ReactHtmlParser(contentL2)}</p>
              </div>
              <div className={classes.absImg}>
                <img className={classes.imgLogo2} src={contentImg2} alt=" " />
              </div>
            </div>
            {ReactHtmlParser(contentL3)}
          </>

          <div className="d-flex flex-row m-0 p-0 mb-5">{contentBox}</div>

          <div
            className={[classes.contentLink].join(" ")}
            onClick={this.toggleDetail}
          >
            <div className="d-flex">
              <div className={classes.outerCircle}>
                <div className={classes.innerCircle}></div>
              </div>
              <p className={classes.contentLink}>{this.props.readLess}</p>
            </div>
          </div>
        </div>
      ) : (
          <></>
        );
    } else {
      title = <p className={classes.title}>{dummy[0].content}</p>;
      desc = <p className={classes.content}>{dummy[1].content}</p>;
      img = (
        <img className={classes.imgContentD} src={dummy[2].assets} alt="" />
      );
      content = (
        <div className={classes.contentDummy}>
          <ul>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
            <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
          </ul>
        </div>
      );
    }
    let customMargin = !this.state.isDetail ? "mb-5" : "";
    return (
      <div className={classes.sectionDistribution}>
        <div
          className={[
            "container d-flex flex-column",
            classes.customContainer,
          ].join(" ")}
        >
          <div className={classes.boxImage}>
            <div className={classes.animate}>{img}</div>
          </div>
          <div className={["d-flex", customMargin].join(" ")}>
            <div className={classes.boxContent}>
              {title}
              {desc}
              {link}
            </div>
            <div className={classes.boxOverflow}>
              <div className={classes.boxContentOverflow}>{content}</div>
            </div>
          </div>

          {detailContent}
        </div>
      </div>
    );
  }
}

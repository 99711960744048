import React, { Component, createRef } from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import styled from "styled-components"

import Logo from "../../sections/Logo/Logo"
import Menu from "../../sections/Menu/Menu"
import Footer from "../../sections/Footer/Footer"

import ContactUs from "../../sections/ContactUs/ContactUs"

const CustomDiv = styled.div`
  min-height: 100%;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
`

class ContactUsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      override: true,
      darkSection: true,
      section: [{ id: 1, title: localStorage.getItem("language") === "English" ? "Contact Us" : "Contact Us" }],
    }
    this.updateLogo = this.updateLogo.bind(this)
    this.override1 = this.override1.bind(this)
    this.scrollingHandler = this.scrollingHandler.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener("scroll", this.scrollingHandler, true)
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollingHandler, true)
  }
  section1 = createRef()
  updateLogo(value) {
    this.setState({
      darkSection: value,
    })
  }
  override1(value) {
    this.setState({
      override: value,
    })
  }

  scrollingHandler() {
    if (this.section1.current.getBoundingClientRect().bottom < this.section1.current.getBoundingClientRect().height && this.section1.current.getBoundingClientRect().bottom > -1 && this.state.override) {
      this.setState({
        currentSection: "Section1",
        darkSection: true,
      })
    }
  }

  render() {
    return (
      <div>
        <Logo logoColor={this.state.darkSection} />
        <Menu logoColor={this.state.darkSection} />
        <CustomDiv>
          <ParallaxProvider>
            <div ref={this.section1}>
              <ContactUs updateLogo={this.updateLogo} override={this.override1} />
            </div>
          </ParallaxProvider>
        </CustomDiv>
        <Footer />
      </div>
    )
  }
}

export default ContactUsPage

import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import { ParallaxProvider } from "react-scroll-parallax"

import Api from "./Api"
import Views from "./Views/Views"
import Core from "./Core"

import BackgroundMask from "./BackgroundMask"

import classes from "./News.module.css"

export default class Section1 extends Component {
    constructor() {
        super()
        this.state = {
            currentPage: null,

            tabs: [
                {
                    id: 1,
                    title: "All news & Events",
                    component: <div key={1} />,
                    logo: <img className={classes.button_tab} src={require("./assets/Polygon7.svg")} alt='description' />,
                },
            ],
            dummy: [
                {
                    id: 644,
                    title: "NEWS",
                    Content: "Education Cooperation – The highlight in international relations of Phenikaa Uni" + "versity",
                    Date: "28/10/2019",
                    Detail: "Phenikaa University signed the memorandum of understanding with Andrews Universi" + "ty...",
                    img: {
                        type: "side",
                        src: require("./assets/news.png"),
                    },
                },
                {
                    id: 744,
                    title: "NEWS",
                    Content: "Education Cooperation – The highlight in international relations of Phenikaa Uni" + "versity",
                    Date: "28/10/2020",
                    Detail: "Phenikaa University signed the memorandum of understanding with Andrews Universi" + "ty...",
                    img: {
                        type: "behind",
                        src: require("./assets/AEC-final.jpg"),
                    },
                },
                {
                    id: 844,
                    title: "NEWS",
                    Content: "Education Cooperation – The highlight in international relations of Phenikaa Uni" + "versity",
                    Detail: "Phenikaa University signed the memorandum of understanding with Andrews Universi" + "ty...",
                    Date: "28/10/2021",
                    img: {
                        type: "behind",
                        src: require("./assets/news.png"),
                    },
                },
                {
                    id: 944,
                    title: "EVENT",
                    Content: "Education Cooperation – The highlight in international relations of Phenikaa Uni" + "versity",
                    Detail: "Phenikaa University signed the memorandum of understanding with Andrews Universi" + "ty...",
                    Date: "28/10/2022",
                    img: {
                        type: "behind",
                        src: require("./assets/news.png"),
                    },
                },
                {
                    id: 1044,
                    title: "NEWS",
                    Content: "Education Cooperation – The highlight in international relations of Phenikaa Uni" + "versity",
                    Detail: "Phenikaa University signed the memorandum of understanding with Andrews Universi" + "ty...",
                    Date: "28/10/2023",
                    img: {
                        type: "behind",
                        src: require("./assets/news.png"),
                    },
                },
                {
                    id: 1144,
                    title: "NEWS",
                    Content: "Education Cooperation – The highlight in international relations of Phenikaa Uni" + "versity",
                    Detail: "Phenikaa University signed the memorandum of understanding with Andrews Universi" + "ty...",
                    Date: "28/10/2023",
                    img: {
                        type: "behind",
                        src: require("./assets/news.png"),
                    },
                },
                {
                    id: 1244,
                    title: "NEWS",
                    Content: "Education Cooperation – The highlight in international relations of Phenikaa Uni" + "versity",
                    Detail: "Phenikaa University signed the memorandum of understanding with Andrews Universi" + "ty...",
                    Date: "28/10/2023",
                    img: {
                        type: "behind",
                        src: require("./assets/news.png"),
                    },
                },
            ],

            sections: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
            views: null,
            activePage: <div></div>,
            valueQuery: null,
            articleID: null,
            sectionName: "",
            idArticle: null,
        }
        this.getSectionDetail = this.getSectionDetail.bind(this)
    }

    componentDidMount() {
        // console.log("masuk");
        let id = this.props.id
        // console.log(id);
        this.getSectionDetail(id)
    }

    getSectionDetail(id) {
        const apiData = new Api()
        this.setState(
            {
                sections: [],
            },
            () => {
                apiData
                    .getSectionDetail(id)
                    .then((result) => {
                        this.setState({ isApiLoaded: true })
                        const sections = result.data

                        const widget = []
                        result.data.widgets.forEach((item) => {
                            widget.push({
                                id: item.id,
                                index: item.index,
                                category: item.category,
                                name: item.name,
                                sections: item,
                            })
                        })

                        sections.page = widget
                        this.setState({
                            sectionName: result.data.name,
                            sections: widget,
                            isLoaded: true,
                            idArticle: result.data.id,
                            articleID: widget[0].id,
                        })
                    })

                    .catch((error) => {
                        // console.log(error);
                        this.setState({ isApiLoaded: true })
                    })
            }
        )
    }

    render() {
        const { sections, dummy } = this.state
        let readMore, share, searchHere, newsButton, textSearch

        // console.log(this.state.idArticle);

        let dummyContentList = dummy.map((s, i) => {
            let backgroundColor = s.title === "NEWS" ? classes.newsColor : classes.eventColor
            let formatedDate = new Date(s.date)
            let day = formatedDate.getDay()
            let month = formatedDate.getMonth() + 1
            let year = formatedDate.getFullYear()
            formatedDate = day + "-" + month + "-" + year
            return (
                <Link to={"/news/detail/" + s.id} className={classes.link}>
                    <div className={classes.contentWrap}>
                        <div className={classes.boxContent}>
                            <div className={classes.contentBox}>{s.title}</div>
                            <div className={classes.title_content}>{s.Content}</div>
                            <div className={classes.txt_content}>{s.Detail}</div>
                            <div className={classes.foot_content}>
                                <p>Read more</p>
                                <p className={classes.date_right}>{s.Date}</p>
                            </div>
                        </div>
                        <div className={classes.boxImage}>
                            <figure className={classes.wrapp_image}>
                                <img className={classes.img_content} src={s.img.src} alt='description' /> {/* <div className={classes.inside_image}>test</div> */}
                                <div className={classes.inside_image}>
                                    <img className={classes.icon_share} src={require("./assets/Group 843.svg")} alt='description' />
                                    <br />
                                    <p>Share on :</p>
                                    <img className={classes.icon} src={require("./assets/Subtraction 1.svg")} alt='description' />
                                    <img className={classes.icon} src={require("./assets/Group 17.svg")} alt='description' />
                                    <img className={classes.icon} src={require("./assets/Group 19.svg")} alt='description' />
                                    <img className={classes.icon} src={require("./assets/Group 831.svg")} alt='description' />
                                </div>
                            </figure>
                        </div>
                    </div>
                </Link>
            )
        })

        let dummyContentGrid = dummy.map((s, i) => {
            let gridrow = null
            // if (i > 0) {
            gridrow = (
                <div>
                    <Link to={"/news/detail/" + s.id} className={classes.link}>
                        <div className={classes.contentWrap}>
                            <div className={classes.boxContent1}>
                                <div className={classes.contentBox}>{s.title}</div>
                                <div className={classes.title_content}>{s.Content}</div>
                                <div className={classes.txt_content}>{s.Detail}</div>
                                <div className={classes.foot_content}>
                                    <p>Read more</p>
                                    <p className={classes.date_right}>{s.Date}</p>
                                </div>
                                <div className={classes.box_behind}>
                                    <div className={classes.box_content_image}>
                                        <img className={classes.box_image} src={s.img.src} alt='description' />
                                    </div>
                                    <div className={classes.div_readmore1}>
                                        <p className={classes.readmore_btn1}>Read more</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            )
            // }
            let backgroundColor = s.title === "NEWS" ? classes.newsColor : classes.eventColor
            return <div>{gridrow}</div>
        })

        let viewOnchange

        if (this.state.isLoaded) {
            var articleIds = []
            var article_categories = []
            viewOnchange = sections.map((item) => {
                switch (item.index) {
                    case 1:
                        readMore = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Read Less"
                        break
                    case 2:
                        share = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Don't share"
                        break
                    case 3:
                        searchHere = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Don't search"
                        break
                    case 4:
                        newsButton = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Less news"
                        break
                    case 5:
                        textSearch = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Search here. . ."
                        break
                    case 6:
                        var url = item.sections.url ? item.sections.url : "/news/detail/"
                        articleIds.push(item.id)
                        item.sections.article_categories.forEach((category) => {
                            if (!article_categories.includes(category)) {
                                article_categories.push(category)
                            }
                        })
                        //return( <Views id={item.id} url={url} category={item.sections.article_categories} view={"list"} readMore={readMore} share={share} title={this.state.sectionName} searchHere={searchHere} newsButton={newsButton}/>);
                        break
                    case 7:
                        var url = item.sections.url ? item.sections.url : "/news/detail/"
                        articleIds.push(item.id)
                        item.sections.article_categories.forEach((category) => {
                            if (!article_categories.includes(category)) {
                                article_categories.push(category)
                            }
                        })
                        console.log("ArticleIds")
                        console.log(articleIds)
                        return <Views id={articleIds} idArt={this.state.idArticle} url={url} category={article_categories.sort()} view={"grid"} readMore={readMore} share={share} title={this.state.sectionName} searchHere={searchHere} newsButton={newsButton} textSearch={textSearch}/>
                    default:
                }
            })
        } else if (this.state.isApiLoaded) {
            viewOnchange = dummyContentList
        }

        let navItem = this.state.tabs.map((n) => {
            let key = "tab" + n.id
            let activeTab = this.state.tabsActive === n.id ? classes.isActive : ""
            return (
                <div key={n.id} className={[classes.box, activeTab].join(" ")} onClick={() => this.toggleActiveHandler(n.component, n.id)}>
                    <span id={key}>{n.title}</span>
                    <div className={classes.btn_wrap}>{n.logo}</div>
                </div>
            )
        })

        return (
            <div className='page-news'>
                <div className={classes.main}>
                    <ParallaxProvider>
                        <Fragment>
                            <BackgroundMask />
                            <div className={classes.content}>
                                <div className={["container", classes.boxC].join(" ")}>{viewOnchange}</div>
                            </div>
                            <div className={classes.Core}>
                                <Core />
                            </div>
                        </Fragment>
                    </ParallaxProvider>
                </div>
            </div>
        )
    }
}

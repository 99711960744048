import React, { Component } from 'react'

export default class Sample extends Component {
    render() {
        return (
            <div>
                <p>test</p>
            </div>
        )
    }
}

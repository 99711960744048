import React, { Component } from "react";
import classes from "./Section3.module.css";
import Api from "./Api";
import Box from "./Box";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

class SD_Section3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        {
          id: 1,
          name: "article",
          title: "Lorem ipsum...",
          content: "Lorem ipsum dummy data",
          asset: require("./assets/default-image.svg"),
        },
        {
          id: 2,
          name: "read more text",
          content: "read more",
        },
      ],
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              url: item.url,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {
    const { contentSection, dummy, isLoaded, isApiLoaded } = this.state;

    let dummy_title,
      dummy_content,
      dummy_image_art,
      boxO,
      title,
      content,
      image_art,
      readText,
      urlText,
      LinkItem = null;

    dummy_title = <p className={classes.title}>{dummy[0].title}</p>;
    dummy_content = <p className={classes.content}>{dummy[0].content}</p>;
    dummy_image_art = (
      <div className={classes.boxRight}>
        <img className={classes.contentImgRight} src={dummy[0].asset} />
      </div>
    );

    if (isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Article") {
          item.sections.widget_contents.forEach((z) => {
            if (z.title) {
              title = <p className={classes.title}>{z.title}</p>;
            } else {
              title = <p className={classes.title}>Title content</p>;
            }
            if (z.content) {
              content = (
                <p className={classes.content}>{ReactHtmlParser(z.content)}</p>
              );
            } else {
              content = (
                <p className={classes.content}>Content dummy lorem ipsum...</p>
              );
            }
            image_art = z.assets[0] ? (
              <div className={classes.boxRight}>
                <img
                  className={classes.contentImgRight}
                  src={z.assets[0].file}
                  alt=" "
                />
              </div>
            ) : (
              <div className={classes.boxRight}>
                <img
                  className={classes.contentImgRight}
                  src={require("./assets/default-image.svg")}
                  alt=" "
                />
              </div>
            );
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Article"
        ) {
          title = dummy_title;
          content = dummy_content;
          image_art = dummy_image_art;
        }
        if (count === 2 && item.index === 2 && item.category === "Text") {
          urlText = item.url;
          LinkItem = item.sections.widget_contents.map((z) => {
            if (z.content) {
              readText = <p className={classes.contentLink}>{z.content}</p>;
            } else {
              readText = <p className={classes.contentLink}>Read More</p>;
            }
            return (
              <Link to={urlText} className={classes.Link}>
                <div className={classes.readMore}>
                  <div className={[classes.contentLink].join(" ")}>
                    <div className="d-flex">
                      <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                      </div>
                      {readText}
                    </div>
                  </div>
                </div>
              </Link>
            );
          });
        }
      });
    } else if (isApiLoaded) {
      title = dummy_title;
      content = dummy_content;
      image_art = dummy_image_art;
    }

    return (
      <div className={classes.Section3}>
        <div className={["container", classes.boxC, classes.customContainer].join(" ")}>
          {title}
          <div className={classes.box}>
            <div className={classes.boxLeft}>
              {content}
              {LinkItem}
            </div>
            {image_art}
          </div>
        </div>
      </div>
    );
  }
}
export default SD_Section3;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Slide } from "react-awesome-reveal";
import classes from "./SectionFour.module.css";
import Api from "./Api";
import ReactHtmlParser from "react-html-parser";

class ArtBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentArticle: {
        widgets: [],
      },
      url: "",
      readMore: "Read More",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);
  }
  componentDidMount() {
    if (this.props.id) {
      this.getArticleDetail(this.props.id);
      //   console.log(this.props.url)
      this.setState({
        url: this.props.url,
      });
    }
  }

  getArticleDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetail(param)
        .then((result) => {
          const articles = result.data;
          const art = [];

          console.log(articles);

          const assets = [];
          const langs = [];

          articles.results[0].article_assets.forEach((ass, i) => {
            console.log(ass);
            if (i === 0) {
              assets.push({
                asset: ass.asset_file,
              });
            }
          });

          art.push({
            id: articles.results[0].id,
            category: articles.results[0].category_name,
            name: articles.results[0].name,
            title: articles.results[0].article_languages.title,
            content: articles.results[0].article_languages.content,
            assets: assets,
            date: articles.results[0].date,
          });

          //   console.log(art);

          this.setState({
            contentArticle: art,
            isLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {
    const { contentArticle } = this.state;

    let contentDesc,
      contentOverflow = null;

    // console.log(contentArticle);

    if (this.state.isLoaded) {
      contentArticle.forEach((z) => {
        console.log(z);

        let isImage = z.assets[0] ? (
          <img
            className={classes.imgOverflow}
            src={z.assets[0].asset}
            alt=" "
          ></img>
        ) : (
          <img
            className={classes.imgOverflow}
            src={require("./assets/default-image.svg")}
            alt=""
          />
        );

        contentDesc = (
          <div className={[classes.contentBoxChild].join(" ")}>
            <p className={[classes.title, classes.mask].join(" ")}>{z.title}</p>
            <span className={classes.content}>
              {ReactHtmlParser(z.content)}
            </span>
            <Link
              to={"/article"}
              className={classes.link}
              to={{
                pathname: `/article/${this.state.url}/${z.id}`,
              }}
            >
              <p style={{ color: "#223771" }}>
                <strong>Read more</strong>
              </p>
            </Link>
          </div>
        );

        contentOverflow = (
          <div className={classes.imgLeft}>
            <Slide triggerOnce direction="top">
              {isImage}
            </Slide>
          </div>
        );
      });
    }

    return (
      <div>
        <div className="container">
          <div className="pt-lg-5 pb-lg-5">
            <div className={classes.boxContentDesc}>{contentDesc}</div>
          </div>
        </div>
        {contentOverflow}
      </div>
    );
  }
}

export default ArtBox;

import React, { Component } from "react";

import Header from "../../sections/Header/Header";
import History from "../../sections/AU_History/AU_History"

class historysample extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <Header onPage="about-us"/>
                <History />
            </div>
        );
    }
}

export default historysample;

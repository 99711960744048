import React, { Component } from "react";
import classes from "./Job_Details.module.css";
import Api from "./Api";

export default class BackNext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        widgets: [],
      },
      url: "",
      readMore: "Read More",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ Sections: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const data = [];

          data.push({
            backJob: sections.widgets[4].widget_contents[0]
              ? sections.widgets[4].widget_contents[0].content
              : "Back",
            nextJob: sections.widgets[5].widget_contents[0].content
              ? sections.widgets[5].widget_contents[0].content
              : "Next",
          });


          this.setState({
            article: data,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { article } = this.state;
    let backJob, nextJob;

    if (this.state.isLoaded) {
      backJob = article[0].backJob;
      nextJob = article[0].nextJob;
    }
    return (
      <>
        <div className={["container", classes.contBtn].join(" ")}>
          <div className={classes.back}>
            <p>{backJob}</p>
          </div>
          <div className={classes.next}>
            <p>{nextJob}</p>
          </div>
          {/* <BackBox /> */}
        </div>
      </>
    );
  }
}

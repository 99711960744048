import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import Table from './Table/Table'

import Api from './Api'
import classes from './stakeholders.module.css';

class SD_Stakeholders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: <div></div>,

            data: [
                {
                    id: 1,
                    title: "Workers"
                }, {
                    id: 2,
                    title: "Customer"
                }, {
                    id: 3,
                    title: "Student, parents"
                }, {
                    id: 4,
                    title: "Partners and suppliers"
                }, {
                    id: 5,
                    title: "Social community"
                }, {
                    id: 6,
                    title: "House Agencies"
                }, {
                    id: 7,
                    title: "Country"
                }, {
                    id: 8,
                    title: "Shareholders and Investors"
                }
            ],
            contentSection: {
                widgets: []
            },
            isLoaded: false,
            isApiLoaded: false,
            activeSidebar: 0
        };
        this.getSectionDetail = this
            .getSectionDetail
            .bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    changeActiveSidebar(key) {
        this.setState({ activeSidebar: key });
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({
            contentSection: [],
            contentBox1: [],
            contentBox2: []
        }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    console.log(sections)

                    result
                        .data
                        .widgets
                        .forEach((item) => {
                            if (item.category === "Article") {
                                switch (item.index) {
                                    case 4:
                                        this.setState({ contentBox1: item.widget_contents });
                                        break;
                                    case 5:
                                        this.setState({ contentBox2: item.widget_contents });
                                        break;
                                    default:
                                        break;
                                }
                            }
                            widget.push({
                                id: item.id,
                                index: item.index,
                                category: item.category,
                                name: item.name,
                                url: item.is_internal,
                                titleStake: sections.widgets[2].widget_contents[0].content,
                                sections: item
                            });
                        });

                    sections.page = widget;
                    this.setState({ contentSection: widget, isLoaded: true });
                })
                .catch((error) => {
                    this.setState({ isApiLoaded: true });
                });
        });
    }
    render() {

        const { data, contentSection } = this.state;

        let contentFlow, dummyData = null
        let title, desc, contentTable, link = null

        if (this.state.isLoaded) {
            console.log(contentSection)
            let columnTable = []
            contentSection.forEach((item, i) => {
                switch (i) {
                    case 0:
                        title = item.sections.widget_contents.map((s) => {
                            return (
                                <p className={classes.title}>{s.content ? s.content : ''}</p>
                            )
                        })
                        break;
                    case 1:
                        desc = item.sections.widget_contents.map((s) => {
                            return (
                                <p className={classes.desc}>{s.content ? s.content : ''}</p>
                            )
                        })
                        break;
                    case 2:

                        break;
                    case 3:
                        item.sections.widget_contents.forEach((s) => {
                            s.content ? columnTable.push(s.content) : columnTable.push('title')
                        })
                        break;
                    case 4:

                        contentTable = <Table id={item.id} column={columnTable} />
                        break;
                    case 5:
                        link = item.sections.widget_contents.map((s) => {
                            return (
                                <div className={classes.navBack}>
                                    <Link className={classes.customLink} to={item.sections.url ? item.sections.url : '/sustainable'}>
                                        <div className={'d-flex'}>
                                            <div className={'d-flex'}>
                                                <i className={[classes.arrow, classes.left, classes.customMargin].join(" ")}></i>
                                            </div>
                                            <span className={classes.textBack}>{s.content ? s.content : 'Back to prev'}</span>
                                        </div>
                                    </Link>
                                </div >
                            )
                        });
                        break;
                    default:
                        break;
                }
            })
        }

        contentFlow = data.map((e, i) => {
            // console.log(e)
            return (
                <div className={classes.wrappContentBox}>
                    <div className={classes.circleLine2}>
                        <div className={classes.circleLineIn2}></div>
                    </div>
                    <div className={classes.boxRound}>
                        <p className={classes.textInboxRound}>{e.title}</p>
                        <div className={classes.circleLine}>
                            <div className={classes.circleLineIn}></div>
                        </div>
                    </div>
                    <div className={classes.Line}></div>
                </div>
            )
        })


        return (
            <div className={classes.contentSection}>

                <div className={["container", classes.customContainer].join(' ')}>
                    {title}
                    {desc}
                    {contentTable}
                    {link}
                </div>
            </div>

        );
    }
}

export default SD_Stakeholders;
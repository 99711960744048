import React, { Component } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import styled from "styled-components";

import Logo from '../../sections/Logo/Logo'
import Menu from '../../sections/Menu/Menu'
import Footer from '../../sections/Footer/Footer'

import CareerOverview from '../../sections/Career_Overview/Career_Overview'
import CareerTop from '../../sections/Career_Top/Career_Top'
import NewsSlider1row from '../../sections/News_Slider_1_row/News_Slider_1_row'
import CareerFullScreenImagePart from '../../sections/Career_Full_Screen_Image_Part/Career_Full_Screen_Image_Part';
import Join from "../../sections/Career_Top/Join/Join"

const CustomDiv = styled.div`
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
`;

class historysample extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <Logo />
                <Menu />
                <CustomDiv>
                    <ParallaxProvider>
                        <React.Fragment>
                            <Join />
                            <CareerTop/>
                            <NewsSlider1row/>
                            <CareerOverview />
                            <CareerFullScreenImagePart/>                            
                        </React.Fragment>
                    </ParallaxProvider>
                </CustomDiv>
                {/* <Footer /> */}
            </div>
        );
    }
}

export default historysample;

import React from 'react'

import { isMobile  } from "react-device-detect";

import Desktop from './Desktop/Desktop'
import Mobile from './Mobile/Mobile'

export default function AU_Section3() {
    let content = !isMobile ? <Desktop /> : <Mobile />
    return (
        <>
          {content}  
        </>
    )
}

import React, { Component } from "react";

import Logo from '../../sections/Logo/Logo'
import Menu from '../../sections/Menu/Menu'
import Section1 from "../../sections/GS_Section1/GS_Section1";
import Footer from "../../sections/Footer/Footer";
import { ParallaxProvider } from "react-scroll-parallax";

import styled from "styled-components";

const CustomDiv = styled.div`
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
`;
export default class GlobalSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: null,
            querySearch: null,
            isParam: false
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let search = this.props.location.search ? this.props.location.search : ''

        this.setState({
            querySearch: search.substring(search.indexOf('=') + 1),
            isParam: true
        })
        
    }
    render() {
        let content = null
        if (this.state.isParam){
            content = <Section1 param={this.state.querySearch} />
        }
        return (
            <div className="news-page">
                <Logo />
                <Menu onPage={""} />
                <CustomDiv>
                    <ParallaxProvider>
                        <React.Fragment>
                            <div ref={this.section1}>
                                {content}
                            </div>
                        </React.Fragment>
                    </ParallaxProvider>
                </CustomDiv>
                <Footer />
            </div>
        );
    }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import Api from "./Api";
import classes from "./Section3.module.css";

import Slider from './Slider/Slider';
import Card from './Card/Card';

export default class Section3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        { id: 1, name: "title", content: "Công bố khoa học và dự án R&D" },
        {
          id: 2,
          name: "article",
          articles: [
            {
              name: "Slider1",
              title: "RESEARCH & SCIENCE PROJECT",
              content: "Lorem ipsum lorem ipsum lorem ipsum",
              assets: require("./assets/image.png"),
            },
          ],
        },
      ],
      contentSection: {
        widgets: [],
      },
      dataUrl: null,
      dataIndex: null,
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];
          const dataParamApi = [];

          dataParamApi.push({
            url: sections.widgets[1].url,
            index: sections.widgets[1].widget_contents[0].id,
          });

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              is_internal: item.is_internal,
              name: item.name,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
            dataIndex: dataParamApi[0].index,
            dataUrl: dataParamApi[0].url,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { dummy, contentSection, dataParam } = this.state;
    let dummyTitle,
      dummyArticle,
      dummyReadMore,
      title,
      article,
      url,
      readMore = null;

    dummyTitle = <p className={classes.title}>{dummy[0].content}</p>;
    dummyArticle = dummy[1].articles.map((s, i) => {
      return (
        <>
          <div className={classes.boxImage}>
            <img src={s.assets} alt=""></img>
          </div>
          <p className={classes.contentTitle}>{s.title}</p>
          <p className={classes.content}>{s.content}</p>
        </>
      );
    });
    dummyReadMore = (
      <div className={classes.boxLink}>
        <div className={classes.outerCircle}>
          <div className={classes.innerCircle}></div>
        </div>
        <p className={classes.linkAll}>Read more</p>
      </div>
    );

    if (this.state.isLoaded) {
      let count = 0;

      contentSection.forEach((item) => {
        count = count + 1;

        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              title = <p className={classes.title}>{s.content}</p>;
            } else {
              title = dummyTitle;
            }
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Text"
        ) {
          title = dummyTitle;
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              readMore = s.content;
            } else {
              readMore = "Read More";
            }
          });
        } else if (
          count === 2 &&
          item.index === 2 &&
          item.category !== "Text"
        ) {
          readMore = "Read More"
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          if (item.sections.is_internal) {
            url = item.sections.url;
            article = item.sections.widget_contents ? 
              item.sections.widget_contents.map((s) => {
                return (
                    <Card 
                      imgUrl={s.assets[0].file}
                      text={readMore}
                      url={`${url}/${s.id}`}
                    />
                );
              })
              : dummyArticle;
          }
        } else if (
          count === 3 &&
          item.index === 3 &&
          item.category !== "Article"
        ) {
          article = dummyArticle;
        }
      });
    } else if (this.state.isApiLoaded) {
      title = dummyTitle;
      article = dummyArticle;
      readMore = dummyReadMore;
    }

    return (
      <div className={classes.contentSection}>
        <div className={["container", classes.boxC].join(" ")}>
          {title}
          <Slider content={article} />
          <hr className={classes.hrGeneral} />
        </div>
      </div>
    );
  }
}

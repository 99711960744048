import React, { Component } from "react"
import { Link } from "react-router-dom"

import { Slide } from "react-awesome-reveal"
import dateFormat from 'dateformat';
import ReactHtmlParser from "react-html-parser"

import Navbar from "./Navbar/Navbar"

import Api from "./../Api"
import classes from "./../News.module.css"

class views extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contentArticle: {
                widgets: [],
            },
            isLoaded: false,
            isApiloaded: false,
            views: null,
            idNews: "",
            filtered: "",
            articleCategories: null,
            pagination: 6,
            articleID: null,
            displayStyle: "grid",
            loadedArticle: 6
        }
        this.getArticleDetail = this.getArticleDetail.bind(this)

        this.onFilterChange = this.onFilterChange.bind(this)
        this.getSearchedData = this.getSearchedData.bind(this)
        this.changeDisplay = this.changeDisplay.bind(this)
        this.loadMoreNews = this.loadMoreNews.bind(this)
        this.setDefaultLoadedArticle = this.setDefaultLoadedArticle.bind(this)
        this.insertArticleInArray = this.insertArticleInArray.bind(this)
    }

    componentDidMount() {
        this.setState({
            articleID: this.props.id ? this.props.id : "",
            articleCategories: this.props.category ? this.props.category : "",
        })
        if (this.props.id) {
            this.getArticleDetail(this.props.id, "", "", this.props.valueSearch ? this.props.valueSearch : "")
        }

        this.setDefaultLoadedArticle()
    }

    setDefaultLoadedArticle() {
        // const isLarge = useMediaQuery({
        //     query: '(min-device-width: 2560px)'
        // })
        if (window.screen.width >= 2560) {
            this.setState({
                loadedArticle: 12,
            })
        } else {
            this.setState({
                loadedArticle: 6,
            })
        }
    }

    insertArticleInArray(array, articleToInsert) {
        var inserted = false;
        for (var i = 0; i < array.length; i++) {
            
            if (articleToInsert.date > array[i].date) {
                    
                let startArray = array.slice(0, i);
                let endArray = array.slice(i,array.length)
                startArray.push(articleToInsert);
                console.log(array)
                console.log(startArray)
                console.log(endArray)
                array = startArray.concat(endArray)
                console.log(array)
                inserted = true;
                break;
            }
         }
        if (!inserted) { array.push(articleToInsert) }
        return array;
    }

    getArticleDetail(param, filter, size, search) {
        let count = 0
        if (count === 0) {
            const apiData = new Api()

            this.setState(
                {
                    contentArticle: [],
                },
                () => {
                    var art = []

                    param.forEach((id) => {
                        apiData
                            .getArticleDetailByYearDescending(id, filter, size, search)
                            .then((result) => {
                                const articles = result.data
                                articles.results.forEach((a) => {
                                    const assets = []

                                    a.article_assets.forEach((ass, i) => {
                                        if (i === 0) {
                                            // assets.push({ asset: ass.asset_file ? ass.asset_file : ass.asset_link })

                                            if (ass.asset_file) {
                                                assets.push({ asset: ass.asset_file })
                                            } else {
                                                assets.push({ assetV: ass.asset_link })
                                            }
                                        }
                                    })

                                    let newArticle = {
                                        id: a.id,
                                        category: a.category_name,
                                        name: a.name,
                                        title: a.article_languages.title,
                                        content: a.article_languages.content,
                                        assets: assets,
                                        date: a.date,
                                    }

                                    art = this.insertArticleInArray(art, newArticle);
                                    /*
                                    art.push({
                                        id: a.id,
                                        category: a.category_name,
                                        name: a.name,
                                        title: a.article_languages.title,
                                        content: a.article_languages.content,
                                        assets: assets,
                                        date: a.date,
                                    })
                                    */
                                    this.setState({ contentArticle: art, isLoaded: true })
                                })
                            })
                            .catch((error) => {
                                console.log(error)
                                this.setState({ isApiLoaded: true })
                            })

                        count = 1
                    })
                }
            )
        }
    }

    onFilterChange(e) {
        this.setState({
            filtered: e.target.value,
        })
        this.getArticleDetail(this.state.articleID, e.target.value, "", "")
    }

    loadMoreNews() {
        //console.log(this.state.loadedArticle)
        this.setState((prevState) => {
            return { pagination: prevState.pagination + this.state.loadedArticle }
        })
    }

    getSearchedData(e) {
        if (e.keyCode === 13) {
            if (e.target.value !== "") {
                this.getArticleDetail(this.state.articleID, "", "", e.target.value)
            }
        }
    }

    changeDisplay = (params) => {
        this.setState({ displayStyle: params })
    }

    imageOrVideo = (url, param, display) => {
        let content, imgStyle, vidStyle
        if (display === "grid"){
            imgStyle = classes.boxImageGrid
            vidStyle = classes.boxVideoGrid
        } else {
            imgStyle = classes.box_image
            vidStyle = classes.boxVideo
        }
        if (url !== "") {
            switch (param) {
                case "img":
                    content = <img className={imgStyle} src={url ? url : require('./assets/default-image.svg')} alt='description' />
                    break;
                case "vid":
                    if (url.includes('phenikaa-assets.s3')) {
                        content = <video className={vidStyle} controls loop={true} autoPlay='autoplay'>
                            <source src={url} type='video/mp4' />
                        </video>
                    } else {
                        content = <iframe src={url} className={vidStyle} title="Iframe Example"></iframe>
                    }
                    break;
                default:
                    break;
            }
        } else {
            content = <img className={imgStyle} src={require('./assets/default-image.svg')} alt='description' />
        }
        return content
    }

    render() {
        const { contentArticle, pagination } = this.state

        // SET CONTENT NEWS LIST MODE
        let apiContentlist = null
        if (this.state.isLoaded) {
            let count = 0
            let delay = 0
            const arr = contentArticle
            //console.log(arr)
            apiContentlist = arr.slice(0, pagination).map((s, i) => {
                // console.log(i)
                // console.log(i)

                count = count + 1
                delay = delay + 50
                let backgroundColor,
                    backgroundColor2,
                    titleBox = null

                if (count % 2 === 0) {
                    titleBox = classes.titleBoxEvent
                    backgroundColor2 = classes.boxContentL1
                } else {
                    titleBox = classes.titleBoxNews
                    backgroundColor2 = classes.boxContentL2
                }

                function getCategoryIndex(categoryList, category) {
                    var count = 1

                    var returnValue = 0
                    categoryList.forEach((thisCategory) => {
                        if (thisCategory.localeCompare(category) === 0) {
                            returnValue = count
                        }
                        count += 1
                    })
                    return returnValue
                }

                switch (getCategoryIndex(this.state.articleCategories, s.category)) {
                    case 1:
                        backgroundColor = classes.boxContentL
                        // titleBox = classes.titleBoxEvent
                        break
                    case 2:
                        backgroundColor = classes.boxContentL1
                        // titleBox = classes.titleBoxNews
                        break
                    default:
                        backgroundColor = classes.boxContentL2
                        // titleBox = classes.titleBoxUpcoming
                        break
                }
                // let d_news = new Date(s.date)
                // const options = { year: "numeric", month: "long", day: "numeric" }
                // let date_news = d_news.toLocaleDateString("vi", options)
                const NewDate = dateFormat(s.date, 'dd-mm-yyyy')
                let param = s.assets[0] ? (s.assets[0].asset ? ('img') : ('vid')) : ''
                let newAsset = this.imageOrVideo((s.assets[0] ? (s.assets[0].asset ? (s.assets[0].asset) : (s.assets[0].assetV)) : ''), param, "list")
                return (
                    <Link to={`${this.props.url}/${s.id}`} className={classes.link}>
                        <Slide triggerOnce delay={delay} direction={"right"}>
                            <div className={[classes.contentWrap1, classes.fadeInBottomm].join(" ")}>
                                <div className={backgroundColor2}>
                                    {/* <div className={titleBox}>
                                            <p>{s.category}</p>
                                    </div> */}
                                    <div className={classes.title_content}>
                                        <p>{s.title}</p>
                                    </div>
                                    <div className={classes.txt_content}>
                                        <p>{ReactHtmlParser(s.content)}</p>
                                    </div>
                                    <div className={classes.foot_content}>
                                        <div className={classes.boxLinks}>
                                            <div className={classes.outerCircle2}>
                                                <div className={classes.innerCircle2}></div>
                                            </div>
                                            <p className={classes.readMore}>{this.props.readMore}</p>
                                        </div>

                                        <p className={classes.date_right}>{NewDate}</p>
                                    </div>
                                </div>
                                <div className={classes.boxImage}>
                                    <figure className={classes.wrapp_image}>
                                        {/* <img className={classes.img_content} src={s.assets[0] ? s.assets[0].asset : ""} alt='description' /> 
                                        <div className={classes.inside_image}>test</div> */}
                                        {newAsset}
                                        <div className={classes.inside_image}>
                                            <img className={classes.icon_share} src={require("./../assets/Group 843.svg")} alt='description' />
                                            <br />
                                            <p>Share on :</p>
                                            <img className={classes.icon} src={require("./../assets/Subtraction 1.svg")} alt='description' />
                                            <img className={classes.icon} src={require("./../assets/Group 17.svg")} alt='description' />
                                            <img className={classes.icon} src={require("./../assets/Group 19.svg")} alt='description' />
                                            <img className={classes.icon} src={require("./../assets/Group 831.svg")} alt='description' />
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        </Slide>
                    </Link>
                )
            })
        }

        // SET CONTENT NEWS GRID MODE
        let apiContentGrid = null
        let delayGrid = 0
        if (this.state.isLoaded) {
            // const arr = contentArticle.reverse()
            // console.log(contentArticle)
            apiContentGrid = contentArticle.slice(0, pagination).map((s, i) => {
                delayGrid = delayGrid + 50
                // let d_news = new Date(s.date)
                // const options = { year: "numeric", month: "long", day: "numeric" }
                // let date_news = d_news.toLocaleDateString("vi", options)
                // console.log(i)
                // if (i === 1) {
                //   backgroundColor = classes.boxContent1;
                // } else if (s.category === "Events") {
                //   backgroundColor = classes.boxContent2;
                // } else {
                //   backgroundColor = classes.boxContent3;
                // }
                const NewDate = dateFormat(s.date, 'dd-mm-yyyy')
                // console.log(s.assets[0])

                let param = s.assets[0] ? (s.assets[0].asset ? ('img') : ('vid')) : ''
                let newAsset = this.imageOrVideo((s.assets[0] ? (s.assets[0].asset ? (s.assets[0].asset) : (s.assets[0].assetV)) : ''), param, "grid")

                return (
                    <Slide triggerOnce delay={delayGrid} direction={"top"}>
                        <div className={[classes.contentWrap, classes.fadeInBottomm].join(" ")}>
                            <div className={classes.boxContent1}>
                                {/* <div className={classes.contentBox}>{s.category}</div>
                                        <div className={classes.title_content1}>{s.title}</div>
                                        <div className={classes.txt_content1}>{ReactHtmlParser(s.content)}</div>
                                        <div className={classes.foot_content1}>
                                        <p>{this.props.readMore}</p>
                                        <p className={classes.date_right1}>{s.date}</p>
                                </div> */}
                                <div className={classes.box_behind}>
                                    <div className={classes.box_content_image}>
                                        {/* <img className={classes.box_image} src={s.assets[0] ? s.assets[0].asset : ""} alt='description' /> */}
                                        {newAsset}
                                    </div>
                                    <Link to={`${this.props.url}/${s.id}`} className={classes.link}>
                                        <div className={classes.div_readmore1}>
                                            <div className={classes.title_content1}><p>{s.title}</p></div>
                                            <div className={classes.foot_content1}>
                                                <div className={classes.boxLinks}>
                                                    <div className={classes.outerCircle2}>
                                                        <div className={classes.innerCircle2}></div>
                                                    </div>
                                                    <p className={classes.readMore}>{this.props.readMore}</p>
                                                </div>
                                                {/* <p>{this.props.readMore}</p> */}
                                                <p className={classes.date_right1}>{NewDate}</p>
                                            </div>
                                            {/* <p className={classes.readmore_btn1}>{this.props.readMore}</p> */}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Slide>
                )
            })
        }

        // SET NEWS CATEGORY
        let filters = null
        const { articleCategories } = this.state
        if (articleCategories) {
            filters = articleCategories.map((f) => {
                return <option value={f}>{f}</option>
            })
        }

        // CHANGE LIST/GRID VIEW
        let viewOnchange,
            nav = null
        if (this.state.displayStyle === "" || this.state.displayStyle === "list" || this.state.displayStyle === "list2") {
            viewOnchange = <div className={classes.component}>{apiContentlist}</div>
            nav = <Navbar listClick={() => this.changeDisplay("list")} gridClick={() => this.changeDisplay("grid")} listClick2={() => this.changeDisplay("list2")} gridClick2={() => this.changeDisplay("grid2")} type={"list"} onKeyDown={this.getSearchedData} onChange={this.onFilterChange} category={articleCategories} title={this.props.title} searchHere={this.props.textSearch} />
            // colorList = <img className={classes.img_list} onClick={() => this.changeDisplay("list")} src={require('./assets/list2.svg')} alt="description" />
            // colorGrid = <img className={classes.img_grid} onClick={() => this.changeDisplay("grid")} src={require('./assets/grid.svg')} alt="description" />
        } else {
            viewOnchange = (
                <div className={classes.componentgrid}>
                    <div className={["row", classes.box_grid].join(" ")}>{apiContentGrid}</div>
                </div>
            )
            nav = <Navbar listClick={() => this.changeDisplay("list")} gridClick={() => this.changeDisplay("grid")} type={"grid"} listClick2={() => this.changeDisplay("list2")} gridClick2={() => this.changeDisplay("grid2")} onKeyDown={this.getSearchedData} onChange={this.onFilterChange} category={articleCategories} title={this.props.title} searchHere={this.props.textSearch} />
            // colorGrid = <img className={classes.img_grid} onClick={() => this.changeDisplay("grid")} src={require('./assets/grid2.svg')} alt="description" />
            // colorList = <img className={classes.img_list} onClick={() => this.changeDisplay("list")} src={require('./assets/list.svg')} alt="description" />
        }

        return (
            <>
                <div className={classes.background_blur}></div>
                {nav}
                {viewOnchange}
                <div className={classes.older} onClick={this.loadMoreNews}>
                    <p>{this.props.newsButton}</p>
                </div>
            </>
        )
    }
}

export default views

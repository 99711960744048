import React, { Component } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import styled from "styled-components";

import Header from "../../sections/Header/Header";
import Section1 from "../../sections/AU_Org_Chart/AU_Org_Chart";
import Footer from "../../sections/Footer/Footer";


const CustomDiv = styled.div`
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
`;
export default class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: null,

        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="news-page">
                <Header onPage={"News"} />
                <CustomDiv>
                    <ParallaxProvider>
                        <React.Fragment>
                            <div ref={this.section1}>
                                <Section1 />
                            </div>
                        </React.Fragment>
                    </ParallaxProvider>
                </CustomDiv>
                <Footer />
            </div>
        );
    }
}

import React, { Component } from 'react'

import dateFormat from "dateformat"

import Api from '../Api'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import classes from './Mobile.module.css'

export default class MobileViews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [],
            contentArticle: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: true,
        }
        this.getArticleDetail = this.getArticleDetail.bind(this);
    }

    componentDidMount() {
        if (this.props.id) {
            this.getArticleDetail(this.props.id)
            this.setState({
                historyId: this.props.id,
            })
        }
    }

    getArticleDetail(param) {
        const apiData = new Api();
        this.setState({ contentArticle: [] }, () => {
            apiData.getArticleDetail(param)
                .then(result => {
                    const articles = result.data
                    const art = []
                    articles.results.forEach((a) => {
                        const assets = []
                        a.article_assets.forEach((ass, i) => {
                            if (i === 0) {
                                assets.push({
                                    asset: ass.asset_file
                                })
                            }
                        })

                        art.push({
                            id: a.id,
                            category: a.category_name,
                            name: a.name,
                            title: a.article_languages.title,
                            content: a.article_languages.content,
                            assets: assets,
                            date: a.date
                        })
                    })

                    this.setState({
                        contentArticle: art,
                        isLoaded: true,
                        nav1: this.slider1,
                        nav2: this.slider2,
                    })
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        })
    }

    render() {
        const { contentArticle } = this.state
        let contentSlider = null
        if (this.state.isLoaded) {
            contentSlider = contentArticle.map((s) => {
                let isImage = s.assets[0] ? s.assets[0].asset : require("../assets/default-image.svg")
                let strYear = dateFormat(s.date, "yyyy");
                return (
                    <div className={classes.box}>
                        <div className={classes.boxImage}>
                            <img className={classes.contentImage} src={isImage} alt=""></img>
                        </div>

                        <div className={classes.boxContent}>
                            <div className={classes.boxYear}>
                                <p className={classes.contentYear}>{strYear}</p>
                            </div>
                            <p className={classes.content}>{s.title}</p>
                        </div>
                        <div className={classes.boxLink}>
                            <p className={classes.textLink}>View all history...</p>
                        </div>
                    </div>
                );
            })
        }
        const settings = {
            className: "",
            infinite: false,
            centerPadding: "",
            variableWidth: true,
            slidesToShow: 1,
            swipeToSlide: true,
        }
        return (
            <Slider {...settings}>
                {contentSlider}
            </Slider>
        )
    }
}

import React, { Component } from "react";
import HorizontalTimeline from "react-horizontal-timeline";
import classes from "./timeline.module.css";
import Api from "./Api";
import { getElementError } from "@testing-library/react";
import { split } from "lodash";

export default class timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      previous: 0,
      years: null,
      data: {
        widgets: [],
      },
      yearData: [],
      isLoaded: false,
      isApiLoaded: false,
      arrs_state: [],
    };
    this.getArticleGroupbyYear = this.getArticleGroupbyYear.bind(this);
  }

  componentDidMount() {
    let id = this.props.id;
    if (id !== "dummy") {
      this.getArticleGroupbyYear(id);
    } else {
      this.setState({
        isApiLoaded: true,
      });
    }
  }

  getArticleGroupbyYear(param) {
    const apiData = new Api();
    this.setState(
      {
        sections: [],
      },
      () => {
        apiData
          .getArticleGroupbyYear(param)
          .then((result) => {
            this.setState({
              isApiLoaded: true,
            });
            const sections = result.data;
            const widget = [];
            const arrs = [];

            result.data.forEach((element) => {
              let cc = "" + element.year + "";
              arrs.push(element.year);
              widget.push({
                year: cc,
                art: element.articles,
              });
            });

            this.setState({
              yearData: widget,
              isLoaded: true,
              arrs_state: arrs,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              isApiLoaded: true,
            });
          });
      }
    );
  }

  render() {
    const { data, isApiLoaded, yearData, arrs_state } = this.state;
    let boxYear,
      timelineHori = null;
    let arr = [];
    if (this.state.isLoaded) {
      yearData.map((ele) => {
        let dd = "" + ele.year + "";
        arr.push(dd);
        return arr;
      });
      // const VALUES = arr;
      const VALUES = ["2014", "2015", "2016", "2017", "2018", "2019", "2020"];
      timelineHori = (
        <div style={{ width: "100%", height: "70px", margin: "0 auto" }}>
          <HorizontalTimeline
            index={this.state.value}
            indexClick={(index) => {
              this.setState({ value: index, previous: this.state.value });
            }}
            values={VALUES}
          />
        </div>
      );
    }

    return (
      <div className={classes._boxOne}>
        {timelineHori}
        <div className="text-center">{this.state.value}</div>;
      </div>
    );
  }
}

import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import Styled from "styled-components";
import Api from "../Api";
import classes from "./Join.module.css";

const CustomDiv = Styled.div`
position: fixed;

//long icon
// top: 0.5rem;
// z-index: 99;
// width: 9%;

//box icon
top: 0rem;
z-index: 99;
width: 7%;
`;
export default class Logo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      joinText: null,
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];

          let join = sections.widgets[1].widget_contents[0]
            ? sections.widgets[1].widget_contents[0].content
            : "Join us";
          // console.log(join);
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            joinText: join,
            isLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  // let imgSource = props.onPage ? require('./assets/iconW.svg') : require('./assets/icon.svg')

  render() {
    let joinUs = null;

    const { contentSection, isLoaded, joinText } = this.state;

    if (isLoaded) {
      joinUs = joinText;
    }

    return (
      <div className={classes.circleLink}>
        <div>
          <span>{joinUs}</span>
        </div>
      </div>
    );
  }
}

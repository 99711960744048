import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ReactHtmlParser from "react-html-parser";

import Api from './Api'

import classes from './BS_Page3.module.css'

export default class BS_Page2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
            ],

            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.getHtmlParagraph = this.getHtmlParagraph.bind(this);
        this.getUrlParser = this.getUrlParser.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        });
    }

    getHtmlParagraph(str) {
        if (str !== '') {
            return str.replace(/<\/?p>/gi, " ")
                .replace(/<p>/gi, " ");
        }
    }

    getUrlParser(url) {
        if (url !== '') {
            return url.includes('http') ?
                <a className={classes.customLink} href={url} target="_blank" rel="noopener noreferrer">
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.viewMore}>View Details</p>
                </a> :
                <Link className={classes.customLink} to={url}>
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.viewMore}>View Details</p>
                </Link>
        }
    }

    render() {
        const { contentSection } = this.state

        let backButton, contentCategory, title, contentImg, contentImg2, titleContent = null
        let contentL1, contentL2, contentL3, contentL4, contentL5, contentL6, contentL7, contentL8, contentL9, contentL10, contentL11, contentL12 = null
        let contentR1, contentR2, contentR3, contentR4 = null
        let contentBox, contentBox1, contentBox3, contentFlip = null

        let dummyUrl = require('./assets/default-image.svg')
        if (this.state.isLoaded) {
            contentSection.forEach((x, i) => {
                if (x.index === 1 && x.category === "Article") {
                    x.sections.widget_contents.forEach((s, i) => {
                        const splitStr = s.content.split('#')
                        switch (i) {
                            case 0:
                                contentCategory = s.category ? <p className={classes.category}>{this.getHtmlParagraph(splitStr[0] ? splitStr[0] : '')}</p> : <></>
                                title = s.title ? <p className={classes.title}>{s.title}</p> : <></>
                                contentImg = s.assets[0] ? s.assets[0].file : dummyUrl
                                titleContent = this.getHtmlParagraph(splitStr[1] ? splitStr[1] : '')
                                contentL1 = this.getHtmlParagraph(splitStr[2] ? splitStr[2] : '')
                                contentR1 = this.getHtmlParagraph(splitStr[3] ? splitStr[3] : '')
                                contentR2 = this.getHtmlParagraph(splitStr[4] ? splitStr[4] : '')

                                break;
                            case 1:
                                contentL2 = this.getHtmlParagraph(splitStr[0] ? splitStr[0] : '')
                                contentL3 = this.getHtmlParagraph(splitStr[1] ? splitStr[1] : '')
                                contentR3 = this.getHtmlParagraph(splitStr[2] ? splitStr[2] : '')
                                contentR4 = this.getHtmlParagraph(splitStr[3] ? splitStr[3] : '')
                                contentL5 = this.getHtmlParagraph(splitStr[4] ? splitStr[4] : '')
                                contentL6 = this.getHtmlParagraph(splitStr[5] ? splitStr[5] : '')

                                break;
                            case 2:
                                contentL7 = this.getHtmlParagraph(splitStr[0] ? splitStr[0] : '')
                                contentL8 = this.getHtmlParagraph(splitStr[1] ? splitStr[1] : '')
                                contentL9 = this.getHtmlParagraph(splitStr[2] ? splitStr[2] : '')
                                contentL10 = this.getHtmlParagraph(splitStr[3] ? splitStr[3] : '')
                                contentL11 = this.getHtmlParagraph(splitStr[4] ? splitStr[4] : '')
                                contentL12 = this.getHtmlParagraph(splitStr[5] ? splitStr[5] : '')

                                break;
                            default:
                        }
                        // contentImg2 = s.assets ? (s.assets[1].file ? s.assets[1].file : dummyUrl) : dummyUrl
                    })
                }
                if (x.index === 2 && x.category === "Text") {
                    contentL4 = x.sections.name ? <p className={classes.titleSection}>{x.sections.name}</p> : <></>
                    contentBox = x.sections.widget_contents.map((s, i) => {
                        return (
                            <div className={classes.boxX}>
                                <p className={classes.num}>{s.name}</p>
                                <p className={classes.boxxContent}>{s.content}</p>
                            </div>
                        )
                    })
                }
                if (x.index === 3 && x.category === "Text") {
                    contentFlip = x.sections.widget_contents.map((s, i) => {
                        return (
                            <span >{s.content}</span>
                        )
                    })
                }
                if (x.index === 4 && x.category === "Text") {
                    contentBox1 = x.sections.widget_contents.map((s, i) => {
                        return (
                            <div className={classes.boxX}>
                                <p className={classes.num1}>{s.name}</p>
                                <p className={classes.boxxContent}>{s.content}</p>
                            </div>
                        )
                    })
                }
                if (x.index === 5 && x.category === "Article") {
                    contentBox3 = x.sections.widget_contents.map((s, i) => {
                        let url = s.assets ? s.assets[0].file : require('./assets/default-image.svg')

                        let link = this.getUrlParser(s.url)
                        return (
                            <div className={classes.box}>
                                <img src={url} alt=""></img>
                                <div className={classes.boxMask}></div>
                                <div className={classes.contentFront}>
                                    <div className={classes.boxContentIn}>
                                        <p>{s.title}</p>
                                    </div>
                                </div>
                                <div className={classes.contentBack}>
                                    <div className={classes.boxContentIn}>
                                        <div className="d-flex flex-column text-right mt-auto mb-auto">
                                            <p>{ReactHtmlParser(s.content)}</p>
                                            <div className="d-flex flex-row justify-content-end position-relative">
                                                {link}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    })
                }
                switch (i) {
                    case 5:
                        backButton =
                            <Link className={classes.customLink} to={x.sections.url ? x.sections.url : '/business'}>
                                <p className={classes.backButton}>{x.sections.widget_contents[0] ? x.sections.widget_contents[0].content : 'back'}</p>
                            </Link>
                        break;
                    default:
                }
            })

        }
        return (
            <div className={classes.contentSection}>
                <div className={['container', classes.customContainer].join(' ')}>
                    <div className={classes.boxContent}>
                        <div className={classes.boxNav}>
                            <img src={require('./assets/back.svg')} alt=""></img>
                            {backButton}
                        </div>
                        {contentCategory}
                        {title}
                        <div className={classes.section1}>
                            <div className={classes.boxImage}>
                                <img className={classes.contentImg} src={contentImg} alt=""></img>
                            </div>
                            <div className="d-flex flex-row mt-3">
                                <div className={classes.boxLeft}>
                                    <p className={classes.titleContent}>{titleContent}</p>
                                    <p className={classes.contentDesc}>{contentL1}</p>
                                </div>
                                <div className={classes.boxRight}>
                                    <div className={classes.sectionContent} style={{ marginTop: '3rem' }}>
                                        {ReactHtmlParser(contentR1)}
                                    </div>
                                    <div className={classes.boxContentOverflow}>
                                        {ReactHtmlParser(contentR2)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={classes.section2}>
                            <div className={classes.sectionContent}>
                                <span className={classes.subTitle}>{ReactHtmlParser(contentL2)}</span>
                                <div className="d-flex flex-row">
                                    <div className={classes.s2_boxLeft}>
                                        {ReactHtmlParser(contentL3)}
                                        {contentL4}
                                        <div className='d-flex flex-row flex-wrap'>
                                            {contentBox}
                                        </div>
                                    </div>
                                    <div className={classes.s2_boxRight}>
                                        <div>
                                            {ReactHtmlParser(contentR3)}
                                            <div className={classes.boxList}>
                                                {ReactHtmlParser(contentR4)}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex flex-row">
                                    <div className={classes.s2_boxLeft}>
                                        {ReactHtmlParser(contentL5)}
                                        <div className={[classes.wordFlip, classes.animateFlip].join(' ')}>
                                            {contentFlip}
                                        </div>
                                        <br />
                                        {ReactHtmlParser(contentL6)}
                                    </div>
                                    <div className={classes.s2_boxRight}>
                                        <div className={classes.boxImageLeft}>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className={classes.section3}>
                            <div className={classes.sectionContent}>
                                <p className={classes.titleContent}>
                                    {contentL7}
                                </p>
                            </div>
                            <div className="d-flex flex-row m-0 p-0">
                                <div className={classes.s2_boxLeft}>
                                    <p className={classes.content}>{ReactHtmlParser(contentL8)}</p>
                                    <p className={classes.content}>{ReactHtmlParser(contentL9)}</p>


                                </div>
                                <div className={classes.s2_boxRight}>
                                    <div className={classes.boxImageLeft}>

                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-row flex-wrap justify-content-center m-0 p-0 mt-5">
                                {contentBox1}
                            </div>

                            <p className={classes.content}>{ReactHtmlParser(contentL10)}</p>
                            <div className="d-flex flex-row m-0 p-0">
                                <div className={classes.s2_boxLeft}>
                                    <p className={classes.content}>{ReactHtmlParser(contentL11)}</p>

                                </div>
                                <div className={classes.s2_boxRight}>
                                    <p className={classes.content}>{ReactHtmlParser(contentL12)}</p>
                                </div>
                            </div>

                            <div className="d-flex flex-row m-0 p-0">
                                {contentBox3}
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

import React, { Component, Fragment, createRef } from "react"
import { Row, Col, Container, Card, CardGroup } from "react-bootstrap"

import SmallEarth from "./SmallEarth/MeshComponent"

import classes from "./overview.module.css"
import Api from "./Api"
import ReactHtmlParser from "react-html-parser"
import EarthVideo from "./assets/earth-video-smaller.mp4"

class overview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: null,
      sections: {
        widgets: [],
      },
      expanded: false,
      dummy: [
        {
          id: 1,
          name: "Main Text",
          content: "Example main text...",
          url: "http://www.google.com",
        },
        {
          id: 2,
          name: "Main Image",
          assets: require("./assets/banner.png"),
          url: "http://www.google.com",
        },
        {
          id: 3,
          name: "Introduction",
          url: "http://www.google.com",
          articles: [
            {
              name: "Main Article",
              title: "What is Lorem Ipsum?",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./assets/default-image.svg"),
            },
          ],
        },
        {
          id: 4,
          name: "Running Text",
          content: "Lorem Ipsum is simply dummy text",
          url: "http://www.google.com",
        },
      ],
      isLoaded: false,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
    this.scrollingHandlerAu = this.scrollingHandlerAu.bind(this)
    this.handleOverride = this.handleOverride.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
    window.addEventListener("scroll", this.scrollingHandlerAu, true)
    this.handleOverride()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollingHandlerAu, true)
  }

  au_sec1_div1 = createRef()
  au_sec1_div2 = createRef()

  handleOverride() {
    this.props.override(false)
  }

  scrollingHandlerAu() {
    // console.log(this.au_sec1_div1.current.getBoundingClientRect().bottom, this.sec2.current.getBoundingClientRect().bottom)
    if (this.au_sec1_div1.current.getBoundingClientRect().bottom < this.au_sec1_div1.current.getBoundingClientRect().height && this.au_sec1_div1.current.getBoundingClientRect().bottom > -1) {
      this.props.updateLogo(true)
    }
    //if(this.sec2.current.getBoundingClientRect().y < 100 && this.sec2.current.getBoundingClientRect().bottom > 100){
    if (this.au_sec1_div2.current.getBoundingClientRect().bottom < this.au_sec1_div2.current.getBoundingClientRect().height && this.au_sec1_div2.current.getBoundingClientRect().bottom > -1) {
      this.props.updateLogo(false)
    }
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState(
      {
        sections: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            this.setState({
              isApiLoaded: true,
            })
            const sections = result.data
            const widget = []
            // console.log(sections);
            result.data.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                sections: item,
              })
            })
            sections.page = widget
            this.setState({
              sections: widget,
              isLoaded: true,
            })
          })
          .catch((error) => {
            // console.log(error);
            this.setState({
              isApiLoaded: true,
            })
          })
      }
    )
  }

  render() {
    const { sections, dummy, expanded } = this.state
    const toggledClass = expanded ? "expanded" : "collapsed"
    let mainText,
      dummyMainText,
      dummyimageBack,
      imageBack,
      dummyName,
      content,
      dummyContent,
      runningText,
      dummyRunning,
      dummyContentIntro,
      imgd,
      name = null

    let count = 0

    dummyMainText = <span>{dummy[0].content}</span>
    dummyimageBack = <img className={classes.imgTop} src={dummy[1].assets} alt='' />
    dummyRunning = <span>{dummy[3].content}</span>
    dummyName = <span>{dummy[2].name}</span>
    dummyContent = <span>{dummy.content}</span>
    dummyContentIntro = dummy[2].articles.map((s) => {
      dummyName = <span>{dummy[2].name}</span>
      dummyContent = <span>{s.content}</span>
    })

    if (this.state.isLoaded) {
      sections.forEach((item) => {
        count += 1
        if (count === 1 && item.index === 1 && item.category === "Text") {
          // console.log(item);
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              mainText = <span>{s.content}</span>
            } else {
              mainText = dummyMainText
            }
          })
        } else if (count === 1 && item.index === 1 && item.category !== "Text") {
          mainText = dummyMainText
        }

        if (count === 2 && item.index === 2 && item.category === "Media") {
          // console.log(item.index);
          item.sections.widget_contents.forEach((s) => {
            // console.log(s.file);

            imageBack = s.file ? <img className={classes.imgTop} src={s.file} alt='' /> : <img className={classes.imgTop} src={require("./assets/default-image.svg")} alt='' />

            return <>{imageBack}</>
          })
        } else if (count === 2 && item.index === 2 && item.category !== "Media") {
          imageBack = dummyimageBack
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          item.sections.widget_contents.forEach((s) => {
            console.log(s)
            if (s.title !== "") {
              name = <span>{s.title}</span>
            } else {
              name = dummyName
            }

            if (s.content !== "") {
              content = <p>{ReactHtmlParser(s.content)}</p>
            } else {
              content = dummyContent
            }
          })
        } else if (count === 3 && item.index === 3 && item.category !== "Article") {
          name = dummyName
          content = dummyContent
        }

        if (count === 4 && item.index === 4 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              runningText = <span>{s.content}</span>
            } else {
              runningText = dummyRunning
            }

            return <>{runningText}</>
          })
        } else if (count === 4 && item.index === 4 && item.category !== "Text") {
          runningText = dummyRunning
        }
      })
    } else if (this.state.isApiLoaded) {
      dummy.forEach((s) => {
        if (s.id === 1) {
          mainText = <span>{s.content}</span>
        } else if (s.id === 2) {
          imageBack = <img className={classes.imgTop} src={require("./assets/default-image.svg")} alt='' />

          return <>{imageBack}</>
        } else if (s.id === 3) {
          s.articles.map((s) => {
            name = <span>{s.title}</span>
            content = <p>{ReactHtmlParser(s.content)}</p>
          })
        } else if (s.id === 4) {
          runningText = <span>{s.content}</span>
        }
      })
    }

    return (
      <Fragment>
        <div>
          <div ref={this.au_sec1_div1} className={classes.bannerTop}>
            {/* {imageBack} */}
            <div className={classes.boxImage}>
              <video className={classes.imgTop} autoPlay loop muted>
                <source src={EarthVideo} type='video/mp4' />
              </video>
            </div>
            {/* <SmallEarth /> */}
            <div className={classes.contentInsideImage}>
              <p className={[classes.p_0, classes.p_0_animation].join(" ")}>{mainText}</p>
              <div className={[classes.p_0_underline]}></div>
            </div>
            <div className={classes.contentRunningText}>{/* <div className={classes.p_3}>{runningText}</div> */}</div>
          </div>
          {/* <div className={["container", classes.cusT].join(" ")}>
            <div className={classes.marg}>
              <div className={classes.p_1}>
                <p>{name}</p>
              </div>

              <div className={classes.p_2}>{content}</div>
            </div>
          </div> */}
          <div ref={this.au_sec1_div2} className={classes.wraperOveriew}>
            <div>
              <Row className={classes.p_1}>
                <p className={classes.p1a}>{name}</p>
              </Row>

              <div className={classes.p_2}>{content}</div>
            </div>
            <Row>{/* <Col
               
                className={classes.p_3}
              >
                {runningText}
              </Col> */}</Row>
          </div>
        </div>
      </Fragment>
    )
  }
}

export default overview

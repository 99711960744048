import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";

import Api from "./Api";

import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import ResponsiveSlider from "./Slider";

import "./sec2.css";
import classes from "./sec2.module.css";

class sec2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        { id: 1, name: "title", content: "Lorem Ipsum Lorem Ipsum?" },
        {
          id: 2,
          name: "subtitle",
          content:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry standard dummy text ever since the 1500s",
        },
        {
          id: 3,
          name: "slider",
          articles: [
            {
              name: "Slider1",
              title: "What is Lorem Ipsum?",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./assets/default-image.svg"),
            },
            {
              name: "Slider2",
              title: "What is Lorem Ipsum?",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./assets/default-image.svg"),
            },
            {
              name: "Slider3",
              title: "What is Lorem Ipsum?",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./assets/default-image.svg"),
            },
            {
              name: "Slider4",
              title: "What is Lorem Ipsum?",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./assets/default-image.svg"),
            },
            {
              name: "Slider5",
              title: "What is Lorem Ipsum?",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./assets/default-image.svg"),
            },
          ],
        },
        {
          id: 4,
          name: "slider",
          articles: [
            {
              name: "Slider1",
              title: "Lorem Ipsum",
              assets: require("./assets/default-image.svg"),
            },
            {
              name: "Slider2",
              title: "Lorem Ipsum?",
              assets: require("./assets/default-image.svg"),
            },
            {
              name: "Slider3",
              title: "Lorem Ipsum?",
              assets: require("./assets/default-image.svg"),
            },
            {
              name: "Slider4",
              title: "Lorem Ipsum?",
              assets: require("./assets/default-image.svg"),
            },
            {
              name: "Slider5",
              title: "Lorem Ipsum?",
              assets: require("./assets/default-image.svg"),
            },
          ],
        },
      ],

      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      isBoxHover: false,
      boxCount: 0,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  toggleBoxHandlerEnter = () => {
    this.setState((prevState) => {
      return { isBoxHover: !prevState.isBoxHover };
    });
  };

  render() {
    const { contentSection, dummy } = this.state;
    let titleContent,
      contentDesc,
      contentSliderTop,
      contentSliderBot,
      dummyTitle,
      dummyDesc,
      dummySliderTop,
      dummySliderBot,
      logo,
      readMore,
      viewDetail = null;

    dummyTitle = <p className={classes.title}>{dummy[0].content}</p>;
    dummyDesc = <p className={classes.title}>{dummy[1].content}</p>;
    dummySliderTop = dummy[2].articles.map((s, i) => {
      let startBox = (i + 1) % 2 !== 0 ? "" : classes.havingMargin;
      let contentBack = this.state.isBoxHover ? classes.isBoxHover : "";

      let isImage = s.assets[0] ? (
        <img className={classes.boxImg} src={s.assets} alt=""></img>
      ) : (
        <img
          className={classes.boxImgDefault}
          src={require("./assets/default-image.svg")}
          alt=""
        />
      );

      return (
        <Card key={s.title} className={[classes.box, startBox].join(" ")}>
          <div className={classes.contentFront}>
            <figure className={classes.contentFigure}>{isImage}</figure>
            <div className={classes.boxVertical}>
              <div className={classes.contentBoxTitle}>
                <div className={classes.outerCircle}>
                  <div className={classes.innerCircle}></div>
                </div>
                <div className={classes.circle}></div>
                <p className={classes.boxTitle}>{s.title}</p>
              </div>
            </div>
          </div>
          <div className={[classes.contentBack, contentBack].join(" ")}>
            <div className="justify-content-center align-self-center">
              <p className={classes.contentTitle}>{s.title}</p>
              <span className={classes.content}>{s.content}</span>
              <p className={classes.content}>
                <strong>Read more</strong>
              </p>
            </div>
          </div>
        </Card>
      );
    });
    dummySliderBot = dummy[2].articles.map((s, i) => {
      let bgColor =
        (i + 1) % 2 === 0 ? "cv-color-sec2-blue" : "cv-color-orange-sec2";
      let isImage = s.assets[0] ? (
        <Card.Img className="img-thumb-sec2" src={s.assets} alt="" />
      ) : (
        <Card.Img
          className="img-thumb-sec2"
          src={require("./assets/default-image.svg")}
          alt=""
        />
      );

      return (
        <Col
          key={s.title}
          className={["image-zoom-sec2", classes.boxSmall].join(" ")}
        >
          <Card className="card-image-zoom">
            {isImage}
            <Card.ImgOverlay
              className={[bgColor, classes.contentAbsolute].join(" ")}
            ></Card.ImgOverlay>
            <div className={classes.contentAbsolute}>
              <div className={classes.contentInBox}>
                <div className="text-midle4">
                  {s.title}
                  {/* <div className={classes.boxLinks}>
                    <div className={classes.outerCircleBot}>
                      <div className={classes.innerCircleBot}></div>
                    </div>

                    <span className={classes.readMore}>View Detail</span>
                  </div> */}
                  <div className={classes.contentBottomTitle}>
                    <div className={classes.outerCircleBot}>
                      <div className={classes.innerCircleBot}></div>
                    </div>
                    <span>View Detail</span>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      );
    });

    if (this.state.isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;

        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              titleContent = <p className={classes.title}>{s.content}</p>;
            }
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Text"
        ) {
          titleContent = dummyTitle;
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              contentDesc = <p className={classes.subTitle}>{s.content}</p>;
            } else {
              contentDesc = <></>;
            }
          });
        } else if (
          count === 2 &&
          item.index === 2 &&
          item.category !== "Text"
        ) {
          contentDesc = <></>;
        }

        if (count === 3 && item.index === 3 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              // readMore = <p className={classes.contentReadmore}>{s.content}</p>;
              readMore = (
                <div className={classes.boxLinks}>
                  <div className={classes.outerCircleBot}>
                    <div className={classes.innerCircleBot}></div>
                  </div>

                  <span className={classes.readMore}>{s.content}</span>
                </div>
              );
            } else {
              readMore = "Read More";
            }
          });
        } else if (
          count === 3 &&
          item.index === 3 &&
          item.category !== "Text"
        ) {
          readMore = "Read More";
        }

        if (count === 4 && item.index === 4 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              viewDetail = <p className={classes.viewMore}>{s.content}</p>;

              // <div className={classes.boxLinks}>
              //   <div className={classes.outerCircleBot}>
              //     <div className={classes.innerCircleBot}></div>
              //   </div>

              //   <span className={classes.viewMore}>{s.content}</span>
              // </div>

              // viewDetail = <p className={classes.viewMore}>{s.content}</p>;
            } else {
              viewDetail = "View Detail";
            }
          });
        } else if (
          count === 4 &&
          item.index === 4 &&
          item.category !== "Text"
        ) {
          viewDetail = "View Detail";
        }

        if (count === 5 && item.index === 5 && item.category === "Article") {
          item.sections.widget_contents
            ? (contentSliderTop = item.sections.widget_contents.map((s, i) => {
                let startBox = (i + 1) % 2 !== 0 ? "" : classes.havingMargin;
                let contentBack = this.state.isBoxHover
                  ? classes.isBoxHover
                  : "";
                let findImage = null;

                var logoIcon;
                s.assets.forEach((assetItems) => {
                  if (assetItems.description === "systemicon") {
                    // logoIcon = assetItems.file;
                  } else {
                    if (findImage === null) {
                      findImage = (
                        <img
                          className={classes.boxImg}
                          src={assetItems.file}
                          alt=""
                        ></img>

                      );
                    }
                  }
                });

                var isImage = findImage ? 
                  findImage
                   : 
                  <img className={classes.boxImgDefault} src={require("./assets/default-image.svg")} alt=""/>
                
                switch (i) {
                  case 0:
                    logoIcon = require("./assets/1.svg")
                    break;
                    case 1:
                      logoIcon = require("./assets/2.svg")
                    
                    break;
                    case 2:
                      logoIcon = require("./assets/3.svg")
                    
                    break;
                    case 3:
                      logoIcon = require("./assets/4.svg")
                    
                    break;
                    case 4:
                      logoIcon = require("./assets/5.svg")
                    
                    break;
                  default:
                    break;
                }  

                logo = (
                  // <img
                  //   className={classes.innerCircle}
                  //   src={logoIcon ? logoIcon : require("./assets/2.svg")}
                  //   alt=" "
                  // />
                  <object type="image/svg+xml" data={logoIcon ? logoIcon : require("./assets/2.svg")} className={classes.innerCircle} aria-label="icon"/>

                );

                return (
                  <Card
                    key={s.title}
                    className={[classes.box, startBox].join(" ")}
                  >
                    <div className={classes.contentFront}>
                      <figure className={classes.contentFigure}>
                        {isImage}
                      </figure>
                      <div className={classes.boxVertical}>
                        <div className={classes.contentBoxTitle}>
                          {logo}
                          <p className={classes.boxTitle}>{s.title}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={[classes.contentBack, contentBack].join(" ")}
                    >
                      <div className="justify-content-center align-self-center">
                        <p className={classes.contentTitle}>{s.title}</p>
                        <span className={classes.content}>
                          {ReactHtmlParser(s.content)}
                        </span>

                        <Link
                          to={{
                            // pathname: item.sections.url ? `/business/${i}/` : "",
                            pathname: item.sections.url
                              ? `${item.sections.url}/${i}`
                              : "",
                            // hash: '#' + item.sections.url.substring(item.sections.url.indexOf('#') + 1),
                          }}
                        >
                          {/* <p className={classes.content}> */}
                          {readMore}
                          {/* </p> */}
                        </Link>
                      </div>
                    </div>
                  </Card>
                );
              }))
            : (contentSliderTop = dummySliderTop);
        } else if (
          count === 5 &&
          item.index === 5 &&
          item.category !== "Article"
        ) {
          contentSliderTop = dummySliderTop;
        }

        if (count === 6 && item.index === 6 && item.category === "Article") {
          item.sections.widget_contents
            ? (contentSliderBot = item.sections.widget_contents.map((s, i) => {
                let contentUrl = null;
                let bgColor =
                  (i + 1) % 2 === 0
                    ? "cv-color-sec2-blue"
                    : "cv-color-orange-sec2";
                let isImage = s.assets[0] ? (
                  <Card.Img
                    className="img-thumb-sec2"
                    src={s.assets[0].file}
                    alt=""
                  />
                ) : (
                  <Card.Img
                    className="img-thumb-sec2"
                    src={require("./assets/default-image.svg")}
                    alt=""
                  />
                );
                if (s.url) {
                  contentUrl =
                    s.url.substring(0, 4) === "http" ? (
                      <a
                        href={s.url}
                        className={classes.customLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {viewDetail}
                      </a>
                    ) : (
                      <Link className={classes.customLink} to={s.url}>
                        {viewDetail}
                      </Link>
                    );
                } else {
                  contentUrl = (
                    <Link className={classes.customLink} to={s.url}>
                      {viewDetail}
                    </Link>
                  );
                }
                return (
                  <Col
                    key={s.title}
                    className={["image-zoom-sec2", classes.boxSmall].join(" ")}
                  >
                    <Card className="card-image-zoom">
                      {isImage}
                      <Card.ImgOverlay
                        className={[bgColor, classes.contentAbsolute].join(" ")}
                      ></Card.ImgOverlay>
                      <div className={classes.contentAbsolute}>
                        <div className={classes.contentInBox}>
                          <div className={classes.txtMid}>
                            {s.title}
                            <div className={classes.boxLinks}>
                              <div className={classes.outerCircleBot}>
                                <div className={classes.innerCircleBot}></div>
                              </div>

                              {/* <span className={classes.readMore}> */}
                                {contentUrl}
                              {/* </span> */}
                            </div>
                            {/* <div className={classes.contentBottomTitle}>
                              <div className={classes.outerCircleBot}>
                                <div className={classes.innerCircleBot}></div>
                              </div>
                              {contentUrl}
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              }))
            : (contentSliderBot = dummySliderBot);
        } else if (
          count === 6 &&
          item.index === 6 &&
          item.category !== "Article"
        ) {
          contentSliderBot = dummySliderBot;
        }
      });
    } else if (this.state.isApiLoaded) {
      titleContent = dummyTitle;
      contentDesc = dummyDesc;
      contentSliderTop = dummySliderTop;
      contentSliderBot = dummySliderBot;
    }

    return (
      <div className={classes.contentSection2}>
        <div className={["container", classes.customContainer].join(" ")}>
          {titleContent}
          {contentDesc}
        </div>
        <div className={classes.contentTop}>
          <ResponsiveSlider setting={"top"} content={contentSliderTop} />
        </div>
        <div className={classes.contentBottom}>
          <ResponsiveSlider setting={"bottom"} content={contentSliderBot} />
        </div>
      </div>
    );
  }
}

export default sec2;

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ReactHtmlParser from "react-html-parser";

import Api from './Api'

import classes from './BS_Page1.module.css'

export default class BS_Page1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
            ],

            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.getHtmlParagraph = this.getHtmlParagraph.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        });
    }

    getHtmlParagraph(str) {
        if (str !== '') {
            // return str.match(/<p>(.*?)<\/p>/g).map(function (val) {
            //     return val.replace(/<\/?p>/g, ' ')
            // });
            // return str.replace(/(<([^>]+)>)/gi, " ")
            //         .replace(/&nbsp;/gi, " ");
            return str.replace(/<\/?p>/gi, " ")
                .replace(/<p>/gi, " ");
        }
    }
    render() {
        const { contentSection } = this.state

        let backButton, contentCategory, title, contentImg, titleContent = null
        let contentL1, contentR1, contentL2, contentL3, contentL4, contentR2, contentL5, contentL6, contentL7, contentL8 = null
        if (this.state.isLoaded) {
            contentSection.forEach((x, i) => {
                if (x.index === 1 && x.category === "Article") {
                    title = x.sections.name ? <p className={classes.title}>{x.sections.name}</p> : <></>
                    x.sections.widget_contents.forEach((s, i) => {
                        contentImg = s.assets[0] ? s.assets[0].file : require('./assets/default-image.svg')
                        titleContent = s.title ? <p className={classes.titleContent}>{s.title}</p> : <></>

                        const splitStr = s.content.split('#')
                        contentCategory = s.category ? <p className={classes.category}>{this.getHtmlParagraph(splitStr[0] ? splitStr[0] : '')}</p> : <></>
                        contentL1 = this.getHtmlParagraph(splitStr[1] ? splitStr[1] : '')
                        contentR1 = this.getHtmlParagraph(splitStr[2] ? splitStr[2] : '')
                        contentL2 = this.getHtmlParagraph(splitStr[3] ? splitStr[3] : '')
                        contentL3 = this.getHtmlParagraph(splitStr[4] ? splitStr[4] : '')
                        contentL4 = this.getHtmlParagraph(splitStr[5] ? splitStr[5] : '')
                        contentR2 = this.getHtmlParagraph(splitStr[6] ? splitStr[6] : '')
                        contentL5 = this.getHtmlParagraph(splitStr[7] ? splitStr[7] : '')
                        contentL6 = this.getHtmlParagraph(splitStr[8] ? splitStr[8] : '')

                        contentL7 = this.getHtmlParagraph(splitStr[9] ? splitStr[9] : '')
                        contentL8 = this.getHtmlParagraph(splitStr[10] ? splitStr[10] : '')

                    })
                }
                switch (i) {
                    case 1:
                        backButton =
                            <Link className={classes.customLink} to={x.sections.url ? x.sections.url : '/business'}>
                                <p className={classes.backButton}>{x.sections.widget_contents[0].content ? x.sections.widget_contents[0].content : 'back'}</p>
                            </Link>
                        break;
                    default:
                }
            })

        }
        return (
            <div className={classes.contentSection}>
                <div className={['container', classes.customContainer].join(' ')}>
                    <div className={classes.boxContent}>
                        <div className={classes.boxNav}>
                            <img src={require('./assets/back.svg')} alt=""></img>
                            {backButton}
                        </div>
                        {contentCategory}
                        {title}
                        <div className={classes.section1}>
                            <div className={classes.boxImage}>
                                <img className={classes.contentImg} src={contentImg} alt=""></img>
                            </div>
                            <div className="d-flex flex-row">
                                <div className={classes.boxLeft}>
                                    {titleContent}
                                    <p className={classes.contentDesc}>{ReactHtmlParser(contentL1)}</p>
                                </div>
                                <div className={classes.boxRight}>
                                    <div className={classes.boxContentOverflow}>
                                        {ReactHtmlParser(contentR1)}
                                    </div>
                                </div>
                            </div>

                            <div className={classes.sectionContent}>
                                {ReactHtmlParser(contentL2)}
                            </div>
                        </div>

                        <div className={classes.section2}>
                            <div className={classes.sectionContent}>
                                {ReactHtmlParser(contentL3)}
                                <div className='d-flex flex-row mt-3'>
                                    <div className={classes.s2_boxLeft}>
                                        <div>
                                            {ReactHtmlParser(contentL4)}
                                        </div>
                                    </div>
                                    <div className={classes.s2_boxRight}>
                                        <div>
                                            {ReactHtmlParser(contentR2)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.section3}>
                            <div className={classes.sectionContent}>
                                <div>
                                    {ReactHtmlParser(contentL5)}
                                    {ReactHtmlParser(contentL6)}
                                </div>
                                <div>
                                    {ReactHtmlParser(contentL7)}
                                    {ReactHtmlParser(contentL8)}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

import React, { Component } from "react";
import classes from "./Section1.module.css";
import Api from "./Api";
import ReactHtmlParser from "react-html-parser";
// import SmallSizeVideo from './assets/video-background.mp4';
import SmallSizeVideo from './assets/solar-panels-and-wind-generator-HYH74FQ1.mp4'

export default class SD_Section1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        { id: 1, name: "title", content: "Phát triển bền vững trên nềnc" },
        {
          id: 2,
          name: "image",
          content:
            "Trên quan điểm spanhát triển bền vững, Tập đoàn Phenikaa xác định kinh doanh hiệu quả không chỉ giới hạn ở các chỉ tiêu tài chính tăng trưởng ổn định. Nền tảng Phát triển bền vững nằm ở giá trị gia tăng mà doanh nghiệp mang lại cho đối tác, khách hàng, người lao động, cộng đồng xã hội và các bên liên quan, trên cơ sở hợp tác, hài hòa lợi ích, chia sẻ tri thức và cùng hành động để đảm bảo môi trường sinh thái bền vững, giải quyết các thách thức toàn cầu, vì một cuộc sống nhân văn, hạnh phúc cho mỗi con người trong xã hội hiện tại và thế hệ mai sau.",
          asset: require("./assets/section1.png"),
        },
        {
          id: 3,
          name: "image background",
          asset: require("./assets/Image147.png"),
        },
      ],
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {
    const { contentSection, dummy } = this.state;
    let imgcont,
      titlecont,
      imgback,
      datacont,
      dummy_titlecont,
      dummy_datacont,
      dummy_imageback,
      dummy_imgcont = null;

    if (this.state.isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Article") {
          item.sections.widget_contents.forEach((ele) => {
            titlecont = (
              <div className={["container", classes.contentInBox].join(" ")}>
                <span className={classes.textInside}>
                  <p>{ele.title}</p>
                </span>
                <div className={[classes.p_0_underline]}></div>
              </div>
            );
            imgcont = (
                <div className={classes.boxImage}>
                  <video className={classes.imgContent} autoPlay loop muted>
                      <source src={SmallSizeVideo} type='video/mp4' />
                  </video>
                </div>
              //   <img
              //     className={classes.imgContent}
              //     src={ele.assets[0].file}
              //     alt=""
              //   />
            );
            datacont = (
              <div className={classes.boxTextBottom}>
                <span>{ReactHtmlParser(ele.content)}</span>
              </div>
            );
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Text"
        ) {
          titlecont = dummy_titlecont;
          datacont = dummy_datacont;
          imgcont = dummy_imgcont;
        }

        if (count === 2 && item.index === 2 && item.category === "Media") {
          item.sections.widget_contents.forEach((ele) => {
            imgback = (
              <div className={classes.overlayBackground}>
                <img className={classes.imgBackground} src={ele.file} />
              </div>
            );
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Media"
        ) {
          imgback = dummy_imageback;
        }
      });
    } else if (this.state.isApiLoaded) {
      titlecont = dummy_titlecont;
      datacont = dummy_datacont;
      imgcont = dummy_imgcont;
      imgback = dummy_imageback;
    }

    return (
      <div className={classes.Section1}>
        {imgback}
        {imgcont}
        {titlecont}

        <div className={["container", classes.boxC, classes.customContainer].join(" ")}>
          {datacont}
        </div>
      </div>
    );
  }
}

import React, { Fragment, Component } from "react"
import { Row } from "react-bootstrap"

import dateFormat from "dateformat"

import Api from "../Api"
import HistoryViews from "../HistoryViews"

import classes from "../history.module.css"

class Desktop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      box: [
        {
          id: "",
          num: "",
          text_1: "",
          text_2: ",",
        },
        {
          id: "",
          num: "",
          text_1: "",
          text_2: ",",
        },
        {
          id: "",
          num: "",
          text_1: "",
          text_2: ",",
        },
      ],
      dummy: [
        {
          id: 1,
          name: "Our History",
          articles: [
            {
              name: "Main Article",
              title: "What is Lorem Ipsum?",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../assets/default-image.svg"),
            },
          ],
        },
        {
          id: 2,
          name: "Articles",
          articles: [
            {
              name: "Articles1",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../assets/default-image.svg"),
            },
            {
              name: "Articles2",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../assets/default-image.svg"),
            },
            {
              name: "Articles3",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../assets/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../assets/default-image.svg"),
            },
          ],
        },
      ],
      currentPage: null,
      sections: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      updateValue: 0,
      slideIndex: 0,
      nav1: null,
      nav2: null,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
    this.next = this.next.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  next() {
    this.slider2.slickNext()
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState(
      {
        sections: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            this.setState({
              isApiLoaded: true,
            })
            const sections = result.data
            const widget = []
            result.data.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                contentTitle: sections.widgets[2].widget_contents[0].content,
                category: item.category,
                internal: item.is_internal,
                sections: item,
              })
            })
            sections.page = widget
            this.setState({
              sections: widget,
              isLoaded: true,
            })
          })
          .catch((error) => {
            console.log(error)
            this.setState({
              isApiLoaded: true,
            })
          })
      }
    )
  }

  render() {
    const { sections, dummy, updateValue, slideIndex } = this.state

    let name,
      contents,
      year,
      titleContent,
      historyViews,
      dummyTitleContent,
      contentSlider,
      imageSlider,
      dummyImageSlider,
      dummyContentSlider = null

    let count = 0

    dummyContentSlider = dummy[1].articles.map((s) => {
      year = <p>{s.year}</p>
      titleContent = <p>{s.title}</p>
      return (
        <div>
          <div onChange={(e) => this.slider.slickGoTo(e.target.value)} value={this.state.slideIndex} type='range' min={0} max={4}>
            {year}
          </div>
          <div className={classes.p_col_2} onChange={(e) => this.slider.slickGoTo(e.target.value)} value={this.state.slideIndex} type='range' min={0} max={4}>
            {titleContent}
          </div>
        </div>
      )
    })

    dummyImageSlider = dummy[1].articles.map((s) => {
      let isImage = s.assets[0] ? <img className={classes.img_col_1} src={s.assets[0].file} alt='' /> : <img className={classes.img_col_1} src={require("../assets/default-image.svg")} alt='' />

      return <>{isImage}</>
    })

    let viewAllContent = ""

    if (this.state.isLoaded) {
      sections.forEach((item) => {
        count += 1

        name = <p>{item.name}</p>

        switch (count) {
          case 1:
            viewAllContent = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "view all"

            break
          case 2:
            historyViews = <HistoryViews id={item.id} title={item.contentTitle} url={item.sections.url ? item.sections.url : "/"} viewAll={viewAllContent} />
            contentSlider = item.sections.widget_contents.map((s) => {
              let strYear = s.date
              let yearFull = new Date(strYear)
              year = yearFull.getFullYear()

              if (s.title !== "") {
                titleContent = <p>{s.title}</p>
              } else {
                titleContent = dummyTitleContent
              }

              return (
                <div>
                  <div onChange={(e) => this.slider.slickGoTo(e.target.value)} value={this.state.slideIndex} type='range' min={0} max={4}>
                    {year}
                  </div>
                  <div className={classes.p_col_2} onChange={(e) => this.slider.slickGoTo(e.target.value)} value={this.state.slideIndex} type='range' min={0} max={4}>
                    {titleContent}
                  </div>
                </div>
              )
            })
            imageSlider = item.sections.widget_contents.map((s) => {
              let isImage = s.assets[0] ? <img className={classes.img_col_1} src={s.assets[0].file} alt='' /> : <img className={classes.img_col_1} src={require("../assets/default-image.svg")} alt='' />

              return <>{isImage}</>
            })
          case 3:
            viewAllContent = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "view all"

            break
        }
       
      })
    } else if (this.state.isApiLoaded) {
      dummy.forEach((s) => {
        if (s.id === 1) {
          name = <p>{s.name}</p>
        } else if (s.id === 2) {
          contentSlider = s.articles.map((s) => {
            if (s.title !== "") {
              titleContent = <p>{s.title}</p>
            } else {
              titleContent = dummyTitleContent
            }

            return (
              <div>
                <div onChange={(e) => this.slider.slickGoTo(e.target.value)} value={this.state.slideIndex} type='range' min={0} max={4}>
                  {s.year}
                </div>
                <div className={classes.p_col_2} onChange={(e) => this.slider.slickGoTo(e.target.value)} value={this.state.slideIndex} type='range' min={0} max={4}>
                  {titleContent}
                </div>
              </div>
            )
          })

          imageSlider = s.articles.map((s) => {
            let isImage = <img className={classes.img_col_1} src={s.assets} alt='' />

            return <>{isImage}</>
          })
        }
      })
    }

    return (
      <Fragment>
        <div fluid>
          <Row className={classes.row_1}>{historyViews}</Row>
        </div>
      </Fragment>
    )
  }
}

export default Desktop

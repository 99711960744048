import React, { Component, createRef } from "react";
import Header from "../../sections/Header/Header";

import Section1 from "../../sections/IN_Section1/IN_Section1";
import Section2 from "../../sections/IN_Section2/IN_Section2";
import Section3 from "../../sections/IN_Section3/IN_Section3";
import Section4 from "../../sections/IN_Section4/IN_Section4";
import HomeSectionShortcut from "./SectionShortcut/InSectionShortcut";
import SectionShortcut from "./SectionShortcut/SectionShortcut";

import Footer from "../../sections/Footer/Footer";
import { ParallaxProvider } from "react-scroll-parallax";

import styled from "styled-components";

const CustomDiv = styled.div`
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
`;
export default class Innovation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      section: [
        {
          id: 1,
          title: "SÁNG TẠO VÀ SẺ CHIA",
          component: "Section 1",
        },
        {
          id: 2,
          title: "BLOG",
          component: "Section 2",
        },
        {
          id: 3,
          title: "Công bố khoa học và dự án R&D",
          component: "Section 3",
        },
        {
          id: 4,
          title: "Kaizen",
          component: "Section 4 ",
        },
      ],
    };
  }
  section1 = createRef();
  section2 = createRef();
  section3 = createRef();
  section4 = createRef();

  scrolltoSectionHandler = (key) => {
    if (key === "section1") {
      this.section1.current.scrollIntoView({ behavior: "smooth" });
    } else if (key === "section2") {
      this.section2.current.scrollIntoView({ behavior: "smooth" });
    } else if (key === "section3") {
      this.section3.current.scrollIntoView({ behavior: "smooth" });
    } else if (key === "section4") {
      this.section4.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    let sec = this.state.section.map((s) => {
      let ref = "section" + s.id;
      return (
        <SectionShortcut
          key={s.id}
          title={s.title}
          onClick={() => this.scrolltoSectionHandler(ref)}
        />
      );
    });
    let homeSectionShortcut = <HomeSectionShortcut section={sec} />;
    return (
      <div className="innovation-page">
        <Header onPage={"innovation"} />
        <CustomDiv>
          <ParallaxProvider>
            <React.Fragment>
              <div ref={this.section1}>
                <Section1 />
              </div>
              <div ref={this.section2}>
                <Section2 />
              </div>
              <div ref={this.section3}>
                <Section3 />
              </div>
              <div ref={this.section4}>
                <Section4 />
              </div>
            </React.Fragment>
            {homeSectionShortcut}
          </ParallaxProvider>
        </CustomDiv>
        <Footer />
      </div>
    );
  }
}

import React, { Component } from 'react'
import MegaMenuItem from './MegaMenuItem/MegaMenuItem'
import Aux from '../../hoc/Auxs/Auxs'
import Preview1 from '../MenuDetail/MenuPreview1/MenuPreview1'
import Preview2 from '../MenuDetail/MenuPreview2/MenuPreview2'
import Preview3 from '../MenuDetail/MenuPreview3/MenuPreview3'
import Preview4 from '../MenuDetail/MenuPreview4/MenuPreview4'
import Preview5 from '../MenuDetail/MenuPreview5/MenuPreview5'
import Preview6 from '../MenuDetail/MenuPreview6/MenuPreview6'

class MegaMenuItems extends Component {
    state = {
        items: [
            { id: 1, title: 'ABOUT US', action: 'hover', type: 'expand', link: '/about-us' },
            { id: 2, title: 'BUSINESS AREA', action: 'hover', type: 'expand', link: '/business' },
            { id: 3, title: 'SUSTAINABLE DEVELOPMENT', action: 'hover', type: 'expand', link: '/sustainable' },
            { id: 4, title: 'INNOVATION', action: 'hover', type: 'expand', link: '/innovation' },
            { id: 5, title: 'NEWS', action: 'hover', type: 'expand', link: '/news' },
            { id: 6, title: 'CAREER', action: 'hover', type: 'disable', link: '/' },
            { id: 7, title: 'CONTACT', action: 'click', type: 'link', link: '/' },
        ],

        content: <div></div>
    }
    onMouseEnterHandler = (val) => {
        this.setState({
            content: [val],
        });
    }

    render() {
        let menusApi, menuDummy = null
        menuDummy = this.state.items.map(i => {
            let id = 'preview' + i.id
            let showContent = null
            if (i.id === 1) {
                showContent = <Preview1 key={i+1}/>
            } else if (i.id === 2) {
                showContent = <Preview2 key={i+1}/>
            } else if (i.id === 3) {
                showContent = <Preview3 key={i+1}/>
            } else if (i.id === 4) {
                showContent = <Preview4 key={i+1}/>
            } else if (i.id === 5) {
                showContent = <Preview5 key={i+1}/>
            } else if (i.id === 6) {
                showContent = <Preview6 key={i+1}/>
            } else {
                showContent = ''
            }
            return (
                <MegaMenuItem
                    key={i.id}
                    id={id}
                    title={i.title}
                    type={i.type}
                    link={i.link}
                    onMouseEnter={() => this.onMouseEnterHandler(showContent)}
                    content={this.state.content}
                />
            );
        });
        if (this.props.items) {
            const items = this.props.items
            const groupItem = []
            const item = []

            let listItems = null
            items.items.forEach((x) => {
                if (x.children) {
                    x.children.forEach(i => {
                        const child = []
                        if (i.children) {
                            i.children.forEach(y => {
                                child.push(
                                    y
                                )
                            })
                        } else {
                            child.push('none')
                        }

                        item.push({
                            index: i.index,
                            id: i.id,
                            name: i.title,
                            children: child
                        })
                    })
                    groupItem.push({
                        name: x.name,
                        items: item
                    })
                } else {
                    groupItem.push({
                        name: x.name,
                        items: 'null'
                    })
                }

                items.item = groupItem
                listItems = items
            })

            if (listItems) {
                menusApi = listItems.items.map((x, i) => {
                    let showContent, type = null
                    // console.log(x)
                    if (i === 5){
                        // console.log(x)
                    }
                    x.children ? type = 'expand' : type = 'link'
                    if (i === 0 && x.children) {
                        showContent = <Preview1 key={x.id} name={x.name} items={x.children} />
                    } else if (i === 1 && x.children) {
                        showContent = <Preview2 key={x.id} name={x.name} items={x.children} />
                    } else if (i === 2 && x.children) {
                        showContent = <Preview3 key={x.id} name={x.name} items={x.children} />
                    } else if (i === 3 && x.children) {
                        showContent = <Preview4 key={x.id} name={x.name} items={x.children} />
                    } else if (i === 4 && x.children) {
                        showContent = <Preview5 key={x.id} name={x.name} items={x.children} />
                    } else if (i === 5) {
                        showContent = <></>
                    } 
                    return (
                        <MegaMenuItem
                            key={x.name}
                            title={x.name}
                            type={type}
                            link={x.url}
                            onMouseEnter={() => this.onMouseEnterHandler(showContent)}
                            content={this.state.content}
                        />
                    )
                })
            } else {
                menusApi = menuDummy
            }
        } else {
            menusApi = menuDummy
        }
        
        return (
            <Aux>
                {menusApi}
                {this.state.content}
            </Aux>
        )
    }
}

export default MegaMenuItems
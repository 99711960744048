import React, { useContext } from 'react'

import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { AccordionContext } from 'react-bootstrap';

import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from '../Blog.module.scss'

export default function Accordion({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(eventKey,() => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;
    let icon = isCurrentEventKey ? <FontAwesomeIcon color='red' className='ml-auto' icon={faChevronDown} size="lg" /> : <FontAwesomeIcon color='red' className='ml-auto' icon={faChevronUp} size="lg" />
    return (
        <div onClick={decoratedOnClick} className='d-flex relative'>
            <p
                className={classes.titleAccordion}
                // style={{ backgroundColor: isCurrentEventKey ? 'pink' : 'lavender' }}
            >
                {children}
            </p>
            {icon}
        </div>
    )
}

import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import ReactHtmlParser from "react-html-parser";
import Api from './Api'

import classes from './Section2.module.css'

export default class Box extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [],
            contentArticle: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: true,
        }
        this.getArticleDetail = this.getArticleDetail.bind(this);
    }

    componentDidMount() {
        if (this.props.id) {
            this.getArticleDetail(this.props.id)
        }
    }

    getArticleDetail(param) {
        const apiData = new Api();
        this.setState({ contentArticle: [] }, () => {
            apiData.getArticleDetail(param)
                .then(result => {
                    const articles = result.data
                    const art = []
                    articles.results.forEach((a) => {
                        const assets = []
                        a.article_assets.forEach((ass, i) => {
                            if (i === 0) {
                                assets.push({
                                    asset: ass.asset_file
                                })
                            }
                        })

                        art.push({
                            id: a.id,
                            category: a.category_name,
                            name: a.name,
                            author_name: a.author_name, 
                            author_avatar: a.author_avatar,
                            title: a.article_languages.title,
                            content: a.article_languages.content,
                            assets: assets,
                            date: a.date
                        })
                    })

                    this.setState({
                        contentArticle: art,
                        isLoaded: true
                    })
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        })
    }

    render() {
        const { contentArticle } = this.state
        let box = null
        if (this.state.isLoaded) {
            box = contentArticle.map((s, i) => {
                let box = i === 1 ? [classes.box, 'mr-5 ml-5'].join(' ') : classes.box
                return (
                    <div key={s.id} className={box}>
                        <div className={classes.boxBackground}></div>
                        <div className={classes.boxProfile}>
                            {/* <img src={require('./assets/profile.jpg')} alt=''></img> */}
                        </div>
                        <div className={classes.boxContent}>
                            <img className={classes.imgProfile} src={s.author_avatar ? s.author_avatar : require('./assets/dummy-profile.jpg')} alt=''></img>
                            <div className={classes.boxBody}>
                                <span className={classes.titleContent}>{ReactHtmlParser(s.content)}</span>
                                <p className={classes.authorContent}>{s.author_name}</p>
                            </div>
                            <div className={classes.boxFooter}>
                                <div className={classes.boxLinks}>
                                    <div className={classes.outerCircle}>
                                        <div className={classes.innerCircle}></div>
                                    </div>
                                    <Link to={`${this.props.urlDetail}${s.id}`} className={classes.customLink}>
                                        <p className={classes.readMoreContent}>{this.props.readMore}</p>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        return (
            <>
                {box}
            </>
        )
    }
}

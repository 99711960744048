import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import classes from "./Scientific.module.css";

export default class Scientific extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                { id: 1, name: "Title", content: "What is Lorem Ipsum?" },
                {
                    id: 2,
                    name: "Content",
                    content:
                        "Where does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
                },
                {
                    id: 4,
                    name: "Background Image",
                    assets: require("./assets/default-image.svg"),
                },
            ],

            isDetail: false,
        };
    }

    getHtmlParagraph(str) {
        if (str !== "") {
            return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
        }
    }

    toggleDetail = () => {
        this.setState((prevState) => {
            return { isDetail: !prevState.isDetail };
        });
    };

    getUrlParser(url) {
        if (url !== "/") {
            return url.includes("http") ?
                <a className={classes.customLink} href={url} target="_blank" rel="noopener noreferrer">
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.viewMore}>{this.props.viewDetails}</p>
                </a>
                :
                <Link className={classes.customLink} to={url}>
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.viewMore}>{this.props.viewDetails}</p>
                </Link>
                ;
        } else {
            return <Link className={classes.customLink} to={url}>
                <div className={classes.outerCircle}>
                    <div className={classes.innerCircle}></div>
                </div>
                <p className={classes.viewMore}>{this.props.viewDetails}</p>
            </Link>
        }
    }
    render() {
        const { dummy } = this.state;
        let title,
            desc,
            img,
            content,
            link = null;
        let detailContent,
            contentL1,
            contentL2,
            contentL3,
            contentL4,
            contentL5,
            contentL6,
            contentL7,
            contentL8,
            contentL9,
            contentL10,
            contentL11,
            contentBox,
            contentImg1,
            contentImg2 = null;

        const settings = {
            centerMode: false,
            infinite: true,
            centerPadding: "",
            variableWidth: true,
            slidesToShow: 4,
            slidesToScroll: 4,
            speed: 500,
        };

        if (this.props.items) {
            let items = this.props.items;
            title = <p className={classes.title}>{items[0].item.title}</p>;

            let contentImg = items[0].item.assets[0]
                ? items[0].item.assets[0].file
                : require("./assets/default-image.svg");
            img = <img className={classes.imgContent} src={contentImg} alt="" />;
            content = (
                <div className={classes.contentDesc}>
                    {ReactHtmlParser(items[1].desc.content)}
                </div>
            );
            link = !this.state.isDetail ? (
                // <Link className={classes.link} to={{ pathname: `${items[1].desc.url ? items[1].desc.url : '/'}` }} >

                <div
                    className={[classes.contentLink].join(" ")}
                    onClick={this.toggleDetail}
                >
                    <div className="d-flex">
                        <div className={classes.outerCircle2}>
                            <div className={classes.innerCircle2}></div>
                        </div>
                        <p className={classes.contentLink}>{this.props.readMore}</p>
                    </div>
                </div>
            ) : (
                    // <div
                    //   className={[classes.contentLink].join(" ")}
                    //   onClick={this.toggleDetail}
                    // >
                    //   <div className="d-flex">
                    //     <div className={classes.outerCircle}>
                    //       <div className={classes.innerCircle}></div>
                    //     </div>
                    //     <p className={classes.contentLink}>{this.props.readMore}</p>
                    //   </div>
                    // </div>
                    <></>
                );

            const splitStr = items[0].item.content.split("#");
            desc = (
                <span className={classes.content}>
                    {ReactHtmlParser(splitStr[0] ? splitStr[0] : "")}
                </span>
            );
            contentL1 = splitStr[1] ? splitStr[1] : "";
            contentL2 = splitStr[2] ? splitStr[2] : "";
            contentL3 = splitStr[3] ? splitStr[3] : "";
            contentL4 = splitStr[4] ? splitStr[4] : "";
            contentL5 = splitStr[5] ? splitStr[5] : "";
            contentL6 = splitStr[6] ? splitStr[6] : "";
            contentL7 = splitStr[7] ? splitStr[7] : "";
            contentL8 = splitStr[8] ? splitStr[8] : "";
            contentL9 = splitStr[9] ? splitStr[9] : "";
            contentL10 = splitStr[10] ? splitStr[10] : "";
            contentL11 = splitStr[11] ? splitStr[11] : "";

            contentImg1 = items[0].item.assets[1] ? items[0].item.assets[1].file : require("./assets/default-image.svg");

            contentImg2 = items[0].item.assets[2] ? items[0].item.assets[2].file : require("./assets/default-image.svg");

            if (this.props.dataExt) {
                this.props.dataExt.forEach((d, i) => {
                    switch (i) {
                        case 1:
                            contentBox = d.sections.map((s, i) => {
                                let url = s.assets[0]
                                    ? s.assets[0].file
                                    : require("./assets/default-image.svg");
                                let link = s.url ? this.getUrlParser(s.url) : this.getUrlParser("/");
                                return (
                                    <div className={classes.box}>
                                        <img src={url} alt=""></img>
                                        <div className={classes.boxMask}></div>
                                        <div className={classes.contentFront}>
                                            <div className={classes.boxContentIn}>
                                                <p>{this.getHtmlParagraph(s.title)}</p>
                                            </div>
                                        </div>
                                        <div className={classes.contentBack}>
                                            <div className={classes.boxContentIn}>
                                                <div className="d-flex flex-column text-right mt-auto mb-auto">
                                                    <p>{this.getHtmlParagraph(s.content)}</p>
                                                    <div className="d-flex flex-row justify-content-end position-relative mt-2">
                                                        {link}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            });
                            break;
                        default:
                    }
                });
            }

            //   if (this.props.dataExt) {
            //     this.props.dataExt.forEach((d, i) => {
            //       switch (i) {
            //         case 0:
            //           contentBox = d.sections.map((s, i) => {
            //             let url = s.assets[0]
            //               ? s.assets[0].file
            //               : require("./assets/default-image.svg");
            //             let link = s.url ? this.getUrlParser(s.url) : "/";
            //             return (
            //               <div className={classes.box}>
            //                 <img src={url} alt=""></img>
            //                 <div className={classes.boxMask}></div>
            //                 <div className={classes.contentFront}>
            //                   <div className={classes.boxContentIn}>
            //                     <p>{this.getHtmlParagraph(s.title)}</p>
            //                   </div>
            //                 </div>
            //                 <div className={classes.contentBack}>
            //                   <div className={classes.boxContentIn}>
            //                     <div className="d-flex flex-column text-right mt-auto mb-auto">
            //                       <p>{this.getHtmlParagraph(s.content)}</p>
            //                       <div className="d-flex flex-row justify-content-end position-relative mt-2">
            //                         <Link className={classes.customLink} to={link}>
            //                           <div className={classes.outerCircle}>
            //                             <div className={classes.innerCircle}></div>
            //                           </div>
            //                           <span className={classes.viewMore}>
            //                             View Details
            //                           </span>
            //                         </Link>
            //                       </div>
            //                     </div>
            //                   </div>
            //                 </div>
            //               </div>
            //             );
            //           });
            //           break;
            //         default:
            //       }
            //     });
            //   }

            let animate = this.state.isDetail
                ? classes.fadeInBottom
                : classes.fadeOutTop;
            detailContent = this.state.isDetail ? (
                <div className={[classes.detailContent, animate].join(" ")}>
                    <>
                        <hr />
                        <div className={classes.allContentB}>
                            <div className={classes.txtParB}>
                                {/* <p className={classes.pArt}>Title</p>
                <p className={classes.titleCont}>XXX</p> */}
                                <p className={classes.txtReal}>
                                    {ReactHtmlParser(contentL1)}
                                    {ReactHtmlParser(contentL2)}
                                </p>
                            </div>
                            <div className={classes.txtParB2}>
                                <img className={classes.imgLogo} src={contentImg1} alt=" " />
                            </div>
                        </div>
                    </>

                    <>
                        <hr />
                        <div className={classes.allContentC}>
                            <div className={classes.boxPar}>
                                {/* <p className={classes.pArt}>Title</p>
                <p className={classes.titleCont}>XXX</p> */}
                                <p className={classes.txtParC}>{ReactHtmlParser(contentL3)}</p>
                            </div>
                            <div className={classes.absImg}>
                                <img className={classes.imgLogo2} src={contentImg2} alt=" " />
                            </div>
                        </div>
                    </>
                    {/* {ReactHtmlParser(contentL1)}
                    {ReactHtmlParser(contentL2)}

                    <div className='d-flex'>
                        <div className={classes.boxL50}>
                            <figure><img src={contentImg1} alt=""></img></figure>
                        </div>
                        <div className={classes.boxR50}>
                            <div className={classes.boxList}>
                                {ReactHtmlParser(contentL3)}

                            </div>
                        </div>
                    </div> */}
                    {ReactHtmlParser(contentL4)}

                    {ReactHtmlParser(contentL5)}
                    <Slider {...settings}>{contentBox}</Slider>

                    {/* <div className="d-flex flex-row flex-wrap m-0 p-0">
                        {contentBox}
                    </div> */}
                    <div className={classes.csutomContent}>
                        {ReactHtmlParser(contentL6)}
                        <div className={classes.customList}>
                            {ReactHtmlParser(contentL7)}
                            <div className="ml-5 mr-5">{ReactHtmlParser(contentL8)}</div>
                            {ReactHtmlParser(contentL9)}
                        </div>
                        {ReactHtmlParser(contentL10)}
                        {ReactHtmlParser(contentL11)}
                    </div>
                    {/* 
          <div className={classes.boxLinks} onClick={this.toggleDetail}>
            <div className={classes.outerCircle2}>
              <div className={classes.innerCircle}></div>
            </div>
            {this.props.readLess}
          </div> */}

                    <div
                        className={[classes.contentLink].join(" ")}
                        onClick={this.toggleDetail}
                    >
                        <div className="d-flex">
                            <div className={classes.outerCircle2}>
                                <div className={classes.innerCircle2}></div>
                            </div>
                            <p className={classes.contentLink}>{this.props.readLess}</p>
                        </div>
                    </div>
                </div>
            ) : (
                    <></>
                );
        } else {
            title = <p className={classes.title}>{dummy[0].content}</p>;
            desc = <p className={classes.content}>{dummy[1].content}</p>;
            img = (
                <img className={classes.imgContentD} src={dummy[2].assets} alt="" />
            );
            content = (
                <div>
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specimen book
          </p>
                    <br />
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specimen book
          </p>
                    <br />
                    <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specimen book
          </p>
                </div>
            );
        }

        let customMargin = !this.state.isDetail ? "mb-5" : "";

        return (
            <div className={classes.sectionScientific}>
                <div
                    className={[
                        "container d-flex flex-column",
                        classes.customContainer,
                    ].join(" ")}
                >
                    <div className={[classes.boxImage].join(" ")}>
                        <div className={classes.animate}>{img}</div>
                    </div>
                    <div className={["d-flex", customMargin].join(" ")}>
                        <div className={classes.boxContent}>
                            {title}
                            {desc}
                            {link}
                        </div>
                        <div className={classes.boxOverflow}>
                            <div className={classes.boxContentOverflow}>{content}</div>
                        </div>
                    </div>

                    {detailContent}
                </div>
            </div>
        );
    }
}

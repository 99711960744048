import React, { Component } from "react";
import { Slide, Zoom, Pulse } from "react-awesome-reveal";
import ReactHtmlParser from "react-html-parser";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import classes from "./History.module.css";

export class AU_HistoryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
      max: this.props.total,
      maxArticle: 0,
      data: [],
      disabledNext: true,
      disabledPrev: false,
      contentChange: <div></div>,
      content: <div></div>,
      currentIndex: 0,
      prevArrow: false,
    };
    this.toggleNext = this.toggleNext.bind(this);
    this.togglePrev = this.togglePrev.bind(this);
  }

  // componentDidMount(){
  //   console.log('total Article =' + this.props.total)
  // }

  togglePrev() {
    this.setState((prevState) => {
      if (prevState.index <= 0) {
        return { disabledPrev: true };
      } else {
        return { index: prevState.index - 1 };
      }
    });
    console.log(this.state)
  }

  toggleHandlerBack = () => {
    this.setState({
      content: <div></div>,
    });
  };

  toggleNext() {
    this.setState((prevState) => {
      if (prevState.index === (this.state.max-1)) {
        return { disabledNext: false };
      } else if (prevState.index !== (this.state.max - 1)) {
        return { index: prevState.index + 1, disablePrev: false };
      }
    });
  }

  render() {
    let boxDetails,
      boxDetails2,
      year,
      date,
      content,
      file,
      title,
      VALUES = null;

    let data = this.props.data;
    console.log("data")
    console.log(data)
    data.map((ele, index) => {
      if (this.props.index === index) {
        VALUES = ele.art.map((item, i) => {

          if (this.state.index === i) {
            date = item.date;
            // file = item.article_assets[0] ? item.article_assets[0].asset_file : ''
            content = item.article_languages.content;
            title = item.article_languages.title;

            year = dateFormat(date, "yyyy");

            let img = item.article_assets[0]
              ? item.article_assets[0].asset_file
              : require("./asset/default-image.svg");

            boxDetails = (
              // <Pulse>
                <div className={classes._boxIns}>
                  <img className={classes.imgFull} src={img} alt="" />

                  <div className={["container", classes._flex].join(" ")}>
                    <div className={classes._boxIns2}>
                      <div className="d-flex">
                        <div className={classes._box1}>
                          <p className={classes._years}>{year}</p>
                        </div>
                        <div className={classes._box2}>
                          <div className={classes._box2_content}>
                            <div className={classes._column1}>
                              <p className={classes._title1}>{title}</p>
                            </div>
                            <div className={classes._column2}>
                              <p className={classes._title2}>
                                {ReactHtmlParser(content)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes._tmline}></div>
                  </div>
                  {/* <div className={classes._boxBtn}>YYY</div> */}
                </div>
              // </Pulse>
            );
            return <div className={classes.boxPreview}>{boxDetails}</div>;
          }
        });
      }
    });

    const leftArrow = () => (
      <img
      onClick={this.togglePrev}
      className={classes.arrow_left}
      src={require("./asset/left.svg")}
      alt=""
    />
    )

    const rightArrow = () => (
      <img
            onClick={this.toggleNext}
            className={[classes.arrow_right, nextButton].join(' ')}
            src={require("./asset/right.svg")}
            alt=""
          />
    )

    let nextButton = this.state.disabledNext ? '' : classes.disableNext ;
    console.log("Prev + " + this.state.disabledPrev)
    console.log("Next + " + this.state.disabledNext)
    return (
      <div className={classes.boxDetailShow}>
        <div clasName={classes.img_details} onChange={(e) => this.props.index}>
          {VALUES}
          {/* <Link to={"/about-us/history"} className={classes.Link}> */}
            <img
              onClick={this.props.onClick}
              className={classes.img_minus}
              src={require("./asset/minus.svg")}
              alt=""
            />
          {/* </Link> */}

        {/* {this.state.prevArrow} */}

          {!this.state.disabledPrev ? <leftArrow/> : null}

          {/* <img
            onClick={this.togglePrev}
            className={classes.arrow_left}
            disabled={this.state.disabledPrev}
            src={require("./asset/left.svg")}
            alt=""
          /> */}
          {/* <img
            onClick={this.toggleNext}
            className={[classes.arrow_right, nextButton].join(' ')}
            disabled={this.state.disabledNext}
            src={require("./asset/right.svg")}
            alt=""
          /> */}
        </div>
        {this.state.contentChange}
      </div>
    );
  }
}

export default AU_HistoryDetail;

import React, { Component } from 'react'
import { Card, Button } from "react-bootstrap"
import { Slide } from 'react-awesome-reveal';
import Slider from "react-slick";
import posed from 'react-pose';
import Ripples from 'react-ripples'

import Boxs from './Box'
import Api from './Api'
import Aux from './Auxs'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SectionThree.css'
import classes from './SectionThree.module.css';

const Box = posed.div({
    pressable: true,
    init: { scale: 1 },
    press: { scale: 0.8 }
});
class SectionThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                { id: 1, name: 'title', content: 'Lorem Ipsum Lorem Ipsum?' },
                { id: 2, name: 'subtitle', content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry' },
                {
                    id: 3, name: 'slider',
                    articles: [
                        {
                            name: 'Slider1',
                            category: 'News',
                            date: 'XX/XX/XXXX',
                            title: 'What is Lorem Ipsum?',
                            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
                            assets: require('./assets/default-image.svg')
                        },
                        {
                            name: 'Slider2',
                            category: 'News',
                            date: 'XX/XX/XXXX',
                            title: 'What is Lorem Ipsum?',
                            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
                            assets: require('./assets/default-image.svg')
                        },
                        {
                            name: 'Slider3',
                            category: 'News',
                            date: 'XX/XX/XXXX',
                            title: 'What is Lorem Ipsum?',
                            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
                            assets: require('./assets/default-image.svg')
                        },
                        {
                            name: 'Slider4',
                            category: 'News',
                            date: 'XX/XX/XXXX',
                            title: 'What is Lorem Ipsum?',
                            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
                            assets: require('./assets/default-image.svg')
                        },
                        {
                            name: 'Slider5',
                            category: 'News',
                            date: 'XX/XX/XXXX',
                            title: 'What is Lorem Ipsum?',
                            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
                            assets: require('./assets/default-image.svg')
                        }
                    ]
                },
                {
                    id: 4, name: 'slider',
                    articles: [
                        {
                            name: 'Slider1',
                            category: 'Event',
                            date: 'XX/XX/XXXX',
                            title: 'What is Lorem Ipsum?',
                            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
                            assets: require('./assets/default-image.svg')
                        },
                        {
                            name: 'Slider2',
                            category: 'Event',
                            date: 'XX/XX/XXXX',
                            title: 'What is Lorem Ipsum?',
                            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
                            assets: require('./assets/default-image.svg')
                        },
                        {
                            name: 'Slider3',
                            category: 'Upcoming Event',
                            date: 'XX/XX/XXXX',
                            title: 'What is Lorem Ipsum?',
                            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
                            assets: require('./assets/default-image.svg')
                        },
                        {
                            name: 'Slider4',
                            category: 'Upcoming Event',
                            date: 'XX/XX/XXXX',
                            title: 'What is Lorem Ipsum?',
                            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
                            assets: require('./assets/default-image.svg')
                        },
                        {
                            name: 'Slider5',
                            category: 'Upcoming Event',
                            date: 'XX/XX/XXXX',
                            title: 'What is Lorem Ipsum?',
                            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
                            assets: require('./assets/default-image.svg')
                        }
                    ]
                }
            ],
            section: [
                {
                    id: 144, title: 'NEWS', Content: 'News Cooperation – The highlight in international relations of Phenikaa University', Date: '28/10/2020',
                    img: {
                        type: 'side',
                        src: require('./assets/section3.1.jpg')
                    }
                },
                {
                    id: 244, title: 'NEWS', Content: 'News Cooperation – The highlight in international relations of Phenikaa University', Date: '28/10/2020',
                    img: {
                        type: 'behind',
                        src: <img style={{ width: '100%' }} src={require('./assets/section3.1.jpg')} alt='2'></img>
                    }
                },
                {
                    id: 344, title: 'NEWS', Content: 'News Cooperation – The highlight in international relations of Phenikaa University', Detail: 'Phenikaa University signed the memorandum of understanding with Andrews University.', Date: '28/10/2021',
                    img: {
                        type: 'behind',
                        src: <img style={{ width: '100%' }} src={require('./assets/section3.1.jpg')} alt='3'></img>
                    }
                },
                {
                    id: 444, title: 'NEWS', Content: 'News Cooperation – The highlight in international relations of Phenikaa University', Date: '28/10/2022',
                    img: {
                        type: 'behind',
                        src: <img style={{ width: '100%' }} src={require('./assets/section3.1.jpg')} alt='4'></img>
                    }
                },
                {
                    id: 544, title: 'NEWS', Content: 'News Cooperation – The highlight in international relations of Phenikaa University', Date: '28/10/2023',
                    img: {
                        type: 'behind',
                        src: <img style={{ width: '100%' }} src={require('./assets/section3.1.jpg')} alt='5'></img>
                    }
                }
            ],
            sections: [
                {
                    id: 644, title: 'UPCOMING EVENT', Content: 'Education Cooperation – The highlight in international relations of Phenikaa University', Date: '28/10/2019',
                    img: {
                        type: 'side',
                        src: require('./assets/section3.2.jpg')
                    }
                },
                {
                    id: 744, title: 'UPCOMING EVENT', Content: 'Education Cooperation – The highlight in international relations of Phenikaa University', Date: '28/10/2020',
                    img: {
                        type: 'behind',
                        src: <img style={{ width: '100%' }} src={require('./assets/section3.1.jpg')} alt='2'></img>
                    }
                },
                {
                    id: 844, title: 'EVENT', Content: 'Education Cooperation – The highlight in international relations of Phenikaa University', Detail: 'Phenikaa University signed the memorandum of understanding with Andrews University.', Date: '28/10/2021',
                    img: {
                        type: 'behind',
                        src: <img style={{ width: '100%' }} src={require('./assets/section3.1.jpg')} alt='3'></img>
                    }
                },
                {
                    id: 944, title: 'EVENT', Content: 'Education Cooperation – The highlight in international relations of Phenikaa University', Detail: 'Phenikaa University signed the memorandum of understanding with Andrews University.', Date: '28/10/2022',
                    img: {
                        type: 'behind',
                        src: <img style={{ width: '100%' }} src={require('./assets/section3.1.jpg')} alt='4'></img>
                    }
                },
                {
                    id: 1044, title: 'EVENT', Content: 'Education Cooperation – The highlight in international relations of Phenikaa University', Detail: 'Phenikaa University signed the memorandum of understanding with Andrews University.', Date: '28/10/2023',
                    img: {
                        type: 'behind',
                        src: <img style={{ width: '100%' }} src={require('./assets/section3.1.jpg')} alt='5'></img>
                    }
                }
            ],
            contentSection: {
                widgets: []
            },
            readMore: "Read",
            isLoaded: false,
            isApiLoaded: false,
        }
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData.getSectionDetail()
                .then(result => {
                    const sections = result.data
                    const widget = []

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            name: item.name,
                            index: item.index,
                            internal: item.is_internal,
                            category: item.category,
                            // url: "/news/detail/",
                            url: item.url,
                            sections: item
                        })
                    })

                    sections.page = widget
                    this.setState({
                        contentSection: widget,
                        isLoaded: true
                    })
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        })
    }

    render() {
        const { contentSection, dummy } = this.state
        let titleContent1, subTitle1, contentBox1, titleContent2, subTitle2, contentBox2, slideTitle1, slideTitle2, backgroundImage, readMore = null
        let dummytitleContent1, dummycontentBox1, dummytitleContent2, dummySubTitle1, dummycontentBox2, dummybackgroundImage = null

        dummytitleContent1 = <h1 className={classes.sectionTitle}>{dummy[0].content}</h1>
        dummySubTitle1 = dummySubTitle1 = <h1 className={classes.sectionContentTitle}>{dummy[1].content}</h1>
        dummycontentBox1 = dummy[2].articles.map((s, i) => {
            let adjClass = s.content ? 'pb-0' : ''
            let isOnlyImage, isBoxImage = null
            isOnlyImage = <img className={classes.imageContentD} src={require('./assets/default-image.svg')} alt="" />
            isBoxImage = <img className={classes.imgOnBoxD} src={require('./assets/default-image.svg')} alt="" />

            let isImage =
                <div className="content-wrap" >
                    <Card className="card-template-front">
                        <Card.Body className={["card-body-d", adjClass].join(' ')}>
                            <Card.Title className='card-title-d'>{s.category}</Card.Title>
                            <div className="card-content">
                                <p>{s.title}</p>
                            </div>
                        </Card.Body>
                        <div className="card-footer card-footer-d">
                            <p className="left">Read more</p>
                            <p className="right">{s.Date}</p>
                        </div>
                    </Card>
                    <Card className="card-template-back">
                       
                        <div className={classes.contentImage}>
                            {isBoxImage}
                        </div>
                        <div className="card-footer-back">
                            <Box>
                                <Ripples>
                                    <Button href="#" variant="primary" size="md" className="btn-phenikaa">
                                        Read More
                                        </Button>
                                </Ripples>
                            </Box>
                        </div>
                    </Card>
                </div>
            return (
                <Aux key={s.id}>
                    {isImage}
                </Aux>
            );
        })

        let secTypeBackground = null
        let secTypeColor = null
        dummycontentBox2 = dummy[3].articles.map((s, i) => {
            if (s.category === 'Upcoming Event') {
                secTypeBackground = 'white'
                secTypeColor = 'black'
            } else {
                secTypeBackground = '#F16624'
                secTypeColor = 'white'
            }
            let isOnlyImage = <img className={classes.imageContentD} src={require('./assets/default-image.svg')} alt="" />
            let isBoxImage = <img className={classes.imgOnBoxD} src={require('./assets/default-image.svg')} alt="" />

            let isImage = <div className="content-wrap" >
                <Card style={{ backgroundColor: secTypeBackground }} className="card-template-front card-body-d">
                    <Card.Body className='p-0'>
                        <Card.Title className='card-title-d' style={{ color: secTypeColor }}>{s.category}</Card.Title>
                        <div className="card-content">
                            <p style={{ color: secTypeColor }}>{s.title}</p>
                        </div>
                    </Card.Body>
                    <div className="card-footer p-0">
                        <p style={{ color: secTypeColor }} className="left">Read more</p>
                        <p style={{ color: secTypeColor }} className="right">{s.date}</p>
                    </div>
                </Card>
                <Card className="card-template-back">
                <div className={classes.coverBlue}></div>
                    <div className={classes.contentImage}>
                        {isBoxImage}
                    </div>
                    <div className="card-footer-back">
                        <Box>
                            <Ripples>
                                <Button href="#" variant="primary" size="md" className="btn-phenikaa">
                                    Read More
                                        </Button>
                            </Ripples>
                        </Box>
                    </div>
                </Card>
            </div>
            // 1st Content not using Read more
            // <div className="content-wrap" >
            //     <Card style={{ backgroundColor: secTypeBackground }} className="card-template-front-side card-body-d">
            //         <Card.Body className='p-0'>
            //             <Card.Title className='card-title-d' style={{ color: secTypeColor }}>{s.category}</Card.Title>
            //             <div className="card-content">
            //                 <p style={{ color: secTypeColor }}>{s.title}</p>
            //             </div>
            //         </Card.Body>
            //         <div className="card-footer p-0">
            //             <p style={{ color: secTypeColor }} className="left">Read more</p>
            //             <p style={{ color: secTypeColor }} className="right">{s.date}</p>
            //         </div>
            //     </Card>
            //     <Card className="card-template-side">
            //         {isOnlyImage}
            //     </Card>
            // </div>

            return (
                <Aux key={s.id}>
                    {isImage}
                </Aux>
            );
        })


        dummybackgroundImage = {
            width: '100vw',
            height: 'auto',
            backgroundColor: 'rgb(0,0,0, .5)'
        };

        const settings = {
            className: "center",
            centerMode: false,
            infinite: false,
            centerPadding: "",
            variableWidth: true,
            slidesToShow: 1,
            swipe: true
        };

        if (this.state.isLoaded) {
            let count = 0
            contentSection.forEach((item) => {
                count += 1

                if (count === 1 && item.index === 1 && item.category === "Text") {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            readMore = s.content;
                        } else {
                            readMore = "Read More";
                        }
                    })
                } else if (count === 1 && item.index === 1 && item.category !== "Text") {
                    readMore = "Read More";
                }


                if (count === 2 && item.index === 2 && item.category === "Text") {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            titleContent1 = <h1 className={classes.sectionTitle}>{s.content}</h1>
                        } else {
                            titleContent1 = dummytitleContent1
                        }
                    })
                } else if (count === 2 && item.index === 2 && item.category !== "Text") {
                    titleContent1 = dummytitleContent1
                }

                if (count === 3 && item.index === 3 && item.category === "Text") {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            subTitle1 = <h3 className={classes.sectionContentTitle}>{s.content}</h3>
                        } else {
                            subTitle1 = <></>
                        }
                    })
                }

                if (count === 4 && item.index === 4 && item.category === "Article") {
                    contentBox1 = item.internal ? <></> : <Boxs id={item.id} readMore={readMore} url={item.url ? item.url + '/' : '/news-events/news-detail/'} />
                } else if (count === 4 && item.index === 4 && item.category !== "Article") {
                    contentBox1 = dummycontentBox1
                }

                if (count === 5 && item.index === 5 && item.category === "Text") {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            titleContent2 = <h3 className={classes.sectionTitle}>{s.content}</h3>
                        } else {
                            titleContent2 = dummytitleContent2
                        }
                    })
                } else if (count === 5 && item.index === 5 && item.category !== "Text") {
                    titleContent2 = dummytitleContent2
                }


                if (count === 6 && item.index === 6 && item.category === "Text") {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            subTitle2 = <h3 className={classes.sectionContentTitle}>{s.content}</h3>
                        } else {
                            subTitle2 = <></>
                        }
                    })
                }

                if (count === 7 && item.index === 7 && item.category === "Article") {
                    contentBox2 = item.internal ? <></> : <Boxs id={item.id} readMore={readMore} url={item.url ? item.url + '/' : '/news-events/news-detail/'} />
                } else if (count === 7 && item.index === 7 && item.category !== "Article") {
                    contentBox2 = dummycontentBox2
                }

                if (count === 8 && item.index === 8 && item.category === "Media") {
                    item.sections.widget_contents.forEach((s) => {
                        let url = s.file ? s.file : require('./assets/default-image.svg')
                        backgroundImage = {
                            width: '100vw',
                            height: 'auto',
                            backgroundImage: `url(${url})`,
                            backgroundSize: 'cover'
                        };
                    })
                } else if (count === 8 && item.index === 8 && item.category !== "Media") {
                    backgroundImage = dummybackgroundImage
                }



            })
            slideTitle1 =
                <Slide triggerOnce direction="top">
                    {titleContent1}
                    {subTitle1}
                </Slide>
            slideTitle2 =
                <Slide triggerOnce direction="top">
                    {titleContent2}
                    {subTitle2}
                </Slide>
        } else if (this.state.isApiLoaded) {
            titleContent1 = titleContent2 = dummytitleContent1
            subTitle1 = subTitle2 = dummySubTitle1
            contentBox1 =
                <Slide triggerOnce direction="top">
                    <Slider {...settings}>
                        {dummycontentBox1}
                    </Slider>
                </Slide>
            contentBox2 =
                <Slide triggerOnce direction="top">
                    <Slider {...settings}>
                        {dummycontentBox2}
                    </Slider>
                </Slide>
            backgroundImage = dummybackgroundImage
            slideTitle1 = slideTitle2 =
                <Slide triggerOnce direction="top">
                    {titleContent1}
                    {subTitle1}
                </Slide>
        }


        return (
            <div className='section-content'>
                <div style={backgroundImage}>
                    <div className="section-blur">
                        <div className={classes.contentWrapping}>
                            <div className={["container", classes.paddingBoxTitle, classes.customContainer].join(' ')}>
                                {slideTitle1}
                            </div>
                            <div className={classes.secTempl}>
                                <div>
                                    {contentBox1}
                                </div>
                            </div>
                            <div className={["container", classes.paddingBoxTitle, classes.mt5rem, classes.customContainer].join(' ')}>
                                {slideTitle2}
                            </div>
                            <div className={[classes.secTempl, classes.paddingBox].join(' ')}>
                                <div>
                                    {contentBox2}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SectionThree
import React, { Component } from "react";

/*import HouseofPhenikaa from "../Diagram/HouseofPhenikaa";*/
import Circuit from "../Circuit";

import classes from "./Mobile.module.css";

class Ecosystem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
    this.toggleHandlerEnter = this.toggleHandlerEnter.bind(this);
    // this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this);
  }

  toggleHandlerEnter = () => {
    this.setState((prevState) => {
      return {
        isOpen: !prevState.isOpen,
      };
    });
  };

  render() {
    // let contentBack = this.state.isOpen ? [classes.scaleIncenter, classes.absolute].join(' ') : classes.absolute
    let contentFront = this.state.isOpen ? classes.scaleOutCenter : "";

    let contentCore = this.state.isOpen ? (
      <Circuit newStyle={classes.isAbsolute} />
    ) : (
      <Circuit newStyle={classes.transf} />
    );
    let contentMiddle = this.state.isOpen ? (
      <>
        <div
          className={[classes.contentLogo, contentFront].join(" ")}
          onClick={() => this.toggleHandlerEnter()}
        >
          <img
            className={classes.imgLogoSmall}
            src={require("../assets/icon.svg")}
            alt="logo"
          ></img>
        </div>
        <div id="house">
                {/*<HouseofPhenikaa />*/}
        </div>
      </>
    ) : (
      <div
        className={[classes.contentLogo, contentFront].join(" ")}
        onClick={() => this.toggleHandlerEnter()}
      >
        <img
          className={classes.imgLogoSmall}
          src={require("../assets/icon.svg")}
          alt="logo"
        ></img>
      </div>
    );

    return (
      <div
        id="sectionEcosystem"
        className={["section-ecosystem", classes.sectionEcosystem].join(" ")}
      >
        <div className={classes.topLeftImage}>
          <img
            src={require("../assets/circuit.svg")}
            className={classes.iconTrans}
            alt="logo"
          ></img>
        </div>
        <div className={[classes.contentCenter].join(" ")}>
          {contentMiddle}
          {contentCore}
        </div>
      </div>
    );
  }
}

export default Ecosystem;

import axios from "axios"


export default class Api {

    base_api = "http://54.255.70.35/"
    token = '60edaccc8f547344c09fe84bea12029a7aab6b36'

    async getSectionDetail() {
        // let params = 267
        let params = 378
        let url = null
        let lang = localStorage.getItem('language')
        if (lang === 'English') {
            url = this.base_api + `section_customer/${params}/?language=en`
        } else {
            url = this.base_api + `section_customer/${params}`
        }
        return await axios.get(url, {
            params: {
            },
            headers: {
                "Authorization": `Token ${this.token}`,
                "Content-Type": "application/json"
            }
        })
    }

    async getAllArticleDetails(filter, size, search) {
        let setfilter = `&category=${filter ? filter : ''}`
        let setSize = `?page_size=${size}`
        let setSearch = `&search=${search}`
        let url = null
        let lang = localStorage.getItem('language')
        if (lang === 'English') {
            url = this.base_api + `articles/${size ? setSize : `?page_size=50`}&details=true&language=en${filter ? setfilter : ''}${search ? setSearch : ''}`
        } else {
            url = this.base_api + `articles/${size ? setSize : `?page_size=50`}&details=true&language=vi${filter ? setfilter : ''}${search ? setSearch : ''}`
        }
        console.log(url)
        // 'http://54.255.70.35/articles/?details=true&search=vicostone&exclude_category=9'
        return await axios.get(url, {

            params: {
            },
            headers: {
                "Authorization": `Token ${this.token}`,
                "Content-Type": "application/json"
            },
        })
    }

    async getAllCategories() {
        let lang = localStorage.getItem('language')
        let url
        if (lang === 'English') {
            url = this.base_api + 'article_categories/?language=en'
        } else {
            url = this.base_api + 'article_categories/?language=vi'
        }
        console.log(url)
        return await axios.get(url, {

            params: {
            },
            headers: {
                "Authorization": `Token ${this.token}`,
                "Content-Type": "application/json"
            },
        })
    }

}

import React, { Component } from "react";
import { Link } from "react-router-dom";

import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactHtmlParser from "react-html-parser";

import Api from "./Api";
import classes from "./Career_Overview.module.css";

export default class Career_Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        { id: 1, name: "title", content: "CAREER AT PHENIKAA GROUP" },
        { id: 2, name: "subtitle", content: "Lorem ipsum lorem ipsum lorem" },
        {
          id: 3,
          name: "desc",
          content:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
          id: 4,
          name: "brands",
          articles: [
            {
              name: "brand1",
              title: "VICOSTONE",
              assets: require("./assets/Vicostone.jpg"),
            },
            {
              name: "brand2",
              title: "PHENIKAA UNIVERSITY",
              assets: require("./assets/Phenikaa_University.jpg"),
            },
            {
              name: "brand3",
              title: "PRATI",
              assets: require("./assets/Phenikaa_Prati.jpg"),
            },
            {
              name: "brand4",
              title: "SCHOOL",
              assets: require("./assets/Phenikaa_School.jpg"),
            },
            {
              name: "brand5",
              title: "PHENIKAA CHEMICAL",
              assets: require("./assets/Phenikaa_Chermical.jpg"),
            },
            {
              name: "brand6",
              title: "PHENIKAA MANUFACTURING",
              assets: require("./assets/Phenikaa_Manufacturing.jpg"),
            },
          ],
        },
        {
          id: 4,
          name: "culture",
          articles: [
            {
              name: "culture1",
              title: "Lorem Ipsum",
              assets: require("./assets/Vicostone.jpg"),
            },
            {
              name: "culture2",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_University.jpg"),
            },
            {
              name: "culture3",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_Prati.jpg"),
            },
            {
              name: "culture4",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_School.jpg"),
            },
            {
              name: "culture5",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_Chermical.jpg"),
            },
            {
              name: "culture6",
              title: "Lorem Ipsum?",
              assets: require("./assets/Phenikaa_Manufacturing.jpg"),
            },
          ],
        },
      ],

      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.getHtmlParagraph = this.getHtmlParagraph.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      var result = str
        .replace(/<\/?p>/gi, " ")
        .replace(/<p>/gi, " ")
        .split("  ");
      return result;
    } else {
      return [];
    }
  }

  render() {
    let content,
      titleBig = null;

    if (this.state.isLoaded) {
      this.state.contentSection.forEach((item, index) => {
        switch (index) {
          case 0:
            let textLeft = true;
            let letterLimit = 1000;
            let noneBox = null;
            console.log(item);

            content = item.sections.widget_contents.map((article) => {
              let imageIndex = 0;
              const imageArray = [];
              article.assets.forEach((image) => {
                imageArray.push(image.file);
              });
              let paragraphArray = this.getHtmlParagraph(article.content);
              let totalParagraphs = "";
              let letters = 0;
              let arrayToMapAndReturn = [];
              paragraphArray.forEach((paragraph, index) => {
                console.log(index);
                if (index !== 0) {
                  totalParagraphs = "";
                }

                if (paragraphArray[0] === null) {
                  noneBox = classes.noneBox;
                }

                letters += paragraph.length;
                if (imageArray[imageIndex]) {
                  if (letters > letterLimit) {
                    if (textLeft) {
                      textLeft = !textLeft;
                      let leftside = (
                        <div className={classes.allContent}>
                          <div className={classes.boxCT}>
                            <div className={classes.blabla}>
                              <p
                                className={[classes.titleCont, noneBox].join(
                                  " "
                                )}
                              >
                                {ReactHtmlParser(paragraphArray[0])}
                              </p>
                              <p className={classes.textPar}>
                                {ReactHtmlParser(totalParagraphs)}
                              </p>
                            </div>
                          </div>
                          <div className={classes.boxImgL}>
                            <img
                              className={classes.boxImg}
                              src={imageArray[imageIndex]}
                              alt=" "
                            />
                          </div>
                        </div>
                      );
                      arrayToMapAndReturn.push(leftside);
                    } else {
                      textLeft = !textLeft;
                      let rightside = (
                        <div className={classes.allContent2}>
                          <div className={classes.boxImgR}>
                            <img
                              className={classes.boxImg}
                              src={imageArray[imageIndex]}
                              alt=" "
                            />
                          </div>
                          <div className={classes.boxCT2}>
                            <div className={classes.blabla}>
                              <p
                                className={[classes.titleCont, noneBox].join(
                                  " "
                                )}
                              >
                                {ReactHtmlParser(paragraphArray[0])}
                              </p>
                              <p className={classes.textPar2}>
                                {ReactHtmlParser(totalParagraphs)}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                      arrayToMapAndReturn.push(rightside);
                    }
                    imageIndex += 1;
                    letters = 0;
                    paragraph = "";
                  } else {
                    totalParagraphs = totalParagraphs + paragraph;
                  }
                } else {
                  totalParagraphs = totalParagraphs + paragraph;
                }
              });
              if (imageArray[imageIndex]) {
                if (textLeft) {
                  textLeft = !textLeft;
                  let leftside = (
                    <>
                      <hr className={classes.hrLeft} />
                      <div className={classes.allContentB}>
                        <div className={classes.txtParB}>
                          <div className={classes.blabla}>
                            <p className={classes.pArt}>{article.title}</p>
                            <p className={classes.titleCont}>
                              {ReactHtmlParser(paragraphArray[0])}
                            </p>
                            <p className={classes.txtReal}>
                              {ReactHtmlParser(totalParagraphs)}
                            </p>
                          </div>
                        </div>
                        {/* <div className={classes.txtParB}>
                          <p className={classes.pArt}>{article.title}</p>
                          <p className={[classes.titleCont, noneBox].join(" ")}>
                            {ReactHtmlParser(paragraphArray[0])}
                          </p>
                          <p className={classes.txtReal}>
                            {ReactHtmlParser(totalParagraphs)}
                          </p>
                        </div> */}
                        <div className={classes.txtParB2}>
                          <img
                            className={classes.imgLogo}
                            src={imageArray[imageIndex]}
                            alt=" "
                          />
                        </div>
                      </div>
                    </>
                  );
                  arrayToMapAndReturn.push(leftside);
                } else {
                  textLeft = !textLeft;
                  let rightside = (
                    <>
                      <hr />
                      <div className={classes.allContentC}>
                        <div className={classes.absImg}>
                          <img
                            className={classes.imgLogo2}
                            src={imageArray[imageIndex]}
                            alt=" "
                          />
                        </div>
                        <div className={classes.boxPar}>
                          <div className={classes.blabla}>
                            <p className={classes.pArt}>{article.title}</p>
                            <p className={[classes.boxTxt2, noneBox].join(" ")}>
                              {ReactHtmlParser(paragraphArray[0])}
                            </p>
                            <p className={classes.txtParC}>
                              {ReactHtmlParser(totalParagraphs)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                  arrayToMapAndReturn.push(rightside);
                }
              } else {
                arrayToMapAndReturn.push(
                  <div className={classes.boxAll}>
                    <p className={classes.totalPar}>
                      {ReactHtmlParser(totalParagraphs)}
                    </p>
                  </div>
                );
              }

              return <>{arrayToMapAndReturn}</>;
            });

            break;
          case 1:
            titleBig = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Lorem Ipsum";

            break;
          default:
            break;
        }
      });
    }

    return (
      <div className={classes.section}>
        <div className={["container", classes.customContainer].join(" ")}>
          <p className={classes.title}>{titleBig}</p>
          {content}
        </div>
      </div>
    );
  }
}

import React, { Component, Fragment } from "react";
import Ripples from "react-ripples";
import ReactHtmlParser from "react-html-parser";
import Api from "./Api";
import { Link } from "react-router-dom";
import classes from "./sec5.module.css";
import { Slide } from "react-awesome-reveal";
import { Container, CardGroup, Card } from "react-bootstrap";
import Slider from "react-slick";
import "./sec5.css";

class sec5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      boxCount: 0,
      dummy: [
        { id: 1, name: "Title", content: "Lorem Ipsum Lorem Ipsum?" },
        {
          id: 2,
          name: "Content",
          content:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry standard dummy text ever since the 1500s",
        },
        {
          id: 3,
          name: "Slider",
          articles: [
            {
              name: "Slider1",
              title: "What is Lorem Ipsum?",
              content: "Lorem Ipsum is simply dummy text",
              assets: require("./assets/default-image.svg"),
            },
            {
              name: "Slider2",
              title: "What is Lorem Ipsum?",
              content: "Lorem Ipsum is simply lorem ipsum",
              assets: require("./assets/default-image.svg"),
            },
          ],
        },
        {
          id: 4,
          name: "Background Image",
          assets: require("./assets/default-image.svg"),
        },
      ],
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          this.setState({
            isApiLoaded: true,
          });
          const contentSection = result.data;
          const widget = [];
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              url: item.url,
              contentSection: item,
            });
          });
          contentSection.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/&nbsp;/gi, " ");
    }
  }

  render() {
    const settings = {
      className: "",
      // dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const { contentSection, dummy } = this.state;
    let title,
      content,
      buttonText,
      imageSlider,
      imageBackground,
      dummyTitle,
      dummyBackground,
      dummyContent,
      dummySlider = null;
    let buttonURL = "/";

    dummyTitle = <span className={classes.text_title}>{dummy[0].content}</span>;
    dummyContent = (
      <span className={classes.text_content}>{dummy[1].content}</span>
    );

    dummySlider = dummy[2].articles.map((s) => {
      let isImage = s.assets ? (
        <Card.Img className="imgD" src={s.assets} alt="" />
      ) : (
        <Card.Img
          className="imgD"
          src={require("./assets/default-image.svg")}
          alt=""
        />
      );

      return (
        <Card key={s.content}>
          {isImage}
          <Card.ImgOverlay className={classes.imgOverlay}></Card.ImgOverlay>
          <div className={classes.contentAbsolute}>
            <div className={classes.contentInBox}>
              <span className={classes.textInsideOverlay}>
                <p>{s.content}</p>
              </span>
            </div>
          </div>
        </Card>
      );
    });

    dummyBackground = (
      <Card.Img className="imgD" src={dummy[3].assets} alt="" />
    );

    if (this.state.isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.contentSection.widget_contents.forEach((s) => {
            if (s.content !== "") {
              title = <p className={classes.text_title}>{s.content}</p>;
            } else {
              title = dummyTitle;
            }
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Text"
        ) {
          title = dummyTitle;
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          item.contentSection.widget_contents.forEach((s) => {
            if (s.content !== "") {
              content = <span>{s.content}</span>;
            } else {
              content = dummyContent;
            }
          });
        } else if (
          count === 2 &&
          item.index === 2 &&
          item.category !== "Text"
        ) {
          content = dummyContent;
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          imageSlider = item.contentSection.widget_contents.map((s) => {
            let path = s.url;
            let isImage = s.assets[0] ? (
              <Card.Img
                className={["img", classes.imgSlider].join(" ")}
                src={s.assets[0].file}
                alt=""
              />
            ) : (
              <Card.Img
                className={["img", classes.imgSlider].join(" ")}
                src={require("./assets/default-image.svg")}
                alt=""
              />
            );

            return (
              <Card key={s.content} className={classes.contentCard}>
                {/* <div className={classes.boxTop}></div> */}
                <div className={classes.gradientOverlay}></div>

                {isImage}
                {/* <Card.ImgOverlay className={classes.imgOverlay}></Card.ImgOverlay> */}
                <Link to={path} className={classes.link}>
                  <div className={classes.contentAbsolute}>
                    <div className={classes.contentInBox}>
                      <span className={classes.textInsideOverlay}>
                        {ReactHtmlParser(this.getHtmlParagraph(s.content))}
                      </span>
                    </div>
                  </div>
                </Link>
              </Card>
            );
          });
        } else if (
          count === 3 &&
          item.index === 3 &&
          item.category !== "Article"
        ) {
          imageSlider = dummySlider;
        }

        if (count === 4 && item.index === 4 && item.category === "Media") {
          item.contentSection.widget_contents.forEach((s) => {
            if (s.file !== "") {
              imageBackground = (
                <img
                  className={["img", classes.imgContent].join(" ")}
                  src={s.file}
                  alt=""
                />
              );
            } else {
              imageBackground = dummyBackground;
            }
          });
        } else if (
          count === 4 &&
          item.index === 4 &&
          item.category !== "Media"
        ) {
          imageBackground = dummyBackground;
        }

        if (count === 5 && item.index === 5 && item.category === "Text") {
          buttonURL = item.contentSection.url;
          buttonText = <p>{item.contentSection.widget_contents[0].content}</p>;
        }
      });
    } else if (this.state.isApiLoaded) {
      title = dummyTitle;
      content = dummyContent;
      imageSlider = dummySlider;
      imageBackground = dummyBackground;
    }
    return (
      <Fragment>
        <div fluid className={classes.contSec5}>
          <Slide triggerOnce={true} direction={"left"}>
            <div className={classes.cardGroupSec5}>
              <div className={classes.cardSec51}>
                {imageBackground}

                <div className={classes.overlayInfo}></div>

                <div className={classes.infoCareer}>
                  <div className={classes.infoCarReal}>
                    <div className={classes.subCarReal}>{title}</div>
                    <div className={classes.contentCarReal}>{content}</div>
                    <div className={classes.btnCarReal}>
                      <Link to={buttonURL}>
                        <button className={classes.btn_card}>
                          {buttonText}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className={classes.overlaySec5}></div> */}
                {/* <div className={classes.textMidle}>
                  <div className={[classes.text_title, classes.tl].join(" ")}>
                    {title}
                  </div>
                  <div className={classes.text_content}>{content}</div>
                  <div className={classes.footerSec4}>
                    <span className={classes.contentSpan}>
                    </span>
                    <Link to={buttonURL}>
                      <div className={classes.btn_card_ripple}>
                        <button className={classes.btn_card}>
                          {buttonText}
                        </button>
                      </div>
                    </Link>
                  </div>
                </div> */}
              </div>
              {/* <Card className={classes.cardSec5}>
                {imageBackground}
                <Card.ImgOverlay className={classes.overlaySec5}></Card.ImgOverlay>
                <Card className={classes.textMidle}>
                  <Card.Title className={[classes.text_title, classes.tl].join(" ")}>{title}</Card.Title>
                  <Card.Text className={classes.text_content}>{content}</Card.Text>
                  <Card.Footer className={classes.footerSec4}>
                    <span className={classes.contentSpan}>you need sign up to apply</span>
                    <Link to={buttonURL}>
                      <Ripples className={classes.btn_card_ripple}>
                        <button className={classes.btn_card}>{buttonText}</button>
                      </Ripples>
                    </Link>
                  </Card.Footer>
                </Card>
              </Card> */}
              <div className={classes.cardSec5}>
                <Slider {...settings}>{imageSlider}</Slider>
              </div>
            </div>
          </Slide>
        </div>
      </Fragment>
    );
  }
}

export default sec5;

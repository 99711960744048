import React from "react"

import { isMobile } from "react-device-detect"

import Desktop from "./Desktop/Desktop"
import Mobile from "./Mobile/Mobile"

export default function H_Section1(props) {
  let content = !isMobile ? <Desktop updateLogo={props.updateLogo} override={props.override} /> : <Mobile />
  return <>{content}</>
}

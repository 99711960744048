import React, {Component} from "react"

import { Navbar } from 'react-bootstrap'
import Styled from 'styled-components'

import Api from './Api'

import classes from "./Logo.module.css"

const CustomDiv = Styled.div`
position: fixed;

//long icon
// top: 0.5rem;
// z-index: 99;
// width: 9%;

//box icon
top: 0rem;
z-index: 99;
width: 7%;
`
export default class Logo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                {
                    assets: require('./assets/logo-en.png')
                }
            ],

            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }
    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        });
    }
    // let imgSource = props.onPage ? require('./assets/iconW.svg') : require('./assets/icon.svg')

    render(){
        const { dummy, contentSection } = this.state
        let logo, imgSource, whiteIcon = null
        if (this.state.isLoaded){
            let count = 0
            let lang = localStorage.getItem('language')
            let activeLang = lang==='English' ? 0 : 1
            contentSection.forEach((item) => {
                count = count + 1
                if (count === 1 && item.index === 1 && item.category === "Media") {
                    item.sections.widget_contents.forEach((s, index) => {
                        if (activeLang === index){
                            imgSource = s.file
                        }

                        if (index === 2){
                            whiteIcon = s.file;
                        }
                        // imgSource = (s.file ? (s.file[activeLang].file ? s.file[activeLang].file : s.file[0].file) : require('./assets/logo-vn.png')) 
                    });
                } else if (count === 1 && item.index === 1 && item.category !== "Media") {
                    imgSource = dummy[0].assets
                }
            })
            logo = <Navbar.Brand href="/" className={classes.navLogo}><img className={classes.imgLogo} src={this.props.logoColor ? whiteIcon : imgSource} alt="logo" /></Navbar.Brand>
                
        } else if (this.state.isApiLoaded) {
            imgSource = dummy[0].assets
            logo = <Navbar.Brand href="/" className={classes.navLogo}><img className={classes.imgLogo} src={this.props.logoColor ? whiteIcon : imgSource} alt="logo" /></Navbar.Brand>
        }

        return (
            <CustomDiv>
                {logo}
            </CustomDiv>
        );
    }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";

import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Language from './Language'

import Api from "./Api";

import classes from "./Footer.module.css";
// import "./Footer.css"

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    id: 1,
                    title: "Vietnamese",
                    type: "inactive",
                    img: require("./assets/iconVn.svg"),
                },
                {
                    id: 2,
                    title: "English",
                    type: "inactive",
                    img: require("./assets/iconUk.svg"),
                },
            ],
            menus: {
                items: [],
            },
            isLoaded: false,
            activeLang: 0,
        };
        this.getMenu = this.getMenu.bind(this);
        this.checkUrlTarget = this.checkUrlTarget.bind(this);
        //this.renderHeaderItem = this.renderHeaderItem.bind(this);
        //this.renderRootItem = this.renderRootItem.bind(this);
    }

    componentDidMount() {
        this.getMenu();

        let defaultLang = localStorage.getItem("language");
        let active = 0;
        if (defaultLang && defaultLang.toLowerCase() === "vietnamese") {
            active = 0;
        } else if (defaultLang && defaultLang.toLowerCase() === "english") {
            active = 1;
        } else {
            active = 0;
            localStorage.setItem("language", "Vietnamese");
        }

        this.setState({
            activeLang: active,
        });
    }

    getMenu() {
        const apiData = new Api();
        this.setState({ menus: [] }, () => {
            apiData
                .getMenuDetail()
                .then((result) => {
                    const menu = result.data;
                    const item = [];
                    menu.items.forEach((i) => {
                        item.push({
                            index: i.index,
                            id: i.id,
                            name: i.title,
                            url: i.url,
                            children: i.children,
                        });
                    });
                    menu.items = item;
                    this.setState({
                        menus: menu,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    }

    checkUrlTarget(url) {
        console.log(url);
        var substring = url.substr(0, 4);
        if (substring === "http") {
            return "external";
        } else {
            return "internal";
        }
    }

    toggleLanguage = (lang) => {
        let active = null;
        if (lang) {
            if (lang.toLowerCase() === "vietnamese") {
                active = 0;
            } else {
                active = 1;
            }
            this.setState({
                language: lang,
                activeLang: active,
            });
            localStorage.setItem("language", lang);
            window.location.reload(true);
        }
    };

    render() {
        var renderHeaderItemVar = function renderHeaderItem(headerItem) {
            return headerItem.children.map(function (bodyElement) {
                if (bodyElement.url) {
                    if (bodyElement.url.includes("http")) {
                        return (
                            <a
                                key={bodyElement.title}
                                className={classes.customLink}
                                target="_blank"
                                href={bodyElement.url}
                                rel="noopener noreferrer"
                            >
                                <p className={classes.contentFooter}>{bodyElement.title}</p>
                            </a>
                        );
                    } else {
                        return (
                            <Link
                                key={bodyElement.title}
                                className={classes.customLink}
                                target="_blank"
                                to={bodyElement.url ? bodyElement.url : ""}
                            >
                                <p className={classes.contentFooter}>{bodyElement.title}</p>
                            </Link>
                        );
                    }
                    // switch (this.checkUrlTarget(bodyElement.url)) {
                    //     case "external":
                    //         return (<a target="_blank" href={bodyElement.url} rel="noopener noreferrer"><p className={classes.contentFooter}>{bodyElement.title}</p></a>);

                    //     default:
                    //         return (<Link target="_blank" href={bodyElement.url}><p className={classes.contentFooter}>{bodyElement.title}</p></Link>);
                    // }
                } else {
                    return (
                        <p key={bodyElement.title} className={classes.contentIconLeft}>
                            {bodyElement.title}
                        </p>
                    );
                }
            });
        };

        let count = 0
        let lang
        var renderRootItemVar = function renderRootItem(rootItem, index) {
            count += 1
            if (count === (index - 1)) {
                lang = <Language />
            }
            if (rootItem.children) {
                return (
                    <>
                        {rootItem.children.map(function (headerItem, i) {
                            var headerItemContent = "";
                            if (headerItem.children) {
                                headerItemContent = renderHeaderItemVar(headerItem);
                            }
                            if (headerItem.url) {
                                if (headerItem.url.includes("http")) {
                                    return (
                                        <></>
                                        // <div className="">
                                        //   <a
                                        //     className={classes.customLinkHeader}
                                        //     target="_blank"
                                        //     href={headerItem.url}
                                        //     rel="noopener noreferrer"
                                        //   >
                                        //     <p className={classes.titleFooter}>
                                        //       {headerItem.title}
                                        //     </p>
                                        //   </a>
                                        //   {headerItemContent}
                                        // </div>
                                    );
                                } else {
                                    return (
                                        <></>
                                        // <div className="">
                                        //   <Link
                                        //     className={classes.customLinkHeader}
                                        //     target="_blank"
                                        //     to={headerItem.url}
                                        //   >
                                        //     <p className={classes.titleFooter}>
                                        //       {headerItem.title}
                                        //     </p>
                                        //   </Link>
                                        //   {headerItemContent}
                                        // </div>
                                    );
                                }
                                // switch (this.checkUrlTarget(headerItem.url)) {
                                //     case "external":
                                //         return (
                                //             <div className="">
                                //                 <a target="_blank" href={headerItem.url} rel="noopener noreferrer"><p className={classes.titleFooter}>{headerItem.title}</p></a>
                                //                 {headerItemContent}
                                //             </div>
                                //         );
                                //     default:
                                //         return (
                                //             <div className="">
                                //                 <Link target="_blank" href={headerItem.url}><p className={classes.titleFooter}>{headerItem.title}</p></Link>
                                //                 {headerItemContent}
                                //             </div>
                                //         );
                                // }
                            } else {
                                return (
                                    <div key={headerItem.title} className={classes.boxFooter}>
                                        <p className={classes.titleFooter}>{headerItem.title}</p>
                                        {headerItemContent}
                                        {lang}
                                    </div>
                                );
                            }
                        })}
                    </>
                );
            }
        };

        const { menus } = this.state;
        var result = null;

        if (this.state.isLoaded) {
            result = menus.items.map(function (rootItem) {
                return renderRootItemVar(rootItem, menus.items.length);
            });
        } else if (this.state.isApiLoaded) {
            result = (
                <>
                    <div className="">
                        <p className="title-footer">lorem ipsum seo</p>
                        <p className="content-footer">seo keywords</p>
                        <p className="content-footer">human development</p>
                        <p className="content-footer">enviromental development</p>
                        <p className="content-footer">community & society</p>
                    </div>
                    <div>
                        <p className="title-footer">lorem ipsum seo</p>
                        <p className="content-footer">seo keywords</p>
                        <p className="content-footer">human development</p>
                        <p className="content-footer">enviromental development</p>
                        <p className="content-footer">community & society</p>
                    </div>
                    <div>
                        <p className="title-footer">lorem ipsum seo</p>
                        <p className="content-footer">seo keywords</p>
                        <p className="content-footer">human development</p>
                        <p className="content-footer">enviromental development</p>
                        <p className="content-footer">community & society</p>
                    </div>
                    <div>
                        <p className="title-footer">factory</p>
                        <p className="content-icon-left">
                            Hoa Lac Hi-Tech, Thach hoa, Thach That, ha noi, vietnam
            </p>
                        <p className="content-icon-left">+842 433 685 980</p>
                        <p className="content-icon-left">+842 433 687 095</p>
                        <p className="content-icon-left">+Support@phenikaa.com</p>
                    </div>
                    <div>
                        <p className="title-footer">office</p>
                        <p className="content-icon-left">
                            167 hoang ngan, trung hoa, cau giay, ha noi, vietnam
            </p>
                        <p className="title-footer">language</p>
                        <p className="content-icon-left">english - usa</p>
                        <p className="title-footer">social media</p>
                        <div className="row p-0 m-0">
                            <img
                                style={{ width: "40px", height: "40px" }}
                                src={require("./assets/fb.png")}
                                alt=""
                            ></img>
                            <img
                                style={{ width: "40px", height: "40px" }}
                                src={require("./assets/twitter.png")}
                                alt=""
                            ></img>
                            <img
                                style={{ width: "40px", height: "40px" }}
                                src={require("./assets/instagram.png")}
                                alt=""
                            ></img>
                            <img
                                style={{ width: "40px", height: "40px" }}
                                src={require("./assets/pinterest.png")}
                                alt=""
                            ></img>
                            <img
                                style={{ width: "40px", height: "40px" }}
                                src={require("./assets/dropbox.png")}
                                alt=""
                            ></img>
                        </div>
                    </div>
                </>
            );
        }

        return (
            <div className={classes.contentFooter}>
                <div className={["container", classes.cusT].join(" ")}>
                    <div className={classes.sectionFooter}>
                        <div className={classes.flexContainerFooter}>
                            {result}
                            {/* {lang} */}
                            {/* <div className={classes.slNav}>
                                Language:
                                    <ul>
                                    <li>
                                        <b className="ml-2">{langActive}</b>{" "}
                                        <FontAwesomeIcon
                                            color="#d4d4d4"
                                            className="ml-auto"
                                            icon={faCaretDown}
                                            size="lg"
                                        />
                                        <div className={classes.triangle}></div>
                                        <ul>
                                            {langItem}
                                        </ul>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;

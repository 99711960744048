import React, { Component, Fragment } from "react";
import Card from "react-bootstrap/Card";
import classes from "./C_Section1.module.css";
import "./C_Section1.css";
import Api from "./../api";
import BoxCareer from "./BoxCareer";
import Navbar from "../CareerHeader/CareerHeader";
import BoxUp from "./BoxUp";

class sec1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: [],
      articles: [],
      app: null,
      text: "",
      dummy: [
        {
          id: 2,
          name: "boxPositionAv",
          boxPositionAv: [
            {
              title: "Engineering Team Lead - Data Engineering",
              company: "VICOSTONE",
              city: "Ha Noi",
              country: "Vietnam",
              expiredDate: "XX/XX/XXXX",
              category: "Available Position",
            },
            {
              title: "Engineering",
              company: "VICOSTONE",
              city: "Ha Noi",
              country: "Vietnam",
              expiredDate: "XX/XX/XXXX",
              category: "Available Position",
            },
            {
              title: "Engineering Team Lead - Data Engineering",
              company: "VICOSTONE",
              city: "Ha Noi",
              country: "Vietnam",
              expiredDate: "XX/XX/XXXX",
              category: "Available Position",
            },
            {
              title: "Engineering Team Lead - Data Engineering",
              company: "VICOSTONE",
              city: "Ha Noi",
              country: "Vietnam",
              expiredDate: "XX/XX/XXXX",
              category: "Available Position",
            },
            {
              title: "Engineering",
              company: "VICOSTONE",
              city: "Ha Noi",
              country: "Vietnam",
              expiredDate: "XX/XX/XXXX",
              category: "Available Position",
            },
            {
              title: "Engineering Team Lead - Data Engineering",
              company: "VICOSTONE",
              city: "Ha Noi",
              country: "Vietnam",
              expiredDate: "XX/XX/XXXX",
              category: "Available Position",
            },
          ],
        },
      ],
    };

    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.getArticles = this.getArticles.bind(this);
    this.getHtmlParagraph = this.getHtmlParagraph.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }
  getSectionDetail() {
    const articleCollection = [];
    const apiData = new Api();
    this.setState(
      {
        sections: [],
        articleArray: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            this.setState({ isApiLoaded: true });
            const sections = result.data;
            var categories = [];
            const widget = [];

            let textAv = sections.widgets[3].widget_contents[0]
              ? sections.widgets[3].widget_contents[0].content
              : "Available";

            result.data.widgets.forEach((item) => {
              if (item.category === "Article" && !item.is_internal) {
                this.getArticles(item.id, item.url);
              }
              categories = item.article_categories;
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                sections: item,
                contentItems: 20,

                // nameAwards: sections.widgets[6].widget_contents[0].content,
                // nameOverview: sections.widgets[7].widget_contents[0].content
              });
            });

            sections.page = widget;
            this.setState({
              sectionName: result.data.name,
              sections: widget,
              isLoaded: true,
              articleID: widget[0].id,
              articleArray: articleCollection,
              categories: categories,
              text: textAv,
            });
          })
          .catch((error) => {
            // console.log(error);
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  getArticles(param, articleURL) {
    const apiData = new Api();
    this.setState(
      {
        //contentArticle: [],
      },
      () => {
        apiData
          .getArticleDetailByYear(param, null, 50, null)
          .then((result) => {
            const articles = result.data;
            const art = [];

            articles.results.forEach((a) => {
              const assets = [];

              a.article_assets.forEach((ass, i) => {
                if (i === 0) {
                  assets.push({ asset: ass.asset_file });
                }
              });

              art.push({
                id: a.id,
                category: a.category_name,
                name: a.name,
                title: a.article_languages.title,
                content: a.article_languages.content,
                assets: assets,
                date: a.date,
                url: articleURL + "/" + a.id,
              });
            });

            this.setState({
              articleArray: art,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }
  render() {
    var boxPositionAv,
      availablePositionsText,
      openPositionsText,
      careerList,
      navBar,
      info = null;

    let availablePositions = 0;
    var today = new Date();
    var date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    var expiredText = "Expired";
    var openText = "Open";

    if (this.state.isLoaded) {
      this.state.sections.forEach((item, index) => {
        switch (index) {
          case 0:
            openPositionsText = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Open Positions";
            break;
          case 1:
            careerList = (
              <BoxCareer
                id={item.id}
                array={this.state.articleArray}
                app={this.state.app}
              />
            );
            info = <BoxUp id={item.id} avl={this.state.text}/>;

            break;

          case 2:
            openText = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Open";
            expiredText = item.sections.widget_contents[1]
              ? item.sections.widget_contents[1].content
              : "Expired";
            break;
          case 3:
            // availablePositionsText = <BoxUp id={item.id} />;
            // availablePositionsText = item.sections.widget_contents[0]
            //   ? item.sections.widget_contents[0].content
            //   : "Available Positions";
            break;
          case 4:
            break;
          case 5:
            this.state.app = item.sections.widget_contents[0].content;
            break;
          case 6:
            navBar = <Navbar widget={item.sections.widget_contents} />;
            break;

          default:
        }
      });

      // boxPositionAv = this.state.articleArray.map((position) => {
      //   var expired = date > position.date ? openText : expiredText;
      //   let strDate = position.date;
      //   let getDate = dateFormat(strDate, "dd-mm-yyyy");

      //   return (
      //     <>
      //       {/* <Link to={position.url}></Link> */}
      //       <Card className={classes.box}>
      //         <div className={classes.cardContent}>
      //           <h4 className={classes.titleCard}>{position.title}</h4>
      //           <p className={classes.titleExp}>{expired}</p>
      //           <p className={classes.titleDate}>{getDate}</p>
      //         </div>
      //         <div className={classes.cardContentHover}></div>
      //       </Card>
      //     </>
      //   );
      // });
    } else {
      boxPositionAv = this.state.dummy[0].boxPositionAv.map((position) => {
        return (
          <div>
            <Card className={classes.box}>
              <div className={classes.cardContent}>
                <h4 className={classes.titleCard}>{position.title}</h4>
                <div className={classes.company}>
                  <p className={[classes.text, classes.font_16].join(" ")}>
                    {position.company}
                  </p>
                  <p className={[classes.text, classes.font_16].join(" ")}>
                    <span>{position.city}</span> -{" "}
                    <strong>
                      <span>{position.country}</span>
                    </strong>
                  </p>
                </div>
                <div>
                  <p className={[classes.text, classes.font_16].join(" ")}>
                    Expired
                  </p>
                  <p
                    className={[
                      classes.expiredDate,
                      classes.text,
                      classes.font_16,
                    ].join(" ")}
                  >
                    {position.expiredDate}
                  </p>
                </div>
              </div>
            </Card>
          </div>
        );
      });
    }

    return (
      <Fragment>
        {navBar}
        <div className={classes.top}>
          <div className={["container", classes.cusT].join(" ")}>
            <div className="row">
              <div className={["col", classes.pad0].join(" ")}>
                <h3 className={classes.title}>{openPositionsText}</h3>
                {info}
                <div className={classes.blackCircleBtn}>
                  <i class="fas fa-caret-up"></i>
                </div>
              </div>
              <div className={classes.rightCol}>
                <div className={classes.purpleCircleBtn}>
                  <i class="fas fa-caret-up"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.bottom}>
          <div className={["container", classes.cusT].join(" ")}>
            <h3 className={classes.bottomTitle}>{availablePositionsText}</h3>
          </div>
          <div>{careerList}</div>
        </div>
      </Fragment>
    );
  }
}

export default sec1;

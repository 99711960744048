import React, { Component } from "react";
import classes from "./BlogView.module.css";
import Api from "./Api";
import dateFormat from "dateformat";

export default class BlogBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        widgets: [],
      },
      url: "",
      readMore: "Read More",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    if (this.props.id) {
      let param = this.props.id;
      this.getSectionDetail(param);
      this.setState({
        url: this.props.url,
      });
    }
  }

  getSectionDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getSectionDetail(param)
        .then((result) => {
          const articles = result.data;
          const data = [];

          data.push({
            back: articles.widgets[0].widget_contents[0].content,
            next: articles.widgets[1].widget_contents[0].content,
            author: articles.widgets[2].widget_contents[0].content,
            publish: articles.widgets[3].widget_contents[0].content,
            share: articles.widgets[4].widget_contents[0].content,
          });

          this.setState({
            article: data,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {

    const { article } = this.state;
    let backText,
      shareText,
      nextText,
      author,
      publish,
      share,
      dateNow = null;
    if (this.state.isLoaded) {
      let nows = this.props.date;
      let now = new Date(nows);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      dateNow = now.toLocaleDateString("vi", options);
      // let now = this.props.date;
      // dateNow = dateFormat(now, "dddd, mmmm dS, yyyy");

      backText = article[0].back ? article[0].back : "Back to Blog List";
      nextText = article[0].next ? article[0].next : "Next Blog";
      author = article[0].author ? article[0].author : "Name of Author";
      publish = article[0].publish ? article[0].publish : "Published";
      share = article[0].share ? article[0].share : "Share";
    }
    return (
      <div className={classes.boxProfile}>
        <div className={classes.profile}>
          <img
            src={
              this.props.author_avatar
                ? this.props.author_avatar
                : require("./assets/man.svg")
            }
            alt=""
          ></img>
        </div>
        <div className={classes.author}>
          <p className={classes.titleProfile}>{author}</p>
          <p className={classes.contentProfile}>{this.props.author_name}</p>
        </div>
        <div className={classes.published}>
          <p className={classes.titleProfile}>{publish}</p>
          <p className={classes.contentProfile}>{dateNow}</p>
        </div>
        <div className={classes.share}>
          <p className={classes.contentProfile}>{share}: </p>
          <img
            className={classes.icon}
            src={require("./assets/fb.png")}
            alt=""
          ></img>
          <img
            className={classes.icon}
            src={require("./assets/twitter.png")}
            alt=""
          ></img>
          <img
            className={classes.icon}
            src={require("./assets/instagram.png")}
            alt=""
          ></img>
          <img
            className={classes.icon}
            src={require("./assets/pinterest.png")}
            alt=""
          ></img>
          <img
            className={classes.icon}
            src={require("./assets/dropbox.png")}
            alt=""
          ></img>
        </div>
      </div>
    );
  }
}

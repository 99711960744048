import React, { Component } from 'react'
import ReactHtmlParser from "react-html-parser";

import Api from '../Api'

import classes from './Mobile.module.css'

export default class Mobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: null,
            sections: {
                widgets: [],
            },
            dummy: [
                {
                    id: 1,
                    name: "Article1",
                    title: "What is Lorem Ipsum?",
                    content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. unknown printer took a galley of type and scrambled it to make a type specimen book",
                    assets: require("../assets/default-image.svg"),

                },
            ],
            isLoaded: false,
            isApiLoaded: false,

        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }
    getSectionDetail() {
        const apiData = new Api();
        this.setState(
            {
                sections: [],
            },
            () =>
                apiData
                    .getSectionDetail()
                    .then((result) => {
                        this.setState({
                            isApiLoaded: true,
                        });
                        const sections = result.data;
                        const widget = [];
                        result.data.widgets.forEach((item) => {
                            widget.push({
                                id: item.id,
                                index: item.index,
                                category: item.category,
                                name: item.name,
                                sections: item,
                            });
                        });
                        sections.page = widget;
                        console.log(widget);
                        this.setState({
                            sections: widget,
                            isLoaded: true,
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            isApiLoaded: true,
                        });
                    })
        );
    }
    render() {
        const { sections, dummy } = this.state;

        let imgChairman, contentArticle, dummyImgChairman, dummyArticle = null

        dummyImgChairman = <img className={classes.imgContent} src={dummy[0].assets} alt="" />
        dummyArticle = 
        <div className={classes.boxContent}>
            <p className={classes.title}>{dummy[0].title}</p>
            <span>{ReactHtmlParser(dummy[0].content)}</span>
            <p className={classes.readMore}>Read more</p>
        </div>

        if (this.state.isLoaded) {
            let count = 0;
            sections.forEach((item) => {
                count = count + 1;
                switch (item.index) {
                    case 1:
                        item.sections.widget_contents.forEach((s) => {
                            imgChairman = s.file !== "" ? <img className={classes.imgContent} src={s.file} alt="" /> : dummyImgChairman;
                        });
                        break;
                    case 2:
                        contentArticle = item.sections.widget_contents.map((s) => {
                            let isImage = s.assets ? s.assets[0].file : require("../assets/default-image.svg")
                            return (
                                <div className={classes.boxContent}>
                                    <p className={classes.title}>{s.title}</p>
                                    <span>{ReactHtmlParser(s.content)}</span>
                                    <p className={classes.readMore}>Read more</p>
                                </div>
                            );
                        });
                        break;
                    default:
                    // code block
                }
            })
        } else if (this.state.isApiLoaded) {
            imgChairman = dummyImgChairman
            contentArticle = dummyArticle
        }

        return (
            <div className={classes.contentSection}>
                {imgChairman}
                <div className={classes.boxContent}>
                    {contentArticle}
                </div>
            </div>
        )
    }
}

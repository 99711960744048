import React from 'react'
import { isMobile  } from "react-device-detect";
import Desktop from './Desktop'
import Mobile from './Mobile'

export default function MegaMenuItems(props) {

    let content = !isMobile ? <Desktop items={props.items}/> : <Mobile items={props.items}/>

    return (
        <>
          {content}  
        </>
    )
}

import React, { Component } from "react";
import classes from "./BlogView.module.css";
import Api from "./Api";
import dateFormat from "dateformat";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class BackNext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        widgets: [],
      },
      url: "",
      readMore: "Read More",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const articles = result.data;
          const data = [];
          data.push({
            back: articles.widgets[0].widget_contents[0]
              ? articles.widgets[0].widget_contents[0].content
              : "Back",
            next: articles.widgets[1].widget_contents[0]
              ? articles.widgets[1].widget_contents[0].content
              : "Next",
          });

          this.setState({
            article: data,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { isLoaded, isApiLoaded, article } = this.state;
    let back,
      next = null;

    if (isLoaded) {
      back = article[0].back;
      next = article[0].next;
    }

    return (
      <div className={classes.pagination}>
        <div className={classes.previous}>
          <FontAwesomeIcon
            color="white"
            className="mr-2"
            icon={faArrowLeft}
            size="xl"
          />
          <span className={classes.lblPagination}>{back}</span>
        </div>
        <div className={classes.next}>
          <span className={classes.lblPagination}>{next}</span>
          <FontAwesomeIcon
            color="white"
            className="ml-2"
            icon={faArrowRight}
            size="xl"
          />
        </div>
      </div>
    );
  }
}

import * as React from "react"
import useScrollPosition from "@react-hook/window-scroll"
import classes from "../../News.module.css"
import clases from "./Navbar.module.css"
import dateFormat from "dateformat"

export default function Navbar(props) {
  const scrollY = useScrollPosition(60)
  let nav,
    sticky,
    contentSticky,
    filters,
    colorGrid,
    colorList,
    colorGrid2,
    colorList2 = null
  // console.log(props)
  if (props.type) {
    // console.log(props.type)
    if (props.type === "list" || props.type === "list2") {
      colorList = <img className={classes.img_list} onClick={props.listClick} src={require("../assets/list2.svg")} alt='description' />
      colorList2 = <img className={classes.img_list} onClick={props.listClick2} src={require("../assets/list2White.svg")} alt='description' />
      colorGrid = <img className={classes.img_grid} onClick={props.gridClick} src={require("../assets/grid.svg")} alt='description' />
      colorGrid2 = <img className={classes.img_grid} onClick={props.gridClick2} src={require("../assets/gridWhite.svg")} alt='description' />
    } else {
      colorList = <img className={classes.img_list} onClick={props.listClick} src={require("../assets/list.svg")} alt='description' />
      colorList2 = <img className={classes.img_list} onClick={props.listClick2} src={require("../assets/listWhite.svg")} alt='description' />
      colorGrid = <img className={classes.img_grid} onClick={props.gridClick} src={require("../assets/grid2.svg")} alt='description' />
      colorGrid2 = <img className={classes.img_grid} onClick={props.gridClick2} src={require("../assets/grid2White.svg")} alt='description' />
    }
  }

  if (props.category) {
    filters = props.category.map((f) => {
      return <option value={f}>{f}</option>
    })
  }

  if (scrollY > "36") {
    let now = new Date()
    const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" }
    let dateNow = now.toLocaleDateString("vi", options)
    sticky = clases.sticky
    contentSticky = clases.contentSticky
    nav = (
      <div className={["d-flex flex-row", classes.customNavbar].join(" ")}>
        <select name='filter' id='filter' className={classes.customSelect} onChange={props.onChange}>
          {filters}
        </select>
        <p className={clases.date2}>
          {dateNow}
          {/* <strong>Monday,</strong> September 7, 2020 | 10:09 (GMT + 7) */}
        </p>
        <div className={classes.wrap_option}>
          <div className={classes.grid}>{colorGrid}</div>
          <div className={classes.list}>{colorList}</div>
        </div>
        <div>
          <label className={classes.box_search}>
            <input onKeyDown={props.onKeyDown} className={classes.input_search} type='text' placeholder={props.searchHere} name='search' />
            <img className='pull-right' src={require("../assets/search.svg")} alt='' />
          </label>
        </div>
      </div>
    )
  } else {
    // console.log("masuk");
    let now = new Date()
    const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" }
    let dateNow = now.toLocaleDateString("vi", options)
    sticky = contentSticky = ""
    nav = (
      <>
        <p className={clases.navTitle}>{props.title}</p>
        <div className={[clases.boxNavbar].join(" ")}>
          <select name='filter' id='filter' onChange={props.onChange}>
            <option value=''>Tất cả</option>
            {filters}
          </select>
          <p className={clases.date}>
            {dateNow}
            {/* <strong>Monday,</strong> September 7, 2020 | 10:09 (GMT + 7) */}
          </p>
          <div className={clases.colorGridWhite}>{colorGrid2}</div>
          <div className={clases.colorListWhite}>{colorList2}</div>

          <div className={clases.boxSearch}>
            <input onKeyDown={props.onKeyDown} className={clases.inputSearch} type='text' placeholder={props.searchHere} />
          </div>
        </div>
      </>
    )
  }
  return (
    <div className={[clases.customNavbar, sticky].join(" ")}>
      <div className={clases.navbar}>{nav}</div>
    </div>
  )
}

import React, { Component } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import styled from "styled-components";

import Logo from '../../sections/Logo/Logo'
import Menu from '../../sections/Menu/Menu'
import Footer from '../../sections/Footer/Footer'

import BSPage5 from "../../sections/BS_Page5/BS_Page5"

const CustomDiv = styled.div`
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
`;

class Distribution extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div>
                <Logo />
                <Menu />
                <CustomDiv>
                    <ParallaxProvider>
                        <React.Fragment>
                            <BSPage5 />
                        </React.Fragment>
                    </ParallaxProvider>
                </CustomDiv>
                <Footer />
            </div>
        );
    }
}

export default Distribution;

import React, { Component } from 'react'

import ReactHtmlParser from "react-html-parser";


import classes from './Table.module.css'
import Api from '../Api'

export default class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentArticle: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: true,
        };
        this.getArticleDetail = this.getArticleDetail.bind(this);
    }

    componentDidMount() {
        if (this.props.id) {
            this.getArticleDetail(this.props.id);
        }
    }

    getArticleDetail(param) {
        const apiData = new Api();
        this.setState({ contentArticle: [] }, () => {
            apiData.getArticleDetail(param)
                .then(result => {
                    const articles = result.data
                    const art = []
                    articles.results.forEach((a) => {
                        const assets = []
                        a.article_assets.forEach((ass, i) => {
                            if (i === 0) {
                                assets.push({
                                    asset: ass.asset_file
                                })
                            }
                        })

                        art.push({
                            id: a.id,
                            category: a.category_name,
                            name: a.name,
                            title: a.article_languages.title,
                            content: a.article_languages.content,
                            assets: assets,
                            date: a.date
                        })
                    })

                    this.setState({
                        contentArticle: art,
                        isLoaded: true
                    })
                })
                .catch(error => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        })
    }

    getHtmlParagraph(str) {
        if (str !== '') {
            return str.replace(/<\/?p>/gi, " ")
                .replace(/<p>/gi, " ");
        }
    }
    render() {
        const { contentArticle } = this.state

        let contentTable, contentHeader = null
        let c1, c2, c3, c4 = null
        if (this.state.isLoaded) {
            this.props.column.forEach((c, i) => {
                switch (i) {
                    case 0:
                        c1 = <div className={classes.firstColumn}>
                            <span>{c}</span>
                        </div>
                        break;
                    case 1:
                        c2 = <div className={classes.secondColumn}>
                            <span>{c}</span>
                        </div>
                        break;
                    case 2:
                        c3 = <div className={classes.thirdColumn}>
                            <span>{c}</span>
                        </div>
                        break;
                    case 3:
                        c4 = <div className={classes.fourthColumn}>
                            <span>{c}</span>
                        </div>
                        break;
                    default:
                        break;
                }

                // return (
                //     <div className={classes.customRow}>
                //         {c1}
                //         {c2}
                //         {c3}
                //         {c4}
                //     </div>
                // )
            })
            contentTable = contentArticle.map((c, i) => {
                let title, desc, cont1, cont2, icon = null
                if (c.content && c.content !== "") {
                    // let result = (c.content.match(/<p>(.*?)<\/p>/g) || c.content.match(/<p>(.*?)<\/p>/g)).map(function (val) {
                    //     return val.replace(/<\/?p>/g, ' ')
                    //         .replace(/&nbsp;/gi, " ");
                    // });
                    const splitStr = c.content.split('#')
                    title = splitStr[0] ? this.getHtmlParagraph(splitStr[0]) : ''
                    desc = splitStr[1] ? this.getHtmlParagraph(splitStr[1]) : ''
                    cont1 = splitStr[2] ? this.getHtmlParagraph(splitStr[2]) : ''
                    cont2 = splitStr[3] ? this.getHtmlParagraph(splitStr[3]) : ''
                }
                icon = c.assets[0] ? c.assets[0].asset : require('../assets/default-image.svg')
                return (
                    <div className={classes.customRow}>
                        <div className={classes.firstColumnT}>
                            <div className={classes.boxIcon}>
                                <img className={classes.iconTable} src={icon} alt=""></img>
                                <p className={classes.titleIcon}>{title}</p>
                            </div>
                        </div>
                        <div className={classes.secondColumnT}>
                            {ReactHtmlParser(cont1)}
                        </div>
                        <div className={classes.thirdColumnT}>
                            {ReactHtmlParser(cont2)}
                        </div>
                    </div>
                )
            })
        }

        return (
            <div className={classes.contentSection}>
                <div className={['container', classes.customContainer].join(' ')}>
                    <div className={classes.wrapHeader}>
                        {c1}
                        {/* {c2} */}
                        {c3}
                        {c4}
                    </div>
                    <div className={classes.wrapTable}>
                        {contentTable}
                    </div>
                </div>
            </div>
        )
    }
}

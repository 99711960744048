import React, { Component } from "react";
import HorizontalScroll from "react-scroll-horizontal";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import ReactHtmlParser from "react-html-parser";
import AUHistoryDetail from "./AU_HistoryDetail";
import Api from "./Api";
import classes from "./History.module.css";
import Timeline from "./timeline";

class historysample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        {
          id: 2,
          name: "Articles",
          articles: [
            {
              name: "Articles1",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles2",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles3",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "What is Lorem Ipsum?",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
          ],
        },
      ],
      content: <div></div>,
      contentDetail: false,
      currentPage: null,
      contentArticle: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      indexYear: false,
      disabledNext: true,
      disabledPrev: true,
      currentIndex: 0,
    };
    this.getArticleGroupbyYear = this.getArticleGroupbyYear.bind(this);
    this.toggleHandlerEnter = this.toggleHandlerEnter.bind(this);
    this.toggleNext = this.toggleNext.bind(this);
    this.togglePrev = this.togglePrev.bind(this);
    this.toggleNextButton = this.toggleNextButton.bind(this);
    this.togglePrevButton = this.togglePrevButton.bind(this);
  }

  toggleHandlerBack = () => {
    this.setState({
      contentDetail: false,
    });
  };

  toggleHandlerEnter = (i) => {
    this.setState({
      indexYear: i + 1,
      contentDetail: true,
      currentIndex: 0,
    });
    this.togglePrevButton(true);
    if (this.state.contentArticle[i].art.length === 1) {
      this.toggleNextButton(true);
    } else {
      this.toggleNextButton(false);
    }
  };

  toggleExit = () => {
    this.setState({
      indexYear: false,
      currentIndex: 0,
    });
    this.toggleNextButton(true);
  };

  changeHighlight(key) {
    this.setState({ activeYear: key });
  }

  changeBox = () => {
    this.setState({
      content: <div></div>,
    });
  };

  componentDidMount() {
    let id = this.props.id;
    if (id !== "dummy") {
      this.getArticleGroupbyYear(id);
    } else {
      this.setState({
        isApiLoaded: true,
      });
    }
  }

  toggleNextButton(onOrOff) {
    this.setState({
      disabledNext: onOrOff,
    });
  }

  togglePrevButton(onOrOff) {
    this.setState({
      disabledPrev: onOrOff,
    });
  }

  togglePrev() {
    var k = this.state.currentIndex;
    //console.log("togglePrev " + k);
    if (k > 0) {
      this.setState({
        currentIndex: k - 1,
        //indexYear: currentIndex
      });
      if (k - 1 === 0) {
        this.togglePrevButton(true);
      }
      if (
        k < this.state.contentArticle[this.state.indexYear - 1].art.length &&
        this.state.contentArticle[this.state.indexYear - 1].art.length > 1
      ) {
        this.toggleNextButton(false);
      }
    }
  }

  toggleNext() {
    var k = this.state.currentIndex;
    // if (
    //   k + 1 ===
    //   this.state.contentArticle[this.state.indexYear - 1].art.length
    // ) {
    //   this.setState({ disabledNext: false });
    //console.log("K: " + k)
    if (
      k + 2 ===
      this.state.contentArticle[this.state.indexYear - 1].art.length
    ) {
      this.toggleNextButton(true);
    }

    if (
      k + 1 <
      this.state.contentArticle[this.state.indexYear - 1].art.length
    ) {
      this.setState({
        currentIndex: k + 1,
      });
      this.togglePrevButton(false);
    }
  }

  getArticleGroupbyYear(param) {
    const apiData = new Api();
    this.setState(
      {
        sections: [],
      },
      () => {
        apiData
          .getArticleGroupbyYear(param)
          .then((result) => {
            this.setState({
              isApiLoaded: true,
            });
            const sections = result.data;
            const widget = [];
            const years = [];
            result.data.forEach((element) => {
              let cc = "" + element.year + "";
              years.push(element.year);

              widget.push({
                year: element.year,
                art: element.articles,
              });
            });

            this.setState({
              contentArticle: widget,
              years: years,
              isLoaded: true,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              isApiLoaded: true,
            });
          });
      }
    );
  }

  render() {
    const { contentArticle, isLoaded, dummy } = this.state;

    let year,
      date,
      file,
      contents,
      content,
      titleContent,
      contentSample,
      isImage,
      title,
      img,
      boxYear,
      contentDetail,
      indexY,
      highlight,
      indexA,
      boxNone,
      activeImg,
      buttonNext,
      buttonNext1,
      buttonNext2,
      buttonPrev,
      dummyContentSample = null;
    let count = 0;

    if (isLoaded) {
      boxYear = this.state.years.map((item, index) => {
        let textZoom = null;
        if (this.state.indexYear - 1 === index) {
          textZoom = classes.textZoom;
          highlight = classes.highlight;
        }
        return (
          <div
            className={[classes.box_main, textZoom].join(" ")}
            onClick={() => this.toggleHandlerEnter(index)}
          >
            <div className={classes._flexD}>
              <p className={classes.box_year}>{item}</p>
            </div>
          </div>
        );
      });
      contentSample = contentArticle.map((ele, index) => {
        var arrayLength = ele.art.length;
        let nextButton = null;
        if (arrayLength === 1) {
          // console.log("masuk");
          buttonNext = buttonNext2;
        } else {
          // console.log("banyak");
          nextButton = classes.disableNext;
          buttonNext = buttonNext1;
        }

        buttonNext1 = (
          <img
            onClick={this.toggleNext}
            className={classes.arrowRight}
            src={require("./asset/right.svg")}
            alt=""
          />
        );
        buttonNext2 = (
          <img
            onClick={this.toggleNext}
            className={[classes.arrowRight, nextButton].join(" ")}
            // disabled={this.state.disabledNext}
            src={require("./asset/right.svg")}
            alt=""
          />
        );
        buttonPrev = (
          <img
            onClick={this.togglePrev}
            className={classes.arrowLeft}
            src={require("./asset/left.svg")}
            alt=""
          />
        );
        ele.art.map((dat, i) => {
          activeImg = this.state.indexYear
            ? this.state.indexYear - 1 === index
              ? classes.activeImg
              : classes.inActiveImg
            : "";

          let nextButton = null;

          if (i === this.state.currentIndex) {
            indexA = i;
            date = dat.date;
            // file = dat.article_assets[0].asset_file ? dat.article_assets[0].asset_file : ;
            content = dat.article_languages.content;
            title = dat.article_languages.title;
            year = dateFormat(date, "yyyy");
            titleContent = <p>{title}</p>;

            boxNone = content ? "" : classes.boxNone;
            isImage = dat.article_assets[0] ? (
              <img
                className={classes.image_box}
                src={dat.article_assets[0].asset_file}
                alt=""
              />
            ) : (
              <img
                className={classes.image_box_default}
                src={require("./asset/default-image.svg")}
                alt=""
              />
            );
          }
        });

        // let nextButton = this.state.disabledNext ? "" : classes.disableNext;
        let customStyle = !this.state.indexYear
          ? classes.colorbox
          : classes.color1;

        return (
          <>
            <div
              className={[classes.box, activeImg].join(" ")}
              onClick={() => this.toggleHandlerEnter(index)}
            >
              {this.state.indexYear - 1 === index ? (
                <div className={classes.p_mid}>
                  <p className={classes.text_fake}>{year}</p>
                  <span className={classes.title_mid}>{titleContent}</span>
                  <p className={classes.p_text}>{ReactHtmlParser(content)}</p>
                </div>
              ) : null}
              <div className={customStyle}>
                <span className={classes.title_mid2}>{titleContent}</span>
                <img
                  className={classes.btn_hover}
                  src={require("./asset/plus.svg")}
                  alt=""
                />
              </div>
              <figure className={classes.figureimg}>{isImage}</figure>
            </div>
          </>
        );
      });
    } else if (this.state.isApiLoaded) {
      dummy.forEach((s) => {
        contentSample = s.articles.map((ele) => {
          if (s.id === 2) {
            year = <p>{ele.year}</p>;
            titleContent = <p>{ele.title}</p>;
            contents = <p>{ele.content}</p>;

            let contentDetail = (
              <AUHistoryDetail
                style={classes.boxDetailShow}
                title={ele.title}
                image={ele.assets}
                text={ele.content}
                year={year}
                date={year}
                data={this.state.dummy}
                onClick={this.toggleHandlerBack}
              />
            );

            return (
              <div>
                <div
                  className={[classes.box, highlight].join(" ")}
                  onClick={() => this.toggleHandlerEnter(contentDetail)}
                >
                  <div className={classes.colorbox}>
                    <p className={classes.text_fake}>{year}</p>

                    <img
                      className={classes.btn_hover}
                      src={require("./asset/plus.svg")}
                      alt=""
                    />
                  </div>
                  <p className={classes.title_mid}>{titleContent}</p>
                  <figure className={classes.figureimg}>
                    <img
                      className={classes.image_box}
                      src={ele.assets}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            );
          }
        });
      });
    }

    dummyContentSample = dummy[0].articles.map((ele) => {
      year = <p>{ele.year}</p>;
      titleContent = <p>{ele.title}</p>;
      contents = <p>{ele.content}</p>;

      let contentDetail = (
        <AUHistoryDetail
          style={classes.boxDetailShow}
          title={ele.title}
          image={ele.assets}
          text={ele.content}
          year={year}
          date={year}
          onClick={this.toggleHandlerBack}
          onChange={this.changeBox}
        />
      );
      return (
        <>
          <div
            className={classes.box}
            onClick={() => this.toggleHandlerEnter(contentDetail)}
          >
            <div className={classes.colorbox}>
              <p className={classes.text_fake}>{year}</p>

              <img
                className={classes.btn_hover}
                src={require("./asset/plus.svg")}
                alt=""
              />
            </div>
            <p className={classes.title_mid}>{titleContent}</p>
            <p className={classes.p_mid}>{contents}</p>
            <figure className={classes.figureimg}>
              <img className={classes.image_box} src={ele.assets} alt="" />
            </figure>
          </div>
        </>
      );
    });

    let activeBtn = this.state.indexYear ? classes.activeMinus : "";
    let activeNav = this.state.indexYear ? classes.activeNav : "";

    return (
      <div>
        <div className={classes.wrapp}>
          <HorizontalScroll
            reverseScroll={true}
            config={{ stiffness: 160, damping: 110 }}
          >
            {contentSample}
          </HorizontalScroll>
          {/* <div className={classes._boxOne}>{boxYear}</div> */}
          <img
            onClick={this.toggleExit}
            className={[classes.toggleHide, activeBtn].join(" ")}
            src={require("./asset/minus.svg")}
            alt=""
          />

          <div className={classes.navBack}>
            <Link to={"/about-us"}>
              <div className={classes._rowDir}>
                <i
                  className={[
                    classes.arrow,
                    classes.left,
                    classes.customMargin,
                  ].join(" ")}
                ></i>
                <p className={classes.backPrev}>{this.props.backBtn}</p>
              </div>
            </Link>

            <div className={classes._boxOne}>{boxYear}</div>
          </div>

          <div className={[classes.toggleNav, activeNav].join(" ")}>
            {this.state.disabledPrev ? null : buttonPrev}
            {this.state.disabledNext ? null : buttonNext}
          </div>

          {/* {contentDetail} */}
          {this.state.content}
        </div>
      </div>
    );
  }
}

export default historysample;

import React from 'react'

import Desktop from './Desktop/Desktop'
import Mobile from './Mobile/Mobile'

import { isMobile  } from "react-device-detect";

export default function H_Section1() {

    let content = !isMobile ? <Desktop /> : <Mobile />

    return (
        <>
          {content}  
        </>
    )
}

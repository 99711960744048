import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Col } from "react-bootstrap";
import Slider from "react-slick";
import dateFormat from "dateformat";

import Api from "./Api";

import classes from "./history.module.css";

export default class HistoryViews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [],
      contentArticle: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: true,
      updateValue: 0,
      slideIndex: 0,
      nav1: null,
      nav2: null,
      url: this.props.url ? this.props.url : "/about-us/history/",
      historyId: null,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);
    this.next = this.next.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      this.getArticleDetail(this.props.id);
      this.setState({
        historyId: this.props.id,
      });
    }
  }

  getArticleDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetailByYear(param)
        .then((result) => {
          const articles = result.data;
          const art = [];
          articles.results.forEach((a) => {
            const assets = [];
            a.article_assets.forEach((ass, i) => {
              if (i === 0) {
                assets.push({
                  asset: ass.asset_file,
                });
              }
            });

            art.push({
              id: a.id,
              category: a.category_name,
              name: a.name,
              title: a.article_languages.title,
              content: a.article_languages.content,
              assets: assets,
              date: a.date,
            });
          });

          this.setState({
            contentArticle: art,
            isLoaded: true,
            nav1: this.slider1,
            nav2: this.slider2,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  next() {
    this.slider2.slickNext();
  }

  render() {
    const { contentArticle } = this.state;
    let contentSlider,
      imageSlider,
      year = null;
    if (this.state.isLoaded) {
      contentSlider = contentArticle.map((s) => {
        let titleContent = null;
        let strYear = s.date;
        year = dateFormat(strYear, "yyyy");

        if (s.title !== "") {
          titleContent = s.title;
        } else {
          titleContent = <></>;
        }

        return (
          <div className={classes._boxSlider}>
            <div className={classes._boxYear}>
              <p
                className={classes.contentBoxTitle}
                onChange={(e) => this.slider.slickGoTo(e.target.value)}
                value={this.state.slideIndex}
                type="range"
                min={0}
                max={4}
              >
                {year}
              </p>
            </div>

            <div className={classes._boxTitle}>
              <p
                className={classes.contentTl}
                onChange={(e) => this.slider.slickGoTo(e.target.value)}
                value={this.state.slideIndex}
                type="range"
                min={0}
                max={4}
              >
                {titleContent}
              </p>
            </div>
          </div>
        );
      });

      imageSlider = contentArticle.map((s) => {
        let year = null;
        let strYear = s.date;
        year = dateFormat(strYear, "yyyy");

        let isImage = s.assets[0] ? (
          <img className={classes.img_col_1} src={s.assets[0].asset} alt="" />
        ) : (
          <img
            className={classes.img_col_1}
            src={require("./assets/default-image.svg")}
            alt=""
          />
        );

        return (
          <>
            <div className={[classes.boxImage, ""].join(" ")}>{isImage}</div>
          </>
        );
      });
    }
    //console.log("HistoryView: " + this.state.url)
    const settings = {
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: () =>
        this.setState((state) => ({ updateValue: state.updateValue + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
    };
    return (
      <div className={["container", classes.joinPad].join(" ")}>
        <h4 className={classes.judul}>{this.props.title}</h4>
        <div className={classes._box1}>
          <div className={classes.col_1}>
            <Slider
              asNavFor={this.state.nav2}
              ref={(slider) => (this.slider1 = slider)}
            >
              {imageSlider}
            </Slider>
          </div>
          <div className={classes.col_2}>
            <div className={classes.absBtm2}>
              <div className={classes.absBtm}>
                {/* <div className={classes.boxBottom}>
                  <div className={classes.insideBox}>
                    <p className={classes.contentBoxTitle}>{year}</p>
                  </div>
                </div> */}
                <div className={classes.card_2020}>
                  <div className={classes._bText}>
                    <Slider
                      asNavFor={this.state.nav1}
                      ref={(slider) => (this.slider2 = slider)}
                      slidesToShow={1}
                      swipeToSlide={false}
                      focusOnSelect={this.next}
                      {...settings}
                    >
                      {contentSlider}
                    </Slider>
                  </div>
                  <div className={classes._bImg}>
                    <img
                      onClick={this.next}
                      src={require("./assets/arrow.svg")}
                      className={classes.arrow_2}
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div className={classes._box4}>
                <Link to={`${this.state.url}`} className={classes.boxLinks}>
                  <div className={classes.outerCircle2}>
                    <div className={classes.innerCircle2}></div>
                  </div>

                  <p className={classes.foot}>{this.props.viewAll}</p>
                  {/* <p className={classes.readMoreContent}>{this.props.readMore}</p> */}

                  {/* <p className={classes.foot}>View All History</p> */}
                  {/* <div className={classes.spinner}></div> */}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// import React from 'react'

// import Mobile from './Mobile/Mobile'
// import Desktop from './Desktop/Desktop'
// import { isMobile  } from "react-device-detect";

// export default function H_Section4() {

//     let content = !isMobile ? <Desktop /> : <Mobile />
//     return (
//         <>
//           {content}
//         </>
//     )
// }

import React from "react";
import { useMediaQuery } from "react-responsive";

import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";

export default function H_Section4() {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 420px)",
  });

  let content = !isMobile ? <Desktop /> : <Mobile />;
  return <>{content}</>;
}

import React, { Component, Fragment, createRef } from "react"
import Header from "../../sections/Header/Header"
import AboutSectionShortcut from "./SectionShortcut/AboutSectionShortcut"
import SectionShortcut from "./SectionShortcut/SectionShortcut"

import AUSection2 from "../../sections/AU_Section2/AU_Section2"
import AUSection4 from "../../sections/AU_Section4/AU_Section4"
import AUSection3 from "../../sections/AU_Section3/AU_Section3"
import AUSection1 from "../../sections/AU_Section1/AU_Section1"
import AUSection5 from "../../sections/AU_Section5/AU_Section5"

import Footer from "../../sections/Footer/Footer"
import { ParallaxProvider } from "react-scroll-parallax"

import Api from "./Api"

import classes from "./AboutUs.module.css"
import "./AboutUs.css"

class aboutUs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: null,
      section: [
        {
          id: 1,
          title: "Tổng quan",
          component: "Overview",
        },
        {
          id: 2,
          title: "Đội ngũ Lãnh đạo",
          component: "Teams",
        },
        {
          id: 3,
          title: "Quá trình hình thành và phát triển",
          component: "History",
        },
        {
          id: 4,
          title: "giải thưởng",
          component: "Awards",
        },
      ],
      sectionList: {
        section: [],
      },
      isLoaded: true,
      isApiLoaded: true,
      darkSection: true,
    }

    this.getSectionList = this.getSectionList.bind(this)
    this.updateLogo = this.updateLogo.bind(this)
    this.override1 = this.override1.bind(this)
    this.override2 = this.override2.bind(this)
    this.override3 = this.override3.bind(this)
    this.override4 = this.override4.bind(this)
    this.override5 = this.override5.bind(this)
    this.scrollingHandler = this.scrollingHandler.bind(this)
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getSectionList()
    window.addEventListener("scroll", this.scrollingHandler, true)
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollingHandler, true)
  }

  section1 = createRef()
  section2 = createRef()
  section3 = createRef()
  section4 = createRef()
  section5 = createRef()
  updateLogo(value) {
    this.setState({
      darkSection: value,
    })
  }
  override1(value) {
    this.setState({
      override: value,
    })
  }

  override2(value) {
    this.setState({
      override: value,
    })
  }

  override3(value) {
    this.setState({
      override: value,
    })
  }

  override4(value) {
    this.setState({
      override: value,
    })
  }

  override5(value) {
    this.setState({
      override: value,
    })
  }

  scrollingHandler() {
    if (this.section1.current.getBoundingClientRect().bottom < this.section1.current.getBoundingClientRect().height && this.section1.current.getBoundingClientRect().bottom > -1 && this.state.override) {
      this.setState({
        currentSection: "Section1",
        darkSection: true,
      })
    }
    if (this.section2.current.getBoundingClientRect().bottom < this.section2.current.getBoundingClientRect().height && this.section2.current.getBoundingClientRect().bottom > -1 && this.state.override) {
      this.setState({
        currentSection: "Section2",
        darkSection: false,
      })
    }
    if (this.section3.current.getBoundingClientRect().bottom < this.section3.current.getBoundingClientRect().height && this.section3.current.getBoundingClientRect().bottom > -1 && this.state.override) {
      this.setState({
        currentSection: "Section3",
        darkSection: false,
      })
    }
    if (this.section4.current.getBoundingClientRect().bottom < this.section4.current.getBoundingClientRect().height && this.section4.current.getBoundingClientRect().bottom > -1 && this.state.override) {
      this.setState({
        currentSection: "Section4",
        darkSection: false,
      })
    }
    if (this.section5.current.getBoundingClientRect().bottom < this.section5.current.getBoundingClientRect().height && this.section5.current.getBoundingClientRect().bottom > -1 && this.state.override) {
      this.setState({
        currentSection: "Section5",
        darkSection: false,
      })
    }
  }

  getSectionList() {
    const apiData = new Api()
    this.setState({ sectionList: [] }, () => {
      apiData
        .getSectionList()
        .then((result) => {
          const sections = result.data
          const sec = []

          sections.results.forEach((item) => {
            sec.push({
              id: item.id,
              section: item.section,
              name: item.name,
              sections: item,
            })
          })

          sections.widget = sec
          this.setState({
            contentSection: sec,
            isLoaded: true,
          })
        })
        .catch((error) => {
          console.log(error)
          this.setState({
            isApiLoaded: true,
          })
        })
    })
  }

  section1 = createRef()
  section2 = createRef()
  section3 = createRef()
  section4 = createRef()
  section5 = createRef()

  scrolltoSectionHandler = (key) => {
    if (key === "section1") {
      this.section1.current.scrollIntoView({ behavior: "smooth" })
    } else if (key === "section2") {
      this.section2.current.scrollIntoView({ behavior: "smooth" })
    } else if (key === "section3") {
      this.section3.current.scrollIntoView({ behavior: "smooth" })
    } else if (key === "section4") {
      this.section4.current.scrollIntoView({ behavior: "smooth" })
    }
  }
  render() {
    const { contentSection } = this.state
    if (this.state.isLoaded) {
      console.log(contentSection)
    }

    let sec = this.state.section.map((s) => {
      let ref = "section" + s.id
      return <SectionShortcut key={s.id} title={s.title} onClick={() => this.scrolltoSectionHandler(ref)} />
    })
    let homeSectionShortcut = <AboutSectionShortcut section={sec} />

    return (
      <div className='' style={{}}>
        <Header onPage='about-us' style={{ display: "block" }} logoColor={this.state.darkSection} />

        <div className={classes.main}>
          <ParallaxProvider>
            <Fragment>
              <div ref={this.section1}>
                <AUSection1 updateLogo={this.updateLogo} override={this.override1} />
              </div>
              <div ref={this.section2}>
                <AUSection2 updateLogo={this.updateLogo} override={this.override2} />
              </div>
              <div ref={this.section3}>
                <AUSection3 updateLogo={this.updateLogo} override={this.override3} />
              </div>
              <div ref={this.section4}>
                <AUSection5 updateLogo={this.updateLogo} override={this.override4} />
              </div>
              <div ref={this.section5}>
                <AUSection4 updateLogo={this.updateLogo} override={this.override5} />
              </div>
            </Fragment>
            {homeSectionShortcut}
          </ParallaxProvider>
        </div>
        <Footer />
      </div>
    )
  }
}

export default aboutUs

import React from 'react'
import { Link } from 'react-router-dom'

import classes from "../MenuDetail.module.css"

const menuPreview1 = (props) => {
    let items = null
    if (props.items) {
        items = props.items.map((i, index) => {
            let title = null
            if (index === 0) {
                title = <p className={classes.title}>{i.title}</p>
            } else {
                title = <p className={classes.title}>&nbsp;</p>
            }

            let url = null
            let item = i.children.map(itm => {
                let uri = null
                uri = itm.url !== '' ? itm.url : '/'
                if (uri.includes('http')) {
                    url = <a key={itm.id} href={uri} className={classes.customLink} target="_blank" rel="noopener noreferrer">
                        <p className={classes.subTitleD}>{itm.title}</p>
                    </a>
                } else {
                    url = <Link key={itm.id} to={uri} className={classes.customLink}>
                        <p className={classes.subTitleD}>{itm.title}</p>
                    </Link>
                }
                return (
                    <>
                        { url}
                    </>
                )
            })

            return (
                <div key={i.id} className='col-md-4 p-0 m-0 pr-2'>
                    {title}
                    {item}
                </div>
            )
        })
    } else {
        items = <div></div>
    }

    return (
        <div className={classes.sectionPreview}>
            <div id='preview1' className='row col-md-12'>
                {items}
            </div>
        </div>

    )

}

export default menuPreview1
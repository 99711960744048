import * as React from "react";

import useScrollPosition from "@react-hook/window-scroll";

import MeshComponent from './Big Earth/MeshComponent'
import Circuit from './Circuit'
import Core from './Core'

import classes from "./News.module.css"

export default function BackgroundMask() {
    const scrollY = useScrollPosition(60);
    let customStyle, circuitStyle = null
    if ((scrollY >= '360')) {
        customStyle = classes.stickyBackground
        circuitStyle = classes.stickyCircuit
    } else {
        customStyle = ''
    }
    return (
        <>
            <div className={[classes.backgroundsticky, customStyle].join(' ')}>
                {/* <MeshComponent /> */}
            </div>

            {/* <div className={[classes.core].join(' ')}>
                <Core />
            </div> */}

            {/* <div className={[classes.Circuit,circuitStyle].join(' ')}>
                <Circuit />
            </div> */}
        </>
    );
}

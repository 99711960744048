import React, { Component } from 'react'
import ReactTooltip from "react-tooltip";
import "./SectionShortcut.css"

class SectionShortcut extends Component {
    render() {
        return (
            <div className="section-shortcut" onClick={() => this.props.onClick(this.props.eventKey)}>
                <div data-tip={this.props.title} title={this.props.title} className="zoomCircle">
                    <div title={this.props.title} className="zoomCircleChild"></div>
                </div>
                <ReactTooltip place="right" type="dark" effect="solid" />
            </div>
        )
    }
}
export default SectionShortcut

import React, { Component, Fragment, createRef } from "react"

import Logo from "../../sections/Logo/Logo"
import Menu from "../../sections/Menu/Menu"
import Ecosystem from "../../sections/BS_Section1/BS_Section1"
import SectionWrapping from "../../sections/BS_Section2/BS_Section2"
import Footer from "../../sections/Footer/Footer"

import { ParallaxProvider } from "react-scroll-parallax"
import classes from "./Business.module.css"
class Business extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabsActive: 0,
      isTab: false,
      override: true,
      darkSection: true,
    }
    this.setDefaultTabs = this.setDefaultTabs.bind(this)
    this.updateLogo = this.updateLogo.bind(this)
    this.override1 = this.override1.bind(this)
    this.override2 = this.override2.bind(this)
    this.scrollingHandler = this.scrollingHandler.bind(this)
  }
  componentDidMount() {
    window.scrollTo(0, 0)
    window.addEventListener("scroll", this.scrollingHandler, true)

    this.setDefaultTabs()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollingHandler, true)
  }

  updateLogo(value) {
    this.setState({
      darkSection: value,
    })
  }
  override1(value) {
    this.setState({
      override: value,
    })
  }

  override2(value) {
    this.setState({
      override: value,
    })
  }

  section1 = createRef()
  section2 = createRef()

  scrollingHandler() {
    if (this.section1.current.getBoundingClientRect().bottom < this.section1.current.getBoundingClientRect().height && this.section1.current.getBoundingClientRect().bottom > -1 && this.state.override) {
      this.setState({
        currentSection: "Section1",
        darkSection: true,
      })
    }
    if (this.section2.current.getBoundingClientRect().bottom < this.section2.current.getBoundingClientRect().height && this.section2.current.getBoundingClientRect().bottom > -1 && this.state.override) {
      this.setState({
        currentSection: "Section2",
        darkSection: false,
      })
    }
  }

  setDefaultTabs() {
    if (this.props.match.params.id) {
      const tabs = this.props.match.params.id
      this.setState({
        tabsActive: tabs,
        isTab: true,
      })
    } else {
      this.setState({
        tabsActive: "0",
        isTab: true,
      })
    }
  }
  render() {
    let tabActive = null
    if (this.state.isTab) {
      tabActive = this.state.tabsActive
    } else {
      tabActive = 0
    }
    return (
      <div className={classes.sectionBusiness}>
        <Logo logoColor={this.state.darkSection} />
        <Menu onPage={"business"} logoColor={this.state.darkSection} />
        <div className={classes.main}>
          <ParallaxProvider>
            <Fragment>
              <div ref={this.section1}>
                <Ecosystem updateLogo={this.updateLogo} override={this.override1} />
              </div>
              <div ref={this.section2}>
                <SectionWrapping tab={tabActive} updateLogo={this.updateLogo} override={this.override2} />
              </div>
            </Fragment>
          </ParallaxProvider>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Business

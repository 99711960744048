import React, { Component, Fragment } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import classes from "./Mobile.module.css";
import Api from "../Api";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import "swiper/swiper.scss";

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      sections: {
        widgets: [],
      },

      awards_1: [],
      awards_2: [],

      isLoaded: false,
      isApiLoaded: false,
      sectionTitle: "",
      sectionDescription: "",
      allAwardsUrl: "",
      awardDetails1: "",
      awardDetails2: "",
      latestAwardsButtonText: "",
      highlightAwardsButtonText: "",
      allAwardsButtonText: "",

      hoverMode: false,
      tabIndex: 1,
      dummy: [
        {
          id: 1,
          name: "Article",
          articles: [
            {
              name: "Main Article",
              title: "What is Lorem Ipsum?",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem" +
                " Ipsum has been the industrys standard dummy text ever since the 1500s, when an " +
                "unknown printer took a galley of type and scrambled it to make a type specimen b" +
                "ook",
              assets: require("../assets/default-image.svg"),
            },
          ],
        },
        {
          id: 2,
          name: "Articles",
          articles: [
            {
              name: "Articles1",
              title: "What is Lorem Ipsum?",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem" +
                " Ipsum has been the industrys standard dummy text ever since the 1500s, when an " +
                "unknown printer took a galley of type and scrambled it to make a type specimen b" +
                "ook",
              assets: require("../assets/default-image.svg"),
            },
            {
              name: "Articles2",
              title: "What is Lorem Ipsum?",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem" +
                " Ipsum has been the industrys standard dummy text ever since the 1500s, when an " +
                "unknown printer took a galley of type and scrambled it to make a type specimen b" +
                "ook",
              assets: require("../assets/default-image.svg"),
            },
            {
              name: "Articles3",
              title: "What is Lorem Ipsum?",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem" +
                " Ipsum has been the industrys standard dummy text ever since the 1500s, when an " +
                "unknown printer took a galley of type and scrambled it to make a type specimen b" +
                "ook",
              assets: require("../assets/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "What is Lorem Ipsum?",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem" +
                " Ipsum has been the industrys standard dummy text ever since the 1500s, when an " +
                "unknown printer took a galley of type and scrambled it to make a type specimen b" +
                "ook",
              assets: require("../assets/default-image.svg"),
            },
          ],
        },
      ],
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.hiddenBackground = this.hiddenBackground.bind(this);
    this.changeBackground = this.changeBackground.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.makeSliderElements = this.makeSliderElements.bind(this);
    this.makeImageHover = this.makeImageHover.bind(this);
    this.getArticles = this.getArticles.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getArticles(param, slider) {
    const apiData = new Api();
    this.setState(
      {
        contentArticle: [],
      },
      () => {
        apiData
          .getArticleDetail(param)
          .then((result) => {
            const articles = result.data;
            const art = [];
            articles.results.forEach((a) => {
              const assets = [];
              a.article_assets.forEach((ass, i) => {
                if (i === 0) {
                  assets.push({ asset: ass.asset_file });
                }
              });

              art.push({
                id: a.id,
                category: a.category_name,
                name: a.name,
                title: a.article_languages.title,
                content: a.article_languages.content,
                assets: assets,
                date: a.date,
              });
            });

            switch (slider) {
              case 1:
                this.setState({ awards_1: art, isLoaded: true });
                break;

              case 2:
                this.setState({ awards_2: art, isLoaded: true });
                break;
              default:
                break;
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  getSectionDetail() {
    const apiData = new Api();
    var slider = 1;
    this.setState(
      {
        sections: [],
        awards_1: [],
        awards_2: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            this.setState({ isApiLoaded: true });
            const sections = result.data;
            const widget = [];
            result.data.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                sections: item,
              });
              if (item.category === "Article") {
                this.getArticles(item.id, slider);
                slider += 1;
              }
            });
            sections.page = widget;
            this.setState({ sections: widget, isLoaded: true });
            console.log(widget);
          })
          .catch((error) => {
            console.log(error);
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  handleSelect(i) {
    this.setState({ tabIndex: i });
  }

  changeBackground(i) {
    this.setState({ hoverMode: i });
    // console.log(e)
  }

  hiddenBackground() {
    this.setState({ hoverMode: false });
  }

  makeSliderElements(sliderInput, url) {
    let titleContent = "";

    return sliderInput.map((s, i) => {
      //console.log(s)
      if (s.title !== "") {
        titleContent = <p>{s.title}</p>;
      } else {
        titleContent = "DummyTitle";
      }

      // console.log(s.id) console.log(url)
      return (
        <div
          className={classes.content_wrap}
          onMouseOver={() => this.changeBackground(i)}
          onMouseLeave={this.hiddenBackground}
        >
          <Link
            className={classes.link}
            to={{
              pathname: `${url}/${s.id}`,
            }}
          >
            <p className={classes.paragraph}>{titleContent}</p>
          </Link>
          <p className={classes.paragraph2}>{s.category}</p>
        </div>
      );
    });
  }

  makeImageHover(imageHoverInput) {
    return imageHoverInput.map((s, i) => {
      let images2 = null;
      if (this.state.hoverMode) {
        if (this.state.hoverMode === i) {
          images2 = (
            <div>
              <Card.Body className={classes.card_body}>
                <img
                  className={classes.imgcard}
                  src={s.assets[0].file}
                  alt=""
                />
              </Card.Body>
            </div>
          );
        }
      }
      return <> {images2} </>;
    });
  }

  render() {
    const { sections, dummy, hoverMode, tabIndex } = this.state;
    // console.log(sections)
    let title,
      content,
      name,
      titleContent,
      dummyName,
      dummyContent,
      mainSlider,
      dummyTitleContent,
      imageHover,
      imgHover,
      dummyContentSlider,
      secondSlider,
      contentSlider,
      hoverSlider,
      content2 = null;

    const widgets = this.state.widgets;

    const settings = {
      className: "",
      infinite: true,
      centerPadding: "60px",
      slidesToShow: 3,
      swipeToSlide: true,
      // initialSlide: 0,
      afterChange: function (index) {
        console.log(
          `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        );
      },
    };

    dummyName = (
      <Card.Title className={[classes.card_row_3_title, "mb-2"].join(" ")}>
        {dummy[0].name}
      </Card.Title>
    );

    dummyContent = dummy[0].articles.map((s) => {
      content = (
        <Card.Text className={classes.card_text_3}>
          {ReactHtmlParser(s.content)}
        </Card.Text>
      );
      return <> {content} </>;
    });

    dummyTitleContent = dummy[1].articles.map((s) => {
      titleContent = <p>{s.title}</p>;
      return <> {titleContent} </>;
    });

    dummyContentSlider = dummy[1].articles.map((s) => {
      titleContent = <p>{s.title}</p>;

      return (
        <div className={classes.content_wrap}>
          <p className={classes.paragraph}>{titleContent}</p>
          <p className={classes.paragraph2}>Vicostone</p>
        </div>
      );
    });

    if (this.state.isLoaded) {
      let count = 0;

      sections.forEach((item) => {
        count += 1;
        //console.log("AwardsSection"); console.log(item.sections.url)
        switch (count) {
          case 1:
            if (!item.sections.widget_contents[0]) {
              name = dummyName;
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                name = (
                  <Card.Title
                    className={[classes.card_row_3_title, "mb-2"].join(" ")}
                  >
                    {item.sections.widget_contents[0].content}
                  </Card.Title>
                );
                this.state.sectionTitle =
                  item.sections.widget_contents[0].content;
              } else {
                name = dummyName;
              }
            }
            break;

          case 2:
            //console.log(item)
            if (!item.sections.widget_contents[0]) {
              content2 = dummyContent;
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                content2 = (
                  <Card.Text className={classes.card_text_3}>
                    {ReactHtmlParser(item.sections.widget_contents[0].content)}
                  </Card.Text>
                );
              } else {
                content2 = dummyContent;
              }
            }
            break;

          case 3:
            //console.log(item)
            if (!item.sections.widget_contents[0]) {
              this.state.latestAwardsButtonText = "Button 1";
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                this.state.latestAwardsButtonText =
                  item.sections.widget_contents[0].content;
              } else {
                this.state.allAwardsButtonText = "Button 1";
              }
            }
            break;

          case 4:
            if (!item.sections.widget_contents[0]) {
              this.state.highlightAwardsButtonText = "Button 2";
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                this.state.highlightAwardsButtonText =
                  item.sections.widget_contents[0].content;
              } else {
                this.state.highlightAwardsButtonText = "Button 2";
              }
            }
            break;

          case 5:
            this.state.allAwardsUrl = item.sections.url
              ? item.sections.url
              : "/awards/";
            if (!item.sections.widget_contents[0]) {
              this.state.allAwardsButtonText = "Button 3";
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                this.state.allAwardsButtonText =
                  item.sections.widget_contents[0].content;
              } else {
                this.state.allAwardsButtonText = "Button 3";
              }
            }
            break;

          case 6:
            //console.log(item);
            this.state.awardDetails1 = item.sections.url
              ? item.sections.url
              : "/awards/detail/";
            if (hoverMode) {
              imageHover = this.makeImageHover(this.state.awards_1);
            }

            mainSlider = this.makeSliderElements(
              this.state.awards_1,
              this.state.awardDetails1
            );
            break;

          case 7:
            this.state.awardDetails2 = item.sections.url
              ? item.sections.url
              : "/awards/detail/";

            if (hoverMode) {
              imgHover = this.makeImageHover(this.state.awards_2);
            }

            secondSlider = this.makeSliderElements(
              this.state.awards_2,
              this.state.awardDetails2
            );
            break;
          default:
        }
      });

      switch (tabIndex) {
        case 1:
          contentSlider = mainSlider;
          hoverSlider = imageHover;
          break;
        case 2:
          contentSlider = secondSlider;
          hoverSlider = imgHover;
          break;
        default:
          contentSlider = mainSlider;
          hoverSlider = imageHover;
          break;
      }

      if (!hoverMode) {
        // console.log("test4")
        hoverSlider = (
          <Card.Body className={classes.card_body}>
            <Card.Text className={classes.text_midle}>
              <img
                src={require("../assets/thropybig.svg")}
                className={classes.thropy_big}
                alt=""
              />{" "}
              {this.state.sectionTitle}
            </Card.Text>
          </Card.Body>
        );
      }
    } else if (this.state.isApiLoaded) {
      dummy.forEach((s) => {
        if (s.id === 1) {
          mainSlider = s.articles.map((s) => {
            titleContent = <p>{s.title}</p>;
            return (
              <div className={classes.content_wrap}>
                <p className={classes.paragraph}>{titleContent}</p>
                <p className={classes.paragraph2}>Vicostone</p>
              </div>
            );
          });
        } else if (s.id === 2) {
          name = (
            <Card.Title
              className={[classes.card_row_3_title, "mb-5"].join(" ")}
            >
              {s.name}
            </Card.Title>
          );

          content2 = s.articles.map((s) => {
            content = <p>{s.content}</p>;
            return (
              <Card.Text className={classes.card_text_3}>
                {ReactHtmlParser(s.content)}
              </Card.Text>
            );
          });
        }
      });
    }

    return (
      <div className="section-mobile">
        <div className={[classes.contentMobile, classes.customBox].join(" ")}>
          <div className="container">
            {" "}
            <div className={classes.padTop}>
              <div className={[classes.card_row_3_title, "mb-2"].join(" ")}>
                AWARDS
              </div>
              {content2}
            </div>
            <div className={classes.padTop}>{name}</div>
            <div className={classes.text}>View all</div>
            <div className={classes.boxLatest}>
              <div
                className={classes.lates}
                onClick={() => this.handleSelect(1)}
              >
                <img
                  src={require("../assets/thropy.svg")}
                  className={classes.thropy}
                  alt=""
                />
                {this.state.latestAwardsButtonText}
              </div>
            </div>
            <div className={[classes.contentCenter, "pr-2 pl-2"].join(" ")}>
              <Swiper>{contentSlider}</Swiper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Mobile;

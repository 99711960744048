import React from "react";

import { isMobile  } from "react-device-detect";

import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";

export default function AU_Section2() {
  let content = !isMobile ? <Desktop /> : <Mobile />;
  return <>{content}</>;
}

import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap'

import Aux from '../hoc/Auxs/Auxs'
import NavbarLogo from './NavbarLogo'
import AboutUsLogo from './AboutUsLogo'
import BusinessLogo from './BusinessLogo'
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu'
import SustainLogo from './SustainLogo'

import Api from '../Api'

import classes from "./Navbar.module.css"

class NavigationBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                {
                    assets: require('../assets/logo-en.png')
                }
            ],

            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }
    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        });
    }

    render() {

        let color = this.props.color ? this.props.color : 'transparent'
        let bgClass = this.props.color ? 'navbar-top-transparent' : 'navbar-top'
        let whatPage, burgerColor, from = null;
        burgerColor = this.props.logoColor ? '#fff' : '#223771';
        /*
        if (this.props.onPage && this.props.onPage === 'about-us') {
            whatPage = <AboutUsLogo />
            from = 'about-us'
        } else if (this.props.onPage && this.props.onPage === 'home') {
            whatPage = <NavbarLogo />
            from = 'home'
        } else if (this.props.onPage && this.props.onPage === 'business') {
            whatPage = <BusinessLogo />
            // burgerColor = '#223771'
            from = 'business'
        } else if (this.props.onPage && this.props.onPage === 'innovation') {
            whatPage = <BusinessLogo />
            // burgerColor = '#223771'
            from = 'innovation'
        } else if (this.props.onPage && this.props.onPage === 'sustainable') {
            whatPage = <SustainLogo />
            // burgerColor = '#223771'
            from = 'sustainable'
        } else if (this.props.onPage && this.props.onPage === 'awards') {
            // whatPage = <SustainLogo />
            // burgerColor = '#fff'
            from = 'awards'
        }
        */

        const { dummy, contentSection } = this.state
        let logo, imgSource, selectLanguage = null

        let placeholder, lang, icon, whiteIcon = null

        if (this.state.isLoaded){
            let count = 0
            let lang = localStorage.getItem('language')
            let activeLang = lang==='English' ? 0 : 1
            contentSection.forEach((item, i) => {
                count = count + 1
                if (count === 1 && item.index === 1 && item.category === "Media") {
                    item.sections.widget_contents.forEach((s, index) => {
                        if (activeLang === index){
                            imgSource = s.file
                        }
                        if (index === 2){
                            whiteIcon = s.file;
                        }
                        // imgSource = (s.file ? (s.file[activeLang].file ? s.file[activeLang].file : s.file[0].file) : require('./assets/logo-vn.png')) 
                    });
                } else if (count === 1 && item.index === 1 && item.category !== "Media") {
                    imgSource = dummy[0].assets
                }
                switch (i) {
                    case 1:
                        item.sections.widget_contents.forEach((s) => {
                            placeholder = s.content ? s.content : 'search here...'
                        })
                        break;
                    case 2:
                        item.sections.widget_contents.forEach((s) => {
                            selectLanguage = s.content ? s.content : 'Select Language...'
                        })
                        break;
                                                    
                    case 3:
                        lang = item
                        break;
                    case 4:
                        icon = item
                        break;
                    default:
                        break;
                }
            })

            logo = <Navbar.Brand href="/" className="nav-text"><img className={classes.imgLogo} src={this.props.logoColor ? whiteIcon : imgSource} alt="logo" /></Navbar.Brand>
                
        } else if (this.state.isApiLoaded) {
            imgSource = dummy[0].assets
            logo = <Navbar.Brand href="/" className="nav-text"><img className={classes.imgLogo} src={this.props.logoColor ? whiteIcon : imgSource} alt="logo" /></Navbar.Brand>
        }
        return (
            <Aux>
                <Navbar bg={color} className={[bgClass, classes.navMobile].join(' ')}>
                    {logo}
                    <Nav className="ml-auto">
                        <HamburgerMenu from={from} burgerColor={this.props.logoColor ? '#fff' : '#223771'} items={this.props.items} placeholder={placeholder} selectLanguage={selectLanguage} lang={lang} icon={icon}/>
                    </Nav>
                </Navbar>
            </Aux>
        );
    }
}

export default NavigationBar;
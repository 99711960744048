import React, { Component } from "react";

import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { Slide } from "react-awesome-reveal";

import { Accordion } from 'react-bootstrap'
import AccordionMobile from './Accordion.js'
import Api from "../Api";
import Aux from "../Auxs";
import ArtBox from "../ArtBox";
import classes from "./Mobile.module.css";

class Mobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                { id: 1, name: "title", content: "Lorem Ipsum Lorem Ipsum?" },
                {
                    id: 2,
                    name: "desc",
                    content:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry standard dummy text ever since the 1500s",
                },
                { id: 3, name: "image", assets: require("../assets/default-image.svg") },
                {
                    id: 4,
                    name: "articles",
                    articles: [
                        {
                            name: "image1",
                            title: "Lorem Ipsum",
                            assets: require("../assets/default-image.svg"),
                        },
                        {
                            name: "image2",
                            title: "Lorem Ipsum",
                            assets: require("../assets/default-image.svg"),
                        },
                        {
                            name: "image3",
                            title: "Lorem Ipsum",
                            assets: require("../assets/default-image.svg"),
                        },
                    ],
                },
            ],
            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            boxCount: 0,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    console.log(sections);
                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            name: item.name,
                            index: item.index,
                            category: item.category,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    render() {
        const { contentSection, dummy } = this.state;
        let titleContent,
            contentDesc,
            contentBox,
            contentOverflow,
            contentMobile,
            dummyTitle,
            dummyDesc,
            InternalBox,
            dummyContentBox,
            dummyContentOverflow = null;
        let arr = []

        dummyTitle = <p className={classes.title}>{dummy[0].content}</p>;
        dummyDesc = (
            <div className={classes.boxParent}>
                <Slide triggerOnce direction="top">
                    <div className={classes.boxChild}>
                        <div className={[classes.contentBoxChild].join(" ")}>
                            <p className={[classes.title, classes.mask].join(" ")}>
                                {dummy[1].title}
                            </p>
                            <span className={classes.content}>{dummy[1].content}</span>
                            <Link to={"/article"} className={classes.link}>
                                <p style={{ color: "#223771" }}>
                                    <strong>Read more</strong>
                                </p>
                            </Link>
                        </div>
                    </div>
                </Slide>
            </div>
        );

        dummyContentOverflow = (
            <div className={classes.imgLeft}>
                <Slide triggerOnce direction="top">
                    <img className={classes.imgOverflow} src={dummy[2].assets} alt="" />
                </Slide>
            </div>
        );
        dummyContentBox = dummy[3].articles.forEach((s, i) => {
            // if (i !== 2) {
            //     arr.push({content: s.title, img: require("../assets/default-image.svg"), count: i})
            // } else {
            //     arr.push({content: s.title, img: require("../assets/uploadLogo.svg"), count: i})
            // }
        });

        if (this.state.isLoaded) {
            let count = 0;
            contentSection.forEach((item) => {
                count = count + 1;
                if (count === 1 && item.index === 1 && item.category === "Text") {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            titleContent = <p className={classes.title}>{s.content}</p>
                        } else {
                            titleContent = dummyTitle;
                        }
                    });
                } else if (count === 1 && item.index === 1 && item.category !== "Text") {
                    titleContent = dummyTitle;
                }

                if (count === 2 && item.index === 2 && item.category === "Article") {
                    contentDesc = item.sections.is_internal ? <>{InternalBox}</>
                    : 
                    <ArtBox id={item.sections.id} url={item.sections.url} name={item.sections.name} />
                    
                    InternalBox = item.sections.widget_contents.forEach((s, i) => {
                        let isImage = s.assets[0] ? 
                            s.assets[0].file
                            : 
                            require("../assets/default-image.svg")
                        
                        arr.push({content: s.title, img: isImage, count: 0})
                    });
                } else if (count === 2 && item.index === 2 && item.category !== "Article") {
                    contentDesc = dummyDesc;
                    contentOverflow = dummyContentOverflow;
                }

                if (count === 3 && item.index === 3 && item.category === "Article") {
                    // contentBox = <AccordionMobile content={item.sections.widget_contents} />
                    contentBox = item.sections.widget_contents.map((s, i) => {
                        let content = null;
                        if (i !== 2) {
                            let isImage = s.assets[0] ?
                                s.assets[0].file
                                :
                                require("../assets/default-image.svg")

                            arr.push({content: s.title, img: isImage, count: i+1})
                            // content = <AccordionMobile content={s.title} img={isImage} count={i+1} />
                        } else {
                            arr.push({content: s.title, img: require("../assets/uploadLogo.svg"), count: i+1})
                            // content = <AccordionMobile content={s.title} img={require("../assets/uploadLogo.svg")} count={i+1}/>
                        }
                        return <>{content}</>;
                    });
                } else if (count === 3 && item.index === 3 && item.category !== "Article") {
                    contentBox = dummyContentBox;
                }
            });
            contentMobile = <AccordionMobile data={arr}/>
        } else if (this.state.isApiLoaded) {
            titleContent = dummyTitle;
            contentDesc = dummyDesc;
            contentBox = dummyContentBox;
            contentOverflow = dummyContentOverflow;
        }
        return (
            <div className={classes.contentSection}>
                <div className={classes.titleGroup}>
                    {titleContent}
                    <p className={classes.subtitle}>Tiên phong sáng tạo vì cuộc sống tốt đẹp hơn</p>
                </div>
                <div className={classes.contentGroup}>
                    {contentMobile}
                </div>
            </div>
        );
    }
}
export default Mobile;

import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom'

import Site from "./pages/Site/Site"

class App extends Component {
  render() {
    return (
      <div className="wrapper">
        <BrowserRouter>
          <Site />
        </BrowserRouter>
      </div>
    );
  }

}

export default App;

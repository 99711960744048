import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser';
import Swiper from 'react-id-swiper';

// import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
// import 'swiper/swiper.scss';
// import 'swiper/components/navigation/navigation.scss';
// import 'swiper/components/pagination/pagination.scss';

import Api from '../Api'

import classes from './Mobile.module.css'
import 'swiper/swiper.scss'
// SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
export default class Mobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: null,
            dummy: [
                { id: 1, name: 'title', content: 'Lorem Ipsum Lorem Ipsum?' },
                { id: 2, name: 'subtitle', content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry standard dummy text ever since the 1500s' },
                {
                    id: 3, name: 'slider',
                    articles: [
                        {
                            name: 'Slider1',
                            title: 'What is Lorem Ipsum?',
                            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
                            assets: require('../assets/default-image.svg')
                        },
                        {
                            name: 'Slider2',
                            title: 'What is Lorem Ipsum?',
                            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
                            assets: require('../assets/default-image.svg')
                        },
                        {
                            name: 'Slider3',
                            title: 'What is Lorem Ipsum?',
                            content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book',
                            assets: require('../assets/default-image.svg')
                        }
                    ]
                }
            ],
            sections: {
                widgets: []
            },
            isLoaded: false,
            isApiLoaded: false,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ sections: [] }, () => {
            apiData.getSectionDetail()
                .then(result => {
                    this.setState({
                        isApiLoaded: true
                    })
                    const sections = result.data
                    const widget = []
                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item
                        })
                    })
                    sections.page = widget
                    this.setState({
                        sections: widget,
                        isLoaded: true
                    })
                })
                .catch(error => {
                    // console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        })
    }
    render() {
        const { sections, dummy } = this.state
        let titleContent, contentDesc, contentSlider, dummyTitle, dummyDesc, dummySlider = null

        dummyTitle = <p className={classes.title}>{dummy[0].content}</p>
        dummyDesc = <p className={classes.content}>{dummy[1].content}</p>

        dummySlider = dummy[2].articles.map((s, i) => {
            let isImage = s.assets ? <img className={classes.image} src={s.assets} alt="" /> : <img className={classes.imageDefault} src={require('../assets/default-image.svg')} alt="" />
            return (
                <div key={s.title + i}>
                    <div className={classes.box}>
                        <p className={classes.titleContent}>Lorem Ipsum</p>
                        <span className={classes.contentD}>
                            định hướng trở thành một Tập đoàn công nghệ và công nghiệp hàng đầu về các giải pháp thông minh và sản xuất thông minh.
                        </span>
                    </div>
                </div>
            )
        })

        if (this.state.isLoaded) {
            let count = 0
            sections.forEach((item) => {
                count = count + 1
                if (count === 1 && item.index === 1 && item.category === "Text") {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            titleContent = <p className={classes.title}>{s.content}</p>
                        } else {
                            titleContent = dummyTitle
                        }
                    })
                } else if (count === 1 && item.index === 1 && item.category !== "Text") {
                    titleContent = dummyTitle
                }

                if (count === 2 && item.index === 2 && item.category === "Text") {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            contentDesc = <p className={classes.content}>{s.content}</p>
                        } else {
                            contentDesc = dummyDesc
                        }
                    })
                } else if (count === 2 && item.index === 2 && item.category !== "Text") {
                    contentDesc = dummyDesc
                }

                if (count === 3 && item.index === 3 && item.category === "Article") {
                    contentSlider = item.sections.widget_contents.map(s => {
                        // let isImage= s.assets[0] ? 'ada' : 'tidak ada'
                        let isImage = s.assets[0] ? <img className={classes.image} src={s.assets[0].file} alt="" /> : <img className={classes.imageDefault} src={require('../assets/default-image.svg')} alt="" />
                        return (
                            // <SwiperSlide key={s.title}>
                                <div key={s.title} className={classes.box}>
                                    <p className={classes.titleContent}>{s.title}</p>
                                    <span className={classes.contentD}>{ReactHtmlParser(s.content)}</span>
                                </div>
                            // </SwiperSlide>
                        )
                    })
                } else if (count === 3 && item.index === 3 && item.category !== "Article") {
                    contentSlider = dummySlider
                }

            })
        } else if (this.state.isApiLoaded) {
            titleContent = dummyTitle
            contentDesc = dummyDesc
            contentSlider = dummySlider
        }

        const params = {
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                dynamicBullets: true
            }
        }

        return (
            <div className="section-mobile">
                <div className={classes.contentWraping}>
                    <div className={[classes.contentMobile, classes.customBox].join(' ')}>
                        {titleContent}
                        <div className={classes.contentCenter}>
                            {contentDesc}
                        </div>

                    </div>
                    <div className={classes.contentMobile}>
                        <div className={[classes.contentCenter, 'pr-2 pl-2'].join(' ')}>
                            {/* <Swiper
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}>
                                {contentSlider}
                            </Swiper> */}
                            <Swiper>
                                {contentSlider}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

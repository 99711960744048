import React, { Component, createRef } from "react";

import Menu from '../../sections/Menu/Menu'
import Logo from '../../sections/Logo/Logo'
// import Header from "../../sections/Header/Header";
import MeshComponent from "../../components/3D/House of Phenikaa/MeshComponent"
import Section1 from "../../sections/NO_Section1/NO_Section1";

import Footer from "../../sections/Footer/Footer";
import { ParallaxProvider } from "react-scroll-parallax";

import styled from "styled-components";

const CustomDiv = styled.div`
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
`;
export default class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: null,
            
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="news-page">
                <Logo onPage={"news"}/>
                <Menu onPage={"news"} />
                {/* <MeshComponent/> */}
                <CustomDiv>
                    <ParallaxProvider>
                        <React.Fragment>

                            <div ref={this.section1}>
                                <Section1 />
                            </div>
                        </React.Fragment>
                    </ParallaxProvider>
                </CustomDiv>
                <Footer />
            </div>
        );
    }
}

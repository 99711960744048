import React, { Component } from "react";
import Api from "./Api";
import classes from "./Action.module.css";
import Article from "./Article";
import { Link } from "react-router-dom";

class SD_Section6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        {
          id: 1,
          name: "Article",
          title: "Lorem ipsum dummy title",
          content: "Lorem ipsum dummy data...",
          //   asset: require("./assets/default-image.svg"),
        },
      ],
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];

          console.log(sections.widgets[5].widget_contents);

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              name: item.name,
              index: item.index,
              type: item.is_internal,
              category: item.category,
              url: item.url,
              click: sections.widgets[5].widget_contents,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {
    const { contentSection, dummy, isLoaded } = this.state;

    let title_content,
      data_content,
      article_box1,
      article_box2,
      article_box3,
      article_content,
      dummy_content_box,
      dummy_title_content,
      dummy_data_content = null;

    dummy_title_content = <p className={classes.title}>XXX</p>;
    dummy_data_content = <p className={classes.contentTextBottom}>DUMMY</p>;
    dummy_content_box = (
      <div className={classes.box_1}>
        <div className={classes.box_hover}>
          <img
            className={classes.img_box}
            src={require("./assets/default-image.svg")}
            alt=" "
          />
          {/* <img
            className={classes.img_box}
            src={
              article.assets.length > 0
                ? article.assets[0].file
                : require("../assets/default-image.svg")
            }
            alt=" "
          /> */}
          {/* <div
            className={[classes._overlay, classes._absolute].join(" ")}
          ></div>
          <div className={classes._absolute}>
            <div className={classes._content}>
              <div className={classes._box}>
                <p className={classes.customUnderline}>{title}</p>
                <div className={classes.contentBottomTitle}>
                  <div className={classes.outerCircle2}>
                    <div className={classes.innerCircle2}></div>
                  </div>
                  <Link to={path} className={classes.link1}>
                    <span className={classes.readMore2}>{readMore}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className={classes.boxBtm}>
          <div className={classes._box}>
            <p className={classes.tx}>Title Content</p>
          </div>
          <div className={classes.outerCircle2}>
            <div className={classes.innerCircle2}></div>
          </div>
          <Link to={"/"} className={classes.link1}>
            <span className={classes.readMore2}>Read More</span>
          </Link>
        </div>
      </div>
    );

    if (isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((ele) => {
            if (ele.content !== "") {
              title_content = <p className={classes.title}>{ele.content}</p>;
            } else {
              title_content = dummy_title_content;
            }
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Text"
        ) {
          title_content = dummy_title_content;
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          item.sections.widget_contents.forEach((ele) => {
            if (ele.content !== "") {
              data_content = (
                <p className={classes.contentTextBottom}>{ele.content}</p>
              );
            }
          });
        } else if (
          count === 2 &&
          item.index === 2 &&
          item.category !== "Text"
        ) {
          data_content = dummy_data_content;
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          if (item.type === false) {
            article_box1 = (
              <Article id={item.id} url={item.url} click={item.click} />
            );
          }
        } else if (
          count === 3 &&
          item.index === 3 &&
          item.category !== "Article"
        ) {
          article_box2 = dummy_content_box;
        }
        if (count === 4 && item.index === 4 && item.category === "Article") {
          if (item.type === false) {
            article_box2 = (
              <Article id={item.id} url={item.url} click={item.click} />
            );
          }
        }
        if (count === 5 && item.index === 5 && item.category === "Article") {
          if (item.type === false) {
            article_box3 = (
              <Article id={item.id} url={item.url} click={item.click} />
            );
          }
        }
        if (count === 6 && item.index === 6 && item.category === "Text") {
          console.log(item);
        }
      });
    } else if (this.state.isApiLoaded) {
      title_content = dummy_title_content;
      data_content = dummy_data_content;
      article_box1 = dummy_content_box;
      article_box2 = dummy_content_box;
      article_box3 = dummy_content_box;
    }

    return (
      <div className={classes.Section6}>
        <div
          className={["container", classes.boxC, classes.customContainer].join(
            " "
          )}
        >
          <div>
            {title_content}
            {data_content}
            <div className={classes.flexDir}>
              {article_box1}
              {article_box2}
              {article_box3}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SD_Section6;

import React, { Component } from 'react';
import styled from 'styled-components';
import MegaMenuItems from './MegaMenuItems/MegaMenuItems'
import LanguageItems from './MegaMenuItems/LanguageItems/LanguageItems'

import Api from '../Api'

import classes from './MegaMenu.module.css'

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;

  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  clip-path: ${({ open }) => open ? 'circle(100% at 50% 0%)' : 'circle(0% at 95.2% 2.7rem)'};
  transition: clip-path 1s, opacity 0.5s;

  height: ${({ open }) => open ? "100vh" : "0"};
  width: ${({ open }) => open ? "100vw" : "0"};
  text-align: left;
  z-index: ${({ open }) => open ? "1" : "-1"};
  padding: 2rem;
  position: absolute;
  top: 0;
  right: 0;

  @media only screen and (max-width: 420px) {
    clip-path: ${({ open }) => open ? 'circle(100% at 50% 30%)' : 'circle(0% at 76% 6%)'};
  }
  @media only screen and (max-width: 360px) {
    clip-path: ${({ open }) => open ? 'circle(100% at 50% 30%)' : 'circle(0% at 88% 6%)'};
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 0rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0D0C1D;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
`
export default class MegaMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
        }
        this.keyPress = this.keyPress.bind(this)
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        });
    }

    keyPress(e) {
        const currentUrl = window.location.href
        const pathname = (window.location.pathname)
        if (e.keyCode === 13) {
            if (pathname === "/") {
                window.location.href = currentUrl + `search/?search=${e.target.value}`
            } else if (pathname === "/search/") {
                window.location.href = currentUrl + `search/?search=${e.target.value}`
            } else {
                window.location.href = currentUrl.replace(pathname, "/") + `search/?search=${e.target.value}`
            }
            // window.location.href = currentUrl + `search/?search=${e.target.value}`
            // const param = `/search/?search=${e.target.value}`
            // console.log(protocol)
            // window.location.href = protocol + '://' + hostname + (port ? ':' + port + param : param)
        }
    }

    render() {
        const { contentSection } = this.state

        let placeholder, selectLanguage, lang, icon = null

        if (this.state.isLoaded) {
            contentSection.forEach((item, i) => {
                switch (i) {
                    case 0:
                        item.sections.widget_contents.forEach((s) => {
                            placeholder = s.content ? s.content : 'search here...'
                        })
                        break;
                    case 1:
                        item.sections.widget_contents.forEach((s) => {
                            selectLanguage = s.content ? s.content : 'Select Language...'
                        })
                        break;
                    case 2:
                        lang = item
                        break;
                    case 3:
                        icon = item
                        break;
                    default:
                        break;
                }
            })
        } else if (this.state.isApiLoaded) {
            placeholder = 'Search here'
            lang = false
        }

        return (
            <StyledMenu open={this.props.open}>
                <div style={{ flex: 'none' }} className='col-md-3'>
                </div>
                <div className='row'>
                    <div style={{ left: '8vw' }} className={["col-md-6", classes.itemList].join(' ')}>
                        <div className={["input-group", classes.customSearch].join(' ')}>
                            <input onKeyDown={this.keyPress} type="text" autoComplete="off" id="btnSearch" placeholder={placeholder} />
                            <i className={["fa fa-search fa-sm", classes.faCustom].join(' ')} aria-hidden="true"></i>
                        </div>
                        <MegaMenuItems items={this.props.items} />
                        <LanguageItems lang={lang} icon={icon} selectLanguage={selectLanguage}/>
                    </div>
                </div>
            </StyledMenu>
        )
    }

}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Api from './Api'
import Blog from './Blog'

import { Accordion } from "react-bootstrap";
import ContextAwareToggle from "./Accordion/Accordion";

import styled from "styled-components";
import classes from "./Blog.module.scss";

const CustomDiv = styled.div`
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
`;
class Blogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                {
                    id: 1,
                    name: "article",
                    articles: [
                        {
                            name: "content1",
                            author: "John doe x Jane doe",
                            title:
                                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
                            content:
                                "From 8h30 to 12h30, the series of event start with the International Workshop on Frontier Materials and Nanotechnology (IWFMT 2018).",
                            assets: require("./assets/default-image.svg"),
                            date: "Monday, September 7, 2020",
                        },
                        {
                            name: "content2",
                            author: "John doe x Jane doe",
                            title:
                                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
                            content:
                                "From 8h30 to 12h30, the series of event start with the International Workshop on Frontier Materials and Nanotechnology (IWFMT 2018).",
                            assets: require("./assets/default-image.svg"),
                            date: "Monday, September 7, 2020",
                        },
                        {
                            name: "content3",
                            author: "John doe x Jane doe",
                            title:
                                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
                            content:
                                "From 8h30 to 12h30, the series of event start with the International Workshop on Frontier Materials and Nanotechnology (IWFMT 2018).",
                            assets: require("./assets/default-image.svg"),
                            date: "Monday, September 7, 2020",
                        },
                    ],
                },
            ],
            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
            idBlogs: "",
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        // console.log(this.props.location.state)
        window.scrollTo(0, 0);
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            url: item.url,
                            internal: item.is_internal,
                            name: item.name,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                        idBlogs: widget[0].id,
                    });
                })
                .catch((error) => {
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    render() {
        const { dummy, contentSection, articleCategories } = this.state;
        let dummyBlog,
            BlogPost,
            filter = null;

        dummyBlog = dummy[0].articles.map((s, i) => {
            return (
                <div className={classes.boxBlog}>
                    <div className={classes.boxBody}>
                        <p className={classes.blogTitle}>{s.title}</p>
                        <p className={classes.blogContent}>{s.content}</p>
                    </div>
                    <div className={classes.boxFooter}>
                        <Link to={`/innovation/blogs/detail?id=${i}`}>
                            <p className={classes.blogLink}>Read more</p>
                        </Link>
                        <div>
                            <p className={classes.blogAuthor}>{s.author}</p>
                            <p className={classes.blogDate}>{s.date}</p>
                        </div>
                    </div>
                    <img
                        className={classes.imgProfile}
                        src={require("./assets/profile.jpg")}
                        alt=""
                    ></img>
                </div>
            );
        });

        let textFilter, textSearch, textCategory, textReadmore, textLoadmore
        if (this.state.isLoaded) {
            contentSection.forEach((item, i) => {
                switch (i) {
                    case 0:
                        item.sections.widget_contents.forEach(s => {
                            textFilter = s.content ? s.content : 'filter'
                        })
                        break;
                    case 1:
                        item.sections.widget_contents.forEach(s => {
                            textSearch = s.content ? s.content : 'Search here...'
                        })
                        break;
                    case 2:
                        let arr = []
                        item.sections.widget_contents.forEach(s => {
                            arr.push(s.content ? s.content : 'Category')
                        })
                        textCategory = arr
                        break;
                    case 3:
                        item.sections.widget_contents.forEach(s => {
                            textReadmore = s.content ? s.content : 'Read more'
                        })
                        break;
                    case 4:
                        item.sections.widget_contents.forEach(s => {
                            textLoadmore = s.content ? s.content : 'Load more'
                        })
                        break;
                    case 5:
                        BlogPost = item.internal ?
                            <></>
                            :
                            <Blog category={item.sections.article_categories} id={item.id} url={item.url} filter={textFilter} search={textSearch} arrCategory={textCategory} readmore={textReadmore} loadmore={textLoadmore} />
                        break;
                    default:
                        break;
                }


            });
        } else if (this.state.isApiLoaded) {
            BlogPost = dummyBlog;
        }
        return (
            <>
                <CustomDiv>
                    {BlogPost}
                </CustomDiv>
            </>
        )
    }
}

export default Blogs;

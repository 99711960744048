import React, { Suspense, useCallback, useRef, useEffect, useState, useMemo, Component } from 'react'
import { Canvas, useFrame, useLoader, useThree } from 'react-three-fiber'
import * as THREE from 'three'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import worldImg from './assets/earth_atmos_2048.jpg'
import cloudImg from './assets/output-onlinepngtools.png'
import moonImg from './assets/2k_moon.jpg'

import { CubeTextureLoader, MeshToonMaterial } from "three";
import { text } from '@fortawesome/fontawesome-svg-core';


import img1 from './assets/1.jpg'
import img2 from './assets/2.jpg'
import img3 from './assets/3.jpg'
import img4 from './assets/4.jpg'
import img5 from './assets/5.jpg'
import img6 from './assets/6.jpg'

import space from './assets/test_1.jpg'


import back from './assets/back.png'
import front from './assets/front.png'
import left from './assets/left.png'
import right from './assets/right.png'
import top from './assets/top.png'
import bottom from './assets/bottom.png'

function BigSkyBox(props){

    const mesh = useRef()
    const texture = useLoader(THREE.TextureLoader, './assets/earth_normal_2048.jpg')

    const loader = new CubeTextureLoader();
    const textureImages = loader.load([
    //const textureImages = useLoader(THREE.TextureLoader, [
     img1, img2, img3, img4, img5, img6
     ]);

      useFrame(() => (mesh.current.rotation.y += 0.0002))

      console.log(texture)

    return (
        <mesh
            {...props}
            ref={mesh}
            scale={[1, 1, 1]}
            >
            <boxBufferGeometry args={[30, 30, 30]}/>
            {/* <meshStandardMaterial color={props.color} /> */}
            <meshStandardMaterial attach="material" map={texture} transparent={true} side={THREE.BackSide}/>

        </mesh>
    )

}

function SphereWithTextureCenter(props) {
    // This reference will give us direct access to the mesh
    const mesh = useRef()

    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)

    //`console.log("Loading texture")
    //try {
    const texture = useLoader(THREE.TextureLoader, props.texture)

    useFrame(() => (mesh.current.rotation.y += (0.0002*props.rotationalSpeed)))


    return (
        <mesh
            {...props}
            ref={mesh}
            scale={[1, 1, 1]}
            onClick={(e) => setActive(!active)}
            onPointerOver={(e) => setHover(true)}
            onPointerOut={(e) => setHover(false)}
        >
            <sphereBufferGeometry args={[props.scaleFactor, 100, 100]}/>
            {/* <meshStandardMaterial color={props.color} /> */}
            <meshStandardMaterial attach="material" map={texture} transparent={true}/>

        </mesh>
    )
}


function SphereWithTextureCirculating(props) {
    // This reference will give us direct access to the mesh
    const mesh = useRef()

    // Set up state for the hovered and active state
    const [hovered, setHover] = useState(false)
    const [active, setActive] = useState(false)

    //`console.log("Loading texture")
    //try {
    const texture = useLoader(THREE.TextureLoader, props.texture)

    useFrame((delta) => (
        //console.log(delta.clock.elapsedTime),
        mesh.current.rotation.y += (0.001*props.rotationalSpeed),
        mesh.current.position.x = props.rotateAround[0]+(Math.cos(delta.clock.elapsedTime/25)*10),
        mesh.current.position.y = props.rotateAround[1]+(Math.sin(delta.clock.elapsedTime/25)*(2.5)),
        mesh.current.position.z = props.rotateAround[2]+(Math.sin(delta.clock.elapsedTime/25)*6)
        ))


    return (
        <mesh
            {...props}
            ref={mesh}
            scale={[1, 1, 1]}
            onClick={(e) => setActive(!active)}
            onPointerOver={(e) => setHover(true)}
            onPointerOut={(e) => setHover(false)}
        >
            <sphereBufferGeometry args={[props.scaleFactor, 100, 100]}/>
            {/* <meshStandardMaterial color={props.color} /> */}
            <meshStandardMaterial attach="material" map={texture} transparent={true}/>

        </mesh>
    )
}


/*
function Camera(props) {
    const ref = useRef()
    const { setDefaultCamera } = useThree()
    // Make the camera known to the system
    useEffect(() => void setDefaultCamera(ref.current), [])
    // Update it every frame
    useFrame(() => ref.current.updateMatrixWorld())
    return <perspectiveCamera ref={ref} {...props} />
  }
  */

 function SkyBox() {
    const { scene } = useThree();
    const loader = new CubeTextureLoader();
    const textureImages = loader.load([
//        img1, img2, img3, img4, img5, img6
//        right, left, top, bottom, front, back
        space, space, space, space, space, space
    ]);        
    //   "./assets/1.jpg",
    //   "./assets/2.jpg",
    //   "./assets/3.jpg",
    //   "./assets/4.jpg",
    //   "./assets/5.jpg",
    //   "./assets/6.jpg"


    //]);
    // Set the scene background property to the resulting texture.
    scene.background = textureImages;
    //console.log("Making the background thing")
    //console.log(scene)
    //console.log(textureImages)
    return null;
  }

  const CameraControls = () => {
      var {camera, gl} = useThree()
      //camera.set
      //camera.setFocalLength(6)
      camera.setLens(7, 9)
      //const camera = new THREE.OrthographicCamera(-distance * aspect, distance * aspect, distance, -distance, -1, -1000)
      //camera.position.set(-10, 10, 10)
      //setDefaultCamera(camera)

      useEffect(() => {
      camera.position.set(0,0,30)
      //const controls = new OrbitControls(camera, gl.domElement);
      //controls.minDistance = 3;
      //controls.maxDistance = 20;
      return () => {
          //controls.dispose();
      };

    }, [camera, gl]);
    return null;      
  }

function MeshComponent () {

        var x = -9;
        var y = -2;
        var z = 3;

        var position = [x,y,z];

        // if(window.innerHeight > 2000){
        //     position = [x,0,z]
        // } else if (window.innerHeight > 1500){
        //     position = [x,0,z]
        // } else if (window.innerHeight > 1400){
        //     position = [x,1,z]
        // } else if (window.innerHeight > 1000){
        //     position = [x,0,z]
        // } else if (window.innerHeight > 700){
        //     position = [x,6.5,z]
        // } else {
        //     position = [x,8,z]
        // }

        const mouse = useRef([0, 0])
        const mouseDown = useRef(false)
        var earthSpeed = 2
        var cloudSpeed = earthSpeed - (earthSpeed/4);

        const onMouseMove = useCallback(({ clientX: x, clientY: y }) => (mouse.current = [x - window.innerWidth / 2, y - window.innerHeight / 2]), [])
        const onMouseDown = useCallback(({}) => (mouseDown.current = true), [])
        const onMouseUp = useCallback(({}) => (mouseDown.current = false), [])
        const onMouseEvent = useCallback((mouseEvent) => {
                if(mouseDown.current){
                    earthSpeed = 0
                    //console.log(mouseEvent.clientX, mouseEvent.clientY)
                    //console.log(clientX, clientY, mouseDown.current)
                    mouse.current = [mouseEvent.clientX - window.innerWidth / 2, mouseEvent.clientY - window.innerHeight / 2]
                    // mouse.current.instanceMatrix.needsUpdate = true
                    // this.setState({reRender: true});
                }
        }, []);
        //console.log(mouse.current[0])
        return (
            <div style={{ width: '100%', height: '100%' }} onMouseMove={onMouseEvent} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
                <Canvas style={{width: '100%', height: '100%'}}>
                    <Suspense fallback={<>loading...</>}>
                        {/* <Camera position={[0, 0, 10]} /> */}
                        <SkyBox/>
                        <CameraControls/>
                        <ambientLight />
                        <pointLight position={[10, 10, 10]} />
                        <SphereWithTextureCirculating position={[7, 0, -3]} texture={moonImg} scaleFactor={0.75} rotationalSpeed={cloudSpeed} rotateAround={position}/>
                        <SphereWithTextureCenter position={position} texture={worldImg} scaleFactor={4.990} rotationalSpeed={earthSpeed}/>
                        <SphereWithTextureCenter position={position} texture={cloudImg} scaleFactor={5} rotationalSpeed={cloudSpeed}/>
                        {/* <BigSkyBox /> */}
                    </Suspense>
                </Canvas>
            </div>
        )
    
}
export default MeshComponent;

import React, { Component } from "react";
import Header from "../../sections/Header/Header";
import classes from "./stakeholders.module.css";
import Footer from "../../sections/Footer/Footer";
import Api from "./Api";
import ReactHtmlParser from "react-html-parser";
import SD_Stakeholders from '../../sections/SD_Stakeholders/SD_Stakeholders';

import { ParallaxProvider } from 'react-scroll-parallax';

import styled from "styled-components";

const CustomDiv = styled.div`
    min-height: 100vh;
    background: white;
    position: relative;
    z-index: 1;
    box-shadow: 0px 3px 6px #00000029;
`;
class stakeholders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: <div></div>,

      data: [
        { id: 1, title: "Workers" },
        { id: 2, title: "Customer" },
        { id: 3, title: "Student, parents" },
        { id: 4, title: "Partners and suppliers" },
        { id: 5, title: "Social community" },
        { id: 6, title: "House Agencies" },
        { id: 7, title: "Country" },
        { id: 8, title: "Shareholders and Investors" },
        { id: 9, title: "Press news agency" },
      ],
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: true,
      activeSidebar: 0,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  changeActiveSidebar(key) {
    this.setState({ activeSidebar: key });
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { contentSection, data } = this.state;

    let stakeholderSidebar,
      content,
      content2,
      box1,
      box2,
      box3,
      box4,
      title,
      dummy_title,
      desc,
      stakeee,
      dummy_desc,
      dummy_stakeholderSidebar,
      dummy_box1,
      dummy_box2 = null;

    dummy_title = <p className={classes.title}>Lorem ipsum...</p>;
    dummy_desc = <p className={classes.text}>Content text dummy data...</p>;
    dummy_stakeholderSidebar = this.state.data.map((ele, index) => {
      let textChange = null;
      let circle = null;
      if (index === this.state.activeSidebar) {
        textChange = classes.textChange;
        circle = classes.activeCircle;
      }
      return (
        <div className={classes.boxStake}>
          <div
            className="d-flex"
            onClick={() => this.changeActiveSidebar(index)}
          >
            <div className={[classes.outerCircle, circle].join(" ")}>
              <div className={classes.innerCircle}></div>
            </div>
            <p className={[classes.contentLink, textChange].join(" ")}>
              {ele.title}
            </p>
          </div>
        </div>
      );
    });

    dummy_box1 = (
      <div className={classes.box_1}>
        <p className={classes.mainRead}>Lorem ipsum...</p>
        <p className={classes.secondRead}>Lorem ipsum data</p>
      </div>
    );
    dummy_box2 = (
      <div className={classes.box_2}>
        <p className={classes.mainRead}>Lorem ipsum...</p>
        <p className={classes.secondRead}>Lorem ipsum data</p>
      </div>
    );

    if (this.state.isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;

        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((ele) => {
            title = <p className={classes.title}>{ele.content}</p>;
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Text"
        ) {
          title = dummy_title;
        }
        if (count === 2 && item.index === 2 && item.category === "Text") {
          item.sections.widget_contents.forEach((ele) => {
            desc = <p className={classes.text}>{ele.content}</p>;
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Text"
        ) {
          title = dummy_desc;
        }
        if (count === 3 && item.index === 3 && item.category === "Text") {
          const dataStake = [];
          stakeholderSidebar = item.sections.widget_contents.forEach(
            (ele, index) => {
              dataStake.push(ele.content);

              //console.log(dataStake);
            }
          );

          stakeee = dataStake.map((z, index) => {
            let textChange = null;
            let circle = null;
            if (index === this.state.activeSidebar) {
              textChange = classes.textChange;
              circle = classes.activeCircle;
            }

            return (
              <div className={classes.boxStake}>
                <div
                  className="d-flex"
                  onClick={() => this.changeActiveSidebar(index)}
                >
                  <div className={[classes.outerCircle, circle].join(" ")}>
                    <div className={classes.innerCircle}></div>
                  </div>
                  <p className={[classes.contentLink, textChange].join(" ")}>
                    {z}
                  </p>
                </div>
              </div>
            );
          });
        } else if (
          count === 3 &&
          item.index === 3 &&
          item.category !== "Text"
        ) {
          stakeholderSidebar = dummy_stakeholderSidebar;
        }
        if (count === 4 && item.index === 4 && item.category === "Article") {
          item.sections.widget_contents.forEach((ele, index) => {
            box1 = (
              <div className={classes.box_1}>
                <p className={classes.mainRead}>{ele.title}</p>
                <p className={classes.secondRead}>
                  {ReactHtmlParser(ele.content)}
                </p>
              </div>
            );
          });
        } else if (
          count === 4 &&
          item.index === 4 &&
          item.category !== "Article"
        ) {
          box1 = dummy_box1;
        }

        if (count === 5 && item.index === 5 && item.category === "Article") {
          item.sections.widget_contents.forEach((ele, index) => {
            box2 = (
              <div className={classes.box_2}>
                <p className={classes.mainRead}>{ele.title}</p>
                <p className={classes.secondRead}>
                  {ReactHtmlParser(ele.content)}
                </p>
              </div>
            );
          });
        } else if (
          count === 5 &&
          item.index === 5 &&
          item.category !== "Article"
        ) {
          box2 = dummy_box2;
        }
      });
    }
    box3 = (
      <div className={classes.box_1}>
        <p className={classes.mainRead}>Concerns of stakeholders</p>
        <p className={classes.secondRead}>• Lorem Ipsum dummy text</p>

        <p className={classes.secondRead}>• Lorem Ipsum dummy text</p>

        <p className={classes.secondRead}>• Lorem Ipsum dummy text</p>

        <p className={classes.secondRead}>• Lorem Ipsum dummy text</p>
      </div>
    );

    box4 = (
      <div className={classes.box_2}>
        <p className={classes.mainRead}>Phenikaa's goals and actions</p>
        <p className={classes.secondRead}>• Lorem Ipsum dummy text</p>

        <p className={classes.secondRead}>• Lorem Ipsum dummy text</p>

        <p className={classes.secondRead}>• Lorem Ipsum dummy text</p>

        <p className={classes.secondRead}>• Lorem Ipsum dummy text</p>
        <p className={classes.secondRead}>• Lorem Ipsum dummy text</p>
      </div>
    );

    content = (
      <div className="d-flex">
        {box1}
        {box2}
      </div>
    );

    content2 = (
      <div className="d-flex">
        {box3}
        {box4}
      </div>
    );

    if (this.state.activeSidebar === 0) {
      content = content;
    } else if (this.state.activeSidebar === 1) {
      content = content2;
    } else if (this.state.activeSidebar % 2 === 0) {
      content = content;
    }


    return (
      <div className="" style={{}}>
        <Header onPage="sustainable" />

        <CustomDiv>
          <ParallaxProvider >

            <React.Fragment>
              <SD_Stakeholders />

            </React.Fragment>
          </ParallaxProvider>
        </CustomDiv>

        {/* <Footer /> */}
      </div>
    );

    // return (
    //   <div className="" style={{}}>
    //     <Header onPage="sustainable" />
    //     <div className={classes.stake}>
    //       <div className={classes.overlayBackground}>
    //         <img
    //           className={classes.imgBackground}
    //           src={require("./assets/Image147.png")}
    //         />
    //       </div>
    //       <div className={["container", classes.boxC].join(" ")}>
    //         <div className={classes.boxTitle}>{title}</div>
    //         <div className={classes.boxText}>{desc}</div>

    //         <div className={classes.flex_0}>
    //           <div className={classes.box_0}>
    //             <p className={classes.mainRead}>Stakeholders</p>
    //             {stakeee}
    //           </div>
    //           {content}
    //         </div>
    //       </div>
    //     </div>
    //     <Footer />
    //   </div>
    // );
  }
}

export default stakeholders;

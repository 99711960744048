import React, { Component, createRef } from "react";

import SectionShortcut from './SectionShortcut/SectionShortcut'
import HomeSectionShortcut from './SectionShortcut/HomeSectionShortcut'

import Logo from '../../sections/Logo/Logo'
import Menu from '../../sections/Menu/Menu'
import Footer from "../../sections/Footer/Footer";

import BOD_Section1 from "../../sections/BOD_Section1/BOD_Section1";
import BOD_Section2 from "../../sections/BOD_Section2/BOD_Section2";

import classes from './BOD.module.css';


class BOD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      section: [
        { id: 1, title: 'Main' },
        { id: 2, title: 'Hội đồng Quản trị' },
        { id: 3, title: 'Ban Điều hành' },
      ]
    };
  }

  section1 = createRef()
  section2 = createRef()
  section3 = createRef()

  scrolltoSectionHandler = (key) => {
    if (key === 'section1') {
        this.section1.current.scrollIntoView({ behavior: "smooth" });
    } else if (key === 'section2') {
        this.section2.current.scrollIntoView({ behavior: "smooth" });
    } else if (key === 'section3') {
        this.section3.current.scrollIntoView({ behavior: "smooth" });
    } 
};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    let sec = this.state.section.map((s => {
        let ref = 'section' + s.id
        return (
            <SectionShortcut
                key={s.id}
                title={s.title}
                onClick={() => this.scrolltoSectionHandler(ref)}
            />
        );
    }));

    let homeSectionShortcut = <HomeSectionShortcut section={sec} />;
    return (
      <div className="news-page">
        <Logo />
        <Menu />

        <main className={classes.main}>
          <div ref={this.section1}>
            <BOD_Section1 />
          </div>
          <div ref={this.section2}>
            <BOD_Section2 />
          </div>
        </main>
        <Footer />
        {homeSectionShortcut}
      </div>
    );
  }
}

export default BOD;

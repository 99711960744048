import React, { Component } from "react";

import Industry from "./Industry/Industry";
import Technology from "./Technology/Technology";
import Educations from "./Educations/Educations";
import Scientific from "./Scientific/Scientific";
import Distributions from "./Distribution/Distribution";
import Api from "../Api";
import Slider from "react-slick";

import classes from "./Mobile.module.css";

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { id: 1, title: "Lorem Ipsum", component: <Industry key={1} /> },
        { id: 2, title: "Lorem Ipsum", component: <Technology key={2} /> },
        { id: 3, title: "Lorem Ipsum", component: <Educations key={3} /> },
        { id: 4, title: "Lorem Ipsum", component: <Scientific key={4} /> },
        { id: 5, title: "Lorem Ipsum", component: <Distributions key={5} /> },
      ],
      currentPage: null,
      tabsActive: this.props.tab,
      activeImg: <img />,
      activePage: <div></div>,

      sections: {
        widgets: [],
      },
      sections1: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      activeImg1: <img />,
      activeImg2: <img />,
      activeImg3: <img />,
      activeImg4: <img />,
      activeImg5: <img />,
      industry: <div></div>,
      technology: <div></div>,
      educations: <div></div>,
      scientific: <div></div>,
      distributions: <div></div>,
      dataImage: [],
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
    console.log("masukkkk");
    // this.setState({
    //     tabsActive: 0,
    //     activePage: <Industry key={1} />
    // });
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ sections: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          // this.setState({
          //   isApiLoaded: true,
          // });
          const sections = result.data;
          const widget = [];
          const dataParamApi = [];

          // console.log(sections);

          dataParamApi.push({
            id: sections.widgets[0].id,
            url: sections.widgets[0].url,
            dataIndex: sections.widgets[0].widget_contents,
          });

          // console.log(dataParamApi);
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });
          sections.page = widget;
          let industry,
            technology,
            educations,
            scientific,
            distributions = null;
          const ind = [];
          const tech = [];
          const edu = [];
          const sci = [];
          const dist = [];
          const dataAll = [];
          const dataImg = [];

          widget.forEach((s) => {
            if (s.index === 1) {
              s.sections.widget_contents.forEach((s, i) => {
                dataImg.push({
                  im: s.assets[0].file,
                });
                dataAll.push({
                  id: s.id,
                  index: i,
                  category: s.category,
                  sections1: s,
                });

                if (i === 0) {
                  ind.push({
                    item: s,
                  });
                } else if (i === 1) {
                  tech.push({
                    item: s,
                  });
                } else if (i === 2) {
                  edu.push({
                    item: s,
                  });
                } else if (i === 3) {
                  sci.push({
                    item: s,
                  });
                } else if (i === 4) {
                  dist.push({
                    item: s,
                  });
                  // distributions = <Distributions items={s}/>
                }
              });
            } else if (s.index === 2) {
              s.sections.widget_contents.forEach((s, i) => {
                if (i === 0) {
                  ind.push({
                    desc: s,
                    id: i,
                  });
                } else if (i === 1) {
                  tech.push({
                    desc: s,
                    id: i,
                  });
                } else if (i === 2) {
                  edu.push({
                    desc: s,
                    id: i,
                  });
                } else if (i === 3) {
                  sci.push({
                    desc: s,
                    id: i,
                  });
                } else if (i === 4) {
                  dist.push({
                    desc: s,
                    id: i,
                  });
                }
              });
            }
          });

          console.log(ind);
          industry = (
            <Industry items={ind} dataAll={dataAll} dataParam={dataParamApi} />
          );
          technology = (
            <Technology
              items={tech}
              dataAll={dataAll}
              dataParam={dataParamApi}
            />
          );
          educations = (
            <Educations
              items={edu}
              dataAll={dataAll}
              dataParam={dataParamApi}
            />
          );
          scientific = (
            <Scientific
              items={sci}
              dataAll={dataAll}
              dataParam={dataParamApi}
            />
          );
          distributions = (
            <Distributions
              items={dist}
              dataAll={dataAll}
              dataParam={dataParamApi}
            />
          );

          let activeTab = null;
          let activeImg = null;
          if (this.props.tab) {
            let tab = this.props.tab;

            if (tab === "0") {
              activeTab = industry;
            } else if (tab === "1") {
              activeTab = technology;
            } else if (tab === "2") {
              activeTab = educations;
            } else if (tab === "3") {
              activeTab = scientific;
            } else if (tab === "4") {
              activeTab = distributions;
            }
          }

          console.log(widget);
          this.setState({
            industry: industry,
            technology: technology,
            educations: educations,
            scientific: scientific,
            distributions: distributions,
            sections: widget,
            sections1: dataAll,
            isLoaded: true,
            dataImage: dataImg,
            tabsActive: this.props.tab,
            activePage: activeTab,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
            activeImg: this.props.img,
            tabsActive: this.props.tab,
            activePage: <Industry key={1} />,
          });
        });
    });
  }

  toggleActiveHandler = (val, key) => {
    this.setState({
      tabsActive: key,
      activeImg: key,
      activePage: [val],
    });
  };

  handlePageChange = (number) => {
    this.setState({ currentPage: number });
  };

  render() {
    console.log("renderdulu..");
    const { sections } = this.state;
    console.log(sections);

    let navItem,
      navImg,
      img = null;

    const settings = {
      // className: "",
      // centerMode: false,
      // infinite: false,
      // centerPadding: "",
      // variableWidth: true,
      // slidesToShow: 3,
      // swipe: true,

      className: "",
      infinite: false,
      centerPadding: "",
      variableWidth: true,
      slidesToShow: 2,
      //   swipe: true,
    };

    // console.log(this.state.dataParam);

    if (this.state.isLoaded) {
      console.log("masukcoy");
      sections.forEach((s, i) => {
        if (s.index === 1) {
          navImg = this.state.dataImage.map((s, i) => {
            if (i === parseInt(this.state.tabsActive)) {
              img = <img className={classes.imgContentD} src={s.im} alt="" />;
              return <>{img}</>;
            }
          });
        } else if (s.index === 2) {
          navItem = s.sections.widget_contents.map((s, i) => {
            // console.log(s);
            let key = "tab" + i;
            let activeTab =
              parseInt(this.state.tabsActive) === i ? classes.isActive : "";

            // let img = null;
            let tab = null;
            if (i === 0) {
              tab = this.state.industry;
            } else if (i === 1) {
              tab = this.state.technology;
            } else if (i === 2) {
              tab = this.state.educations;
            } else if (i === 3) {
              tab = this.state.scientific;
            } else if (i === 4) {
              tab = this.state.distributions;
            }
            return (
              <div
                key={i}
                className={[classes.box, activeTab].join(" ")}
                onClick={() => this.toggleActiveHandler(tab, i)}
              >
                <span id={key}>{s.title}</span>
              </div>
            );
          });
        }
      });
    } else if (this.state.isApiLoaded) {
      console.log("masuksini");
      navItem = this.state.tabs.map((n) => {
        let key = "tab" + n.id;
        let activeTab =
          this.state.tabsActive === n.id - 1 ? classes.isActive : "";
        return (
          <div
            key={n.id}
            className={[classes.box, activeTab].join(" ")}
            onClick={() => this.toggleActiveHandler(n.component, n.id - 1)}
          >
            <span id={key}>{n.title}</span>
          </div>
        );
      });
    }

    return (
      <div className={["section-wrapping", classes.colorGrey].join(" ")}>
        {/* {this.state.activeImg} */}
        <div className={classes.boxImage}>{navImg}</div>
        <div className={[classes.newNavbar].join(" ")}>
          <div
            className={["container d-flex", classes.customContainer].join(" ")}
          >
            <div className="d-flex flex-row">
              <Slider {...settings}>{navItem}</Slider>
            </div>
          </div>
        </div>

        {this.state.activePage}
      </div>
    );
  }
}

export default Mobile;

import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

import Api from "../Api";
import Slider from "react-slick";

import { Row } from "react-bootstrap";
import Particles from "react-tsparticles";
import classes from "./sec1.module.css";

import "./sec1.css";

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      dummy: [
        { id: 1, name: "title", content: "Lorem Ipsum Lorem Ipsum?" },
        {
          id: 2,
          name: "subtitle",
          content:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry standard dummy text ever since the 1500s",
        },
        {
          id: 3,
          name: "slider",
          articles: [
            {
              name: "Slider1",
              title: "What is Lorem Ipsum?",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../assets/default-image.svg"),
            },
            {
              name: "Slider2",
              title: "What is Lorem Ipsum?",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../assets/default-image.svg"),
            },
            {
              name: "Slider3",
              title: "What is Lorem Ipsum?",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../assets/default-image.svg"),
            },
          ],
        },
      ],
      sections: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ sections: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          this.setState({
            isApiLoaded: true,
          });
          const sections = result.data;
          const widget = [];
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });
          sections.page = widget;
          this.setState({
            sections: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          // console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { sections, dummy } = this.state;
    let titleContent,
      contentDesc,
      contentSlider,
      dummyTitle,
      dummyDesc,
      dummySlider = null;

    dummyTitle = <p className="title_tap_doan">{dummy[0].content}</p>;
    dummyDesc = <p className="text-midle-sec1">{dummy[1].content}</p>;
    dummySlider = dummy[2].articles.map((s) => {
      let isImage = s.assets ? (
        <img className={classes.image} src={s.assets} alt="" />
      ) : (
        <img
          className={classes.imageDefault}
          src={require("../assets/default-image.svg")}
          alt=""
        />
      );
      return (
        <div key={s.title}>
          <div classes={classes.content}>
            <div className={classes.contentt}>
              <div className={classes.box}>
                <div className={classes.boxL}>
                  <p className={classes.title}>{s.title}</p>
                  <p className={classes.paragraph}>
                    {ReactHtmlParser(s.content)}
                  </p>
                </div>
              </div>
              <div className={classes.box_2}>{isImage}</div>
            </div>
          </div>
        </div>
      );
    });

    if (this.state.isLoaded) {
      let count = 0;
      sections.forEach((item) => {
        count = count + 1;
        // console.log(count + ' - ' + item.id + ' - ' + item.index + ' - ' + item.category)
        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              titleContent = <p className="title_tap_doan">{s.content}</p>;
            } else {
              titleContent = dummyTitle;
            }
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Text"
        ) {
          titleContent = dummyTitle;
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              contentDesc = <p className="text-midle-sec1">{s.content}</p>;
            } else {
              contentDesc = dummyDesc;
            }
          });
        } else if (
          count === 2 &&
          item.index === 2 &&
          item.category !== "Text"
        ) {
          contentDesc = dummyDesc;
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          contentSlider = item.sections.widget_contents.map((s) => {
            // let isImage= s.assets[0] ? 'ada' : 'tidak ada'
            let isImage = s.assets[0] ? (
              <img className={classes.image} src={s.assets[0].file} alt="" />
            ) : (
              <img
                className={classes.imageDefault}
                src={require("../assets/default-image.svg")}
                alt=""
              />
            );
            return (
              <div key={s.title}>
                <div classes={classes.content}>
                  <div className={classes.contentt}>
                    <div className={classes.boxContainer}>
                      <div className={classes.boxL}>
                        <p className={classes.title}>{s.title}</p>
                        <p className={classes.paragraph}>
                          {ReactHtmlParser(s.content)}
                        </p>
                      </div>
                    </div>
                    <div className={classes.box_2}>{isImage}</div>
                  </div>
                </div>
              </div>
            );
          });
        } else if (
          count === 3 &&
          item.index === 3 &&
          item.category !== "Article"
        ) {
          contentSlider = dummySlider;
        }
      });
    } else if (this.state.isApiLoaded) {
      dummy.forEach((s) => {
        if (s.id === 1) {
          titleContent = <p className="title_tap_doan">{s.content}</p>;
        } else if (s.id === 2) {
          contentDesc = <p className="text-midle-sec1">{s.content}</p>;
        } else if (s.id === 3) {
          contentSlider = s.articles.map((s) => {
            let isImage = s.assets ? (
              <img className={classes.image} src={s.assets} alt="" />
            ) : (
              <img
                className={classes.imageDefault}
                src={require("../assets/default-image.svg")}
                alt=""
              />
            );
            return (
              <div key={s.title}>
                <div classes={classes.content}>
                  <div className={classes.contentt}>
                    <div className={classes.box}>
                      <p className={classes.title}>{s.title}</p>
                      <p className={classes.paragraph}>
                        {ReactHtmlParser(s.content)}
                      </p>
                    </div>
                    <div className={classes.box_2}>{isImage}</div>
                  </div>
                </div>
              </div>
            );
          });
        }
      });
    }

    const settings = {
      dots: true,
      autoplay: true,
      infinite: true,
      speed: 800,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      pauseOnHover: true,

      appendDots: (dots) => <ul>{dots}</ul>,
      customPaging: (i) => (
        <div className="ft-slick__dots--custom">
          <div className="loading" />
        </div>
      ),
    };

    return (
      <div className="wraperr">
        <Particles
          className="partikel"
          options={{
            background: {
              color: {
                value: "#f1f1f1",
              },
            },
            fpsLimit: 60,
            interactivity: {
              detectsOn: "canvas",
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "grab",

                  parallax: {
                    enable: true,
                    force: 100,
                    smooth: 50,
                  },
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 50,
                  duration: 2,
                  opacity: 1,
                  size: 800,
                },
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 1,
                },
              },
            },
            particles: {
              color: {
                value: "#d4d4d4",
              },
              links: {
                color: "#d4d4d4",
                distance: 350,
                enable: true,
                opacity: 0.3,
                width: 2,
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  value_area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 1,
              },
              shape: {
                type: "circle",
              },
              size: {
                random: true,
                value: 3,
              },
            },
            detectRetina: true,
          }}
        />
        <div className="boxAll">
          <div className={["container", "pd-0"].join(" ")}>
            {/* {titleContent} */}
            {contentDesc}
          </div>
        </div>

        <div className="content-section_1">
          <Row className="row_2">
            <div className="card-sec1">
              <Slider {...settings}>{contentSlider}</Slider>
            </div>
          </Row>
        </div>
      </div>
    );
  }
}

export default Desktop;

import React, { Component, Fragment } from "react";
import classes from "./Section1.module.css";
import { Link } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { Slide } from "react-awesome-reveal";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import Api from "./Api";
import Box from "./Box";
import Box2 from "./Box2";
import dateFormat from "dateformat";

// import { map } from 'core-js/fn/array';

class Section1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                {
                    id: 644,
                    title: "NEWS",
                    Content: "Non Existing Article" + "versity",
                    Date: "28/10/2019",
                    Detail:
                        `Phenikaa University signed the memorandum of understanding with Andrews Universi Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to make a type specimen
                    book. It has survived not only five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages, and more
                    and more` + "ty...",
                    img: (
                        <img
                            style={{
                                width: "100%",
                                height: "1080px",
                                objectFit: "cover",
                            }}
                            src={require("./assets/news.png")}
                            alt=""
                        />
                    ),
                },
                {
                    id: 744,
                    title: "NEWS",
                    Content:
                        "Education Cooperation – The highlight in international relations of Phenikaa Uni" +
                        "versity",
                    Date: "28/10/2020",
                    Detail:
                        "Phenikaa University signed the memorandum of understanding with Andrews Universi" +
                        "ty...",
                    img: (
                        <img
                            style={{
                                width: "100%",
                                height: "1080px",
                                objectFit: "cover",
                            }}
                            src={require("./assets/news.png")}
                            alt=""
                        />
                    ),
                },
                {
                    id: 844,
                    title: "NEWS",
                    Content:
                        "Education Cooperation – The highlight in international relations of Phenikaa Uni" +
                        "versity",
                    Detail:
                        "Phenikaa University signed the memorandum of understanding with Andrews Universi" +
                        "ty...",
                    Date: "28/10/2021",
                    img: (
                        <img
                            style={{
                                width: "100%",
                                height: "1080px",
                                objectFit: "cover",
                            }}
                            src={require("./assets/news.png")}
                            alt=""
                        />
                    ),
                },
                {
                    id: 944,
                    title: "EVENT",
                    Content:
                        "Education Cooperation – The highlight in international relations of Phenikaa Uni" +
                        "versity",
                    Detail:
                        "Phenikaa University signed the memorandum of understanding with Andrews Universi" +
                        "ty...",
                    Date: "28/10/2022",
                    img: (
                        <img
                            style={{
                                width: "100%",
                                height: "1080px",
                                objectFit: "cover",
                            }}
                            src={require("./assets/news.png")}
                            alt=""
                        />
                    ),
                },
                {
                    id: 1044,
                    title: "NEWS",
                    Content:
                        "Education Cooperation – The highlight in international relations of Phenikaa Uni" +
                        "versity",
                    Detail:
                        "Phenikaa University signed the memorandum of understanding with Andrews Universi" +
                        "ty...",
                    Date: "28/10/2023",
                    img: (
                        <img
                            style={{
                                width: "100%",
                                height: "1080px",
                                objectFit: "cover",
                            }}
                            src={require("./assets/news.png")}
                            alt=""
                        />
                    ),
                },
            ],

            sections: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
            isLoadedID: false,
            idSection: this.props.id,
            active_img: 0,
            disabledNext: false,
        };
        this.toggleNext = this.toggleNext.bind(this);
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        // let paramId = this.props.match.params.id
        let paramId = this.props.id

        // console.log(paramId)
        this.getSectionDetail(paramId);
    }
    changeSlideBar(key) {
        this.setState({ active_img: key });
    }
    toggleNext() {
        // alert(this.props.id);
        let id = this.props.id;
        this.setState((id) => {
            if (id === id + 1) {
                return { disabledNext: false };
            } else {
                return { i: id + 1 };
            }
        });
    }
    getSectionDetail(paramId) {
        const apiData = new Api();

        this.setState(
            {
                article: [],
                sections: [],
            },
            () => {
                apiData.getSectionDetail().then((result) => {
                    const sections = result.data;
                    const widget = [];
                    let id = sections.id;
                    result.data.widgets.forEach((item) => {
                        widget.push(item);
                    });

                    this.setState({
                        sections: widget,
                        idSection: id,
                        isLoadedID: true
                    });
                });
                apiData
                    .getArticleDetailByArticleId(paramId)
                    .then((result) => {
                        const article = result.data;

                        const assets = [];

                        article.article_assets.forEach((item) => {
                            if (item.asset_file) {
                                assets.push({
                                    asset: item.asset_file,
                                    desc: item.description
                                })
                            } else {
                                assets.push({
                                    assetV: item.asset_link,
                                    desc: item.description
                                })
                            }
                            // assets.push({ asset: item.asset_file, desc: item.description });
                        });

                        const allcontent = article.article_languages;

                        let content = allcontent.content;
                        let title = allcontent.title;
                        let category = article.category_name;
                        let date = article.date;

                        const widget = [];

                        widget.push({
                            content: content,
                            title: title,
                            category: category,
                            date: date,
                            assets: assets,
                        });

                        // console.log(widget);
                        article.page = widget;
                        this.setState({ article: widget, isLoaded: true });
                    })
                    .catch((error) => {
                        // console.log(error);
                        this.setState({ isApiLoaded: true });
                    });
            }
        );
    }

    imageOrVideo = (url, param, index) => {
        let content
        if (url !== "") {
            let customStyle
            // if (param && (param === "img1" || param === "vid1")) {
            //     customStyle = classes._img_slide
            // } else {
            //     customStyle = classes.mediaPreview
            // }
            switch (param) {
                case "img":
                    content = <img className={classes.mediaPreview} src={url} alt='description' />
                    break;
                case "img1":
                    content = <img onClick={() => this.changeSlideBar(index)} className={classes.mediaThumbnail} src={url} alt='description' />
                    break;
                case "vid":
                    if (url.includes('phenikaa-assets.s3')) {
                        content = <video className={classes.mediaPreview} controls loop={true} autoPlay='autoplay'>
                            <source src={url} type='video/mp4' />
                        </video>
                    } else {
                        content = <iframe src={url} className={classes.mediaPreview} title="news movie"></iframe>
                    }
                    break;
                case "vid1":
                    content = <div onClick={() => this.changeSlideBar(index)} className={classes.mediaThumbnail}>
                        {/* <iframe src={require('./assets/default-image.svg')} className={classes.videoThumbnail} title="news movie"></iframe> */}
                        <img src={require('./assets/default-image.svg')} className={classes.videoThumbnail} alt=""></img>
                    </div>
                    // if (url.includes('phenikaa-assets.s3')) {
                    //     content = <video onClick={() => this.changeSlideBar(index)} className={classes.mediaThumbnail} controls loop={true} autoPlay='autoplay'>
                    //         <source src={url} type='video/mp4' />
                    //     </video>
                    // } else {
                    //     content = <video onClick={() => this.changeSlideBar(index)} poster={"./assets/default-image.svg"} className={classes.mediaThumbnail} controls>
                    //         <source src={url} type='video/mp4' />
                    //     </video>
                    //     content = <div onClick={() => this.changeSlideBar(index)} className={classes.mediaThumbnail}>
                    //         <iframe src={require('./assets/default-image.svg')} className={classes.mediaThumbnail} title="news movie"></iframe>
                    //     </div>
                    // }
                    break;
                default:
                    break;
            }
        } else {
            content = <img className={classes.box_image} src={require('./assets/default-image.svg')} alt='description' />
        }
        return content
    }

    render() {
        const settings = {
            className: "",
            infinite: true,
            centerPadding: "0",
            variableWidth: true,
            slidesToShow: 2,
            autoplay: false,
            // autoplaySpeed: 5000,
            swipeToSlide: true,
        };

        const { article, sections, dummy } = this.state;
        var { backText, backUrl, shareText } = "";
        var returnBackElement = null;

        let articleDummy = (
            <div className={classes.box}>
                <div className={classes.boxContent}>
                    <div className={classes.wrappbtnback}>
                        <div onClick={() => (window.location.href = "/news/")} className={classes.images_goback}>
                            <img src={require("./assets/Path235.svg")} alt="" />&nbsp; Back to Prev
                        </div>
                        <div className={classes.share} style={{ fontFamily: "Helvetica" }}>Share on : &nbsp;
                        <img className={classes.icon} src={require("./assets/Subtraction 1.svg")} alt="" />
                            <img className={classes.icon} src={require("./assets/Group 17.svg")} alt="" />
                            <img className={classes.icon} src={require("./assets/Group 19.svg")} alt="" />
                            <img className={classes.icon} src={require("./assets/Group 831.svg")} alt="" />
                        </div>
                    </div>
                    <div className={classes.box_scroll}>
                        <div className={classes.news_title}>{dummy[0].title}</div>
                        <div className={classes.judul}>{dummy[0].Content}</div>
                        <div className={classes.tgl}>{dummy[0].Date}</div>
                        <div className={classes.isi}>{dummy[0].Detail}</div>
                    </div>
                </div>
                <div className={classes.boxImage}>
                    <Slider {...settings}>
                        {dummy.map((images) => (
                            <div key={images.id}>{images.img}</div>
                        ))}
                    </Slider>
                </div>
            </div>
        );

        let articleContent,
            dummydata,
            top,
            top2,
            detailImg,
            imgBottom,
            nextBox,
            nextText,
            aset_img,
            desc = null;

        dummydata = articleDummy;

        if (this.state.isLoaded && this.state.isLoadedID) {

            top = <Box id={this.state.idSection} />
            top2 = <Box2 id={this.state.idSection} />

            sections.forEach((item) => {
                switch (item.index) {
                    case 3:
                        item.widget_contents.forEach(x => {
                            nextText = x.content ? x.content : 'Next page'
                        })
                        break;
                    default:
                        break;
                }
            })

            article.forEach((item) => {
                let strDate = item.date;
                let dateFull = dateFormat(strDate, "dd-mm-yyyy");

                console.log(item.assets)
                if (item.assets.length === 1) {
                    item.assets.map((ele, i) => {
                        let param = item.assets[i] ? (item.assets[i].asset ? ('img') : ('vid')) : ''
                        let newAsset = this.imageOrVideo((item.assets[i] ? (item.assets[i].asset ? (item.assets[i].asset) : (item.assets[i].assetV)) : ''), param, "")
                        
                        if (i === this.state.active_img) {
                            detailImg = (
                                <div className={classes._boxmain1}>
                                    {/* <img className={classes._img1} src={item.assets[i].asset} alt="" /> */}
                                    {newAsset}
                                    <div className={["container", classes.pad].join(" ")}>
                                        <p className={classes.txt2}>{item.assets[i].desc ? (item.assets[i].desc !== "" ? item.assets[i].desc : "description here") : "description here"}</p>
                                    </div>
                                </div>
                            );
                        }
                        nextBox = (
                            <div className={classes._btnBtm} onClick={this.toggleNext} disabled={this.state.disabledNext} >
                                <div className={classes._next}>
                                    <p className={classes._marg}>{nextText} {">"}</p>
                                </div>
                            </div>
                        );
                    });
                    imgBottom = <div></div>;
                } else if (item.assets.length > 1) {
                    let img_slide_box = item.assets.map((ele, i) => {
                        let param = item.assets[i] ? (item.assets[i].asset ? ('img') : ('vid')) : ''
                        let newAsset = this.imageOrVideo((item.assets[i] ? (item.assets[i].asset ? (item.assets[i].asset) : (item.assets[i].assetV)) : ''), param)
                        if (i === this.state.active_img) {
                            console.log(item.assets)
                            detailImg = (
                                <div className={classes._boxmain}>
                                    {newAsset}
                                    {/* <img className={classes._img} src={item.assets[i].asset} alt="" /> */}
                                    <div className={["container", classes.pad].join(" ")}>
                                        <p className={classes.txt2}>{item.assets[i].desc ? (item.assets[i].desc !== "" ? item.assets[i].desc : "description here") : "description here"}</p>
                                    </div>
                                </div>
                            );
                        }
                        nextBox = (
                            <div className={classes._btnBtm} onClick={this.toggleNext}>
                                <div className={classes._next}>
                                    <p className={classes._marg}>{nextText} {">"}</p>
                                </div>
                            </div>
                        );
                        let param1 = ele.asset ? ('img1') : ('vid1')
                        let newAsset1 = this.imageOrVideo((ele.asset ? ele.asset : ele.assetV), param1, i)

                        return (
                            <div className={classes._box2}>
                                {newAsset1}
                                {/* <img onClick={() => this.changeSlideBar(i)} className={classes._img_slide} src={ele.asset} alt="" /> */}
                            </div>
                        );
                    });

                    imgBottom = (
                        <div className={classes._box3}>
                            <div className={["container", classes.padd2].join(" ")}>
                                <Slider {...settings}>{img_slide_box}</Slider>
                            </div>
                        </div>
                    );
                }

                // nextBox = (
                //   <div className={classes._btnBtm} onClick={this.toggleNext}>
                //     <div className={classes._next}>
                //       <p className={classes._marg}>Next Page {">"}</p>
                //     </div>
                //   </div>
                // );

                articleContent = (
                    <div className={classes.box}>
                        <div className={classes.boxContent}>

                            <div className={classes.judul}>{item.title}</div>

                            <div className={classes.box_scroll}>
                                <div className={classes.scroll_content}>
                                    <div className={classes.tgl}>{dateFull}</div>
                                    <div className={classes.isi}>
                                        {ReactHtmlParser(item.content)}
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                {top}
                            </div>
                        </div>
                        <div className={classes.boxContent2}>
                            {top2}
                            <div className={classes.box_scroll}>
                                <div className={classes.boxImage}>
                                    {detailImg}
                                    {imgBottom}
                                    {/* {nextBox} */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });

        } else if (this.state.isApiLoaded) {
            articleContent = articleDummy;
        }
        return (
            <div className="pages-new">
                <div className={classes.wrapper}>
                    <div className={classes.main}>
                        <ParallaxProvider>
                            <Fragment>{articleContent}</Fragment>
                        </ParallaxProvider>
                    </div>
                </div>
            </div>
        );
    }
}

export default Section1;

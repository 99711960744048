import React, { Component, createRef } from "react";
import Header from "../../sections/Header/Header";

import ChangingValueTest from "./ChangingValueTest";
import NewsDetail from '../../sections/ND_Section1/ND_Section1'

import Footer from "../../sections/Footer/Footer";
import { ParallaxProvider } from "react-scroll-parallax";

import styled from "styled-components";

const CustomDiv = styled.div`
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
`;
export default class NewsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: null,
            logoDark: false,

        };

        this.updateLogo = this.updateLogo.bind(this)

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        //console.log("Page: " + this.props.match.params.id)
        // alert(this.props.match.params.id)
    }


    updateLogo(dark){
        this.setState({
            logoDark: dark,
        })
        console.log(this.state)
    }

    render() {
        //let homeSectionShortcut = <HomeSectionShortcut section={sec} />;
        return (
            <div className="news-page">
                <Header onPage={"News"} />
                <CustomDiv>
                    <ParallaxProvider>
                        <React.Fragment>
                            <div ref={this.section1}>
                                {/* <ChangingValueTest darkFunction={this.updateLogo}/>
                                <ChangingValueTest logoDark={this.state.logoDark}/> */}
                                <NewsDetail id={this.props.match.params.id} />
                            </div>
                        </React.Fragment>
                    </ParallaxProvider>
                </CustomDiv>
                <Footer />
            </div>
        );
    }
}

import React, { Component, createRef } from "react"

import SectionShortcut from "./SectionShortcut/SectionShortcut"
import HomeSectionShortcut from "./SectionShortcut/HomeSectionShortcut"

import Header from "../../sections/Header/Header"
import VizSensor from "react-visibility-sensor"
import ImageSlider from "../../sections/H_ImageSlider/H_ImageSlider"
import Sec1 from "../../sections/H_Section1/H_Section1"
import Sec2 from "../../sections/H_Section2/H_Section2"
import Sec3 from "../../sections/H_Section3/H_Section3"
import Sec4 from "../../sections/H_Section4/H_Section4"
import Sec5 from "../../sections/H_Section5/H_Section5"
import Footer from "../../sections/Footer/Footer"
import { ParallaxProvider } from "react-scroll-parallax"

import styled from "styled-components"

const CustomDiv = styled.div`
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
`
class project1 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentPage: null,
      currentSection: null,
      darkLogo: false,
      visible: null,
      section: [
        { id: 1, title: "Menu" },
        { id: 2, title: "Tầm nhìn" },
        { id: 3, title: "lĩnh vực hoạt động" },
        { id: 4, title: "Phát triển bền vững" },
        { id: 5, title: "TIN TỨC & SỰ KIỆN" },
        { id: 6, title: "CƠ HỘI NGHỀ NGHIỆP" },
      ],
    }

    this.updateLogo = this.updateLogo.bind(this)
    this.scrollingHandler = this.scrollingHandler.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollingHandler, true)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollingHandler, true)
  }

  section1 = createRef()
  section2 = createRef()
  section3 = createRef()
  section4 = createRef()
  section5 = createRef()
  section6 = createRef()

  updateLogo(dark) {
    this.setState({
      darkLogo: dark,
    })
    console.log(dark)
  }

  scrolltoSectionHandler = (key) => {
    if (key === "section1") {
      this.section1.current.scrollIntoView({ behavior: "smooth" })
    } else if (key === "section2") {
      this.section2.current.scrollIntoView({ behavior: "smooth" })
    } else if (key === "section3") {
      this.section3.current.scrollIntoView({ behavior: "smooth" })
    } else if (key === "section4") {
      this.section4.current.scrollIntoView({ behavior: "smooth" })
    } else if (key === "section5") {
      this.section5.current.scrollIntoView({ behavior: "smooth" })
    } else if (key === "section6") {
      this.section6.current.scrollIntoView({ behavior: "smooth" })
    }
  }

  scrollingHandler() {
    console.log(this.state.currentSection, this.state.darkLogo)
    if (this.section1.current.getBoundingClientRect().bottom < this.section1.current.getBoundingClientRect().height && this.section1.current.getBoundingClientRect().bottom > -1) {
      this.setState({
        currentSection: "Section1",
        darkLogo: false,
      })
    }
    //if(this.section2.current.getBoundingClientRect().y < 100 && this.section2.current.getBoundingClientRect().bottom > 100){
    if (this.section2.current.getBoundingClientRect().bottom < this.section2.current.getBoundingClientRect().height && this.section2.current.getBoundingClientRect().bottom > -1) {
      this.setState({
        currentSection: "Section2",
        darkLogo: true,
      })
    }
    if (this.section3.current.getBoundingClientRect().bottom < this.section3.current.getBoundingClientRect().height && this.section3.current.getBoundingClientRect().bottom > -1) {
      this.setState({
        currentSection: "Section3",
        darkLogo: true,
      })
    }
    if (this.section4.current.getBoundingClientRect().bottom < this.section4.current.getBoundingClientRect().height && this.section4.current.getBoundingClientRect().bottom > -1) {
      this.setState({
        currentSection: "Section4",
        darkLogo: true,
      })
    }
    if (this.section5.current.getBoundingClientRect().bottom < this.section5.current.getBoundingClientRect().height && this.section5.current.getBoundingClientRect().bottom > -1) {
      this.setState({
        currentSection: "Section5",
        darkLogo: false,
      })
    }
    if (this.section6.current.getBoundingClientRect().bottom < this.section6.current.getBoundingClientRect().height && this.section6.current.getBoundingClientRect().bottom > -1) {
      this.setState({
        currentSection: "Section6",
        darkLogo: true,
      })
    }

    //console.log(this.section1.current.getBoundingClientRect().y, this.section1.current.getBoundingClientRect().top, this.section1.current.getBoundingClientRect().bottom)
    //console.log("S2: ", this.section2.current.getBoundingClientRect().y, window.scrollY)
    //console.log("S3: ", this.section3.current.getBoundingClientRect().y, window.scrollY)
    //console.log("S4: ", this.section4.current.getBoundingClientRect().y, window.scrollY)
    //console.log("S5: ", this.section5.current.getBoundingClientRect().y, window.scrollY)
  }

  render() {
    let sec = this.state.section.map((s) => {
      let ref = "section" + s.id
      return <SectionShortcut key={s.id} title={s.title} onClick={() => this.scrolltoSectionHandler(ref)} />
    })

    //console.log(this.state)
    let homeSectionShortcut = <HomeSectionShortcut section={sec} />
    return (
      <div style={{}}>
        <Header onPage={"home"} style={{ display: "block" }} logoColor={this.state.darkLogo} />
        <CustomDiv>
          <ParallaxProvider>
            <React.Fragment>
              {/* <VizSensor onChange={(isVisible) => {this.setState({visible: "imageSlider", logoDark: false})}}> */}
              <div ref={this.section1}>
                <ImageSlider updateLogo={this.updateLogo} />
              </div>
              {/* </VizSensor>
                            <VizSensor onChange={(isVisible) => {this.setState({visible: "section1", logoDark: true})}}> */}
              <div ref={this.section2}>
                <Sec1 updateLogo={this.updateLogo} />
              </div>
              {/* </VizSensor>
                            <VizSensor onChange={(isVisible) => {this.setState({visible: "section2", logoDark: true})}}> */}
              <div ref={this.section3}>
                <Sec2 updateLogo={this.updateLogo} />
              </div>
              <div ref={this.section4}>
                <Sec4 updateLogo={this.updateLogo} />
              </div>
              <div ref={this.section5}>
                <Sec3 updateLogo={this.updateLogo} />
              </div>
              <div ref={this.section6}>
                <Sec5 updateLogo={this.updateLogo} />
              </div>
            </React.Fragment>
          </ParallaxProvider>
        </CustomDiv>
        <Footer />
        {homeSectionShortcut}
      </div>
    )
  }
}

export default project1

import axios from "axios"

export default class Api {
    base_api = "http://54.255.70.35/"
    token = '60edaccc8f547344c09fe84bea12029a7aab6b36'

    async getSectionDetail() {
        let params = 303
        let url = null
        let lang = localStorage.getItem('language')
        if (lang==='English'){
            url = this.base_api+`section_customer/${params}/?language=en`
        } else {
            url = this.base_api+`section_customer/${params}`
        }
        return await axios.get(url, {
            params: {
            },
            headers: {
                "Authorization": `Token ${this.token}`,
                "Content-Type": "application/json"
            }
        })
    }
}
import React from "react";
import { Card } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import classesD from "../MenuDetail.module.css";
import classes from "./MenuPreview6.module.css";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

const menuPreview6 = (props) => {
  let item = null;
  if (!props.items) {
    props.items.forEach((x, index) => {
      if (index === 0) {
        item = x.widget ? (
          x.widget.widget_contents.map((itm, i) => {
            // console.log(itm.date);
            let strYear = itm.date ? itm.date : "";
            let dateFull = dateFormat(strYear, "dd-mm-yyyy");

            if (i < 3) {
              let img = null;
              img = itm.assets[0]
                ? itm.assets[0].file
                : require("../assets/default-image.svg");
              return (
                <div className="m-3">
                  
                </div>
              );
            }
          })
        ) : (
          <></>
        );
      }
    });
  }

  return (
    <div className={classesD.sectionPreview}>
      <div id="preview6" className="d-flex flex-row">
        <p>test</p>
        {item}
      </div>
    </div>
  );
};

// <div className={classes.sectionPreview}>
//     <div id='preview6' className='row'>
//         <div className='col-md-6 p-0'>
//             <Link to={'/About-Us'} className={classes.customLink}>
//                 <p className={classes.title}>Career</p>
//             </Link>

//             <p className={classes.contentCustomD}>Work Enviroment</p>
//             <div className={classes.square}></div>
//         </div>
//         <div className='col-md-3 p-0 m-0'>
//             <Link to={'/About-Us'} className={classes.customLink}>
//                 <p className={classes.title}>Career</p>
//             </Link>

//             <p className={classes.contentCustomD}>Job Vacancy</p>
//             <p className={classes.subTitleD}>Urgently Needed</p>
//             <p className={[classes.contentCustomD, 'mt-5'].join(' ')}>8 New job position</p>

//             <p className={classes.subTitleD}>Management</p>
//             <p className={[classes.contentCustomD, 'mt-5'].join(' ')}>Accountant</p>

//             <p className={classes.subTitleD}>IT Departement</p>
//             <p className={[classes.contentCustomD, 'mt-5'].join(' ')}>New job position</p>
//         </div>
//         <div className='col-md-3 p-0 m-0'>
//             <Link to={'/About-Us'} className={classes.customLink}>
//                 <p className={classes.title}>Career</p>
//             </Link>

//             <p className={classes.contentCustomD}>My Application</p>
//         </div>

//     </div>
// </div>

export default menuPreview6;

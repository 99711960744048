import React, { Component, createRef } from "react";
import Header from "../../sections/Header/Header";

import Footer from "../../sections/Footer/Footer";
import { ParallaxProvider } from "react-scroll-parallax";

import styled from "styled-components";

const CustomDiv = styled.div`
  min-height: 100vh;
  background: white;
  position: relative;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;
`;
export default class ChangingValueTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dark: false,

        };

        this.clickFunction = this.clickFunction.bind(this)

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        //console.log("Page: " + this.props.match.params.id)
        // alert(this.props.match.params.id)
    }


    clickFunction(){
        let value = this.state.dark;
        this.setState({
            dark: !value,
        })
        console.log(this.state)
        this.props.darkFunction(value);
    }

    render() {
        console.log("Props: ")
        console.log(this.props)
        //let homeSectionShortcut = <HomeSectionShortcut section={sec} />;
        return (
            <div className="news-page">
                        <React.Fragment>
                            <div ref={this.section1}>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>

                                <br/>
                                <br/>

                                <br/>
                                <br/>
                                <div onClick={this.clickFunction}>
                                    Her er en kul text
                                </div>
                            </div>
                        </React.Fragment>

            </div>
        );
    }
}

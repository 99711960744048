import React, { Component } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import styled from "styled-components"
import {isSafari} from 'react-device-detect'

import Aux from "../../../hoc/Auxs/Auxs"

import classes from "./MegaMenuItem.module.css"
import "./MegaMenuItem.css"

const StyledButton = styled.button`
  position: relative;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;

  @media only screen and (max-width: 1400px) {
    width: 15px;
    height: 15px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    background-color: white;
    transition: transform 0.25s ease-out;
  }

  /* Vertical line */
  &:before {
    top: 0;
    left: 50%;
    width: 4px;
    height: 100%;
    margin-left: -2px;
    background: #f16b26;
    border-radius: 10px;
  }

  /* horizontal line */
  &:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 4px;
    margin-top: -2px;
    background: #f16b26;
    border-radius: 10px;
  }

  &:hover {
    cursor: pointer;

    &:before {
      transform: rotate(90deg);
      background: white;
    }
    &:after {
      transform: rotate(180deg);
      background: white;
    }
  }

  @media only screen and (max-width: 1400px) {
    &:before {
      top: 0;
      left: 50%;
      width: 2px;
      margin-left: -2px;
      border-radius: 10px;
    }

    /* horizontal line */
    &:after {
      top: 50%;
      left: -1px;
      height: 2px;
      margin-top: -2px;
      border-radius: 10px;
    }
  }
`

class MegaMenuItem extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    let item = null

    switch (this.props.type) {
        case "expand":
            console.log("Is safari: " + isSafari)
        item = this.props.link.includes("http") ? (
          <Aux>
            <a href={this.props.link} className={classes.customLink} target='_blank' rel='noopener noreferrer'>
              <div className={["row m-0 mb-2", classes.contentItem].join(" ")} onMouseEnter={this.props.onMouseEnter}>
                <p className={classes.contentLinkMenu}>{this.props.title}</p>
                <div className={isSafari ? classes.iconExpandSafari : classes.iconExpand}>
                  <StyledButton className={["expand-list", classes.expandList].join(" ")} />
                </div>
              </div>
            </a>
          </Aux>
        ) : (
          <Aux>
            <Link to={this.props.link} className={classes.customLink}>
              <div className={["row m-0 mb-2", classes.contentItem].join(" ")} onMouseEnter={this.props.onMouseEnter}>
                <p className={classes.contentLinkMenu}>{this.props.title}</p>
                            <div className={isSafari ? classes.iconExpandSafari : classes.iconExpand}>
                  <StyledButton className={["expand-list", classes.expandList].join(" ")} />
                </div>
              </div>
            </Link>
          </Aux>
        )
        break
      case "disable":
        item = this.props.link.includes("http") ? (
          <Aux>
            <a href={this.props.link} className={classes.customLink} target='_blank' rel='noopener noreferrer'>
              <div className={["row m-0 mb-2", classes.contentItem].join(" ")} onMouseEnter={this.props.onMouseEnter}>
                <p className={classes.contentLinkMenu}>{this.props.title}</p>
                <button style={{ backgroundColor: "#F16B26", color: "white", margin: "0.3rem 0 auto 1rem", borderRadius: "20px", minHeight: "15px", fontSize: "10px", border: "none", fontWeight: "normal" }} className='btn btn-require'>
                  {" "}
                  Require Sign in
                </button>
              </div>
            </a>
          </Aux>
        ) : (
          <Aux>
            <Link to={this.props.link} className={classes.customLink}>
              <div className={["row m-0 mb-2", classes.contentItem].join(" ")} onMouseEnter={this.props.onMouseEnter}>
                <p className={classes.contentLinkMenu}>{this.props.title}</p>
                <button style={{ backgroundColor: "#F16B26", color: "white", margin: "0.3rem 0 auto 1rem", borderRadius: "20px", minHeight: "15px", fontSize: "10px", border: "none", fontWeight: "normal" }} className='btn btn-require'>
                  {" "}
                  Require Sign in
                </button>
              </div>
            </Link>
          </Aux>
        )
        break
      case "link":
        item = this.props.link.includes("http") ? (
          <a href={this.props.link} className={classes.customLink} rel='noopener noreferrer'>
            <div className={["row m-0 mb-2", classes.contentItem].join(" ")}>
              <p className={classes.contentLinkMenu}>{this.props.title}</p>
              <div className={classes.iconLink}>
                <div className={classes.icon}>
                  <div className={["arrow", classes.arrow].join(" ")}></div>
                </div>
              </div>
            </div>
          </a>
        ) : (
          <Link to={this.props.link} className={classes.customLink} rel='noopener noreferrer'>
            <div className={["row m-0 mb-2", classes.contentItem].join(" ")}>
              <p className={classes.contentLinkMenu}>{this.props.title}</p>
              <div className={classes.iconLink}>
                <div className={classes.icon}>
                  <div className={["arrow", classes.arrow].join(" ")}></div>
                </div>
              </div>
            </div>
          </Link>
        )
        break
      default:
        item = null
    }

    return item
  }
}

MegaMenuItem.propTypes = {
  type: PropTypes.string.isRequired,
}

export default MegaMenuItem

import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";

import Diagram from "../Diagram/Diagram";
import classes from "./Technology.module.css";

import { Link } from "react-router-dom";
export default class Technology extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                { id: 1, name: "Title", content: "What is Lorem Ipsum?" },
                {
                    id: 2,
                    name: "Content",
                    content:
                        "Where does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
                },
                {
                    id: 4,
                    name: "Background Image",
                    assets: require("./assets/default-image.svg"),
                },
            ],
            isDetail: false,
        };
    }

    getHtmlParagraph(str) {
        if (str !== "") {
            return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
        }
    }

    toggleDetail = () => {
        this.setState((prevState) => {
            return { isDetail: !prevState.isDetail };
        });
    };

    getUrlParser(url) {
        if (url !== "/") {
            return url.includes("http") ?
                <a className={classes.customLink} href={url} target="_blank" rel="noopener noreferrer">
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.viewMore}>{this.props.viewDetails}</p>
                </a>
                :
                <Link className={classes.customLink} to={url}>
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.viewMore}>{this.props.viewDetails}</p>
                </Link>
                ;
        } else {
            return <Link className={classes.customLink} to={url}>
                <div className={classes.outerCircle}>
                    <div className={classes.innerCircle}></div>
                </div>
                <p className={classes.viewMore}>{this.props.viewDetails}</p>
            </Link>
        }
    }

    getParsedHtml(str) {
        let content = str.match(/<p>(.*?)<\/p>/g).map(function (val) {
            return val.replace(/<\/?p>/g, " ").replace(/&nbsp;/gi, " ");
        });
        return content;
    }

    render() {
        const { dummy } = this.state;
        let title,
            desc,
            img,
            content,
            link = null;
        let detailContent,
            contentL1,
            contentL2,
            contentL3,
            contentL4,
            contentL5,
            contentL6,
            contentL7,
            contentL8,
            contentBox = null;

        if (this.props.items) {
            let items = this.props.items;
            title = <p className={classes.title}>{items[0].item.title}</p>;
            desc = (
                <span className={classes.content}>
                    {ReactHtmlParser(items[0].item.content)}
                </span>
            );
            let contentImg = items[0].item.assets[0]
                ? items[0].item.assets[0].file
                : require("./assets/default-image.svg");

            img = <img className={classes.imgContent} src={contentImg} alt="" />;
            content = (
                <div className={classes.contentDesc}>
                    {ReactHtmlParser(items[1].desc.content)}
                </div>
            );
            link = !this.state.isDetail ? (
                // <Link className={classes.link} to={{ pathname: `${items[1].desc.url ? items[1].desc.url : '/'}` }}>
                <div
                    className={[classes.contentLink].join(" ")}
                    onClick={this.toggleDetail}
                >
                    <div className="d-flex">
                        <div className={classes.outerCircle}>
                            <div className={classes.innerCircle}></div>
                        </div>
                        <p className={classes.contentLink}>{this.props.readMore}</p>
                    </div>
                </div>
            ) : (
                    <></>
                );
            // </Link>

            const splitStr = items[0].item.content.split("#");
            desc = (
                <span className={classes.content}>
                    {ReactHtmlParser(splitStr[0] ? splitStr[0] : "")}
                </span>
            );

            let arr = [];

            contentL1 = splitStr[1] ? splitStr[1] : "";
            contentL2 = splitStr[2] ? splitStr[2] : "";
            arr.push(this.getParsedHtml(splitStr[3] ? splitStr[3] : ""));
            arr.push(this.getParsedHtml(splitStr[4] ? splitStr[4] : ""));
            arr.push(this.getParsedHtml(splitStr[5] ? splitStr[5] : ""));
            arr.push(this.getParsedHtml(splitStr[6] ? splitStr[6] : ""));

            contentL7 = splitStr[7] ? splitStr[7] : "";
            contentL8 = splitStr[8] ? splitStr[8] : "";

            let contentImg2 = items[0].item.assets[1] ? items[0].item.assets[1].file : require("./assets/default-image.svg");

            if (this.props.dataExt) {
                contentBox = this.props.dataExt.map((s, i) => {
                    console.log(s);
                    let url = s.sections.assets
                        ? s.sections.assets[0].file
                        : require("./assets/default-image.svg");
                    let link = s.url ? this.getUrlParser(s.url) : this.getUrlParser("/");

                    return (
                        <div className={classes.box}>
                            <img src={url} alt=""></img>
                            <div className={classes.boxMask}></div>
                            <div className={classes.contentFront}>
                                <div className={classes.boxContentIn}>
                                    <p>{s.sections.title}</p>
                                </div>
                            </div>
                            <div className={classes.contentBack}>
                                <div className={classes.boxContentIn}>
                                    <div className="d-flex flex-column text-right mt-auto mb-auto">
                                        {ReactHtmlParser(s.sections.content)}
                                        <div className="d-flex flex-row justify-content-end position-relative mt-2">
                                            {link}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
            }

            let mov
            if (this.props.movie) {
                const urlMov = this.props.movie
                if (urlMov !== "") {
                    mov = <video className={classes.isvideo} controls loop={true} autoPlay='autoplay'>
                        <source src={urlMov} type='video/mp4' />
                    </video>
                } else {
                    mov = <figure><img src={require('./assets/default-image.svg')} alt=""></img></figure>
                }

            }

            let animate = this.state.isDetail
                ? classes.fadeInBottom
                : classes.fadeOutTop;
            detailContent = this.state.isDetail ? (
                <div className={[classes.detailContent, animate].join(" ")}>
                    {ReactHtmlParser(contentL1)}

                    <div className={[classes.boxContents, 'mt-0'].join(' ')}>
                        <div className={classes.boxL50}>
                            {ReactHtmlParser(contentL2)}
                        </div>
                        <div className={classes.boxR50}>
                            {mov}
                        </div>
                    </div>
                    <hr />
                    <div className={classes.boxContents}>
                        <div className={classes.boxL50C}>
                            <figure><img src={contentImg2} alt=""></img></figure>
                            {/* <div className={classes.diagram}>
                                <Diagram arr={arr} />
                            </div> */}
                        </div>
                        <div className={classes.boxR50C}>{ReactHtmlParser(contentL7)}</div>
                    </div>
                    {/* <div className={classes.boxFullImage}>
                        <figure><img src={contentImg2} alt=""></img></figure>
                    </div> */}
                    {ReactHtmlParser(contentL8)}
                    {/* {ReactHtmlParser(contentL5)} */}
                    <div className="d-flex flex-row m-0 p-0">{contentBox}</div>
                    <div className={[classes.contentLink].join(" ")} onClick={this.toggleDetail}>
                        <div className="d-flex">
                            <div className={classes.outerCircle}>
                                <div className={classes.innerCircle}></div>
                            </div>
                            <p className={classes.contentLink}>{this.props.readLess}</p>
                        </div>
                    </div>
                </div>
            ) : (
                    <></>
                );
        } else {
            title = <p className={classes.title}>{dummy[0].content}</p>;
            desc = <p className={classes.content}>{dummy[1].content}</p>;
            img = (
                <img className={classes.imgContentD} src={dummy[2].assets} alt="" />
            );
            content = (
                <div className={classes.contentDummy}>
                    <ul>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit </li>
                    </ul>
                </div>
            );
        }
        let customMargin = !this.state.isDetail ? "mb-5" : "";
        return (
            <div className={classes.sectionTechnology}>
                <div
                    className={[
                        "container d-flex flex-column",
                        classes.customContainer,
                    ].join(" ")}
                >
                    <div className={classes.boxImage}>
                        <div className={classes.animate}>{img}</div>
                    </div>
                    <div className={["d-flex", customMargin].join(" ")}>
                        <div className={classes.boxContent}>
                            {title}
                            {desc}
                            {link}
                        </div>
                        <div className={classes.boxOverflow}>
                            <div className={classes.boxContentOverflow}>{content}</div>
                        </div>
                    </div>

                    {detailContent}
                </div>
            </div>
        );
    }
}

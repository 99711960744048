import React, { Component } from 'react'
import anime from 'animejs';

import classes from './News.module.css'

export default class Core extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false
        };
    }
    render() {
        let pathEls = document.querySelectorAll('path');
        let core = null
        for (var i = 0; i < pathEls.length; i++) {
            let pathEl = pathEls[i];
            let offset = anime.setDashoffset(pathEl);
            pathEl.setAttribute('stroke-dashoffset', offset);
            anime({
                targets: pathEl,
                strokeDashoffset: [offset, 0],
                duration: anime.random(5000, 5000),
                delay: anime.random(0, 5000),
                loop: true,
                direction: 'alternate',
                easing: 'easeInOutSine',
                autoplay: true
            });
        }

        const elements = ['1', '2', '3', '4', '5', '1', '2', '3', '4', '5'];
        const items = []

        for (const [index, value] of elements.entries()) {
            items.push(
                <div>
                    <svg className={this.props.newStyle} version="1.1" id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px" y="0px" width="100%" height="100%" viewBox="0 0 857 581.1" >

                        <path className={classes.st0} d="M6,522.1c8.7,0,18.1,0,26.8,0c6-5.9,11.9-11.7,17.9-17.6c8.8,0,17.6,0,26.4,0c4.6,5,9.2,10.1,13.8,15.1 c5,0,10.1-0.1,15.1-0.1" />
                        <path className={classes.st0} d="M32.6,486.7c15.1,0,30.2,0,45.3,0c6.8,6.8,13.7,13.7,20.5,20.5c6.6,0,13.2,0,19.8,0" />
                        <path className={classes.st0} d="M25.8,473.5c29,0,58,0,87.1,0c11.6,11.6,23.2,23.2,34.8,34.8c29.8,0,59.6,0,89.4,0" />
                        <path className={classes.st0} d="M3.6,464.5c40,0,80,0,119.9,0c11,11,22.1,22.1,33.1,33.1c37.5,0,75,0,112.5,0c6.9-6.9,13.9-13.9,20.8-20.8 c0-14.8,0-29.6,0-44.4c10.9-10.9,21.9-21.9,32.8-32.8c7.4,0,14.8,0,22.2,0c7-7,13.9-13.9,20.9-20.9" />
                        <path className={classes.st0} d="M96.2,559.2c9.8-9.8,19.7-19.7,29.5-29.5c41.7,0,83.5,0,125.2,0c16.3-16.3,32.5-32.5,48.8-48.8 c0-14.8,0-29.6,0-44.3c9.4-9.4,18.8-18.8,28.3-28.3c8.5,0,17,0,25.6,0c4.4-4.4,8.9-8.9,13.3-13.3" />
                        <path className={classes.st0} d="M240,562.1c19.7-19.7,39.3-39.3,59-59" />
                        <path className={classes.st0} d="M264.5,559.5c18.1-18.1,36.2-36.2,54.3-54.3c0-9,0-18,0-27c5.7-5.7,11.4-11.4,17.1-17.1c0-5.1,0-10.3,0-15.4 c10-10,20-20,30-30" />
                        <path className={classes.st0} d="M303.6,558.5c14.7-14.7,29.5-29.5,44.2-44.2c0-12.4,0-24.8,0-37.1c4.8-4.8,9.5-9.5,14.3-14.3 c0-5.6,0-11.3,0-16.9c3.8-3.8,7.7-7.7,11.5-11.5" />
                        <path className={classes.st0} d="M319,558.3c13.8-13.8,27.6-27.6,41.4-41.4c0-14,0-27.9,0-41.9c11.5-11.5,22.9-22.9,34.4-34.4c0-11,0-22,0-32.9 c4.5-4.5,8.9-8.9,13.4-13.4" />
                        <path className={classes.st0} d="M342.5,469.7c1.8-1.8,3.6-3.6,5.4-5.4c0-4.9,0-9.9,0-14.8c12.5-12.5,25-25,37.6-37.6c0-7.3,0-14.6,0-21.9" />
                        <path className={classes.st0} d="M375.4,489.1c9.5-9.5,18.9-18.9,28.4-28.4c0-1.7,0-3.4,0-5.2" />
                        <path className={classes.st0} d="M373.6,548.8c0-10.8,0-21.5,0-32.3c14.2-14.2,28.4-28.4,42.7-42.7c0-11.4,0-22.8,0-34.2 c-2.4-2.4-4.8-4.8-7.3-7.3" />
                        <path className={classes.st0} d="M412.9,580.7c0-27.4,0-54.9,0-82.3c-3.2,0-6.4,0-9.6,0c-4,4-8,8-12,12" />
                        <path className={classes.st0} d="M424.8,561.6c0-49.2,0-98.5,0-147.7" />
                        <path className={classes.st0} d="M440.8,580.8c0-34.4,0-68.7,0-103.1" />
                        <path className={classes.st0} d="M441.5,450.4c1.5-1.5,3-3,4.5-4.5c0-8.7,0-17.4,0-26.1c1.9-1.9,3.8-3.8,5.7-5.7c12.9,12.9,25.9,25.9,38.8,38.8 c0,6.4,0,12.8,0,19.3c-5.5,5.5-11,11-16.4,16.4c0,14.6,0,29.2,0,43.9c8.4,8.4,16.9,16.9,25.3,25.3" />
                        <path className={classes.st0} d="M466,561.8c0-24.3,0-48.5,0-72.8c5.7-5.7,11.5-11.5,17.2-17.2c0-6.6,0-13.1,0-19.7c-6.5-6.5-13-13-19.6-19.6" />
                        <path className={classes.st0} d="M456.3,571.7c0-30.2,0-60.3,0-90.5c4.7-4.7,9.4-9.4,14.1-14.1c0-4,0-8,0-11.9" />
                        <path className={classes.st0} d="M543.3,555.7c-14.1-14.1-28.1-28.1-42.2-42.2c0-22.6,0-45.1,0-67.7c-15.2-15.2-30.5-30.5-45.7-45.7 c-3.7,0-7.3,0-11,0c-2.6-2.6-5.1-5.1-7.7-7.7" />
                        <path className={classes.st0} d="M637.8,543.3c-4.9-4.9-9.8-9.8-14.6-14.6c-29.2,0-58.5,0-87.7,0c-7.7-7.7-15.4-15.4-23.2-23.2 c0-19.2,0-38.4,0-57.7c-18.6-18.6-37.3-37.3-55.9-55.9" />
                        <path className={classes.st0} d="M652.6,545.8c0-7.4,0-14.8,0-22.1c-3.4-3.4-6.8-6.8-10.2-10.2c-32.9,0-65.8,0-98.7,0 c-6.7-6.7-13.4-13.4-20.1-20.1c0-17.7,0-35.4,0-53.1c-22.9-22.9-45.8-45.8-68.7-68.7c-19,0-37.9,0-56.9,0" />
                        <path className={classes.st0} d="M536.6,482.7c0-14.2,0-28.4,0-42.6c-19.9-19.9-39.7-39.7-59.6-59.6" />
                        <path className={classes.st0} d="M556.2,490.8c0-17.5,0-35.1,0-52.6c-18-18-36.1-36.1-54.1-54.1" />
                        <path className={classes.st0} d="M732.8,557.4c0-10.3,0-20.6,0-30.9c-4.6-4.6-9.1-9.1-13.7-13.7c-14.1,0-28.2,0-42.3,0 c-4.1,4.1-8.1,8.1-12.2,12.2" />
                        <path className={classes.st0} d="M745.6,548.6c0-9.6,0-19.3,0-28.9c-5.7-5.7-11.5-11.5-17.2-17.2c-46.3,0-92.7,0-139,0 c-7.4-7.4-14.8-14.8-22.2-22.2c0-15.3,0-30.5,0-45.8c-13.6-13.6-27.2-27.2-40.7-40.7c0-7,0-14,0-21c-2.2-3.8-4.3-7.5-6.5-11.3 c-3.7,0-7.3,0-11,0" />
                        <path className={classes.st0} d="M601.5,491.5c-7.1-7.1-14.3-14.3-21.4-21.4c0-15.7,0-31.5,0-47.2c-11.7-11.7-23.4-23.4-35.1-35.1 c0-9.8,0-19.7,0-29.5c-3.1-3.1-6.1-6.1-9.2-9.2c-9.2,0-18.4,0-27.6,0" />
                        <path className={classes.st0} d="M762.1,561.6c0-14.5,0-29,0-43.5c-8.7-8.7-17.5-17.5-26.2-26.2c-37.1,0-74.1,0-111.2,0 c-11-11-21.9-21.9-32.9-32.9c0-14.8,0-29.6,0-44.4c-11.5-11.5-22.9-22.9-34.4-34.4c0-11,0-21.9,0-32.9c-4.5-4.5-8.9-8.9-13.4-13.4 c-12.5,0-25,0-37.5,0" />
                        <path className={classes.st0} d="M605.3,440.9c0-10.5,0-21.1,0-31.6c-10.9-10.9-21.8-21.8-32.8-32.8" />
                        <path className={classes.st0} d="M809.9,479.1c-57.6,0-115.2,0-172.8,0c-5.5-5.5-11-11-16.4-16.4c0-28.7,0-57.4,0-86.1c-5.5-5.5-11-11-16.5-16.5 c-10.1,0-20.2,0-30.3,0c-2.6-2.6-5.2-5.2-7.8-7.8c0-3.6,0-7.2,0-10.8c-10-10-20-20-29.9-29.9c-11.2,0-22.4,0-33.6,0" />
                        <path className={classes.st0} d="M729.9,464.9c-16.9,0-33.8,0-50.7,0c-11.7-11.7-23.3-23.3-35-35c-2.7,0-5.3,0-8,0c-2.9,2.9-5.7,5.7-8.6,8.6 c0,3.4,0,6.8,0,10.1" />
                        <path className={classes.st0} d="M848,468.7c-30.4,0-60.8,0-91.2,0c-9.3-9.3-18.6-18.6-27.9-27.9c-21.6,0-43.2,0-64.8,0 c-7.8-7.8-15.7-15.7-23.5-23.5" />
                        <path className={classes.st0} d="M773,458.9c-4,0-8.1,0-12.1,0c-9.5-9.5-19-19-28.5-28.5c-19.6,0-39.1,0-58.7,0c-11.6-11.6-23.2-23.2-34.8-34.8 c-1.5,0-2.9,0-4.4,0" />
                        <path className={classes.st0} d="M856.2,446.2c-24.8,0-49.7,0-74.5,0c-10-10-19.9-19.9-29.9-29.9c-25.7,0-51.4,0-77.1,0 c-17-17-33.9-33.9-50.9-50.9" />
                        <path className={classes.st0} d="M848.1,423.1c-22.8,0-45.5,0-68.3,0c0,0,0,0,0,0c-6.1-6.1-12.3-12.3-18.4-18.4c-27,0-54,0-81,0 c-22.1-22.1-44.1-44.1-66.2-66.2c-9.7,0-19.5,0-29.2,0" />
                        <path className={classes.st0} d="M829.5,386.8c-51.2,0-102.4,0-153.6,0c-20-20-40-40-60-60c-16.9,0-33.9,0-50.8,0c-9-9-18-18-27.1-27.1 c-11.7,0-23.4,0-35.1,0" />
                        <path className={classes.st0} d="M815.2,358.9c-16.7,0-33.4,0-50.1,0c-7.1,7.1-14.3,14.3-21.4,21.4c-20.2,0-40.4,0-60.6,0 c-15.8-15.8-31.6-31.6-47.4-47.4" />
                        <path className={classes.st0} d="M821.6,350.2c-23.5,0-47,0-70.5,0c-3.3,3.3-6.6,6.6-9.8,9.8c-18.6,0-37.2,0-55.8,0" />
                        <path className={classes.st0} d="M775.6,342.2c-36.5,0-73.1,0-109.6,0c-9.8-9.8-19.6-19.6-29.4-29.4c-12.2,0-24.5,0-36.7,0 c-3.1-3.1-6.3-6.3-9.4-9.4c-8.2,0-16.5,0-24.7,0" />
                        <path className={classes.st0} d="M806.7,327.9c-41.1,0-82.3,0-123.4,0" />
                        <path className={classes.st0} d="M799.7,285.9c-9.5,9.5-18.9,18.9-28.4,28.4c-20.1,0-40.2,0-60.3,0" />
                        <path className={classes.st0} d="M841.8,270.5c-25.2,0-50.5,0-75.7,0c-9.8,9.8-19.6,19.6-29.3,29.3c-12.8,0-25.7,0-38.5,0 c-5.2,5.2-10.5,10.5-15.7,15.7c-2,0-3.9,0-5.9,0" />
                        <path className={classes.st0} d="M847.7,255.9c-29.8,0-59.6,0-89.4,0c-11.9,11.9-23.8,23.8-35.6,35.6c-12.5,0-25,0-37.5,0 c-4.6,4.6-9.1,9.1-13.7,13.7c-6.3,0-12.5,0-18.8,0" />
                        <path className={classes.st0} d="M848,246.6c-38.9,0-77.9,0-116.8,0c-11,11-22,22-33,33c-16.7,0-33.3,0-50,0c-6.3,6.3-12.5,12.5-18.8,18.8 c-3.4,0-6.9,0-10.3,0" />
                        <path className={classes.st0} d="M851,225.7c-38.8,0-77.5,0-116.3,0c-11.8,11.8-23.6,23.6-35.5,35.5" />
                        <path className={classes.st0} d="M801.8,213.3c-25.4,0-50.8,0-76.2,0c-19.5,19.5-39.1,39.1-58.6,58.6c-13.3,0-26.7,0-40,0 c-4.7,4.7-9.4,9.4-14,14" />
                        <path className={classes.st0} d="M822.2,202.5c-32.9,0-65.8,0-98.7,0c-19.4,19.4-38.8,38.8-58.2,58.2c-15.2,0-30.4,0-45.6,0 c-7.2,7.2-14.4,14.4-21.6,21.6" />
                        <path className={classes.st0} d="M814,176.7c-15.9,0-31.8,0-47.6,0c-5.1,5.1-10.2,10.2-15.4,15.4c-5.5,0-11,0-16.5,0" />
                        <path className={classes.st0} d="M772.7,160.5c-4.5,0-9,0-13.5,0c-7.1,7.1-14.2,14.2-21.3,21.3c-10.2,0-20.3,0-30.5,0 c-21.7,21.7-43.4,43.4-65.1,65.1c-11.4,0-22.8,0-34.3,0c-13,13-26,26-38.9,38.9c-8.5,0-17.1,0-25.6,0" />
                        <path className={classes.st0} d="M697.9,179.2c-18.5,18.5-37,37-55.5,55.5c-6,0-12,0-17.9,0" />
                        <path className={classes.st0} d="M799.9,83.3c-26.2,26.2-52.4,52.4-78.6,78.6" />
                        <path className={classes.st0} d="M755.5,107.1c-38.1,38.1-76.2,76.2-114.3,114.3c-9.8,0-19.5,0-29.3,0c-14.2,14.2-28.4,28.4-42.7,42.7 c-8,0-16,0-24,0c-5.8,5.8-11.5,11.5-17.3,17.3c-8.6,0-17.2,0-25.9,0" />
                        <path className={classes.st0} d="M569.2,231.9c0,4.3,0.1,8.6,0.1,12.9" />
                        <path className={classes.st0} d="M588.5,209.9c-10.2,0-20.3,0-30.5,0c-2.6,2.6-5.1,5.1-7.7,7.7c0,10.7,0,21.3,0,32c-7.8,7.8-15.7,15.7-23.5,23.5 c-8.3,0-16.7,0-25,0" />
                        <path className={classes.st0} d="M600.1,183.1c-4,4-8,8-11.9,11.9c-12.8,0-25.6,0-38.4,0c-7.5,7.5-15,15-22.5,22.5c0,9.5,0,19,0,28.6 c-3.1,3.1-6.3,6.3-9.4,9.4c-5.5,0-10.9,0-16.4,0" />
                        <path className={classes.st0} d="M576.3,131.6c0,7.2,0,14.4,0,21.6c-5.8,5.8-11.5,11.5-17.3,17.3c-6.6,0-13.2,0-19.7,0 c-7.1,7.1-14.3,14.3-21.4,21.4c0,12.9,0,25.9,0,38.8c-2.6,2.6-5.1,5.1-7.7,7.7c-1.4,0-2.8,0-4.2,0" />
                        <path className={classes.st0} d="M543.2,157.9c-1.7,0-3.5,0-5.2,0c-10.9,10.9-21.9,21.9-32.8,32.8c0,9.8,0,19.5,0,29.3c-1.2,0-2.3,0-3.5,0" />
                        <path className={classes.st0} d="M622.3,108.2c-1.7,1.7-3.4,3.4-5.1,5.1c-7.6,0-15.3,0-22.9,0c0,10.2,0,20.3,0,30.5c-1.8,1.8-3.6,3.6-5.5,5.5" />
                        <path className={classes.st0} d="M652.7,115.3c-5.4,5.4-10.8,10.8-16.2,16.2c-5.8,0-11.5,0-17.3,0c-5.6,5.6-11.3,11.3-16.9,16.9" />
                        <path className={classes.st0} d="M669.8,102.7c-11.8,0-23.6,0-35.4,0c0-3.8,0-7.6,0-11.5c18.6,0,37.2,0,55.8,0c0,4.6,0,9.3,0,13.9 c-20.1,20.1-40.3,40.3-60.4,60.4c-13.7,0-27.4,0-41,0c-5.4,5.4-10.9,10.9-16.3,16.3" />
                        <path className={classes.st0} d="M734.1,28.2c0,7.4,0,14.8,0,22.1c-11.5,11.5-23,23-34.5,34.5c-25.4,0-50.7,0-76.1,0c-4.5,4.5-9.1,9.1-13.6,13.6 c-9.7,0-19.3,0-29,0c-30.8,30.8-61.7,61.7-92.5,92.5c0,2.7,0,5.4,0,8.2" />
                        <path className={classes.st0} d="M628.4,74.1c-7.7,0-15.3,0-23,0c-4.5,4.5-9.1,9.1-13.6,13.6c-3.8,0-7.6,0-11.4,0 c-35.2,35.2-70.4,70.4-105.6,105.6c0,1.8,0,3.5,0,5.3" />
                        <path className={classes.st0} d="M648.3,57.1c-13.5,0-26.9,0-40.4,0c-6.5,6.5-13,13-19.5,19.5c-2.6,0-5.2,0-7.7,0 c-39.3,39.3-78.6,78.6-117.9,117.9c0,1.5,0,3,0,4.5" />
                        <path className={classes.st0} d="M590.7,55.7c-45.2,45.2-90.4,90.4-135.7,135.7" />
                        <path className={classes.st0} d="M593.5,24.1c-23.8,23.8-47.5,47.5-71.3,71.3c0,4.1,0,8.2,0,12.3c-22.2,22.2-44.3,44.3-66.5,66.5" />
                        <path className={classes.st0} d="M587,19.6c-12.6,12.6-25.1,25.1-37.7,37.7c-4.9,0-9.7,0-14.6,0c-13,13-26.1,26.1-39.1,39.1c0,7.4,0,14.8,0,22.1 c-14.2,14.2-28.3,28.3-42.5,42.5" />
                        <path className={classes.st0} d="M568.9,25.5c-6.5,6.5-13.1,13.1-19.6,19.6c-6.1,0-12.2,0-18.4,0c-14.7,14.7-29.3,29.3-44,44c0,8.3,0,16.7,0,25 c-14.9,14.9-29.8,29.8-44.7,44.7c0,13.4,0,26.8,0,40.2" />
                        <path className={classes.st0} d="M534,26.6c-21.5,21.5-42.9,42.9-64.4,64.4c0,8.4,0,16.8,0,25.2c-4.7,4.7-9.5,9.5-14.2,14.2" />
                        <path className={classes.st0} d="M524.5,20.7c-21.5,21.5-43,43-64.5,64.5c0,4.8,0,9.7,0,14.5c-3.8,3.8-7.7,7.7-11.5,11.5c0,2.3,0,4.7,0,7" />
                        <path className={classes.st0} d="M505.4,27.5c-22,22-44.1,44.1-66.1,66.1c0,12.5,0,25,0,37.6c-2,2-4.1,4.1-6.1,6.1c0,20.7,0,41.4,0,62.2" />
                        <path className={classes.st0} d="M523.7,0c-30.1,30.1-60.3,60.3-90.4,90.4c0,9.3,0,18.5,0,27.8c-7.7,7.7-15.4,15.4-23.2,23.2c0,6.7,0,13.3,0,20 c-2.5,2.5-5.1,5.1-7.6,7.6c0,9.3,0,18.6,0,27.9c1.9,1.9,3.7,3.7,5.6,5.6" />
                        <path className={classes.st0} d="M421.3,180.8c0,3.6,0,7.2,0,10.9" />
                        <path className={classes.st0} d="M495,17.8c-23.7,23.7-47.5,47.5-71.2,71.2c0,10.2,0,20.4,0,30.6c-6.7,6.7-13.4,13.4-20.1,20.1 c0,6.8,0,13.6,0,20.4c-3.3,3.3-6.6,6.6-9.9,9.9c0,9.7,0,19.4,0,29.1" />
                        <path className={classes.st0} d="M437.3,52.6c0,2.9,0,5.7,0,8.6c-9.9,9.9-19.8,19.8-29.7,29.7c0,4,0,7.9,0,11.9c-5.6,5.6-11.1,11.1-16.7,16.7 c0,10.1,0,20.1,0,30.2" />
                        <path className={classes.st0} d="M417.4,11.3c0,18.4,0,36.8,0,55.2c-12.2,12.2-24.5,24.5-36.7,36.7c0,24.8,0,49.6,0,74.4 c1.7,1.7,3.4,3.4,5.1,5.1c0,1.5,0,3.1,0,4.6" />
                        <path className={classes.st0} d="M402.4,46.7c-5.6,5.6-11.2,11.2-16.7,16.7c0,6.5,0,13.1,0,19.6" />
                        <path className={classes.st0} d="M375,20c0,57,0,114,0,171c2.9,2.9,5.8,5.8,8.7,8.7" />
                        <path className={classes.st0} d="M367.7,13.1c0,61.6,0,123.2,0,184.8" />
                        <path className={classes.st0} d="M354.6,17.2c0,44.9,0,89.8,0,134.7c2.5,2.5,5,5,7.5,7.5c0,9.2,0,18.3,0,27.5" />
                        <path className={classes.st0} d="M347.5,25.4c0,9.3,0,18.6,0,28c-5.9,5.9-11.9,11.9-17.8,17.8c0,22.3,0,44.5,0,66.8 c7.3,11.8,14.6,23.6,21.9,35.5c0,8.6,0,17.2,0,25.9" />
                        <path className={classes.st0} d="M320.4,29.9c0,38.8,0,77.5,0,116.3c6.2,9.5,12.4,19,18.6,28.5c0,7.9,0,15.8,0,23.6" />
                        <path className={classes.st0} d="M324.9,174c0,5.9,0,11.8,0,17.7" />
                        <path className={classes.st0} d="M268.8,93.8c4.3,0,8.6,0,13,0c7.7,7.7,15.5,15.5,23.2,23.2c0,21.4,0,42.7,0,64.1c3.5,3.5,7,7,10.5,10.5 c0,3.8,0,7.5,0,11.3c3.2,3.2,6.4,6.4,9.6,9.6" />
                        <path className={classes.st0} d="M295.9,129.4c0,20.2,0,40.4,0,60.6c1.5,1.5,3,3,4.5,4.5" />
                        <path className={classes.st0} d="M816.6,33.3c-51.5,51.5-102.9,102.9-154.4,154.4" />
                        <path className={classes.st0} d="M772.4,29.3c0,9,0,18,0,27c-45.4,45.4-90.7,90.7-136.1,136.1c0,2.2,0,4.4,0,6.5" />
                        <path className={classes.st0} d="M753.1,21.7c0,11.7,0,23.4,0,35c-46.1,46.1-92.3,92.3-138.4,138.4c0,1.9,0,3.9,0,5.8" />
                        <path className={classes.st0} d="M720.3,32.1c0,6.8,0,13.6,0,20.4c-8.7,0-17.5,0-26.2,0c-8,8-16,16-24,24c-4.7,0-9.4,0-14.1,0" />
                        <path className={classes.st0} d="M709.1,23.5C695.5,37,682,50.6,668.4,64.1" />
                        <path className={classes.st0} d="M303.9,14.1c0,12.9,0,25.7,0,38.6c3.1,3.1,6.3,6.3,9.4,9.4c0,12.8,0,25.5,0,38.3" />
                        <path className={classes.st0} d="M255.9,18.1c16.1,16.1,32.2,32.2,48.4,48.4c0,7.7,0,15.4,0,23.2" />
                        <path className={classes.st0} d="M245,20.2c0,9,0,18,0,27c6.4,0,12.9,0,19.3,0c2.2,2.2,4.5,4.5,6.7,6.7" />
                        <path className={classes.st0} d="M227.6,17.1c0,11.6,0,23.3,0,34.9c4.2,4.2,8.4,8.4,12.6,12.6c5.4,0,10.7,0,16.1,0" />
                        <path className={classes.st0} d="M211.4,16.9c0,11.8,0,23.6,0,35.3c7.4,7.4,14.8,14.8,22.3,22.3c13.1,0,26.2,0,39.4,0" />
                        <path className={classes.st0} d="M192,21.4c0,13,0,26,0,39.1c14.6,14.6,29.2,29.2,43.8,43.8c9.3,0,18.5,0,27.8,0c3,3,6,6,9.1,9.1 c0,8.3,0,16.6,0,24.9c3.5,3.5,7.1,7.1,10.6,10.6" />
                        <path className={classes.st0} d="M178.6,17.1c0,15.3,0,30.5,0,45.8c14.2,14.2,28.5,28.5,42.7,42.7" />
                        <path className={classes.st0} d="M25.8,43c39.2,0,78.3,0,117.5,0c15,15,30,30,45,45" />
                        <path className={classes.st0} d="M0.6,52.8c45.5,0,90.9,0,136.4,0c5.6,5.6,11.3,11.3,16.9,16.9" />
                        <path className={classes.st0} d="M117.7,70.6c5.8,5.8,11.6,11.6,17.4,17.4c5.2,0,10.5,0,15.7,0" />
                        <path className={classes.st0} d="M60.6,70.6c8.8,0,17.5,0,26.3,0C98.6,82.3,110.3,94,122,105.8c25.5,0,51,0,76.6,0c6.1,6.1,12.2,12.2,18.2,18.2 c8.5,0,16.9,0,25.4,0c14.1,14.1,28.1,28.1,42.2,42.2c0,9.4,0,18.7,0,28.1c13.3,13.3,26.7,26.7,40,40" />
                        <path className={classes.st0} d="M-0.8,91.4c27.4,0,54.9,0,82.3,0c8.4,8.4,16.8,16.8,25.3,25.3c27.7,0,55.5,0,83.2,0 c17.5,17.5,34.9,34.9,52.4,52.4c0,9,0,18,0,27c3.1,3.1,6.3,6.3,9.4,9.4c5.8,0,11.6,0,17.4,0c17.9,17.9,35.9,35.9,53.8,53.8" />
                        <path className={classes.st0} d="M271,223.5c12.7,12.7,25.4,25.4,38.2,38.2" />
                        <path className={classes.st0} d="M11.5,109.3c11.4,0,22.8,0,34.2,0c3.2,3.2,6.3,6.3,9.5,9.5c11.8,0,23.6,0,35.3,0c7.1,7.1,14.1,14.1,21.2,21.2 c25.4,0,50.8,0,76.2,0c11.9,11.9,23.9,23.9,35.8,35.8c0,5.5,0,11,0,16.5" />
                        <path className={classes.st0} d="M204.2,174.7c-2-2-4-4-5.9-5.9c-13,0-26.1,0-39.1,0c-3.9-3.9-7.7-7.7-11.6-11.6c-40.5,0-81,0-121.5,0" />
                        <path className={classes.st0} d="M12.2,177.2c12.5,0,25,0,37.6,0c3.3-3.3,6.6-6.6,9.8-9.8c27.5,0,55.1,0,82.6,0c4.2,4.2,8.4,8.4,12.6,12.6 c14.2,0,28.4,0,42.7,0c2.8,2.8,5.6,5.6,8.4,8.4" />
                        <path className={classes.st0} d="M26.2,194.3c11.2,0,22.5,0,33.7,0c2.6-2.6,5.2-5.2,7.8-7.8c22.8,0,45.6,0,68.4,0c2.6,2.6,5.2,5.2,7.8,7.8 c12.2,0,24.4,0,36.6,0" />
                        <path className={classes.st0} d="M27.1,207.1c17.2,0,34.4,0,51.6,0c3.4-3.4,6.7-6.7,10.1-10.1c12,0,24,0,36.1,0c7,7,14,14,21,21 c28.8,0,57.5,0,86.3,0" />
                        <path className={classes.st0} d="M26.4,218.4c26.4,0,52.8,0,79.2,0" />
                        <path className={classes.st0} d="M33.1,231c36.7,0,73.3,0,110,0c1.7,1.7,3.5,3.5,5.2,5.2c18.1,0,36.3,0,54.4,0c3.2-3.2,6.3-6.3,9.5-9.5 c10.6,0,21.3,0,31.9,0c21.8,21.8,43.6,43.6,65.4,65.4c4.8,0,9.5,0,14.3,0" />
                        <path className={classes.st0} d="M12,246.6c35.3,0,70.7,0,106,0c2.9,2.9,5.8,5.8,8.7,8.7c11.1,0,22.2,0,33.2,0c3.3-3.3,6.6-6.6,9.8-9.8 c25.4,0,50.8,0,76.2,0c2.3,2.3,4.6,4.6,7,7" />
                        <path className={classes.st0} d="M27.1,266.9c13.2,0,26.3,0,39.5,0c2.9-2.9,5.8-5.8,8.7-8.7c11.6,0,23.2,0,34.9,0c2.9,2.9,5.7,5.7,8.6,8.6 c5.7,0,11.5,0,17.2,0c3.4,3.4,6.9,6.9,10.3,10.3c5.6,0,11.1,0,16.7,0c2.4-2.4,4.8-4.8,7.2-7.2c10.9,0,21.8,0,32.6,0c2-2,4-4,6-6" />
                        <path className={classes.st0} d="M12,276.7c21.9,0,43.7,0,65.6,0c3,3,6.1,6.1,9.1,9.1c11.4,0,22.8,0,34.1,0c2.3-2.3,4.5-4.5,6.8-6.8" />
                        <path className={classes.st0} d="M32.6,301.5c8.1,0,16.2,0,24.3,0c2.8-2.8,5.6-5.6,8.4-8.4c22.6,0,45.2,0,67.8,0c3.4-3.4,6.9-6.9,10.3-10.3 c5,0,10,0,15,0c3.4,3.4,6.8,6.8,10.1,10.1c16.7,0,33.3,0,50,0" />
                        <path className={classes.st0} d="M26.7,311c31.8,0,63.5,0,95.3,0" />
                        <path className={classes.st0} d="M26.8,322.8c36.8,0,73.7,0,110.5,0c4.8-4.8,9.5-9.5,14.3-14.3c23.7,0,47.4,0,71,0c5-5,10-10,15.1-15.1 c0-8.9,0-17.8,0-26.7" />
                        <path className={classes.st0} d="M38,341.9c32,0,63.9,0,95.9,0c3,3,6,6,9.1,9.1c19.8,0,39.6,0,59.3,0c2,2,4,4,6.1,6.1" />
                        <path className={classes.st0} d="M26.2,374.1c14.2,0,28.4,0,42.6,0c7.1-7.1,14.3-14.3,21.4-21.4c10.7,0,21.4,0,32.2,0" />
                        <path className={classes.st0} d="M54.3,383.1c11.8,0,23.7,0,35.5,0c7-7,14-14,21-21c26.1,0,52.3,0,78.4,0" />
                        <path className={classes.st0} d="M32.9,402c26.5,0,53,0,79.5,0c0,0,0,0,0,0c7.7-7.7,15.4-15.4,23.1-23.1c15.5,0,31,0,46.5,0 c3.2,3.2,6.4,6.4,9.6,9.6c18.3,0,36.5,0,54.8,0c2.6-2.6,5.2-5.2,7.7-7.7c0-3,0-6,0-9.1" />
                        <path className={classes.st0} d="M26.2,413.1c28.9,0,57.7,0,86.6,0c9.3-9.3,18.5-18.5,27.8-27.8c12.6,0,25.2,0,37.8,0c4,4,8,8,12.1,12.1 c17.3,0,34.6,0,51.9,0" />
                        <path className={classes.st0} d="M93.6,424.2c15.1,0,30.1,0,45.2,0c3-3,6.1-6.1,9.1-9.1c17.3,0,34.6,0,52,0c4.9,4.9,9.7,9.7,14.6,14.6 c6.3,0,12.5,0,18.8,0c2.2-2.2,4.4-4.4,6.5-6.5" />
                        <path className={classes.st0} d="M7.9,423.7c16.9,0,33.9,0,50.8,0c9.7,9.7,19.4,19.4,29.1,29.1c13.7,0,27.4,0,41.1,0 c11.3,11.3,22.6,22.6,33.9,33.9c30.4,0,60.8,0,91.3,0c6.6-6.6,13.2-13.2,19.9-19.9c0-18.1,0-36.2,0-54.3 c6.1-6.1,12.3-12.3,18.4-18.4c0-14.8,0-29.6,0-44.3c4.4-4.4,8.8-8.8,13.2-13.2c5.1,0,10.2,0,15.3,0" />
                        <path className={classes.st0} d="M245.5,444.3c-4.3,4.3-8.6,8.6-12.9,12.9c-16.6,0-33.2,0-49.8,0c-2.9-2.9-5.7-5.7-8.6-8.6c0-7.4,0-14.8,0-22.2 c6,0,12,0,18.1,0c1.5,1.5,2.9,2.9,4.4,4.4" />
                        <path className={classes.st0} d="M231.6,474.8c5.4,0,10.9,0,16.3,0c4.6-4.6,9.1-9.1,13.7-13.7c0-19.7,0-39.3,0-59c5.8-5.8,11.6-11.6,17.4-17.4 c0-23.8,0-47.7,0-71.5c3.1,0,6.3,0,9.4,0c2.7,2.7,5.3,5.3,8,8c8.3,0,16.5,0,24.8,0" />
                        <path className={classes.st0} d="M218,374.1c2.2,0,4.4,0,6.6,0c5.6-5.6,11.1-11.1,16.7-16.7c9,0,17.9,0,26.9,0c0-17.5,0-35,0-52.5" />
                        <path className={classes.st0} d="M215,344.8c10.8,0,21.7,0,32.5,0c3.2-3.2,6.4-6.4,9.7-9.7c0-6.4,0-12.7,0-19.1c-3.8-3.8-7.5-7.5-11.3-11.3 c0-11.7,0-23.4,0-35.1c4.3,0,8.5,0,12.8,0c12.3,12.3,24.6,24.6,36.9,36.9c8.5,0,17.1,0,25.6,0" />
                        <path className={classes.st0} d="M152.6,339.1c4.2,0,8.4,0,12.5,0c3.3-3.3,6.6-6.6,9.9-9.9l0,0c21.1,0,42.2,0,63.3,0" />
                        <path className={classes.st0} d="M294.5,406.7c3.7-3.7,7.3-7.3,11-11c0-11.5,0-23,0-34.6c3.6-3.6,7.2-7.2,10.8-10.8c1.9,0,3.7,0,5.6,0" />
                        <path className={classes.st0} d="M615.3,39c15.2,0,30.4,0,45.6,0" />
                        <path className={classes.st0} d="M154.9,208.2c28.9,0,57.7,0,86.6,0c23.4,23.4,46.8,46.8,70.2,70.2" />
                        <path className={classes.st0} d="M242.1,141.2c9.5,9.5,19,19,28.5,28.5c0,5.2,0,10.4,0,15.6" />
                        <circle className={classes.st0} cx="325.1" cy="350.3" r="3.1" />
                        <circle className={classes.st0} cx="241" cy="421" r="3.1" />
                        <circle className={classes.st0} cx="246.9" cy="442.6" r="3.1" />
                        <circle className={classes.st0} cx="239.3" cy="508.3" r="3.1" />
                        <circle className={classes.st0} cx="300.5" cy="501.3" r="3.1" />
                        <circle className={classes.st0} cx="108.6" cy="519.7" r="3.1" />
                        <circle className={classes.st0} cx="121.4" cy="507.2" r="3.1" />
                        <circle className={classes.st0} cx="198.3" cy="432.4" r="3.1" />
                        <circle className={classes.st0} cx="244.9" cy="397.4" r="3.1" />
                        <circle className={classes.st0} cx="254.1" cy="369.1" r="3.1" />
                        <circle className={classes.st0} cx="268.2" cy="302.7" r="3.1" />
                        <circle className={classes.st0} cx="237.8" cy="264.5" r="3.1" />
                        <circle className={classes.st0} cx="129.6" cy="277.1" r="3.1" />
                        <circle className={classes.st0} cx="254.5" cy="254.4" r="3.1" />
                        <circle className={classes.st0} cx="221.2" cy="293.1" r="3.1" />
                        <circle className={classes.st0} cx="124.6" cy="311" r="3.1" />
                        <circle className={classes.st0} cx="210.5" cy="262.1" r="3.1" />
                        <circle className={classes.st0} cx="150.3" cy="339.1" r="3.1" />
                        <circle className={classes.st0} cx="240.9" cy="329.2" r="3.1" />
                        <circle className={classes.st0} cx="209.9" cy="359" r="3.1" />
                        <circle className={classes.st0} cx="191.8" cy="362.1" r="3.1" />
                        <circle className={classes.st0} cx="215.6" cy="373.9" r="3.1" />
                        <circle className={classes.st0} cx="124.6" cy="352.6" r="3.1" />
                        <circle className={classes.st0} cx="324.4" cy="336.7" r="3.1" />
                        <circle className={classes.st0} cx="324.4" cy="321.2" r="3.1" />
                        <circle className={classes.st0} cx="324.4" cy="306.6" r="3.1" />
                        <circle className={classes.st0} cx="327" cy="292" r="3.1" />
                        <circle className={classes.st0} cx="313.7" cy="280" r="3.1" />
                        <circle className={classes.st0} cx="311" cy="263.6" r="3.1" />
                        <circle className={classes.st0} cx="269.7" cy="221.6" r="3.1" />
                        <circle className={classes.st0} cx="108.1" cy="218.5" r="3.1" />
                        <circle className={classes.st0} cx="234.6" cy="218" r="3.1" />
                        <circle className={classes.st0} cx="207.3" cy="190.1" r="3.1" />
                        <circle className={classes.st0} cx="223.6" cy="194.5" r="3.1" />
                        <circle className={classes.st0} cx="270.6" cy="187.4" r="3.1" />
                        <circle className={classes.st0} cx="284.8" cy="150" r="3.1" />
                        <circle className={classes.st0} cx="240.7" cy="139.7" r="3.1" />
                        <circle className={classes.st0} cx="295.9" cy="127.3" r="3.1" />
                        <circle className={classes.st0} cx="155.5" cy="71.4" r="3.1" />
                        <circle className={classes.st0} cx="223.5" cy="107.3" r="3.1" />
                        <circle className={classes.st0} cx="153.3" cy="88.2" r="3.1" />
                        <circle className={classes.st0} cx="190.4" cy="89.6" r="3.1" />
                        <circle className={classes.st0} cx="313.3" cy="103.1" r="3.1" />
                        <circle className={classes.st0} cx="304.3" cy="92.3" r="3.1" />
                        <circle className={classes.st0} cx="259.1" cy="64.5" r="3.1" />
                        <circle className={classes.st0} cx="273" cy="55.7" r="3.1" />
                        <circle className={classes.st0} cx="275.3" cy="74.5" r="3.1" />
                        <circle className={classes.st0} cx="182.8" cy="194.3" r="3.1" />
                        <circle className={classes.st0} cx="205.9" cy="176.2" r="3.1" />
                        <circle className={classes.st0} cx="325" cy="261.4" r="3.1" />
                        <circle className={classes.st0} cx="326.2" cy="235.9" r="3.1" />
                        <circle className={classes.st0} cx="327.1" cy="214.2" r="3.1" />
                        <circle className={classes.st0} cx="324.9" cy="194.2" r="3.1" />
                        <circle className={classes.st0} cx="339" cy="200.8" r="3.1" />
                        <circle className={classes.st0} cx="351.6" cy="202" r="3.1" />
                        <circle className={classes.st0} cx="362.1" cy="189.4" r="3.1" />
                        <circle className={classes.st0} cx="367.7" cy="200.6" r="3.1" />
                        <circle className={classes.st0} cx="385.3" cy="201.4" r="3.1" />
                        <circle className={classes.st0} cx="385.7" cy="189.9" r="3.1" />
                        <circle className={classes.st0} cx="390.9" cy="152.4" r="3.1" />
                        <circle className={classes.st0} cx="385.7" cy="85.6" r="3.1" />
                        <circle className={classes.st0} cx="393.8" cy="202" r="3.1" />
                        <circle className={classes.st0} cx="409.8" cy="204.3" r="3.1" />
                        <circle className={classes.st0} cx="421.3" cy="194" r="3.1" />
                        <circle className={classes.st0} cx="421.3" cy="177.9" r="3.1" />
                        <circle className={classes.st0} cx="404" cy="44.9" r="3.1" />
                        <circle className={classes.st0} cx="437.3" cy="50.3" r="3.1" />
                        <circle className={classes.st0} cx="433.2" cy="202" r="3.1" />
                        <circle className={classes.st0} cx="442.2" cy="201.4" r="3.1" />
                        <circle className={classes.st0} cx="453.4" cy="193.1" r="3.1" />
                        <circle className={classes.st0} cx="462.8" cy="201.4" r="3.1" />
                        <circle className={classes.st0} cx="474.8" cy="200.9" r="3.1" />
                        <circle className={classes.st0} cx="488.3" cy="201.4" r="3.1" />
                        <circle className={classes.st0} cx="587.5" cy="150.8" r="3.1" />
                        <circle className={classes.st0} cx="576.3" cy="129.5" r="3.1" />
                        <circle className={classes.st0} cx="546.3" cy="157.9" r="3.1" />
                        <circle className={classes.st0} cx="623.7" cy="106.3" r="3.1" />
                        <circle className={classes.st0} cx="654.5" cy="113.5" r="3.1" />
                        <circle className={classes.st0} cx="672" cy="102.7" r="3.1" />
                        <circle className={classes.st0} cx="666.6" cy="65.6" r="3.1" />
                        <circle className={classes.st0} cx="653.4" cy="76.6" r="3.1" />
                        <circle className={classes.st0} cx="664.1" cy="38.7" r="3.1" />
                        <circle className={classes.st0} cx="612.9" cy="39" r="3.1" />
                        <circle className={classes.st0} cx="498.9" cy="219.9" r="3.1" />
                        <circle className={classes.st0} cx="570.9" cy="183.7" r="3.1" />
                        <circle className={classes.st0} cx="569.3" cy="228.8" r="3.1" />
                        <circle className={classes.st0} cx="569.3" cy="247.2" r="3.1" />
                        <circle className={classes.st0} cx="614.7" cy="203.9" r="3.1" />
                        <circle className={classes.st0} cx="660.3" cy="190" r="3.1" />
                        <circle className={classes.st0} cx="732.1" cy="192.1" r="3.1" />
                        <circle className={classes.st0} cx="719.7" cy="163.7" r="3.1" />
                        <circle className={classes.st0} cx="636.3" cy="201.4" r="3.1" />
                        <circle className={classes.st0} cx="600.7" cy="150.1" r="3.1" />
                        <circle className={classes.st0} cx="503.5" cy="238.4" r="3.1" />
                        <circle className={classes.st0} cx="498.9" cy="255.5" r="3.1" />
                        <circle className={classes.st0} cx="499.4" cy="273.1" r="3.1" />
                        <circle className={classes.st0} cx="499.8" cy="281.3" r="3.1" />
                        <circle className={classes.st0} cx="541.2" cy="286" r="3.1" />
                        <circle className={classes.st0} cx="616.1" cy="298.3" r="3.1" />
                        <circle className={classes.st0} cx="611.5" cy="287.6" r="3.1" />
                        <circle className={classes.st0} cx="596.5" cy="284.5" r="3.1" />
                        <circle className={classes.st0} cx="622.3" cy="234.8" r="3.1" />
                        <circle className={classes.st0} cx="563" cy="303.3" r="3.1" />
                        <circle className={classes.st0} cx="650.6" cy="305.3" r="3.1" />
                        <circle className={classes.st0} cx="582.5" cy="338.5" r="3.1" />
                        <circle className={classes.st0} cx="570.8" cy="374.8" r="3.1" />
                        <circle className={classes.st0} cx="634" cy="331.4" r="3.1" />
                        <circle className={classes.st0} cx="622.4" cy="363.7" r="3.1" />
                        <circle className={classes.st0} cx="605.3" cy="443.3" r="3.1" />
                        <circle className={classes.st0} cx="630.9" cy="395.5" r="3.1" />
                        <circle className={classes.st0} cx="682.3" cy="360.5" r="3.1" />
                        <circle className={classes.st0} cx="627.7" cy="451.3" r="3.1" />
                        <circle className={classes.st0} cx="663.1" cy="526.1" r="3.1" />
                        <circle className={classes.st0} cx="638.8" cy="415.9" r="3.1" />
                        <circle className={classes.st0} cx="680.8" cy="327.9" r="3.1" />
                        <circle className={classes.st0} cx="809" cy="327.9" r="3.1" />
                        <circle className={classes.st0} cx="708.7" cy="314.3" r="3.1" />
                        <circle className={classes.st0} cx="697.9" cy="262.9" r="3.1" />
                        <circle className={classes.st0} cx="801.2" cy="284.5" r="3.1" />
                        <circle className={classes.st0} cx="674.1" cy="315.6" r="3.1" />
                        <circle className={classes.st0} cx="500.6" cy="299.8" r="3.1" />
                        <circle className={classes.st0} cx="500.3" cy="311.5" r="3.1" />
                        <circle className={classes.st0} cx="504.1" cy="334" r="3.1" />
                        <circle className={classes.st0} cx="505.7" cy="349.1" r="3.1" />
                        <circle className={classes.st0} cx="506.7" cy="361.4" r="3.1" />
                        <circle className={classes.st0} cx="500.4" cy="382.6" r="3.1" />
                        <circle className={classes.st0} cx="475.4" cy="379" r="3.1" />
                        <circle className={classes.st0} cx="454.7" cy="390.2" r="3.1" />
                        <circle className={classes.st0} cx="435.3" cy="390.4" r="3.1" />
                        <circle className={classes.st0} cx="395.4" cy="371.7" r="3.1" />
                        <circle className={classes.st0} cx="367.8" cy="393.6" r="3.1" />
                        <circle className={classes.st0} cx="367.2" cy="377.2" r="3.1" />
                        <circle className={classes.st0} cx="375.4" cy="433" r="3.1" />
                        <circle className={classes.st0} cx="367.2" cy="414.1" r="3.1" />
                        <circle className={classes.st0} cx="385.5" cy="387.9" r="3.1" />
                        <circle className={classes.st0} cx="409.8" cy="392.7" r="3.1" />
                        <circle className={classes.st0} cx="424.8" cy="411.4" r="3.1" />
                        <circle className={classes.st0} cx="439.8" cy="452.1" r="3.1" />
                        <circle className={classes.st0} cx="462" cy="431" r="3.1" />
                        <circle className={classes.st0} cx="470.3" cy="453.1" r="3.1" />
                        <circle className={classes.st0} cx="440.8" cy="474.8" r="3.1" />
                        <circle className={classes.st0} cx="407.4" cy="430.6" r="3.1" />
                        <circle className={classes.st0} cx="403.8" cy="452.9" r="3.1" />
                        <circle className={classes.st0} cx="374.1" cy="490.6" r="3.1" />
                        <circle className={classes.st0} cx="389.6" cy="511.8" r="3.1" />
                        <circle className={classes.st0} cx="454.1" cy="176" r="3.1" />
                        <circle className={classes.st0} cx="451.6" cy="162.7" r="3.1" />
                        <circle className={classes.st0} cx="448.4" cy="120.7" r="3.1" />
                        <circle className={classes.st0} cx="453.7" cy="132.6" r="3.1" />
                        <circle className={classes.st0} cx="324.9" cy="171.4" r="3.1" />
                        <circle className={classes.st0} cx="302" cy="196.3" r="3.1" />
                        <g className={classes.boxCore}>
                            <g id="Group_1470_1_">
                                <path id="Path_11784_1_" className={classes.st1} d="M492.1,365.9h-156v-156h156V365.9z M338.1,363.9h152v-152h-152V363.9z" />
                            </g>
                            <g id="Group_1494_1_">
                                <g id="Group_1483_1_">
                                    <rect id="Rectangle_1117_1_" x="346.6" y="220.4" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1118_1_" x="358.2" y="220.4" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1119_1_" x="369.7" y="220.4" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1120_1_" x="381.3" y="220.4" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1121_1_" x="392.9" y="220.4" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1122_1_" x="404.4" y="220.4" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1123_1_" x="416" y="220.4" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1124_1_" x="427.5" y="220.4" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1125_1_" x="439.1" y="220.4" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1126_1_" x="450.6" y="220.4" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1127_1_" x="462.2" y="220.4" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1128_1_" x="473.8" y="220.4" className={classes.st1} width="8.3" height="8.3" />
                                </g>
                                <g id="Group_1484_1_">
                                    <rect id="Rectangle_1129_1_" x="346.6" y="233.1" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1130_1_" x="358.2" y="233.1" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1131_1_" x="369.7" y="233.1" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1132_1_" x="381.3" y="233.1" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1133_1_" x="392.9" y="233.1" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1134_1_" x="404.4" y="233.1" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1135_1_" x="416" y="233.1" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1136_1_" x="427.5" y="233.1" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1137_1_" x="439.1" y="233.1" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1138_1_" x="450.6" y="233.1" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1139_1_" x="462.2" y="233.1" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1140_1_" x="473.8" y="233.1" className={classes.st1} width="8.3" height="8.3" />
                                </g>
                                <g id="Group_1485_1_">
                                    <rect id="Rectangle_1141_1_" x="346.6" y="245.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1142_1_" x="358.2" y="245.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1143_1_" x="369.7" y="245.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1144_1_" x="381.3" y="245.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1145_1_" x="392.9" y="245.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1146_1_" x="404.4" y="245.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1147_1_" x="416" y="245.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1148_1_" x="427.5" y="245.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1149_1_" x="439.1" y="245.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1150_1_" x="450.7" y="245.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1151_1_" x="462.2" y="245.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1152_1_" x="473.8" y="245.8" className={classes.st1} width="8.3" height="8.3" />
                                </g>
                                <g id="Group_1486_1_">
                                    <rect id="Rectangle_1153_1_" x="346.6" y="258.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1154_1_" x="358.2" y="258.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1155_1_" x="369.8" y="258.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1156_1_" x="381.3" y="258.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1157_1_" x="392.9" y="258.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1158_1_" x="404.4" y="258.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1159_1_" x="416" y="258.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1160_1_" x="427.5" y="258.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1161_1_" x="439.1" y="258.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1162_1_" x="450.7" y="258.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1163_1_" x="462.2" y="258.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1164_1_" x="473.8" y="258.5" className={classes.st1} width="8.3" height="8.3" />
                                </g>
                                <g id="Group_1487_1_">
                                    <rect id="Rectangle_1165_1_" x="346.6" y="271.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1166_1_" x="358.2" y="271.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1167_1_" x="462.2" y="271.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1168_1_" x="473.8" y="271.2" className={classes.st1} width="8.3" height="8.3" />
                                </g>
                                <g id="Group_1488_1_">
                                    <rect id="Rectangle_1169_1_" x="346.6" y="283.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1170_1_" x="358.2" y="283.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1171_1_" x="462.2" y="283.8" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1172_1_" x="473.8" y="283.8" className={classes.st1} width="8.3" height="8.3" />
                                </g>
                                <g id="Group_1489_1_">
                                    <rect id="Rectangle_1173_1_" x="346.6" y="296.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1174_1_" x="358.2" y="296.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1175_1_" x="462.2" y="296.5" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1176_1_" x="473.8" y="296.5" className={classes.st1} width="8.3" height="8.3" />
                                </g>
                                <g id="Group_1490_1_">
                                    <rect id="Rectangle_1177_1_" x="346.6" y="309.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1178_1_" x="358.2" y="309.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1179_1_" x="369.8" y="309.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1180_1_" x="381.3" y="309.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1181_1_" x="392.9" y="309.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1182_1_" x="404.4" y="309.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1183_1_" x="416" y="309.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1184_1_" x="427.5" y="309.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1185_1_" x="439.1" y="309.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1186_1_" x="450.7" y="309.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1187_1_" x="462.2" y="309.2" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1188_1_" x="473.8" y="309.2" className={classes.st1} width="8.3" height="8.3" />
                                </g>
                                <g id="Group_1491_1_">
                                    <rect id="Rectangle_1189_1_" x="346.6" y="321.9" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1190_1_" x="358.2" y="321.9" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1191_1_" x="369.8" y="321.9" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1192_1_" x="381.3" y="321.9" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1193_1_" x="392.9" y="321.9" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1194_1_" x="404.4" y="321.9" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1195_1_" x="416" y="321.9" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1196_1_" x="427.5" y="321.9" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1197_1_" x="439.1" y="321.9" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1198_1_" x="450.7" y="321.9" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1199_1_" x="462.2" y="321.9" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1200_1_" x="473.8" y="321.9" className={classes.st1} width="8.3" height="8.3" />
                                </g>
                                <g id="Group_1492_1_">
                                    <rect id="Rectangle_1201_1_" x="346.6" y="334.6" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1202_1_" x="358.2" y="334.6" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1203_1_" x="369.7" y="334.6" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1204_1_" x="381.3" y="334.6" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1205_1_" x="392.9" y="334.6" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1206_1_" x="404.4" y="334.6" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1207_1_" x="416" y="334.6" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1208_1_" x="427.5" y="334.6" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1209_1_" x="439.1" y="334.6" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1210_1_" x="450.6" y="334.6" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1211_1_" x="462.2" y="334.6" className={classes.st1} width="8.3" height="8.3" />
                                    <rect id="Rectangle_1212_1_" x="473.8" y="334.6" className={classes.st1} width="8.3" height="8.3" />
                                </g>
                                <g id="Group_1493_1_">

                                    <rect id="Rectangle_1213_1_" x="346.6" y="347.9" transform="matrix(1 -4.991621e-03 4.991621e-03 1 -1.7529 1.7554)" className={classes.st1} width="8.3" height="8.3" />

                                    <rect id="Rectangle_1214_1_" x="358.2" y="347.8" transform="matrix(1 -4.991621e-03 4.991621e-03 1 -1.7525 1.8131)" className={classes.st1} width="8.3" height="8.3" />

                                    <rect id="Rectangle_1215_1_" x="369.7" y="347.8" transform="matrix(1 -4.974168e-03 4.974168e-03 1 -1.746 1.8642)" className={classes.st1} width="8.3" height="8.3" />

                                    <rect id="Rectangle_1216_1_" x="381.3" y="347.7" transform="matrix(1 -4.974168e-03 4.974168e-03 1 -1.7455 1.9217)" className={classes.st1} width="8.3" height="8.3" />

                                    <rect id="Rectangle_1217_1_" x="392.9" y="347.7" transform="matrix(1 -4.999937e-03 4.999937e-03 1 -1.7541 1.9895)" className={classes.st1} width="8.3" height="8.3" />

                                    <rect id="Rectangle_1218_1_" x="404.4" y="347.6" transform="matrix(1 -4.999937e-03 4.999937e-03 1 -1.7537 2.0473)" className={classes.st1} width="8.3" height="8.3" />

                                    <rect id="Rectangle_1219_1_" x="416" y="347.5" transform="matrix(1 -4.991621e-03 4.991621e-03 1 -1.7503 2.1015)" className={classes.st1} width="8.3" height="8.3" />

                                    <rect id="Rectangle_1220_1_" x="427.5" y="347.5" transform="matrix(1 -4.974168e-03 4.974168e-03 1 -1.7438 2.1517)" className={classes.st1} width="8.3" height="8.3" />

                                    <rect id="Rectangle_1221_1_" x="439.1" y="347.4" transform="matrix(1 -4.974168e-03 4.974168e-03 1 -1.7434 2.2092)" className={classes.st1} width="8.3" height="8.3" />

                                    <rect id="Rectangle_1222_1_" x="450.6" y="347.4" transform="matrix(1 -4.991621e-03 4.991621e-03 1 -1.749 2.2746)" className={classes.st1} width="8.3" height="8.3" />

                                    <rect id="Rectangle_1223_1_" x="462.2" y="347.3" transform="matrix(1 -4.991621e-03 4.991621e-03 1 -1.7486 2.3323)" className={classes.st1} width="8.3" height="8.3" />

                                    <rect id="Rectangle_1224_1_" x="473.8" y="347.3" transform="matrix(1 -4.991621e-03 4.991621e-03 1 -1.7482 2.39)" className={classes.st1} width="8.3" height="8.3" />
                                </g>
                            </g>
                        </g>

                    </svg>

                </div>
            )
        }


        return (
            <div className='d-flex'>
                <div className='d-flex flex-column w-50'>
                    {items}
                </div>
                <div className='d-flex flex-column w-50'>
                    {items}
                </div>


            </div>


        )
    }
}

import React, { Component } from 'react'

import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Api from './Api'

import classes from './Footer.module.css'

export default class Language extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
            ],

            contentLang: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
            activeLang: 0,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }
    componentDidMount() {
        this.getSectionDetail();

        let defaultLang = localStorage.getItem("language");
        let active = 0;
        if (defaultLang && defaultLang.toLowerCase() === "vietnamese") {
            active = 1;
        } else if (defaultLang && defaultLang.toLowerCase() === "english") {
            active = 0;
        } else {
            active = 1;
            localStorage.setItem("language", "Vietnamese");
        }

        this.setState({
            activeLang: active,
        });
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentLang: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentLang: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        });
    }


    toggleLanguage = (lang) => {
        let active = null;
        if (lang) {
            if (lang.toLowerCase() === "vietnamese") {
                active = 0;
            } else {
                active = 1;
            }
            this.setState({
                language: lang,
                activeLang: active,
            });
            localStorage.setItem("language", lang);
            window.location.reload(true);
        }
    };
    render() {
        const { contentLang } = this.state

        let select, langActive, langItem

        if (this.state.isLoaded) {
            let arr1 = []
            let arr2 = []

            contentLang.forEach((l, i) => {
                switch (i) {
                    case 0:
                        l.sections.widget_contents.forEach(s => {
                            select = s.content ? s.content : 'Select language:'
                        })
                        break;
                    case 1:
                        l.sections.widget_contents.forEach((s, idx) => {
                            arr1.push(s.content)
                        })
                        break;
                    case 2:
                        l.sections.widget_contents.forEach((s, idx) => {
                            arr2.push(s.file ? s.file : '')
                        })
                        break;
                    default:
                        break;
                }
            })


            langItem = arr1.map((i, index) => {
                if (this.state.activeLang === index) {
                    langActive = this.state.activeLang === parseInt(index) ? i : "Vietnamese";
                }
                let type = this.state.activeLang === parseInt(index) ? classes.active : classes.inActive;

                return (
                    <li onClick={() => this.toggleLanguage(i)}>
                        <i className={classes.slFlag}>
                            <img className={classes.imgFlag} src={arr2[index]} alt=""></img>
                        </i>{" "}
                        <span className={type}>{i}</span>
                    </li>
                );
            });
        }

        return (
            <div className={classes.slNav}>
                {select}
                <ul>
                    <li>
                        <b className="ml-2">{langActive}</b>{" "}
                        <FontAwesomeIcon
                            color="#d4d4d4"
                            className="ml-auto"
                            icon={faCaretDown}
                            size="lg"
                        />
                        <div className={classes.triangle}></div>
                        <ul>
                            {langItem}
                            {/* <li onClick={this.toggleLanguage('Vietnamese')}><i className={classes.slFlag}><img className={classes.imgFlag} src={require('./assets/iconVn.svg')} alt=""></img></i> <span>Vietnamese</span></li> */}
                            {/* <li onClick={this.toggleLanguage('English')}><i className={classes.slFlag}><img className={classes.imgFlag} src={require('./assets/iconUk.svg')} alt=""></img></i> <span>English</span></li> */}
                        </ul>
                    </li>
                </ul>
            </div>
        )
    }
}

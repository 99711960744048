import React from "react";
import { Card } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import classesD from "../MenuDetail.module.css";
import classes from "./MenuPreview6.module.css";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

const menuPreview6 = (props) => {
  console.log("oeyyyy");
  let item = null;
  if (props.items) {
    props.items.forEach((x, index) => {
      if (index === 0) {
        item = x.widget ? (
          x.widget.widget_contents.map((itm, i) => {
            let strYear = itm.date ? itm.date : "";
            let dateFull = dateFormat(strYear, "dd-mm-yyyy");

            if (i < 3) {
              let img = null;
              img = itm.assets[0]
                ? itm.assets[0].file
                : require("../assets/default-image.svg");
              return (
                <div className="m-3">
                  <p className={classesD.contentLink}>{itm.category}</p>
                  <Link to={`/news/detail/${itm.id}`} className={classes.Link}>
                    <Card className={classesD.cardPreviewBody}>
                      <Card.Img
                        className={classes.contentImg}
                        variant=""
                        src={img}
                      />
                      <span
                        className={[classes.textTitleNews, classes.custom].join(
                          " "
                        )}
                      >
                        {ReactHtmlParser(itm.title)}
                      </span>
                      <p className={classes.dateTitleNews}>{dateFull}</p>
                    </Card>
                  </Link>
                </div>
              );
            }
          })
        ) : (
          <></>
        );
      }
    });
  }

  return (
    <div className={classesD.sectionPreview}>
      <div id="preview5" className="d-flex flex-row">
        {item}
      </div>
    </div>
  );
};

export default menuPreview6;

import React, { Component } from "react";
import classes from "./Section5.module.css";
import Api from "./Api";
import Card from './Card/Card';
import Slider from './Slider/Slider';

class SD_Section5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        {
          id: 1,
          name: "Article",
          title: "Lorem ipsum dummy title",
          content: "Lorem ipsum dummy data...",
          asset: require("./assets/default-image.svg"),
        },
      ],
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });
          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {

    var filePackage = []
    var title = null
    var fileArray = []
    var imageArray = []
    if(this.state.isLoaded){
      // console.log(this.state.contentSection)
      this.state.contentSection.forEach((item, index) => {
        switch(index){
          case 0:
            imageArray = []
            item.sections.widget_contents.forEach((image) =>{
              imageArray.push([image.file, image.description])
            })
            break;
          case 1:
            fileArray = []
            item.sections.widget_contents.forEach((file) =>{
              fileArray.push([file.file, file.description])
            })
            break;
          case 2:
            title = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Title";
            break;
          default:
            break;

        }
      })
      imageArray.forEach((item, index) =>{
        var image = item[0];
        var file = fileArray[index] ? fileArray[index][0] : "";
        var description = fileArray[index] ? fileArray[index][1] : item[1];
        filePackage.push([image, file, description])
      })
    }

    var content = filePackage.map((item) => {
      return (
        <Card
          url={item[1]}
          text={item[2]}
          imgUrl={item[0]}
        />
      )
    })

/*
    const { dummy, contentSection } = this.state;
    let content_flex_top,
      content_flex_bottom,
      dummycontent_flex_top = null;

    dummycontent_flex_top = (
      <div className={classes.flexContent}>
        <p className={classes.title}>{dummy[0].title}</p>
        <div className={classes.boxImage1}>
          <figure className={classes.wrapp_image}>
            <div className={classes.inside_image}>
              <img
                className={classes.iconhover}
                src={require("./assets/iconhover.svg")}
                alt=""
              />
            </div>
            <img className={classes.img_content} src={dummy[0].asset} alt="" />
          </figure>
        </div>
        <br />
        <Link
          to={"/sustainable-development/stakeholders"}
          className={classes.link}
        >
          <div className={[classes.contentLink].join(" ")}>
            <div className="d-flex">
              <div className={classes.outerCircle}>
                <div className={classes.innerCircle}></div>
              </div>
              <p className={classes.contentLink}>Read More</p>
            </div>
          </div>
        </Link>
      </div>
    );

    if (this.state.isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Article") {
          item.sections.widget_contents.forEach((ele, index) => {
            let imgAsset = ele.assets[0] ? (
              <img
                className={classes.img_content}
                src={ele.assets[0].file}
                alt=" "
              />
            ) : (
              <img
                className={classes.img_content}
                src={require("./assets/default-image.svg")}
                alt=""
              />
            );

            content_flex_top = (
              <div className={classes.flexContent}>
                <p className={classes.title}>{ele.title}</p>
                <div className={classes.boxImage1}>
                  <figure className={classes.wrapp_image}>
                    {imgAsset}
                    <div className={classes.inside_image}>
                      <img
                        className={classes.iconhover}
                        src={require("./assets/iconhover.svg")}
                        alt=""
                      />
                    </div>
                  </figure>
                </div>
                <br />
                <Link
                  to={`/article/in/${ele.id}/${index}`}
                  className={classes.link}
                >
                  <div className={[classes.contentLink].join(" ")}>
                    <div className="d-flex">
                      <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                      </div>
                      <p className={classes.contentLink}>Read More</p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          });
        }
      });
      */
      // contentSection.map((ele) => {
      //   console.log(ele.index);
      //   let imgAsset = ele.asset ? (
      //     <img className={classes.img_content} src={ele.asset} alt=" " />
      //   ) : (
      //     <img
      //       className={classes.img_content}
      //       src={require("./assets/default-image.svg")}
      //       alt=""
      //     />
      //   );

      //   content_flex_top = (
      //     <div className={classes.flexContent}>
      //       <p className={classes.title}>{ele.title}</p>
      //       <div className={classes.boxImage1}>
      //         <figure className={classes.wrapp_image}>
      //           {imgAsset}
      //           <div className={classes.inside_image}>
      //             <img
      //               className={classes.iconhover}
      //               src={require("./assets/iconhover.svg")}
      //               alt=""
      //             />
      //           </div>
      //         </figure>
      //       </div>
      //       <br />
      //       <Link to={"/sustainable/stakeholders"} className={classes.link}>
      //         <div className={[classes.contentLink].join(" ")}>
      //           <div className="d-flex">
      //             <div className={classes.outerCircle}>
      //               <div className={classes.innerCircle}></div>
      //             </div>
      //             <p className={classes.contentLink}>Read More</p>
      //           </div>
      //         </div>
      //       </Link>
      //     </div>
      //   );
      // });
      /*
    } else if (this.state.isApiLoaded) {
      content_flex_top = dummycontent_flex_top;
    }
    */

    //content_flex_bottom = <Action />;
    return (
      <div className={classes.Section5}>
        <div className={["container", classes.customContainer].join(' ')} >
          <h1 className={classes.title}>{title}</h1>
          <div className={classes.boxC}>
            <Slider content={content} />
            {/* {content} */}
          </div>
        </div>
      </div>
    );
  }
}
export default SD_Section5;

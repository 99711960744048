import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Card, Button } from "react-bootstrap";
import Ripples from "react-ripples";
import { Slide } from "react-awesome-reveal";
import posed from "react-pose";
import Slider from "react-slick";

import Sliders from "./Slider";
import Aux from "./Auxs";
import Api from "./Api";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./News.css";
import classes from "./News_Slider_1_row.module.css";
import dateFormat from "dateformat";

const Btn = posed.div({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.8 },
});

export default class Box extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        { id: 1, name: "title", content: "Blog Đổi mới sáng tạo" },
        {
          id: 2,
          name: "content",
          content:
            "“Đổi mới sáng tạo” là một trong những giá trị cốt lõi của Tập đoàn Phenikaa. Với chúng tôi, đổi mới sáng tạo từ mỗi cá nhân, và sẽ được nhân lên với tinh thần sẻ chia, phản biện và hiện thực hóa bằng sức mạnh tập thể. Đây là nơi các chuyên gia, các nhà khoa học uy tín trao đổi về những sáng tạo trong lĩnh vực chuyên môn của họ, hướng tới việc tạo nên những giá trị ảnh hưởng sâu rộng tới Phenikaa nói riêng và cộng đồng xã hội nói chung. Và chính bạn cũng có thể nói lên quan điểm hay chia sẻ câu chuyện sáng tạo của chính mình cùng chúng tôi.",
        },
        {
          id: 3,
          name: "article",
          articles: [
            {
              name: "content1",
              author: "John doe x Jane doe",
              content:
                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
              assets: require("./assets/default-image.svg"),
            },
            {
              name: "content2",
              author: "What is Lorem Ipsum?",
              content:
                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
              assets: require("./assets/default-image.svg"),
            },
            {
              name: "content2",
              author: "What is Lorem Ipsum?",
              content:
                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
              assets: require("./assets/default-image.svg"),
            },
          ],
        },
      ],
      contentArticle: {
        widgets: [],
      },
      url: "",
      readMore: "Read More",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      this.getArticleDetail(this.props.id);
      this.setState({
        url: this.props.url,
        readMore: this.props.readMore,
      });
      //            this.state.url = this.props.url;
      //            this.state.readMore = this.props.readMore;
    }
  }

  getArticleDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetailByYearDescending(param, null, null, null)
        .then((result) => {
          const articles = result.data;
          const art = [];
          articles.results.forEach((a) => {
            const assets = [];
            a.article_assets.forEach((ass, i) => {
              if (i === 0) {
                assets.push({
                  asset: ass.asset_file,
                });
              }
            });

            art.push({
              id: a.id,
              category: a.category_name,
              name: a.name,
              title: a.article_languages.title,
              content: a.article_languages.content,
              assets: assets,
              date: a.date,
            });
          });

          this.setState({
            contentArticle: art,
            isLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { contentArticle } = this.state;
    let box = null;
    const settings = {
      className: "",
      infinite: false,
      centerPadding: "0",
      variableWidth: true,
      slidesToShow: 3,
      swipeToSlide: true,
    };
    if (this.state.isLoaded) {
      //console.log(this.state.readMore);
      // const arr = contentArticle.reverse()
      box = contentArticle.map((s, i) => {
        let strDate = s.date;
        let dateFull = dateFormat(strDate, "dd-mm-yyyy");

        let secTypeBackground,
          secTypeColor = null;
        if (s.category === "Upcoming Events") {
          secTypeBackground = "white";
          secTypeColor = "black";
        } else if (s.category === "Events") {
          secTypeBackground = "#223771";
          secTypeColor = "white";
        } else {
          secTypeBackground = "#223771";
          secTypeColor = "white";
        }
        let adjClass = s.content ? "pb-0" : "";

        let isOnlyImage = s.assets[0] ? (
          <img
            className={classes.imageContent}
            src={s.assets[0].asset}
            alt=""
          ></img>
        ) : (
          <img
            className={classes.imageContentD}
            src={require("./assets/default-image.svg")}
            alt=""
          />
        );
        let isBoxImage = s.assets[0] ? (
          <img
            className={classes.imgOnBox}
            src={s.assets[0].asset}
            alt="2"
          ></img>
        ) : (
          <img
            className={classes.imgOnBoxD}
            src={require("./assets/default-image.svg")}
            alt=""
          />
        );
        let isImage = (
          <div className={classes.bxf1r}>
            <div className={classes.shdw0}>
              <div className={[classes.cardFd1, adjClass].join(" ")}>
                <div className={classes.cardcontent}>
                  <p>{s.title}</p>
                </div>
              </div>
              <div className={classes.cardFd}>
                <p className={[classes.date, "left"].join(" ")}>{dateFull}</p>
              </div>
            </div>
            <Card className={classes.cardtemplateback}>
              <div className={classes.contentImage}>{isBoxImage}</div>
              <div className={classes.cardfooterback}>
                <Link
                  className={classes.customLink}
                  to={`${this.state.url}${s.id}`}
                >
                  <div className={classes.btnRead}>
                    <p>{this.state.readMore}</p>
                  </div>
                </Link>
              </div>
            </Card>
          </div>
        );
        // <div className={classes.ContentWrapp}>
        //   <div className={classes.shadowHover}></div>
        //   </div>
        {
          /* <Card className={classes.cardfront}>
              <Card.Body className={[classes.cardFd1, adjClass].join(" ")}>
                <div className={classes.cardcontent}>
                  <p>{s.title}</p>
                </div>
              </Card.Body>
              <div className={classes.cardFd}>
                <p className={[classes.date, "left"].join(" ")}>{dateFull}</p>
              </div>
            </Card> */
        }

        return <Aux key={s.title}>{isImage}</Aux>;
      });
    }
    // const settings = {
    //   className: "",
    //   centerMode: false,
    //   infinite: true,
    //   centerPadding: "60px",
    //   variableWidth: true,
    //   slidesToShow: 5,
    //   swipe: true,
    // };

    return (
      <>
        <Slider {...settings}>{box}</Slider>
        {/* <Slide triggerOnce direction="top">
          <Sliders setting={"top"} content={box} />
        </Slide> */}
      </>
    );
  }
}

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Box from "./Box";
import Api from "./Api";
import classes from "./Section2.module.css";
// import HorizontalTimeline from "react-horizontal-timeline";
// import Timeline from "./timeline";

export default class Section2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                { id: 1, name: "title", content: "Blog Đổi mới sáng tạo" },
                {
                    id: 2,
                    name: "content",
                    content:
                        "“Đổi mới sáng tạo” là một trong những giá trị cốt lõi của Tập đoàn Phenikaa. Với chúng tôi, đổi mới sáng tạo từ mỗi cá nhân, và sẽ được nhân lên với tinh thần sẻ chia, phản biện và hiện thực hóa bằng sức mạnh tập thể. Đây là nơi các chuyên gia, các nhà khoa học uy tín trao đổi về những sáng tạo trong lĩnh vực chuyên môn của họ, hướng tới việc tạo nên những giá trị ảnh hưởng sâu rộng tới Phenikaa nói riêng và cộng đồng xã hội nói chung. Và chính bạn cũng có thể nói lên quan điểm hay chia sẻ câu chuyện sáng tạo của chính mình cùng chúng tôi.",
                },
                {
                    id: 3,
                    name: "article",
                    articles: [
                        {
                            name: "content1",
                            author: "John doe x Jane doe",
                            content:
                                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
                            assets: require("./assets/default-image.svg"),
                        },
                        {
                            name: "content2",
                            author: "What is Lorem Ipsum?",
                            content:
                                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
                            assets: require("./assets/default-image.svg"),
                        },
                        {
                            name: "content2",
                            author: "What is Lorem Ipsum?",
                            content:
                                "THE INTRODUCTION CEREMONY OF THE TWO RESEARCH INSTITUTE OF PRATI & TIAS",
                            assets: require("./assets/default-image.svg"),
                        },
                    ],
                },
            ],
            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
            value: 0,
            previous: 0,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.getTextContent = this.getTextContent.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    this.state.isApiLoaded = true;
                    const sections = result.data;
                    const widget = [];
                    const data = [];

                    data.push({
                        title: sections.widgets[0].widget_contents[0].content,
                        desc: sections.widgets[1].widget_contents[0].content,
                        sections: sections.widgets[2],
                    });

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            internal: item.is_internal,
                            name: item.name,
                            url: sections.widgets[3].url,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        // contentSection: data,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    getTextContent(item, dummyText) {
        var textContent;

        if (item.sections.widget_contents[0]) {
            item.sections.widget_contents.forEach((s) => {
                if (s.content !== "") {
                    textContent = s.content;
                } else {
                    textContent = dummyText;
                }
            });
        } else {
            textContent = dummyText;
        }

        return textContent;
    }

    render() {
        const { dummy, contentSection } = this.state;
        let dummyTitle,
            dummyDesc,
            dummyContent,
            title,
            Desc,
            Content,
            urlAll,
            urlDetail,
            readMore,
            viewAll = null;

        let VALUES = ["20 april 2020"];

        dummyTitle = <p className={classes.title}>{dummy[0].content}</p>;
        dummyDesc = <p className={classes.content}>{dummy[1].content}</p>;

        dummyContent = dummy[2].articles.map((s, i) => {

            

            let box = i === 1 ? [classes.box, "mr-5 ml-5"].join(" ") : classes.box;
            return (
                <div className={box}>
                    <div className={classes.boxBackground}></div>
                    <div className={classes.boxProfile}>
                        {/* <img src={require('./assets/profile.jpg')} alt=''></img> */}
                    </div>
                    <div className={classes.boxContent}>
                        <img
                            className={classes.imgProfile}
                            src={require("./assets/profile.jpg")}
                            alt=""
                        ></img>
                        <div className={classes.boxBody}>
                            <p className={classes.titleContent}>{s.content}</p>
                            <p className={classes.authorContent}>{s.author}</p>
                        </div>
                        <div className={classes.boxFooter}>
                            <div className={classes.boxLinks}>
                                <div className={classes.outerCircle}>
                                    <div className={classes.innerCircle}></div>
                                </div>
                                <p className={classes.readMoreContent}>Read more</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        if (this.state.isApiLoaded) {
            let count = 0;

            contentSection.forEach((item) => {

                count = count + 1;
                switch (count) {
                    case 1:
                        title = (
                            <p className={classes.title}>
                                {this.getTextContent(item, dummyTitle)}
                            </p>
                        );
                        break;
                    case 2:
                        Desc = (
                            <p className={classes.content}>
                                {this.getTextContent(item, dummyDesc)}
                            </p>
                        );
                        break;
                    case 3:
                        readMore = this.getTextContent(item, "Read More");
                        break;
                    case 4:
                        urlDetail = item.sections.url
                            ? item.sections.url
                            : "/innovation/blogs/details/";
                        Content = item.internal ? (
                            <></>
                        ) : (
                                <Box
                                    category={item.sections.article_categories}
                                    urlDetail={urlDetail}
                                    id={item.id}
                                    readMore={readMore}
                                />
                            );
                        break;
                    case 5:
                        viewAll = this.getTextContent(item, "View All");
                        urlAll = item.sections.url
                            ? item.sections.url
                            : "/innovation/blogs/";
                        break;
                    default:
                }
            });
        } else if (this.state.isApiLoaded) {
            title = dummyTitle;
            Desc = dummyDesc;
            Content = dummyContent;
        }
        return (
            <div className={classes.contentSection}>
                <div className={["container", classes.boxC].join(" ")}>
                    {title}
                    {Desc}
                    <div className={classes.boxs}>{Content}</div>
                    <div className={classes.boxLink}>
                        <div className={classes.outerCircle}>
                            <div className={classes.innerCircle}></div>
                        </div>

                        <Link
                            className={classes.linkAll}
                            to={{
                                pathname: urlAll,
                                // pathname: `/innovation/blogs/${urlDetail}`,
                                // search: "?sort=name",
                                // hash: "#the-hash",
                                // state: { articleCategories: this.state.contentSection }
                            }}
                        >
                            {viewAll}
                        </Link>
                    </div>
                    {/* <div>
                        <Timeline />
                        <div>
              <div style={{ width: "100%", height: "100px", margin: "0 auto" }}>
                <HorizontalTimeline
                  index={this.state.value}
                  indexClick={(index) => {
                    this.setState({ value: index, previous: this.state.value });
                  }}
                  values={VALUES}
                />
              </div>
              <div className="text-center">{this.state.value}</div>
            </div>
                    </div> */}
                    <hr className={classes.hrGeneral} />
                </div>
            </div>
        );
    }
}

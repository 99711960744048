import * as React from "react";

import useScrollPosition from "@react-hook/window-scroll";
import classes from "../../GS.module.css"
import clases from "./Navbar.module.css"

export default function Navbar(props) {
    const scrollY = useScrollPosition(60);
    let nav, sticky, contentSticky, filters, colorGrid, colorList = null

    if (props.type) {
        if (props.type === "list") {
            colorList = <img className={classes.img_list} onClick={props.listClick} src={require('../assets/list2.svg')} alt="description" />
            colorGrid = <img className={classes.img_grid} onClick={props.gridClick} src={require('../assets/grid.svg')} alt="description" />
        } else {
            colorList = <img className={classes.img_list} onClick={props.listClick} src={require('../assets/list.svg')} alt="description" />
            colorGrid = <img className={classes.img_grid} onClick={props.gridClick} src={require('../assets/grid2.svg')} alt="description" />
        }
    }

    if (props.category) {
        filters = props.category.map(f => {
            return (
                <option value={f}>{f}</option>
            )
        })
    }

    sticky = contentSticky = ''
    nav =
        <>
            {/* <p className={clases.navTitle}>{props.title}</p> */}
            <input onKeyDown={props.onKeyDown} className={clases.searchHere} type='text' placeholder={props.searchHere !== "" ? props.searchHere : ". . ."} />
            <p className={clases.placeholder}>{props.textSearch ? props.textSearch : 'type article name, news, events, blog'}</p>
            <div className={[clases.boxNavbar].join(' ')}>
                <select name="filter" id="filter" onChange={props.onChange}>
                    <option value="">{props.textFilter ? props.textFilter : 'All'}</option>
                    {filters}
                </select>
                <p className={clases.contentTotal}><span className={clases.total}>{props.total ? props.total : 'no'}</span> results found</p>
                {/* <p className={clases.date}><strong>Monday,</strong> September 7, 2020 | 10:09 (GMT + 7)</p> */}
                {/* <div className={clases.boxSearch}>
                    <input onKeyDown={props.onKeyDown} className={clases.inputSearch} type='text' placeholder={props.searchHere} />
                </div> */}
            </div>
        </>

    return (
        <div className={[clases.customNavbar, sticky].join(' ')}>
            <div className={clases.navbar}>
                {nav}
            </div>
        </div>
    );
}

import React, { Component } from "react";
import { Controls, PlayState, Timeline, Tween } from "react-gsap";
import "./Zoom.css";

class Zoom extends Component {
  render() {
    let TargetWithNames = null;

    TargetWithNames = <p className="text-first">TEST</p>;
    return (
      <div>
          <Timeline target={TargetWithNames}>
            <Tween
              from={{ y: "100vw", scaleY: 0.5, font: "24px" }}
              target="p1"
              position="0"
              duration={3}
            />
            <Tween to={{ y: "200vw", scaleY: 2, font: "50px" }} />
          </Timeline>
      </div>
    );
  }
}

export default Zoom;

import React, { Component, Fragment } from "react";
import classes from "./AD_Section1.module.css";
import { ParallaxProvider } from "react-scroll-parallax";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import Api from "./Api";
import AwardsBox from "./AwardsBox";
import AwardsBox2 from "./AwardsBox2";
import dateFormat from "dateformat";

class AD_Section1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        {
          id: 644,
          title: "NEWS",
          Content: "Non Existing Article" + "versity",
          Date: "28/10/2019",
          Detail:
            `Phenikaa University signed the memorandum of understanding with Andrews Universi Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to make a type specimen
                    book. It has survived not only five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages, and more
                    and more` + "ty...",
          img: (
            <img
              style={{
                width: "100%",
                height: "1080px",
                objectFit: "cover",
              }}
              src={require("./assets/news.png")}
              alt=""
            />
          ),
        },
        {
          id: 744,
          title: "NEWS",
          Content:
            "Education Cooperation – The highlight in international relations of Phenikaa Uni" +
            "versity",
          Date: "28/10/2020",
          Detail:
            "Phenikaa University signed the memorandum of understanding with Andrews Universi" +
            "ty...",
          img: (
            <img
              style={{
                width: "100%",
                height: "1080px",
                objectFit: "cover",
              }}
              src={require("./assets/news.png")}
              alt=""
            />
          ),
        },
        {
          id: 844,
          title: "NEWS",
          Content:
            "Education Cooperation – The highlight in international relations of Phenikaa Uni" +
            "versity",
          Detail:
            "Phenikaa University signed the memorandum of understanding with Andrews Universi" +
            "ty...",
          Date: "28/10/2021",
          img: (
            <img
              style={{
                width: "100%",
                height: "1080px",
                objectFit: "cover",
              }}
              src={require("./assets/news.png")}
              alt=""
            />
          ),
        },
        {
          id: 944,
          title: "EVENT",
          Content:
            "Education Cooperation – The highlight in international relations of Phenikaa Uni" +
            "versity",
          Detail:
            "Phenikaa University signed the memorandum of understanding with Andrews Universi" +
            "ty...",
          Date: "28/10/2022",
          img: (
            <img
              style={{
                width: "100%",
                height: "1080px",
                objectFit: "cover",
              }}
              src={require("./assets/news.png")}
              alt=""
            />
          ),
        },
        {
          id: 1044,
          title: "NEWS",
          Content:
            "Education Cooperation – The highlight in international relations of Phenikaa Uni" +
            "versity",
          Detail:
            "Phenikaa University signed the memorandum of understanding with Andrews Universi" +
            "ty...",
          Date: "28/10/2023",
          img: (
            <img
              style={{
                width: "100%",
                height: "1080px",
                objectFit: "cover",
              }}
              src={require("./assets/news.png")}
              alt=""
            />
          ),
        },
      ],
      idSection: this.props.id,
      sections: null,
      article: null,
      isLoaded: false,
      isApiLoaded: false,
      active_img: 0,
    };
    this.toggleNext = this.toggleNext.bind(this);
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  toggleNext() {
    // alert(this.props.id);
    let id = null;
    id = this.props.id;
    this.setState((id) => {
      if (id === id + 1) {
        return { disabledNext: false };
      } else {
        return { i: id + 1 };
      }
    });
  }
  componentDidMount() {
    let paramId = this.props.id;
    this.getSectionDetail(paramId);
  }
  changeSlideBar(key) {
    this.setState({ active_img: key });
  }
  getSectionDetail(paramId) {
    const apiData = new Api();
    this.setState(
      {
        article: [],
        sections: [],
      },
      () => {
        apiData.getSectionDetail().then((result) => {
          const sections = result.data;
          const widget = [];
          let id = sections.id;
          result.data.widgets.forEach((item) => {
            widget.push(item);
          });

          this.setState({
            idSection: id,
          });
          this.state.sections = widget;
        });

        apiData
          .getArticleDetailByArticleId(paramId)
          .then((result) => {
            const article = result.data;
            const assets = [];
            article.article_assets.forEach((item) => {
              assets.push({ assets: item.asset_file, desc: item.description });
            });

            const allcontent = article.article_languages;
            let content = allcontent.content;
            let title = allcontent.title;
            let category = article.category_name;
            let date = article.date;

            const widget = [];

            widget.push({
              content: content,
              title: title,
              category: category,
              date: date,
              assets: assets,
            });

            article.page = widget;
            this.setState({
              article: widget,
              isLoaded: true,
            });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  render() {
    const settings = {
      className: "",
      infinite: false,
      centerPadding: "0",
      variableWidth: true,
      slidesToShow: 2,
      autoplay: false,
      // autoplaySpeed: 5000,
      swipeToSlide: true,
    };

    const { article, sections, dummy } = this.state;
    var { backText, backUrl, shareText } = "";
    var returnBackElement = null;

    let articleDummy = (
      <div className={classes.box}>
        <div className={classes.boxContent}>
          <div className={classes.wrappbtnback}>
            <div
              onClick={() => (window.location.href = "/news/")}
              className={classes.images_goback}
            >
              <img src={require("./assets/Path235.svg")} />
              &nbsp; Back to Prev
            </div>
            <div
              className={classes.share}
              style={{
                fontFamily: "Helvetica",
              }}
            >
              Share on : &nbsp;
              <img
                className={classes.icon}
                src={require("./assets/Subtraction 1.svg")}
                alt=""
              />
              <img
                className={classes.icon}
                src={require("./assets/Group 17.svg")}
                alt=""
              />
              <img
                className={classes.icon}
                src={require("./assets/Group 19.svg")}
                alt=""
              />
              <img
                className={classes.icon}
                src={require("./assets/Group 831.svg")}
                alt=""
              />
            </div>
          </div>
          <div className={classes.box_scroll}>
            {/* <div className={classes.news_title}>{dummy[0].title}</div> */}
            <div className={classes.judul}>{dummy[0].Content}</div>
            <div className={classes.tgl}>{dummy[0].Date}</div>
            <div className={classes.isi}>{dummy[0].Detail}</div>
          </div>
        </div>
        <div className={classes.boxImage}>
          <Slider {...settings}>
            {dummy.map((images) => (
              <div key={images.id}>{images.img}</div>
            ))}
          </Slider>
        </div>
      </div>
    );

    let articleContent,
      dummydata,
      top,
      top2,
      detailImg,
      imgBottom,
      nextBox = null;

    dummydata = articleDummy;

    if (this.state.isLoaded) {
      top = <AwardsBox id={this.state.idSection} button={this.props.history} />;
      top2 = <AwardsBox2 id={this.state.idSection} />;

      article.forEach((item) => {
        let strDate = item.date;
        let dateFull = dateFormat(strDate, "dd-mm-yyyy");
        if (item.assets.length === 1) {
          item.assets.map((ele, i) => {
            if (i === this.state.active_img) {
              detailImg = (
                <div className={classes._boxmain1}>
                  <img
                    className={classes._img1}
                    src={item.assets[i].assets}
                    alt=" "
                  />
                  <div className={["container", classes.pad].join(" ")}>
                    <p className={classes.txt2}>{item.assets[i].desc}</p>
                  </div>
                </div>
              );
            }
            nextBox = (
              <div
                className={classes._btnBtm}
                onClick={this.toggleNext}
                disabled={this.state.disabledNext}
              >
                <div className={classes._next}>
                  <p className={classes._marg}>Next Page {">"}</p>
                </div>
              </div>
            );
          });
          imgBottom = <div></div>;
        } else if (item.assets.length > 1) {
          let img_slide_box = item.assets.map((ele, i) => {
            if (i === this.state.active_img) {
              detailImg = (
                <div className={classes._boxmain}>
                  <img className={classes._img} src={ele.assets} alt=" " />
                  <div className={["container", classes.pad].join(" ")}>
                    <p className={classes.txt2}>{ele.desc}</p>
                  </div>
                </div>
              );
            }
            nextBox = (
              <div className={classes._btnBtm} onClick={this.toggleNext}>
                <div className={classes._next}>
                  <p className={classes._marg}>Next Page {">"}</p>
                </div>
              </div>
            );

            return (
              <div className={classes._box2}>
                <img
                  onClick={() => this.changeSlideBar(i)}
                  className={classes._img_slide}
                  src={ele.assets}
                  alt=" "
                />
              </div>
            );
          });

          imgBottom = (
            <div className={classes._box3}>
              <div className={["container", classes.padd2].join(" ")}>
                <Slider {...settings}>{img_slide_box}</Slider>
              </div>
            </div>
          );
        }
        // let imagesSlide = item.assets.map((images) => (
        //   <img className={classes.images_slide} src={images.assets} alt="" />
        // ));
        articleContent = (
          <div className={classes.box}>
            <div className={classes.boxContent}>
              <div className="d-flex">
                {top}

                {/* <div className={classes.news_title}>{item.category}</div> */}
              </div>
              <div className={classes.judul}>{item.title}</div>

              <div className={classes.box_scroll}>
                <div className={classes.scroll_content}>
                  {/* <div className={classes.judul}>{item.title}</div> */}
                  <div className={classes.tgl}>{dateFull}</div>
                  <div className={classes.isi}>
                    {ReactHtmlParser(item.content)}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.boxContent2}>
              {top2}
              {/* <div className={classes.news_title}>{item.category}</div> */}
              <div className={classes.box_scroll}>
                <div className={classes.boxImage}>
                  {detailImg}
                  {imgBottom}
                  {nextBox}
                </div>
                {/* <div className={classes.scroll_content}>
                <div className={classes.judul}>{item.title}</div>
                <div className={classes.tgl}>{item.date}</div>
                <div className={classes.isi}>
                  {ReactHtmlParser(item.content)}
                </div>
              </div> */}
              </div>
            </div>
            {/* <div className={classes.boxImage}>
            {detailImg}
            {imgBottom}
            {nextBox}
          </div> */}
          </div>
        );
      });
    } else if (this.state.isApiLoaded) {
      articleContent = articleDummy;
    }
    return (
      <div className="pages-new">
        <div className={classes.wrapper}>
          <div className={classes.main}>
            <ParallaxProvider>
              <Fragment>{articleContent}</Fragment>
            </ParallaxProvider>
          </div>
        </div>
      </div>
    );
  }
}

export default AD_Section1;

import React, { Component } from "react";

import { Link } from 'react-router-dom'
import ReactHtmlParser from "react-html-parser";

import Api from './Api'

import classes from "./BS_Page5.module.css";

export default class BS_Page5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
            ],

            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.getHtmlParagraph = this.getHtmlParagraph.bind(this);
        this.getUrlParser = this.getUrlParser.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        });
    }

    getHtmlParagraph(str) {
        if (str !== '') {
            return str.replace(/<\/?p>/gi, " ")
                .replace(/<p>/gi, " ");
        }
    }

    getUrlParser(url) {
        if (url !== '') {
            return url.includes('http') ?
                <a className={classes.customLink} href={url} target="_blank" rel="noopener noreferrer">
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.viewMore}>View Details</p>
                </a> :
                <Link className={classes.customLink} to={url}>
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.viewMore}>View Details</p>
                </Link>
        }
    }

    render() {
        const { contentSection } = this.state

        let backButton, contentCategory, title, contentImg, contentImg2, titleContent = null
        let contentL1, contentR1, contentL2, contentL3, contentL4, contentR2, contentR3, contentBox, contentL5, contentL6, contentL7, contentBox1 = null
        let dummyUrl = require('./assets/default-image.svg')
        if (this.state.isLoaded) {
            contentSection.forEach((x, i) => {
                if (x.index === 1 && x.category === "Article") {
                    x.sections.widget_contents.forEach((s, i) => {
                        contentImg = s.assets ? s.assets[0].file : dummyUrl
                        title = s.title ? <p className={classes.title}>{s.title}</p> : <></>

                        const splitStr = s.content.split('#')
                        contentCategory = s.category ? <p className={classes.category}>{this.getHtmlParagraph(splitStr[0] ? splitStr[0] : '')}</p> : <></>
                        // titleContent = this.getHtmlParagraph(splitStr[1] ? splitStr[1] : '')

                        contentL1 = this.getHtmlParagraph(splitStr[1] ? splitStr[1] : '')
                        contentL2 = this.getHtmlParagraph(splitStr[2] ? splitStr[2] : '')
                        contentL3 = this.getHtmlParagraph(splitStr[3] ? splitStr[3] : '')
                        contentImg2 = s.assets[1] ? <img className={classes.contentImg} src={s.assets[1].file} alt=""></img> : <></>

                        contentL4 = this.getHtmlParagraph(splitStr[4] ? splitStr[4] : '')
                        contentR1 = this.getHtmlParagraph(splitStr[5] ? splitStr[5] : '')
                        contentL5 = this.getHtmlParagraph(splitStr[6] ? splitStr[6] : '')

                    })
                }
                if (x.index === 2 && x.category === "Article") {
                    contentBox = x.sections.widget_contents.map((s, i) => {
                        let url = s.assets[0] ? s.assets[0].file : require('./assets/default-image.svg')

                        let link = this.getUrlParser(s.url)
                        return (
                            <div className={classes.box}>
                                <img src={url} alt=""></img>
                                <div className={classes.boxMask}></div>
                                <div className={classes.contentFront}>
                                    <div className={classes.boxContentIn}>
                                        <p>{s.title}</p>
                                    </div>
                                </div>
                                <div className={classes.contentBack}>
                                    <div className={classes.boxContentIn}>
                                        <div className="d-flex flex-column text-right mt-auto mb-auto">
                                            <p>{ReactHtmlParser(s.content)}</p>
                                            <div className="d-flex flex-row justify-content-end position-relative">
                                                {link}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    })
                }
                switch (i) {
                    case 2:
                        backButton =
                            <Link className={classes.customLink} to={x.sections.url ? x.sections.url : '/business'}>
                                <p className={classes.backButton}>{x.sections.widget_contents[0].content}</p>
                            </Link>
                        break;
                    default:
                }
            })

        }
        return (
            <div className={classes.contentSection}>
                <div className={["container", classes.joinC].join(" ")}>
                    <div className={classes.boxContent}>
                        <div className={classes.boxNav}>
                            <img src={require("./assets/back.svg")} alt=""></img>
                            {backButton}
                        </div>
                        {contentCategory}
                        {title}

                        <div className={classes.section1}>
                            <div className={classes.boxImage}>
                                <img className={classes.contentImg} src={contentImg} alt=""></img>
                            </div>

                            <div className={classes.boxLeft}>
                                <p className={classes.contentDesc}>{ReactHtmlParser(contentL1)}</p>
                            </div>

                            <div className={classes.sectionContent}>
                                {ReactHtmlParser(contentL2)}
                                <div className='d-flex flex-row'>
                                    <div className={classes.s2_left}>
                                        <div className={classes.customSectionL}>
                                            {ReactHtmlParser(contentL3)}
                                        </div>
                                    </div>
                                    <div className={classes.s2_right}>
                                        <div className={classes.boxImage2}>
                                            {contentImg2}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.sectionContent}>
                                <div className="d-flex flex-row">
                                    <div className={classes.s2_left}>
                                        <div className={classes.customSectionL}>
                                            {ReactHtmlParser(contentL4)}
                                        </div>
                                    </div>
                                    <div className={classes.s2_right}>
                                        <div className={classes.customSectionR}>
                                            {ReactHtmlParser(contentR1)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {ReactHtmlParser(contentL5)}
                            <div className="d-flex flex-row">
                                {contentBox}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

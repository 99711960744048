import React, {Component} from "react";
import classes from "./Section4.module.css";
import {Link} from "react-router-dom";
import Api from "./Api";
import Box from "./Box";
import ReactHtmlParser from "react-html-parser";


import visualclass from './SD_Section4.module.css'

class SD_Section4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                {
                    id: 1,
                    name: "article",
                    title: "Gắn kết các bên liên quan",
                    content: "Hiện thực hóa mọi cam kết, đảm bảo sự hài hòa lợi ích giữa Tập đoàn với c" +
                            "ác bên liên quan, thượng tôn pháp luật, xây dựng và duy trì mối quan hệ trên ti" +
                            "nh thần tôn trọng và hợp tác, thấu hiểu và đồng hành."
                }, {
                    id: 2,
                    name: "stakeholder",
                    content: [
                        {
                            title: "Khách hàng"
                        }, {
                            title: "Người lao động"
                        }, {
                            title: "Cơ quan thông tấn báo chí"
                        }
                    ]
                }
            ],
            contentSection: {
                widgets: []
            },
            aboutPhenikaa: [],
            isLoaded: false,
            isApiLoaded: true
        };
        this.getSectionDetail = this
            .getSectionDetail
            .bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({
            contentSection: []
        }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];
                    const aboutPhenikaa = [];
                    console.log(sections);
                    result
                        .data
                        .widgets
                        .forEach((item) => {
                            widget.push({
                                id: item.id,
                                index: item.index,
                                category: item.category,
                                name: item.name,
                                url: item.is_internal,
                                urlType: item.url,
                                sections: item
                            });
                        });

                    result
                        .data
                        .widgets[2]
                        .widget_contents
                        .forEach((item) => {
                            aboutPhenikaa.push(item.title);
                        });

                    sections.page = widget;
                    this.setState({contentSection: widget, aboutPhenikaa: aboutPhenikaa, isLoaded: true});
                })
                .catch((error) => {
                    this.setState({isApiLoaded: true});
                });
        });
    }

    getHtmlParagraph(str) {
        if (str !== '') {
            return str
                .replace(/<\/?p>/gi, " ")
                .replace(/<p>/gi, " ");
        }
    }

    render() {
        const {contentSection, dummy, aboutPhenikaa, isLoaded, isApiLoaded} = this.state;
        let title_content,
            dummy_title_content,
            data_content,
            item_title,
            contentB,
            title,
            dummy_data_content,
            urlText,
            readText,
            boxBottom,
            LinkItem,
            contentss,
            LinkTap,
            tapText,
            contentVisual = null;

        if (isLoaded) {
            let count = 0;
            contentSection.forEach((item) => {
                count = count + 1;
                if (count === 1 && item.index === 1 && item.category === "Text") {
                    item
                        .sections
                        .widget_contents
                        .forEach((ele) => {
                            if (ele.content !== "") {
                                title_content = <p className={classes.title}>{ele.content}</p>;
                            } else {
                                title_content = dummy_title_content;
                            }
                        });
                } else if (count === 1 && item.index === 1 && item.category !== "Text") {
                    title_content = dummy_title_content;
                }

                if (count === 2 && item.index === 2 && item.category === "Text") {
                    item
                        .sections
                        .widget_contents
                        .forEach((ele) => {
                            if (ele.content !== "") {
                                data_content = <p className={classes.text}>{ele.content}</p>;
                            }
                        });
                } else if (count === 2 && item.index === 2 && item.category !== "Text") {
                    data_content = dummy_data_content;
                }

                if (count === 3 && item.index === 3 && item.category === "Article") {
                    if (item.url === false) {
                        contentB = <Box id={item.id}/>;
                    }
                }
                if (count === 4 && item.index === 4 && item.category === "Text") {
                    urlText = item.urlType;

                    LinkItem = item
                        .sections
                        .widget_contents
                        .map((z) => {
                            if (z.content) {
                                readText = <p className={classes.contentLink}>{z.content}</p>;
                            } else {
                                readText = <p className={classes.contentLink}>Read More</p>;
                            }
                            return (
                                <Link to={urlText} className={classes.link}>
                                    <div className={classes.padd}>
                                        <div className={[classes.contentLink].join(" ")}>
                                            <div className={classes.boxLinks}>
                                                <div className={classes.outerCircle2}>
                                                    <div className={classes.innerCircle2}></div>
                                                </div>
                                                {readText}
                                            </div>
                                            {/* <div className="d-flex">
                      <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                      </div>
                      {readText}
                    </div> */}
                                        </div>
                                    </div>
                                </Link>
                            );
                        });
                }
                if (count === 5 && item.index === 5 && item.category === "Text") {
                    urlText = item.urlType;

                    LinkTap = item
                        .sections
                        .widget_contents
                        .map((z) => {
                            if (z.content) {
                                tapText = <p className={classes.mainRead}>{z.content}</p>;
                            } else {
                                tapText = (
                                    <p className={classes.contentLink}>Tap Doan PHENIKAA</p>
                                );
                            }
                            return (
                                <Link to={urlText} className={classes.link}>
                                    <div className={classes.padd}>
                                        {tapText}
                                        <hr/>
                                    </div>
                                </Link>
                            );
                        });
                }
                if (count === 6 && item.index === 6 && item.category === "Article") {

                    let arr = []
                    let result = null
                    boxBottom = item
                        .sections
                        .widget_contents
                        .map((z) => {

                            result = z
                                .content
                                .match(/<p>(.*?)<\/p>/g)
                                .map(function (val) {
                                    return val
                                        .replace(/<\/?p>/g, ' ')
                                        .replace(/&nbsp;/gi, " ");
                                });

                            // let content = this.getHtmlParagraph(z.content) arr.push(content) if
                            // (z.content) {     contentss = (         <p
                            // className={classes.ptitle}>{ReactHtmlParser(z.content)}</p>     ); } let img
                            // = z.assets[0] ? (     <img className={classes.imgImg} src={z.assets[0].file}
                            // alt=" " /> ) : (         <img             className={classes.imgImg}
                            //    src={require("./assets/default-image.svg")}             alt=" "         />
                            //     ); return (     <div className={classes.boxBottom}>         <div
                            // className={classes.boxOne}>{contentss}</div>         <div
                            // className={classes.boxTwo}>{img}</div>     </div> );
                        });

                    contentVisual = result.map((e, i) => {
                        return (
                            <div className={visualclass.wrappContentBox}>
                                <div className={visualclass.circleLine2}>
                                    <div className={visualclass.circleLineIn2}></div>
                                </div>
                                {/* <div className={visualclass.boxDescription}>
                                    <p className={visualclass.textInBox}>Lorem Ipsum is Dummy Text Lorem Ipsum is
                                        Dummy Text Lorem Ipsum is Dummy Text Lorem Ipsum is Dummy Text Lorem Ipsum is
                                        Dummy Text
                                    </p>
                                </div> */}
                                <div className={visualclass.boxRound}>
                                    <p className={visualclass.textInboxRound}>{ReactHtmlParser(e)}</p>
                                    <div className={visualclass.circleLine}>
                                        <div className={visualclass.circleLineIn}></div>
                                    </div>
                                </div>
                                <div className={visualclass.Line}></div>
                            </div>
                        )
                    })

                }
            });
        } else if (isApiLoaded) {
            title_content = <p className={classes.title}>Title Content?</p>;
            data_content = <p className={classes.text}>Content?</p>;
            LinkTap = (
                <Link to={"/"} className={classes.link}>
                    <div className={classes.padd}>
                        <p className={classes.mainRead}>Text ?</p>
                        <hr/>
                    </div>
                </Link>
            );
            LinkItem = (
                <Link to={"/"} className={classes.link}>
                    <div className={classes.padd}>
                        <div className={[classes.contentLink].join(" ")}>
                            <div className="d-flex">
                                <div className={classes.outerCircle}>
                                    <div className={classes.innerCircle}></div>
                                </div>
                                <p className={classes.contentLink}>Read More?</p>
                            </div>
                        </div>
                    </div>
                </Link>
            );
        }

        return (
            <div className={classes.Section4}>
                <div className={["container", classes.boxC].join(" ")}>
                    <div className={classes.boxTitle}>{title_content}</div>
                    <div className={classes.boxText}>{data_content}</div>
                    {LinkItem}
                    <div className={visualclass.backgroundchart}>
                        <div className={visualclass.wrappContent}>
                            <div className={visualclass.chart}>
                                <div className={visualclass.circle}>
                                    <div className={visualclass.circleFake}></div>
                                    <div className={visualclass.circleInner}>
                                        <p className={visualclass.textInnerCircle}>Tập đoàn Phenikaa</p>
                                    </div>
                                </div>
                                {contentVisual}
                            </div>
                        </div>
                    </div>

                    {/* <div className={classes.flexContent}>
                        {boxBottom}
                        {LinkTap}
                        {contentB}
                        {LinkItem}
                    </div> */}
                </div>
            </div>
        );
    }
}
export default SD_Section4;

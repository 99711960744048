import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { Line } from "react-chartjs-2";
import Api from "./Api";
import Box from "./Box";
import BoxImage from "./BoxImage";
import BoxTitle from "./BoxTitle";
import BoxBtm from "./BoxBtm";
import classes from "./development.module.css";
import clases from "./dev.module.css";

class development extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                { id: 1, name: "title", content: "Lorem ipsum" },
                {
                    id: 2,
                    name: "image",
                    content: "Lorem ipsum",
                    asset: require("./assets/default-image.svg"),
                },
                {
                    id: 3,
                    name: "image background",
                    asset: require("./assets/default-image.svg"),
                },
            ],
            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: true,
            chartData: {},
            titleCor: null,
            activeImg: 0,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];
                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            type: item.is_internal,
                            url: item.url,
                            name: item.name,
                            sections: item,
                        });
                    });

                    const chartX = [];

                    let title = result.data.widgets[2].widget_contents[0]
                        ? result.data.widgets[2].widget_contents[0].content
                        : "title coordinat";

                    result.data.widgets[3].widget_contents.forEach((ele, index) => {
                        chartX.push(ele.content.split(" ", 2));
                        return chartX;
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                        titleCor: title,
                        chartData: chartX,
                    });
                })
                .catch((error) => {
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    toggleExtends = (i) => {
        this.setState({
            activeImg: i,
        });
    };

    render() {
        var options = {
            responsive: true,
            maintainAspectRatio: true,
            animation: {
                easing: "easeInOutQuad",
                duration: 520,
            },
            scales: {
                xAxes: [
                    {
                        gridLines: {
                            // color: 'rgba(200, 200, 200, 0.05)',
                            color: "rgba(20, 122, 214, 0.05)",
                            lineWidth: 1,
                        },
                    },
                ],
                yAxes: [
                    {
                        gridLines: {
                            // color: 'rgba(200, 200, 200, 0.08)',
                            color: "rgba(20, 122, 214, 0.1)",
                            lineWidth: 1,
                        },
                    },
                ],
            },
            elements: {
                line: {
                    tension: 0.4,
                },
            },
            legend: {
                display: false,
            },
            point: {
                backgroundColor: "black",
            },
            tooltips: {
                titleFontFamily: "Open Helvetica",
                backgroundColor: "rgba(0,0,0,0.8)",
                titleFontColor: "red",
                caretSize: 5,
                cornerRadius: 2,
                xPadding: 10,
                yPadding: 10,
            },
        };
        const { contentSection, dummy, chartData, isLoaded } = this.state;

        let title,
            dummy_title,
            img_article,
            box_article,
            dummy_box_article,
            title_banner,
            dataChart,
            titleChart,
            dummy_title_banner,
            dummy_image_art,
            gallery,
            titleCor,
            link, boxBtm = null;

        dummy_title = <p className={classes.title}>{dummy[0].content}</p>;
        dummy_image_art = (
            <img className={classes.imgContent} src={dummy[1].asset} alt="" />
        );

        dummy_box_article = (
            <div className={classes.mainBox}>
                <p className={classes.mainTitle}>Lorem ipsum</p>
                <div className={classes.insideBox}>
                    <p className={classes.text}>Lorem ipsum...</p>
                </div>
            </div>
        );

        dummy_title_banner = (
            <span className={classes.textInside}>
                <p>Example text</p>
            </span>
        );

        if (isLoaded) {
            let count = 0;
            contentSection.forEach((item) => {
                console.log(item)
                count = count + 1;
                if (count === 1 && item.index === 1 && item.category === "Article") {
                    if (item.type === false) {
                        box_article = <Box id={item.id} url={item.url} />;
                        img_article = <BoxImage id={item.id} />;
                        title = <BoxTitle id={item.id} />;
                        boxBtm = <BoxBtm id={item.id} />
                    }
                }
                if (count === 2 && item.index === 2 && item.category === "Text") {
                    item.sections.widget_contents.forEach((ele) => {
                        title_banner = (
                            <span className={classes.textInside}>
                                <p>{ele.content}</p>
                            </span>
                        );
                    });
                } else if (
                    count === 2 &&
                    item.index === 2 &&
                    item.category !== "Text"
                ) {
                    title_banner = dummy_title_banner;
                }
                if (count === 3 && item.index === 3 && item.category === "Text") {
                    item.sections.widget_contents.forEach((ele) => {
                        titleCor = ele.content;
                    });
                } else if (
                    count === 3 &&
                    item.index === 3 &&
                    item.category !== "Text"
                ) {
                    title_banner = dummy_title_banner;
                }
                if (count === 4 && item.index === 4 && item.category === "Text") {
                    let XX = [];
                    let YY = [];
                    chartData.map((n, index) => {
                        XX.push(n[0]);
                        YY.push(n[1]);
                    });

                    dataChart = (canvas) => {
                        const ctx = canvas.getContext("2d");
                        const gradient = ctx.createLinearGradient(0, 0, 0, 450);
                        gradient.addColorStop(0, "rgba(20, 122, 214, 0.5)");
                        gradient.addColorStop(0.5, "rgba(20, 122, 214, 0.25)");
                        gradient.addColorStop(1, "rgba(20, 122, 214, 0)");
                        return {
                            labels: XX,
                            datasets: [
                                {
                                    label: this.state.titleCor,
                                    backgroundColor: gradient,
                                    pointBackgroundColor: "white",
                                    borderWidth: 1,
                                    borderColor: "#147AD6",
                                    data: YY,
                                },
                            ],
                        };
                    };
                } else if (
                    count === 4 &&
                    item.index === 4 &&
                    item.category !== "Text"
                ) {
                    title_banner = dummy_title_banner;
                }

                if (count === 5 && item.index === 5 && item.category === "Text") {
                    item.sections.widget_contents.map((z) => {
                        titleChart = <p className={classes.titleChart}>{z.content}</p>;
                    });
                }

                if (count === 6 && item.index === 6 && item.category === "Media") {
                    gallery = item.sections.widget_contents.map((s, i) => {
                        let active = this.state.activeImg === i ? classes.activeImg : "";
                        return (
                            <div className={[classes.boxGallery, active].join(" ")} onClick={() => this.toggleExtends(i)}>
                                <figure>
                                    <img src={s.file} alt=""></img>
                                </figure>
                            </div>
                        );
                    });
                }

                if (count === 7 && item.index === 7 && item.category === "Text") {
                    link = item.sections.widget_contents.map((ele) => {
                        return (
                            <Link
                                className={classes.customLink}
                                to={item.sections.url ? item.sections.url : "/sustainable"}
                            >
                                <div className={"d-flex"}>
                                    <div className={"d-flex"}>
                                        <i
                                            className={[
                                                classes.arrow,
                                                classes.left,
                                                classes.customMargin,
                                            ].join(" ")}
                                        ></i>
                                    </div>
                                    <span className={classes.textBack}>
                                        {ele.content ? ele.content : "Back to prev"}
                                    </span>
                                </div>
                            </Link>
                        );
                    });
                } else if (
                    count === 7 &&
                    item.index === 7 &&
                    item.category !== "Text"
                ) {
                    link = (
                        <div className={classes.navBack}>
                            <Link className={classes.customLink} to={"/sustainable"}>
                                <div className={"d-flex"}>
                                    <div className={"d-flex"}>
                                        <i
                                            className={[
                                                classes.arrow,
                                                classes.left,
                                                classes.customMargin,
                                            ].join(" ")}
                                        ></i>
                                    </div>
                                    <span className={classes.textBack}>Back to prev</span>
                                </div>
                            </Link>
                        </div>
                    );
                }
            });
        }

        return (
            <div className="" style={{}}>
                <div className={classes.mainDev}>
                    <div className={classes.boxImage}>
                        {img_article}
                        <div className={classes.contentInBox}>
                            {title_banner}
                            <div className={[classes.p_0_underline]}></div>
                        </div>
                    </div>
                    <div className={["container", classes.boxC].join(" ")}>
                        {title}
                        <div className="d-flex">
                            <div className={clases.boxLeftx}>{box_article}</div>

                            <div className={clases.boxRightx}>
                                <div className={classes.boxChart}>
                                    {titleChart}
                                    <Line
                                        id="lineChart"
                                        ref={this.chartRef}
                                        data={dataChart}
                                        width={100}
                                        height={55}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                        {boxBtm}

                        <div className={classes.wrapGallery}>{gallery}</div>

                        {link}
                    </div>
                </div>
            </div>
        );
    }
}

export default development;

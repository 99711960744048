import React, { Component } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import ReactHtmlParser from "react-html-parser";
import Api from "./Api";
import dateFormat from "dateformat";

import classes from "./BlogView.module.css";
import BlogBox from "./BlogBox";
import BackNext from "./BackNext";

export default class BLD_Section1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogActive: 0,
      isBlog: false,
      contentArticle: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      id: "0",
      idSection: this.props.id,
    };
    // this.setActiveBlog = this.setActiveBlog.bind(this);
    this.getArticleDetailByArticleId = this.getArticleDetailByArticleId.bind(
      this
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let param = this.props.id;
    this.getArticleDetailByArticleId(param);
  }
  setActiveBlog() {
    if (this.props.location.search) {
      const params = this.props.location.search;
      let n = params.lastIndexOf("=");
      let result = params.substring(n + 1);
      this.setState({
        blogActive: result,
        isBlog: true,
      });
    } else {
      this.setState({
        blogActive: "0",
        isBlog: false,
      });
    }
  }

  getArticleDetailByArticleId(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetailByArticleId(param)
        .then((result) => {
          const articles = result.data;
          const art = [];
          const assets = [];
          articles.article_assets.forEach((ass, i) => {
            if (i === 0) {
              assets.push({
                asset: ass.asset_file,
              });
            }
          });

          art.push({
            id: articles.id,
            category: articles.category_name,
            name: articles.name,
            author_name: articles.author_name,
            author_avatar: articles.author_avatar,
            title: articles.article_languages.title,
            content: articles.article_languages.content,
            assets: assets,
            date: articles.date,
          });
          // })

          this.setState({
            contentArticle: art,
            isLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {
    const { blogActive, contentArticle } = this.state;
    let Title,
      Content,
      contentBlog,
      pagination,
      shareOn,
      dateNow = null;
    if (this.state.isLoaded) {
      let a = contentArticle;
      let nows = a[0].date;
      let now = new Date(nows);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let dateNow = now.toLocaleDateString("vi", options);

      // dateNow = dateFormat(now, "dd-mm-yyyy");

      Title = <p className={classes.title}>{a[0].title}</p>;
      Content = (
        <p className={classes.content}>{ReactHtmlParser(a[0].content)}</p>
      );
      pagination = (
        <BackNext />
        // <div className={classes.pagination}>
        //   <div className={classes.previous}>
        //     <FontAwesomeIcon
        //       color="white"
        //       className="mr-2"
        //       icon={faArrowLeft}
        //       size="xl"
        //     />
        //     <span className={classes.lblPagination}>Back</span>
        //   </div>
        //   <div className={classes.next}>
        //     <span className={classes.lblPagination}>Next blog</span>
        //     <FontAwesomeIcon
        //       color="white"
        //       className="ml-2"
        //       icon={faArrowRight}
        //       size="xl"
        //     />
        //   </div>
        // </div>
      );
      shareOn = (
        <BlogBox
          id={this.state.idSection}
          date={a[0].date}
          author_name={a[0].author_name}
          author_avatar={a[0].author_avatar}
        />
      );

      contentBlog = (
        <div className={["container", classes.blogs].join(" ")}>
          {pagination}

          <div className=" d-flex">
            <div className={classes.boxContent}>
              <p className={classes.blogTitle}>Blog Innovation</p>
              {Title}
              <p className={classes.date}>{dateNow}</p>
              {Content}
            </div>
            {shareOn}
          </div>
        </div>
      );
    }
    return (
      <div className="blog">
        <div className={classes.boxBanner}>
          <img src={require("./assets/image.png")} alt=""></img>
        </div>
        {/* <div className={classes.mainClass}> */}
        <ParallaxProvider>
          <React.Fragment>{contentBlog}</React.Fragment>
        </ParallaxProvider>
      </div>
      // </div>
    );
  }
}

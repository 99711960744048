import React, { Component } from "react";
import Api from "./Api";
import classes from "./Section3.module.css";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

export default class Box extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentArticle: [],
      aboutPhenikaa: [],
      isLoaded: false,
      isApiLoaded: true,
      activeSidebar: 0,
      box1: null,
      box2: null,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      this.getArticleDetail(this.props.id);
    }
  }
  changeActiveSidebar(key) {
    this.setState({ activeSidebar: key });
  }
  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }

  getArticleDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetail(param)
        .then((result) => {
          const articles = result.data;
          const assets = [];
          articles.results.forEach((a, index) => {
            if (index === 0) {
              assets.push({
                title: a.article_languages.title,
                content: a.article_languages.content,
                asset: a.article_assets[0].asset_file,
              });
            }
          });

          this.setState({
            contentArticle: assets,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {
    const { contentArticle, isLoaded } = this.state;
    let title,
      content,
      image_art = null;

    if (isLoaded) {
      contentArticle.map((z) => {
        if (z.title) {
          title = <p className={classes.title}>{z.title}</p>;
        } else {
          title = <p className={classes.title}>Title content</p>;
        }

        if (z.content) {
          content = (
            <p className={classes.content}>{ReactHtmlParser(z.content)}</p>
          );
        } else {
          content = (
            <p className={classes.content}>Content dummy lorem ipsum...</p>
          );
        }

        image_art = z.asset ? (
          <div className={classes.boxRight}>
            <img className={classes.contentImgRight} src={z.asset} alt=" " />
          </div>
        ) : (
          <div className={classes.boxRight}>
            <img
              className={classes.contentImgRight}
              src={require("./assets/default-image.svg")}
              alt=" "
            />
          </div>
        );
      });
    }
    return (
      <div className={["container", classes.boxC].join(" ")}>
        {title}
        <div className={classes.box}>
          <div className={classes.boxLeft}>
            {content}
            <Link
              to={"/sustainable-development/orientation"}
              className={classes.Link}
            >
              <div className={classes.readMore}>
                <div className={[classes.contentLink].join(" ")}>
                  <div className="d-flex">
                    <div className={classes.outerCircle}>
                      <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.contentLink}>Read More</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          {image_art}
        </div>
      </div>
    );
  }
}

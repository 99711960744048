import React, { Component } from "react";
import classes from "./Job_Details.module.css";
import Api from "./Api";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";

import {
  faArrowRight,
  faArrowLeft,
  faCircle,
  faMapMarkerAlt,
  faShareAlt,
  faBookmark,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-bootstrap";

export default class JobBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        widgets: [],
      },
      url: "",
      readMore: "Read More",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ Sections: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const data = [];
          console.log(sections);
          data.push({
            back: sections.widgets[0].widget_contents[0]
              ? sections.widgets[0].widget_contents[0].content
              : "Back",
            url: sections.widgets[0].url ? sections.widgets[0].url : "/",
          });

          this.setState({
            article: data,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { article } = this.state;
    let back,
      url,
      navLink = null;

    if (this.state.isLoaded) {
      back = article[0].back;
      url = article[0].url;
      navLink = (
        <div className={["d-flex", classes.marg].join(" ")}>
          <div className={"d-flex"}>
            <i
              className={[
                classes.arrow,
                classes.left,
                classes.customMargin,
              ].join(" ")}
            ></i>
          </div>
          <Link to={url} className={classes.customLink}>
            <span className={classes.textBack}>{back}</span>
          </Link>
        </div>
        // <div className={["container", classes.navLeft].join(" ")}>
        //   <FontAwesomeIcon className={classes.navIcon} icon={faArrowLeft} />
        //   <Link to={url} className={classes.customLink}>
        //     {back}
        //   </Link>
        // </div>
      );
    }
    return <>{navLink}</>;
  }
}

import React, { Component } from "react";
import classes from "./Job_Details.module.css";
import Api from "./Api";
import dateFormat from "dateformat";
import {
  faArrowRight,
  faArrowLeft,
  faCircle,
  faMapMarkerAlt,
  faShareAlt,
  faBookmark,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class JobBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        widgets: [],
      },
      url: "",
      readMore: "Read More",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ Sections: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const data = [];
          data.push({
            title: sections.widgets[1].widget_contents[0]
              ? sections.widgets[1].widget_contents[0].content
              : "Title",
          });

          this.setState({
            article: data,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { article } = this.state;
    let title = null;

    if (this.state.isLoaded) {
      title = article[0].title;
    }
    return (
      <div className={["container", classes.sectionTitle].join(" ")}>
        {/* {title} */}
        {/* <h2 className={classes.sectionTitle}>{title}</h2> */}
      </div>
    );
  }
}
